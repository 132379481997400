import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  HostListener,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  addDays,
  addMonths,
  endOfMonth,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import { CALENDAR_US_LOCALE } from '../../../../utils/datepicker.util';
import {
  CalendarUtils,
  DateInterval,
} from '../../../../libs/component-lib/utils/calendar.utils';
import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';
import { Booking } from '../../../../shared/models/booking';

type CalendarEventColor = 'primary' | 'secondary' | 'tertiary';

export interface CalendarEvent {
  interval: DateInterval;
  color: CalendarEventColor;
  booking: Booking;
  id?: string;
}

class CalendarView {
  month: Date;
  weeks: CalendarWeek[];
  events: CalendarViewEvent[];
}

class CalendarViewEvent {
  interval: DateInterval;
  color: CalendarEventColor;
  x: number;
  y: number;
  width: number;
  height: number;
  isStart: boolean;
  isEnd: boolean;
  originalEvent: CalendarEvent;
}

interface CalendarWeek {
  days: CalendarDay[];
}

interface CalendarDay {
  date: Date;
  number: number;
  isCurrentMonth: boolean;
  isToday: boolean;
  color: CalendarEventColor | undefined;
  event: CalendarEvent;
}

interface DayRect {
  x: number;
  y: number;
  width: number;
  height: number;
}
/**
 * @author Libor Staněk
 */
@Component({
  selector: 'app-user-reservations-calendar',
  templateUrl: './user-reservations-calendar.component.html',
  styleUrls: ['./user-reservations-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserReservationsCalendarComponent
  extends AbstractComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input()
  date: Date = new Date();
  @Input()
  events: CalendarEvent[];

  @Output()
  eventClick = new EventEmitter<CalendarEvent>();
  @Output()
  dateChange = new EventEmitter<Date>();

  @ViewChild('monthRef', { static: true })
  monthRef: ElementRef<HTMLDivElement>;

  view: CalendarView;
  dayNamesSingle = CALENDAR_US_LOCALE.dayNamesSingle;

  constructor(private readonly changeDetectionRef: ChangeDetectorRef) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.events) {
      this.refreshMonth();
    }
  }

  ngOnInit(): void {
    this.view = new CalendarView();
    this.view.month = startOfMonth(this.date);
    this.refreshMonth();
  }

  ngAfterViewInit(): void {
    this.refreshMonth();
  }

  @HostListener('window:resize')
  onResize() {
    this.refreshMonth();
  }

  private refreshMonth() {
    if (!this.view) {
      return;
    }
    this.view.weeks = this.generateWeeks();
    this.changeDetectionRef.detectChanges();
    this.view.events = this.generateViewEvents();
    this.changeDetectionRef.detectChanges();
  }

  private generateWeeks(): CalendarWeek[] {
    const month = this.view.month;
    const monthNumber = month.getMonth();
    const todayDate = new Date().getDate();
    const firstDay = startOfMonth(month);
    const lastDay = startOfDay(endOfMonth(month));
    const numberOfDaysInMonth = lastDay.getDate();
    const weeks: CalendarWeek[] = [];
    let currentWeek: CalendarWeek;
    const startOffset = firstDay.getDay();
    const endOffset = 6 - lastDay.getDay();
    const numberOfDays = startOffset + numberOfDaysInMonth + endOffset;
    const startDate = addDays(firstDay, -startOffset);
    const reversedArray = [...this.events].reverse();
    for (let i = 0; i < numberOfDays; i++) {
      if (i % 7 === 0) {
        currentWeek = {
          days: [],
        };
        weeks.push(currentWeek);
      }
      const date = addDays(startDate, i);
      const currentMonth = date.getMonth() === monthNumber;
      const dateNumber = date.getDate();
      const event = reversedArray.find(
        e => e.interval.start <= date && e.interval.end >= date,
      );
      const day: CalendarDay = {
        date: date,
        number: dateNumber,
        isCurrentMonth: currentMonth,
        isToday: currentMonth && todayDate === dateNumber,
        color: event?.color,
        event: event,
      };
      currentWeek.days.push(day);
    }
    return weeks;
  }

  private generateViewEvents(): CalendarViewEvent[] {
    const month = this.view.month;
    const viewEvents: CalendarViewEvent[] = [];
    this.events.forEach(event => {
      let weekIntervals = CalendarUtils.splitIntervalIntoWeeks(event.interval, {
        splitMonths: true,
      });
      weekIntervals = weekIntervals.filter(
        interval => interval.start.getMonth() === month.getMonth(),
      );
      // For each week interval, generate view interval
      weekIntervals.forEach(interval => {
        const startDayRect = this.getDayRect(interval.start);
        const endDayRect = this.getDayRect(interval.end);
        const x = startDayRect.x - 8;
        const y = startDayRect.y + 1;
        const width = endDayRect.x + endDayRect.width - startDayRect.x + 16;
        const height = 30;
        const isStart = interval.start === event.interval.start;
        const isEnd = interval.end === event.interval.end;
        const viewEvent: CalendarViewEvent = {
          x: x,
          y: y,
          width: width,
          height: height,
          color: event.color,
          interval: interval,
          isStart: isStart,
          isEnd: isEnd,
          originalEvent: event,
        };
        viewEvents.push(viewEvent);
      });
    });
    return viewEvents;
  }

  private getDayRect(date: Date): DayRect | undefined {
    if (date.getMonth() !== this.view.month.getMonth()) {
      return undefined;
    }
    const monthElement = this.monthRef.nativeElement;
    const dayIndex = this.view.month.getDay() + date.getDate() - 1;
    const dayElement =
      monthElement.children[Math.floor(dayIndex / 7) + 1].children[
        dayIndex % 7
      ];
    const monthElementRect = monthElement.getBoundingClientRect();
    const dayElementRect = dayElement.getBoundingClientRect();
    return {
      x: dayElementRect.left - monthElementRect.left,
      y: dayElementRect.top - monthElementRect.top,
      width: dayElementRect.width,
      height: dayElementRect.height,
    };
  }

  onDayClick(day: CalendarDay) {
    if (day.event) {
      this.eventClick.emit(day.event);
    }
  }

  onPrevMonth() {
    this.view.month = addMonths(this.view.month, -1);
    this.refreshMonth();
    this.dateChange.emit(this.view.month);
  }

  onNextMonth() {
    this.view.month = addMonths(this.view.month, 1);
    this.refreshMonth();
    this.dateChange.emit(this.view.month);
  }
}
