import {
  Inject,
  Injectable,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserService } from './user.service';
import { User } from '../../shared/models/user.model';
import { Booking } from '../../shared/models/booking';
import { addMinutes, isBefore } from 'date-fns';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../environments/environment';
import { first } from 'rxjs/operators';
import * as sha1 from 'js-sha1';
import { StorageService } from './storage.service';
import { StorageKey } from '../../shared/enums/local-storage-key.enum';
import { ToggleService } from '../modules/toggle/toggle.service';

/**
 * Service check if client is redirected from any of partner´s link provided by impact.
 * If does a few scripts need to be generated to send data about created bookings directly into impact to reward these partners.
 */
@Injectable({
  providedIn: 'root',
})
export class ImpactService {
  user$ = this.userService.getCurrentUser();
  private readonly renderer2: Renderer2;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly rendererFactory: RendererFactory2,
    private readonly storageService: StorageService,
    private readonly toggleService: ToggleService,
    @Inject(PLATFORM_ID) private readonly platformId: any,
  ) {
    this.renderer2 = rendererFactory.createRenderer(null, null);
  }

  init() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const impactPartnerId = this.route.snapshot.queryParams?.irclickid;
        if (impactPartnerId) {
          this.setPartnerId(impactPartnerId);
          this.listenOnToggleReady();
        } else {
          if (
            this.getPartnerId()?.id &&
            isBefore(new Date(), new Date(this.getPartnerId()?.expiration))
          ) {
            this.listenOnToggleReady();
          } else {
            this.removePartnerId();
          }
        }
        routerSubscription.unsubscribe();
      }
    });
  }

  listenOnToggleReady() {
    this.toggleService.toggleReady$.pipe(first()).subscribe(_value => {
      const impactEnabled = !!environment.enabledFeatures.impact;
      if (!impactEnabled) {
        return;
      }
      if (
        this.getPartnerId()?.id &&
        isBefore(new Date(), new Date(this.getPartnerId()?.expiration))
      ) {
        this.generateImpactUniversalTrackingTag();
      } else {
        const impactPartnerId = this.route.snapshot.queryParams?.irclickid;
        if (impactPartnerId) {
          this.setPartnerId(impactPartnerId);
          this.generateImpactUniversalTrackingTag();
        }
      }
    });
  }

  invokeUserSubscription() {
    this.user$.subscribe(user => {
      this.generateImpactIdentifyScript(user);
    });
  }

  private generateImpactUniversalTrackingTag() {
    const text = `
     (function(a,b,c,d,e,f,g){e['ire_o']=c;e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};f=d.createElement(b);
     g=d.getElementsByTagName(b)[0];f.async=1;f.src=a;g.parentNode.insertBefore(f,g);})
     ('https://utt.impactcdn.com/A2834440-86e0-4f11-be3e-2a925f335d551.js','script','ire',document,window);
    `;
    const script = this.generateScript(
      'text/javascript',
      'impact-universal-script',
      text,
    );
    this.renderer2.appendChild(document.head, script);
    this.invokeUserSubscription();
  }

  private generateImpactIdentifyScript(user?: User) {
    this.removeImpactIdentifyScript();
    let emailHashed = '';
    if (user && user.email?.emailAddress) {
      emailHashed = sha1(user.email.emailAddress);
    }
    const text = `ire('identify', {customerId: '${
      user ? user.id : ''
    }', customerEmail: '${emailHashed}'});`;
    const script = this.generateScript(
      'text/javascript',
      'impact-identify-script',
      text,
    );
    this.renderer2.appendChild(document.body, script);
  }

  generateTrackingImpactScript(booking: Booking) {
    if (!document.getElementById('impact-tracking-script')) {
      const eventTypeId = environment.impact.eventTypeId;
      const text = `
      ire('trackConversion', ${eventTypeId}, {
      orderId: "${booking.id}",
      customerId: "${
        booking.client.userId ? booking.client.userId : booking.client.clientId
      }",
      customerStatus: "${booking.client.userId ? 'Returning' : 'New'}",
      currencyCode: "USD",
      orderPromoCode: "",
      items: [
              {
              subTotal: "${
                Math.round(
                  (booking.totalPrice -
                    booking.clientPricing.taxes +
                    Number.EPSILON) *
                    100,
                ) / 100
              }",
              category: "bookings",
              sku: "${booking.id}",
              quantity: 1
              }
      ]
      },
      {
        verifySiteDefinitionMatch:true
      });
      `;
      const script = this.generateScript(
        'text/javascript',
        'impact-tracking-script',
        text,
      );
      this.renderer2.appendChild(document.body, script);
    }
  }

  setPartnerId(id: string) {
    const expirationTime = addMinutes(new Date(), 30);
    this.storageService.setItem(
      StorageKey.IMPACT_PARTNER_ID,
      JSON.stringify({
        id,
        expiration: expirationTime,
      }),
    );
  }

  getPartnerId() {
    return JSON.parse(
      this.storageService.getItem(StorageKey.IMPACT_PARTNER_ID),
    );
  }

  removePartnerId() {
    this.storageService.removeItem(StorageKey.IMPACT_PARTNER_ID);
  }

  private removeImpactIdentifyScript() {
    const impactIdentifyScript = document.getElementById(
      'impact-identify-script',
    );
    if (impactIdentifyScript) {
      this.renderer2.removeChild(document.body, impactIdentifyScript);
    }
  }

  removeTrackingImpactScript() {
    const impactTrackingScript = document.getElementById(
      'impact-tracking-script',
    );
    if (impactTrackingScript) {
      this.renderer2.removeChild(document.body, impactTrackingScript);
    }
  }

  generateScript(type: string, id: string, text: string) {
    const impactScript = this.renderer2.createElement('script');
    impactScript.type = type;
    impactScript.id = id;
    impactScript.text = text;
    return impactScript;
  }
}
