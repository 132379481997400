import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, of } from 'rxjs';
import { env as environment } from '../../dynamic-environment';
import { SKIP_AUTHORIZATION_HEADER } from '../interceptors/auth.interceptor';
import { map } from 'rxjs/operators';

export interface HomeFooterLink {
  category: string;
  text: string;
  url: string;
}

export interface Picks {
  [blockNumber: number]: PickBlock;
}

export interface PickBlock {
  categories: PickCategory[];
}

export interface PickCategory {
  title: string;
  subtitle?: string;
  description?: string;
  maxRows: number;
  items: CategoryLink[];
  showMoreButton?: {
    text: string;
    url: string;
  };
}
export type BadgeType = 'top_pick' | 'hot' | 'top_rated' | 'new';
interface PickItem {
  badgeType?: BadgeType;
}

export interface CategoryLink extends PickItem {
  label: string;
  imageUrl: string;
  url: string;
  subLabel?: string;
  price?: number;
  params?: string[];
  isProperty?: boolean;
  propertyId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  constructor(private readonly http: HttpClient) {}
  getPicks(): Observable<Picks> {
    return this.http
      .get<Picks>(`${environment.content.url}picks.json`, {
        headers: new HttpHeaders({
          [SKIP_AUTHORIZATION_HEADER]: '',
          'Cache-Control': 'no-store, no-cache',
        }),
      })
      .pipe(
        map(rawPicks => this.filterValidPicks(rawPicks)),
        catchError(() => of({})),
      );
  }

  private filterValidPicks(picks: Picks): Picks {
    const filteredPicks: Picks = {};

    for (const [position, block] of Object.entries(picks)) {
      filteredPicks[position] = {
        categories: block.categories.map(
          (category: { items: CategoryLink[] }) => ({
            ...category,
            items: category.items.filter(item =>
              this.isValidCategoryLink(item),
            ),
          }),
        ),
      };
    }

    return filteredPicks;
  }

  private isValidCategoryLink(item: CategoryLink): boolean {
    return (
      'label' in item &&
      'imageUrl' in item &&
      'url' in item &&
      item.label !== undefined &&
      item.imageUrl !== undefined &&
      item.url !== undefined
    );
  }

  getLinks(): Observable<HomeFooterLink[]> {
    return this.http
      .get<HomeFooterLink[]>(`${environment.content.url}links.json`, {
        headers: new HttpHeaders({
          [SKIP_AUTHORIZATION_HEADER]: '',
          'Cache-Control': 'no-store, no-cache',
        }),
      })
      .pipe(catchError(() => of([])));
  }
}
