import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../../core/core.module';
import { ButtonModule } from '@components/button/button.module';
import { IconModule } from '@components/icon/icon.module';
import { TypographyModule } from '@components/typography/typography.module';
import { NavbarComponent } from './components/navbar/navbar.component';
import { OverlayModule } from '@angular/cdk/overlay';
import {
  NavbarBackComponent,
  NavbarConfigComponent,
  NavbarContentComponent,
  NavbarPositionComponent,
  NavbarTabsComponent,
} from './components/navbar-config/navbar-config.component';
import { MenuUserPanelComponent } from '../navigation/menu-user-panel/menu-user-panel.component';

@NgModule({
  declarations: [
    NavbarComponent,
    NavbarConfigComponent,
    NavbarPositionComponent,
    NavbarContentComponent,
    NavbarTabsComponent,
    NavbarBackComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    ButtonModule,
    IconModule,
    TypographyModule,
    ReactiveFormsModule,
    RouterModule,
    MatDividerModule,
    OverlayModule,
    MenuUserPanelComponent,
  ],
  exports: [
    NavbarComponent,
    NavbarConfigComponent,
    NavbarPositionComponent,
    NavbarContentComponent,
    NavbarTabsComponent,
    NavbarBackComponent,
  ],
})
export class NavbarModule {}
