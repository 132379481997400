import { Booking } from 'src/app/shared/models/booking';
import { Property } from 'src/app/shared/models/property';

export enum ConversationParticipantType {
  SYSTEM = 'system',
  PROPERTY_OWNER = 'property_owner',
  CLIENT = 'client',
  GENERIC = 'generic',
}

export interface ConversationContext {
  propertyId?: string;
  bookingId?: string;
  lastOpenedAt?: Date;
}

export interface ConversationContextDetail {
  property: Property;
  booking: Booking;
  lastOpenedAt?: Date;
}

export interface ConversationParticipant {
  userId: string;
  type: ConversationParticipantType;
  lastReadAt: Date;
  archived: boolean;
  firstName: string;
  lastName: string;
  avatarUrl: string;
}

export interface Conversation {
  id: string;
  context: ConversationContext[];
  participants: ConversationParticipant[];
  lastMessageAt: Date;
  lastMessageText: string;

  // only frontend
  open: boolean;
}
