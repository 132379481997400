import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';
import { Component, Input } from '@angular/core';
import { Booking, BookingStatus } from '../../../../shared/models/booking';
import { isAfter } from 'date-fns';

@Component({
  selector: 'app-property-booking-header',
  template: ` <div class="d-flex align-items-center">
    <ng-container [ngSwitch]="booking?.status">
      <ng-container *ngSwitchCase="BookingStatus.AUTHORIZED">
        <icon variant="clock" color="tertiary" class="mr-8"></icon>
        <h2 typography variant="h4" color="tertiary">Vacay is Pending</h2>
      </ng-container>
      <ng-container *ngSwitchCase="BookingStatus.APPROVED">
        <ng-container *ngIf="isPastBooking; else pastBooking">
          <icon variant="check_circle" color="green" class="mr-8"></icon>
          <h2 typography variant="h4" color="green">Vacay is Confirmed</h2>
        </ng-container>
        <ng-template #pastBooking>
          <h2 typography variant="h4" color="primary">Past Booking</h2>
        </ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="BookingStatus.CANCELED">
        <icon variant="cross_circle" color="red" class="mr-8"></icon>
        <h2 typography variant="h4" color="red">Vacay was Canceled</h2>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <icon variant="message" color="blue" class="mr-8"></icon>
        <h2 typography variant="h4" color="blue">Inquiry</h2>
      </ng-container>
    </ng-container>
  </div>`,
})
export class PropertyBookingHeaderComponent extends AbstractComponent {
  @Input()
  booking: Booking;

  readonly BookingStatus = BookingStatus;

  get isPastBooking() {
    return isAfter(this.booking.interval.checkIn, new Date());
  }
}
