<div class="footer">
  <div class="footer-content">
    <div class="footer-row" *ngIf="vacayLinks.length !== 0">
      <div class="footer-row-title">
        <img src="/assets/img/vacay-white.svg" loading="lazy" alt="logo" height="35px" />
      </div>
      <div *ngFor='let link of vacayLinks' class="mt-8">
        <a typography variant="body1" color="white" [href]="[link.url]">{{link.text}}</a>
      </div>
      <div class="policy-links mt-24">
        <a [routerLink]="Url.TERMS_AND_CONDITIONS"><p typography variant="body3">Terms of Use</p></a>
        <a [routerLink]="Url.PRIVACY_POLICY"><p typography variant="body3">Privacy Policy</p></a>
      </div>
    </div>
    <div class="footer-row" *ngIf="vacayHosts.length !== 0">
      <p typography variant="h4" color="white" class="footer-row-title">Vacay Hosts</p>
      <div *ngFor='let link of vacayHosts' class="mt-8">
        <a typography variant="body1" color="white" [href]="[link.url]">{{link.text}}</a>
      </div>
    </div>
    <div class="footer-row" *ngIf="vacayDestinations.length !== 0">
      <p typography variant="h4" color="white" class="footer-row-title">Popular Destinations</p>
      <div *ngFor='let link of vacayDestinations' class="mt-8">
        <a typography variant="body1" color="white" [href]="[link.url]">{{link.text}}</a>
      </div>
    </div>
    <div class="footer-row">
      <p typography variant="h4" color="white" class="footer-row-title">Keep me posted</p>
      <form [formGroup]="form" (submit)="submit($event)">
          <input-text [formControl]="form.controls.email" label="Email" data-test="email-subscribe"></input-text>
          <button button type="submit" color="primary" variant="contained">Sign Me Up</button>
      </form>
      <div class="policy-links">
        <p typography variant="body3">By signing up you agree to our <a [routerLink]="Url.PRIVACY_POLICY">Privacy Policy</a></p>
      </div>

      <p typography variant="h4" color="white" class="footer-row-title">Follow us on social media</p> 
      <div class="social-links">
        <a href="https://www.facebook.com/VacayMyWay/" rel="noopener" target="_blank"><img loading="lazy" src="/assets/img/social/facebook.svg" alt="facebook"/></a>
        <a href="https://www.instagram.com/vacaymywayofficial/" rel="noopener" target="_blank"><img loading="lazy" src="/assets/img/social/instagram.svg" alt="instagram" /></a>
        <a href="https://www.linkedin.com/company/vacaymyway/" rel="noopener" target="_blank"><img loading="lazy" src="/assets/img/social/linkedin.svg" alt="linkedin" /></a>
      </div>
    </div>
  </div>
</div>

<div class="post-footer">
  <p typography variant="body2" color="black">Copyright {{ copyrightYear }} © VacayMyWay. All Rights Reserved. </p>
</div>
