import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { IconColor, IconVariant } from '@components/icon/icon.component';

export type ButtonVariant = 'contained' | 'text' | 'inline';
type ButtonSize = 'normal' | 'small';
export type ButtonColor =
  | 'primary'
  | 'secondary'
  | 'white'
  | 'critical'
  | 'warning';

/**
 * @see https://www.figma.com/file/4URZYURZBcwfpYp3HvkSL6/Web-components-VMW?node-id=5088%3A20036
 * @see https://www.figma.com/file/4URZYURZBcwfpYp3HvkSL6/Web-components-VMW?node-id=4879%3A19422
 * @author Libor Staněk
 */
@Component({
  selector: '[button]',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <icon
      *ngIf="icon"
      [variant]="icon"
      size="inherit"
      [color]="iconColor"
      class="button-icon button-icon-center"
    ></icon>
    <icon
      *ngIf="startIcon"
      [variant]="startIcon"
      size="inherit"
      [color]="iconColor"
      class="button-icon button-icon-start"
    ></icon>
    <ng-content></ng-content>
    <icon
      *ngIf="endIcon"
      [variant]="endIcon"
      size="inherit"
      [color]="iconColor"
      class="button-icon button-icon-end"
    ></icon>
  `,
})
export class ButtonComponent implements OnInit, OnChanges {
  @Input()
  variant: ButtonVariant = 'contained';
  @Input()
  size: ButtonSize = 'normal';
  @Input()
  color: ButtonColor = 'primary';
  @Input()
  icon: IconVariant;
  @Input()
  startIcon: IconVariant;
  @Input()
  endIcon: IconVariant;
  @Input()
  iconColor: IconColor = 'inherit';

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    const element = this.elementRef.nativeElement as HTMLButtonElement;
    this.renderer.addClass(element, `button2`);
    this.renderer.addClass(element, `button-variant-${this.variant}`);
    this.renderer.addClass(element, `button-size-${this.size}`);
    this.renderer.addClass(element, `button-color-${this.color}`);
  }

  ngOnChanges(changes: SimpleChanges) {
    const element = this.elementRef.nativeElement;
    if (changes.variant) {
      this.renderer.removeClass(
        element,
        `button-variant-${changes.variant.previousValue}`,
      );
      this.renderer.addClass(
        element,
        `button-variant-${changes.variant.currentValue}`,
      );
    }
    if (changes.size) {
      this.renderer.removeClass(
        element,
        `button-size-${changes.size.previousValue}`,
      );
      this.renderer.addClass(
        element,
        `button-size-${changes.size.currentValue}`,
      );
    }
    if (changes.color) {
      this.renderer.removeClass(
        element,
        `button-color-${changes.color.previousValue}`,
      );
      this.renderer.addClass(
        element,
        `button-color-${changes.color.currentValue}`,
      );
    }
  }
}
