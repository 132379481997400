import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'dialog-header',
  template: `
    <div class="dialog-header">
      <h2 typography variant="h4" class="dialog-header-title">
        {{ title }}
      </h2>
      <ng-content></ng-content>
      <button
        *ngIf="showClose && dialogClose.observers.length"
        button
        type="button"
        variant="inline"
        color="secondary"
        icon="cross"
        title="Close dialog"
        aria-label="close dialog"
        class="p-4"
        data-test="button-close"
        (click)="closeDialog()"
      ></button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogHeaderComponent {
  @Input() title = '';
  @Input() showClose = true;

  @Output()
  dialogClose = new EventEmitter();

  closeDialog() {
    this.dialogClose.emit();
  }
}
