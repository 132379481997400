import { Booking } from '../../../../shared/models/booking';

export enum PropertyEventType {
  BOOKING = 'booking',
  BLOCK_RESERVATION = 'block-reservation',
  CALENDAR_SOURCE = 'calendar-source',
  EXTERNAL_BLOCK = 'external_block',
  EXTERNAL_BOOKING = 'external_booking',
  // Only on frontend
  COLLISION = 'collision',
}

export interface PropertyEvent {
  type: PropertyEventType;
  id: string;
  start: Date;
  end: Date;
  calendarSourceId?: string;
  propertyOwnerNote?: string;
  userId?: string;
  payload?: {
    booking?: Booking;
    userMemberSince?: Date;
    userAvatarUrl?: string;
    calendarSource?: {
      name: string;
    };
    // Only on frontend
    collision?: {
      events: PropertyEvent[];
    };
  };
}
