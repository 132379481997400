import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Message, MessageType } from '../models/message.model';
import { Subject } from 'rxjs';
import { PagedModel } from '../../../shared/models/pagination';
import { filter, map, take, tap } from 'rxjs/operators';
import { GatewayService } from '../../../core/services/gateway.service';
import {
  ChatMessageGatewayEvent,
  GatewayEventType,
} from '../../../core/model/gateway-event';
import {
  ChatApi,
  GetConversationsOptions,
  GetMessagesOptions,
} from '../../../core/api/chat.api';
import {
  InquiryInfo,
  InquiryMessage,
  TextMessage,
} from '../models/text-message.model';
import { Conversation } from '../models/conversation.model';
import { ChatStore } from './chat.store';
import { BookingMessage } from '../models/booking-message.model';
import { ChatUtils } from '../utils/chat.utils';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private readonly messagesSubject: Subject<ChatMessageGatewayEvent>;
  private readonly inquiryInfoSubject: BehaviorSubject<InquiryInfo>;

  messages$: Observable<ChatMessageGatewayEvent>;
  inquiryInfo$: Observable<InquiryInfo>;

  constructor(
    private readonly chatApi: ChatApi,
    private readonly chatStore: ChatStore,
    private readonly gatewayService: GatewayService,
  ) {
    this.messagesSubject = new Subject<ChatMessageGatewayEvent>();
    this.messages$ = this.messagesSubject.asObservable();

    this.inquiryInfoSubject = new BehaviorSubject<InquiryInfo>(null);
    this.inquiryInfo$ = this.inquiryInfoSubject.asObservable();

    this.listenForMessages();
  }

  clearInquiryInfo() {
    this.inquiryInfoSubject.next(null);
  }

  setInquiryInfo(inquiryInfo: InquiryInfo) {
    this.inquiryInfoSubject.next(inquiryInfo);
  }

  listenForMessages() {
    this.gatewayService.fromEvent(ChatMessageGatewayEvent).subscribe(event => {
      const { message } = event;
      if (message.type === MessageType.INQUIRY) {
        this.chatStore.updatePropertyInContext(
          event.conversationId,
          (message as InquiryMessage).payload.propertyId,
        );
      } else if (ChatUtils.isBookingMessage(message)) {
        this.chatStore.updateBookingInContext(
          event.conversationId,
          (message as BookingMessage).payload.bookingId,
        );
      }

      this.messagesSubject.next(event);
    });
  }

  getConversations(
    options: GetConversationsOptions,
  ): Observable<PagedModel<Conversation>> {
    return this.chatApi.getConversations(options);
  }

  getConversation(conversationId: string): Observable<Conversation> {
    this.inquiryInfo$.pipe(take(1)).subscribe(inquiryInfo => {
      if (inquiryInfo && inquiryInfo.conversationId !== conversationId) {
        this.clearInquiryInfo();
      }
    });

    return this.chatApi.getConversation({ conversationId });
  }

  getMessages(options: GetMessagesOptions): Observable<PagedModel<Message>> {
    return this.chatApi.getMessages(options);
  }

  subscribeMessages(conversationId: string): Observable<Message> {
    return this.messagesSubject.pipe(
      filter(event => event.conversationId === conversationId),
      map(event => event.message),
    );
  }

  sendTextMessage(
    conversationId: string,
    type: MessageType.TEXT | MessageType.INQUIRY,
    text: string,
    inquiryInfo: InquiryInfo,
  ): Observable<TextMessage | InquiryMessage> {
    if (type === MessageType.INQUIRY) {
      this.clearInquiryInfo();
    }

    return this.chatApi
      .sendTextMessage({ conversationId, text, type, ...inquiryInfo })
      .pipe(
        tap(message => {
          this.messagesSubject.next({
            conversationId: conversationId,
            message: message,
            type: GatewayEventType.CHAT_MESSAGE,
            userId: undefined,
          });
        }),
      );
  }
}
