import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  Injector,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInputComponent } from '@components/abstract/abstract-input.component';
import { InputSuffixDirective } from '@components/input/input-suffix.directive';
import { InputPrefixDirective } from '@components/input/input-prefix.directive';
import { IConfig } from 'ngx-mask';

@Component({
  selector: 'input-number',
  styleUrls: ['../input.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputNumberComponent,
      multi: true,
    },
  ],
  template: `
    <mat-form-field subscriptSizing="dynamic">
      <mat-label>{{ label }}{{ required ? '*' : '' }}</mat-label>
      <input
        #input
        matInput
        [formControl]="control"
        [placeholder]="placeholder"
        [readonly]="readonly"
        [mask]="mask"
        [patterns]="maskPatterns"
        [prefix]="maskPrefix"
        [suffix]="maskSuffix"
        [attr.data-test]="dataTest"
        [errorStateMatcher]="errorStateMatcher"
        (input)="onInput($event)"
      />
      <mat-error *ngIf="errorMessage$ | async as errorMessage">
        {{ errorMessage }}
      </mat-error>
    </mat-form-field>
  `,
})
export class InputNumberComponent
  extends AbstractInputComponent<number>
  implements OnInit, AfterContentInit
{
  @Input()
  placeholder: string;
  @Input()
  readonly: boolean;
  @Input()
  required: boolean;

  /** Input value prefix, i.e. '$' sign. */
  @Input() maskPrefix = '';
  /** Input value suffix, i.e. '%' sign. */
  @Input() maskSuffix = '';
  /** Allowed decimal length of input number. 0 by default. */
  @Input() maxDecimalLength = 0;
  /** Input data test */
  @Input() dataTest?: string;

  @Input()
  mask = 'F*';
  @Input()
  maskPatterns: IConfig['patterns'] = {
    0: { pattern: /[0-9]/ },
    9: { pattern: /[0-9]/, optional: true },
    F: { pattern: new RegExp('[0-9,.\\-]') },
  };

  @ViewChild('input', { static: false })
  input: ElementRef<HTMLInputElement>;

  @ContentChild(InputPrefixDirective, { static: false })
  inputPrefix: InputPrefixDirective;

  @ContentChild(InputSuffixDirective, { static: false })
  inputSuffix: InputSuffixDirective;

  /** Last valid input value as string to revert to */
  lastInputValue = '';

  constructor(
    injector: Injector,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly cdRef: ChangeDetectorRef,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.renderer.addClass(this.elementRef.nativeElement, 'input2');
    this.renderer.addClass(this.elementRef.nativeElement, 'input-text');
  }

  focus() {
    this.input.nativeElement.focus();
  }

  onInput(event: any) {
    const newValue = event.target.value;
    if (
      event.inputType === 'deleteContentBackward' ||
      event.inputType === 'deleteContentForward'
    ) {
      this.setInputValue(newValue);
      return;
    }
    let tempNewValue = newValue;
    if (tempNewValue.startsWith(this.maskPrefix)) {
      tempNewValue = tempNewValue.substr(this.maskPrefix.length);
    }
    if (tempNewValue.endsWith(this.maskSuffix)) {
      tempNewValue = tempNewValue.substr(
        0,
        tempNewValue.length - this.maskSuffix.length,
      );
    }
    const regExp: RegExp = new RegExp(
      `^-?[0-9]*${
        this.maxDecimalLength > 0
          ? `([,.][0-9]{0,${this.maxDecimalLength}})`
          : ``
      }?$`,
    );
    if (!tempNewValue.match(regExp)) {
      this.setInputValue(this.lastInputValue);
    } else {
      this.setInputValue(newValue);
    }
  }

  setInputValue(value: string) {
    const processed = this.processValue(value);
    if (processed?.endsWith('.')) {
      return;
    }
    this.lastInputValue = value;
    const numValue = +processed;
    this.control.setValue(isNaN(numValue) ? undefined : numValue, {
      onlySelf: true,
      emitEvent: false,
    });
    this.writeValue(isFinite(numValue) ? numValue : undefined);
  }

  override writeValue(value: number): void {
    super.writeValue(value);
    setTimeout(() => this.cdRef.markForCheck(), 0);
  }

  /**
   * Strip prefix and suffix, replace decimal separator with '.' if ',' is used.
   * @param s string value of field for processing
   */
  private processValue(s: string): string {
    if (s === undefined || s === null) {
      return s;
    }
    if (s === '') {
      return undefined;
    }
    s = s.replace(/,/g, '.');
    if (s.startsWith(this.maskPrefix)) {
      s = s.substr(this.maskPrefix.length);
    }
    if (s.endsWith(this.maskSuffix)) {
      s = s.substr(0, s.length - this.maskSuffix.length);
    }
    return s;
  }
}
