import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from '../../../../../../core/services/dialog.service';
import { BookingService } from '../../../../../../core/services/booking.service';
import { BookingCancellationRefund } from '../../../../../../shared/models/booking';
import { BreakpointService } from '../../../../../../core/services/breakpoint.service';
import { Property } from '../../../../../../shared/models/property';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from '../../../../../../core/services/notification.service';
import { AbstractComponent } from '../../../../../../core/components/abstract/abstract.component';
import { BlockUiService } from '../../../../../../core/services/block-ui.service';
import {
  GAData,
  GoogleAnalytics4Service,
} from '../../../../../../core/services/google-analytics4.service';
import { Url } from '../../../../../../url';
import { isRefundRuleExpired } from 'src/app/utils/refund.util';
import {
  UserReservationCancelReasons,
  UserReservationCancelReasonsOptions,
} from './user-reservation-cancel-reasons';

@Component({
  selector: 'app-user-reservation-cancel-dialog',
  templateUrl: './user-reservation-cancel-dialog.component.html',
  styleUrls: ['./user-reservation-cancel-dialog.component.scss'],
})
export class UserReservationCancelDialogComponent extends AbstractComponent {
  bookingId: string;

  refundRule: BookingCancellationRefund;

  property: Property;

  largeScreen$ = this.bs.largeScreen$;

  form: FormGroup = new FormGroup(
    {
      reason: new FormControl('', Validators.required),
      message: new FormControl(''),
    },
    { validators: this.customMessageRequired() },
  );

  UserReservationCancelReasons = UserReservationCancelReasons;
  reasonsOptions = UserReservationCancelReasonsOptions;

  readonly isRefundRuleExpired = isRefundRuleExpired;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private readonly dialog: DialogService,
    private readonly bookingService: BookingService,
    private readonly bs: BreakpointService,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
    private readonly blockUiService: BlockUiService,
    protected readonly ga4: GoogleAnalytics4Service,
  ) {
    super();
    this.bookingId = data.bookingId;
    this.refundRule = data.refundRule;
    this.property = data.property;
  }

  customMessageRequired(): ValidatorFn {
    return (form: AbstractControl): { [key: string]: any } | null => {
      const reason = form.get('reason')?.value;
      const message = form.get('message')?.value;

      // If the reason is anything other than 'other', the message is required
      if (reason === UserReservationCancelReasons.OTHER && !message) {
        form.get('message')?.setErrors({ required: true });
        return { messageRequired: true };
      } else {
        form.get('message')?.setErrors(null);
        return null;
      }
    };
  }

  closeDialog() {
    this.dialog.clear();
  }

  get currentReason(): UserReservationCancelReasons {
    return this.form.get('reason').value;
  }

  cancel() {
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();

    if (this.form.valid) {
      let selectedReason = this.form.get('reason').value;
      const reasonName =
        selectedReason === UserReservationCancelReasons.OTHER
          ? this.form.get('message').value
          : UserReservationCancelReasonsOptions.find(
              r => r.value === selectedReason,
            )?.name;

      this.bookingService
        .cancelBooking(this.bookingId, reasonName)
        .pipe(this.untilDestroyed(), this.blockUiService.blockPipe())
        .subscribe(res => {
          const data: GAData = {
            bookingStatus: res.status,
            dateFrom: res.interval.checkIn,
            dateTo: res.interval.checkOut,
            totalPrice: res.totalPrice,
            currency: res.currency,
            property: this.property,
          };
          // google analytics 4
          this.ga4.sendBookingInfo(data);

          this.closeDialog();
          this.router.navigate([Url.VACAYS]);
          this.notificationService.showNotification({
            color: 'success',
            text: 'Booking successfully canceled.',
          });
        });
    }
  }
}
