import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { BreakpointService } from '../../../../../core/services/breakpoint.service';
import { AbstractComponent } from '../../../../../core/components/abstract/abstract.component';

@Component({
  selector: 'app-line-item',
  template: `
    <div
      class="d-flex justify-content-between align-items-center mt-8"
      [class.variant-discount]="variant === 'discount'"
      [class.variant-reward-points]="variant === 'reward-points'"
    >
      <div class="d-flex align-items-center">
        <span typography variant="body2">
          <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>
        </span>
        <ng-container *ngIf="hintTemplate">
          <icon
            #hintTooltip="tippy"
            variant="info"
            [tp]="hintTooltipContent"
            [tpPlacement]="popperPlacement"
            [tpDuration]="0"
            [tpAppendTo]="'parent'"
            class="ml-4"
          ></icon>
          <ng-template #hintTooltipContent>
            <div (click)="hintTooltip.hide()">
              <ng-container [ngTemplateOutlet]="hintTemplate"></ng-container>
            </div>
          </ng-template>
        </ng-container>
      </div>
      <ng-container [ngTemplateOutlet]="valueTemplate"></ng-container>
    </div>
  `,
  styleUrls: ['./booking-pricing.component.scss'],
})
export class LineItemComponent extends AbstractComponent implements OnInit {
  popperPlacement: 'right' | 'bottom' = 'right';

  @Input() labelTemplate: TemplateRef<any>;
  @Input() valueTemplate: TemplateRef<any>;
  @Input() hintTemplate?: TemplateRef<any>;
  @Input() variant: 'normal' | 'discount' | 'reward-points' = 'normal';

  constructor(
    private readonly bs: BreakpointService,
    private cdRef: ChangeDetectorRef,
  ) {
    super();
  }
  ngOnInit(): void {
    this.bs.largeScreen$.pipe(this.untilDestroyed()).subscribe(ls => {
      this.popperPlacement = ls ? 'right' : 'bottom';
      this.cdRef.detectChanges();
    });
  }
}
