import { Booking, BookingStatus } from 'src/app/shared/models/booking';
import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Property } from 'src/app/shared/models/property';
import { PropertyEventService } from '../../property-dashboard/services/property-event.service';
import { addMonths } from 'date-fns';
import { BehaviorSubject, Observable, map, take } from 'rxjs';
import { CalendarEvent } from 'src/app/modules/user/shared/user-reservations-calendar/user-reservations-calendar.component';
import { PropertyEventType } from '../../property-dashboard/models/property-event';
import { Router } from '@angular/router';

@Component({
  selector: 'app-property-availability',
  template: `<div *ngIf="calendarEventsLoaded$ | async">
    <span typography variant="h5">Property availability</span>
    <div class="mt-8">
      <app-user-reservations-calendar
        (eventClick)="onEventClick($event)"
        [date]="calendarDate"
        [events]="(calendarEvents$ | async) || []"
      ></app-user-reservations-calendar>
    </div>
    <div
      *ngIf="calendarEvents$ | async as calendarEvents"
      class="d-flex flex-wrap mr-8"
    >
      <div *ngIf="booking" class="d-flex align-items-center">
        <div class="legend-color tertiary mx-8"></div>
        Current booking
      </div>
      <div
        *ngIf="includeBooked(calendarEvents)"
        class="d-flex align-items-center mr-8"
      >
        <div class="legend-color secondary mx-8"></div>
        Booked
      </div>
      <div
        *ngIf="includeBlocked(calendarEvents)"
        class="d-flex align-items-center mr-8"
      >
        <div class="legend-color primary mx-8"></div>
        Blocked
      </div>
    </div>
  </div>`,
  styleUrl: './property-availability.component.scss',
})
export class PropertyAvailabilityComponent
  extends AbstractComponent
  implements OnChanges
{
  @Input()
  property: Property;
  @Input()
  booking: Booking;

  calendarEvents$: Observable<CalendarEvent[]>;
  calendarEventsSubject: BehaviorSubject<CalendarEvent[]>;

  calendarEventsLoaded$: Observable<boolean>;
  calendarEventsLoadedSubject: BehaviorSubject<boolean>;

  constructor(
    private readonly propertyEventService: PropertyEventService,
    private readonly router: Router,
  ) {
    super();
    this.calendarEventsSubject = new BehaviorSubject<CalendarEvent[]>([]);
    this.calendarEvents$ = this.calendarEventsSubject.asObservable();
    this.calendarEventsLoadedSubject = new BehaviorSubject<boolean>(false);
    this.calendarEventsLoaded$ =
      this.calendarEventsLoadedSubject.asObservable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (
        changes.booking?.currentValue !== changes.booking?.previousValue ||
        changes.property?.currentValue !== changes.property?.previousValue
      ) {
        this.calendarEventsLoadedSubject.next(false);
        this.propertyEventService
          .getPropertyEvents(this.property.id, {
            start: new Date(),
            end: addMonths(new Date(), 12),
          })
          .pipe(
            take(1),
            map(propertyEvents => {
              return propertyEvents
                .filter(propertyEvent => {
                  if (propertyEvent.type === PropertyEventType.BOOKING) {
                    if (
                      propertyEvent.payload.booking.status ===
                        BookingStatus.CANCELED &&
                      propertyEvent.payload.booking.id !== this.booking?.id
                    ) {
                      return false;
                    }
                  }
                  return true;
                })
                .map(propertyEvent => {
                  let color = 'secondary';
                  const eventBooking = propertyEvent.payload?.booking;
                  if (
                    propertyEvent.type === PropertyEventType.BOOKING &&
                    eventBooking.id === this.booking?.id
                  ) {
                    color = 'tertiary';
                  } else if (
                    propertyEvent.type === PropertyEventType.BLOCK_RESERVATION
                  ) {
                    color = 'primary';
                  }

                  return {
                    interval: {
                      start: propertyEvent.start,
                      end: propertyEvent.end,
                    },
                    booking: propertyEvent.payload?.booking,
                    color,
                    id: eventBooking ? eventBooking.id : propertyEvent.id,
                  } as CalendarEvent;
                });
            }),
          )
          .subscribe(events => {
            this.calendarEventsSubject.next(events);
            this.calendarEventsLoadedSubject.next(true);
          });
      }
    }
  }

  includeBooked(calendarEvents: CalendarEvent[]) {
    return calendarEvents?.some(c => c.color === 'secondary');
  }

  includeBlocked(calendarEvents: CalendarEvent[]) {
    return calendarEvents?.some(c => c.color === 'primary');
  }

  get calendarDate() {
    return this.booking?.interval.checkIn || new Date();
  }

  onEventClick(event: CalendarEvent) {
    if (event.id) {
      this.router.navigate([
        '/properties',
        this.property.id,
        'dashboard',
        event.id,
      ]);
    }
  }
}
