
<div class="menu-header">
  <div class="d-flex flex-wrap">
    <ng-container *ngIf="user$ | async as user; else loggedOut">
      <avatar
        class="d-sm-block d-none mr-4" size="small"
        hoverDisabled="true"
        [src]="user.avatarUrl"
      >{{ user | userInitials }}</avatar>
      <div class="menu-user-info">
        <app-user-email-verification-info [user]="user" variant="h5"></app-user-email-verification-info>
      </div>
    </ng-container>
    <ng-template #loggedOut>
      <div typography variant="h5" color="ink" class="menu-user-info">User Account</div>
    </ng-template>

    <button type="button" class="menu-close" (click)="onCloseMenu()">
      <icon variant="chevron_left" size="20"></icon>
    </button>
  </div>

  <div *ngIf="(user$ | async) === null" class="mt-16 pr-24">
    <a
      button
      variant="contained"
      color="primary"
      class="menu-button-header"
      (click)="onSignIn()"
    >
      Sign In/Up
    </a>
  </div>
</div>
