import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API_USER_BASE } from '../../url';

/** Phone API endpoint path. */
const USER_PHONE_API_BASE = API_USER_BASE + '/phone';

export interface PhoneVerificationIntent {
  id?: string;
  phoneNumber: string;
  expires?: Date;
}

export interface PhoneVerification {
  verificationCode: string;
  phoneVerificationId?: string;
}

/**
 * Phone service communicating with phone API endpoints to verify unauthorized users.
 */
@Injectable({
  providedIn: 'root',
})
export class PhoneService {
  constructor(private readonly http: HttpClient) {}

  /** Send request to verify phone number.
   */
  verifyPhoneNumber(phoneVerification: PhoneVerification): Observable<boolean> {
    return this.http.patch<boolean>(USER_PHONE_API_BASE, phoneVerification);
  }

  /** Send request to verify phone number. */
  createPhoneVerificationIntent(
    phoneVerificationIntent: PhoneVerificationIntent,
  ): Observable<PhoneVerificationIntent> {
    return this.http.post<PhoneVerificationIntent>(
      USER_PHONE_API_BASE,
      phoneVerificationIntent,
    );
  }

  resendPhoneVerification(phoneIntent: PhoneVerificationIntent) {
    return this.http.post(
      `${USER_PHONE_API_BASE}/verify-phone/resend`,
      phoneIntent,
    );
  }
}
