import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  BookingDeclinedMessage,
  BookingDeclinedReason,
} from 'src/app/modules/chat/models/booking-message.model';
import { Booking } from 'src/app/shared/models/booking';

@Component({
  selector: 'app-chat-message-booking-declined',
  templateUrl: './chat-message-booking-declined.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageBookingDeclinedComponent {
  @Input() conversationId: string;
  @Input() message: BookingDeclinedMessage;

  booking: Booking;
  @Input() set bookings(value: Booking[]) {
    this.booking = value.find(v => v.id === this.message.payload.bookingId);
  }

  get isTooltipEnabled() {
    return this.message.payload.declinedReason !== undefined;
  }

  getDeclinedText(reason: BookingDeclinedReason) {
    switch (reason) {
      case BookingDeclinedReason.EXPIRED:
        return 'Time for approving has expired';
      case BookingDeclinedReason.PROPERTY_OWNER:
        return 'Canceled by property owner';
    }
  }
}
