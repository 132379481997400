import { Injectable, TemplateRef } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import {
  MatDialogRef,
  MatDialogConfig,
  MatDialog,
} from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private readonly openDialogsCountSubject: BehaviorSubject<number>;
  openDialogsCount$: Observable<number>;

  constructor(private readonly matDialog: MatDialog) {
    this.openDialogsCountSubject = new BehaviorSubject<number>(
      this.matDialog.openDialogs.length,
    );
    this.openDialogsCount$ = this.openDialogsCountSubject.asObservable();
    this.matDialog.afterAllClosed.subscribe(() => {
      this.updateOpenDialogsCount();
    });
  }

  clear(): void {
    this.matDialog.closeAll();
  }

  open(
    componentOrTemplateRef: ComponentType<any> | TemplateRef<any>,
    config?: MatDialogConfig,
  ): MatDialogRef<any> {
    const dialogRef = this.matDialog.open(componentOrTemplateRef, {
      backdropClass: 'dialog-background',
      minWidth: '100%',
      position: {
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
      ...config,
    });
    this.updateOpenDialogsCount();
    return dialogRef;
  }

  clearAndOpen(
    componentOrTemplateRef: ComponentType<any> | TemplateRef<any>,
    config?: MatDialogConfig,
  ): MatDialogRef<any> {
    this.clear();
    return this.open(componentOrTemplateRef, config);
  }

  updateOpenDialogsCount() {
    const total = this.matDialog.openDialogs.length;
    if (this.openDialogsCountSubject.getValue() === total) {
      return;
    }
    this.openDialogsCountSubject.next(total);
  }
}
