import { NgModule } from '@angular/core';
import { StarsComponent } from './components/stars.component';
import { CommonModule } from '@angular/common';
import { TypographyModule } from '@components/typography/typography.module';
import { IconModule } from '@components/icon/icon.module';

@NgModule({
  declarations: [StarsComponent],
  imports: [CommonModule, TypographyModule, IconModule],
  exports: [StarsComponent],
})
export class StarsLegacyModule {}
