import { Pipe, PipeTransform } from '@angular/core';
import * as Stripe from '@stripe/stripe-js';

@Pipe({
  name: 'formatAvailablePaymentMethods',
})
export class AvailablePaymentMethodsPipe implements PipeTransform {
  transform(result: Stripe.CanMakePaymentResult, ...args: any[]) {
    if (result.applePay && result.googlePay) {
      return 'Pay with Apple Pay or Google Pay';
    } else if (result.applePay) {
      return 'Pay with Apple Pay';
    } else if (result.googlePay) {
      return 'Pay with Google Pay';
    }
  }
}
