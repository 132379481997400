import { Property, ProtectionVariant } from './property';
import { ClientInfo, HostInfo } from './user.model';
import { Address } from './address.model';
import { BookingTaxJurisdiction } from './booking-taxes';
import { BookingFee, BookingFees } from './booking-fees';

export enum BookingStatus {
  DRAFT = 'draft',
  AWAITING_FOR_PAYMENT = 'awaiting_for_payment',
  AUTHORIZED = 'authorized',
  APPROVED = 'approved',
  CANCELED = 'canceled',
}

export enum BookingReservationStatus {
  UPCOMING = 'upcoming',
  HISTORY = 'history',
  CANCELLED = 'cancelled',
}

export enum BookingActor {
  PROPERTY_OWNER = 'property_owner',
  CLIENT = 'client',
  SYSTEM = 'system',
}

export enum BookingAction {
  CREATED = 'created',
  PAYMENT_CREATED = 'payment_created',
  PAYMENT_AUTHORIZED = 'payment_authorized',
  PAYMENT_CANCELED = 'payment_canceled',
  PAID = 'paid',
  REFUNDED = 'refunded',
  APPROVED = 'approved',
  DECLINED = 'declined',
  CANCELED = 'canceled',
  COMPLETED = 'completed',
}

class ReferrerInfo {
  partnerId: string;
}

export class CreateBookingRequest {
  propertyId: string;
  interval: BookingInterval;
  guests: number;
  numberOfPets: number;
  referrer?: ReferrerInfo;
}

export class PricingDetail {
  stayPrice: number;
  cleaningFee: number;
  taxes: number;
}

export class BookingPricing {
  currency: string;
  taxRatePercent: number;
  feesDetail?: BookingFees;
  discounts?: BookingDiscounts;
  totalPrice: number;
  clientPricing: PricingDetail;
  totalRevenue: number;
}

export class BookingCalendarInfoInput {
  propertyId: string;
  interval: BookingInterval;
}

export interface BookingCalendarInfoDay {
  /**
   * number if day is available for reservation
   * true if day is available only for check-out
   * false if day is not available
   */
  price: number | boolean;
  date: Date;
  checkInBefore: Date;
}

export class BookingCalendarInfoResponse {
  days: BookingCalendarInfoDay[];
}

export class BookingCancellationRefund {
  dateBefore: Date;
  name?: string;
  taxesRefunded: number;
  totalAmount: number;
}

export class Booking {
  id: string;
  locked?: boolean;
  propertyId: string;
  property: Property;
  // todo remove when auto registration in booking will be active
  client: ClientInfo;
  host?: HostInfo;
  interval: BookingInterval;
  guests: number;
  pets?: boolean;
  numberOfPets: number;
  instantBooking: boolean;
  address: Address;
  status: BookingStatus;

  currency: string;
  discounts?: BookingDiscounts;
  taxRatePercent: number;
  totalPrice: number;
  clientPricing: PricingDetail;
  clientRewardAmount?: number;

  totalRevenue?: number;
  vacayFee?: number;
  propertyOwnerPricing?: PricingDetail;
  taxesDetail?: BookingTaxJurisdiction[];
  feesDetail?: BookingFee[];

  cancellationPolicy: {
    name: string;
    refundRules?: BookingCancellationRefund[];
  };
  refundedAmount?: number;
  authorizedAt?: Date;
  history?: BookingEvent[];
  protection?: BookingProtection;
  autoWithdrawal?: BookingAutoWithdrawal;
  expiresAt?: Date;
}

export interface BookingInfo extends Booking {
  cancelDetail: BookingCancelDetail;
  cancelable: boolean;
}

export class BookingAutoWithdrawal {
  bankAccountId: string;
}

export class BookingDiscounts {
  rewardPoints?: RewardPointDiscount;
  weeklyDiscount?: LengthOfStayDiscount;
  monthlyDiscount?: LengthOfStayDiscount;
}

interface LengthOfStayDiscount extends Discount {
  discountPercent: number;
}

export interface Discount {
  discountAmount: number;
}

interface RewardPointDiscount extends Discount {
  rewardPoints: number;
}

export class BookingProtection {
  isProtected: boolean;
  variant: ProtectionVariant;
}
export class BookingEvent {
  createdAt: Date;
  actor: BookingActor;
  action: BookingAction;
  message?: string;
}

export class BookingAveragePriceResponse {
  averagePrice: number;
}

export class PropertyPublicPricing {
  minimalPrice: number;
  weeklyDiscountPercent: number;
  monthlyDiscountPercent: number;
}

export class BookingInterval {
  checkIn: Date;
  checkOut: Date;
}

export class BookingPayRequest {
  address: Address;
  messageToPO: string;
}

export class BookingPayRequestResponse {
  bookingId: string;
  clientSecret?: string; // Stripe payment secret
}

export interface UpdateBookingRequest {
  interval?: BookingInterval;
  guests?: number;
  pets?: boolean;
  numberOfPets?: number;
  client?: ClientInfo;
  impactPartnerId?: string;
  rewardPointAmountUsed?: number;
}

export interface BookingCancelDetail {
  cancelAllowedBefore: Date;
}
