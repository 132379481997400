import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PartnersDialogComponent } from '../../../partners/components/partners-dialog/partners-dialog.component';
import { DialogService } from '../../../../core/services/dialog.service';
import { ALL_PARTNERS, PartnerInfo } from '../../../partners/partners.model';

@Component({
  selector: 'app-home-partners',
  templateUrl: './home-partners.component.html',
  styleUrls: ['./home-partners.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePartnersComponent {
  readonly partners: PartnerInfo[] = ALL_PARTNERS;
  constructor(private readonly dialogService: DialogService) {}

  openDialogInfo(partner: PartnerInfo) {
    this.dialogService.clearAndOpen(PartnersDialogComponent, {
      data: {
        partner: partner,
      },
    });
  }
}
