import { NgModule } from '@angular/core';
import { PropertySearchDestinationDialogComponent } from '@components/property-search-dialog/property-search-destination-dialog.component';
import { PropertySearchCalendarDialogComponent } from '@components/property-search-dialog/property-search-calendar-dialog.component';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@components/dialog/dialog.module';
import { ButtonModule } from '@components/button/button.module';
import { InputLocationModule } from '@components/input-location/input-location.module';
import { InputCalendarComponent } from '@components/input-calendar/input-calendar.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TypographyModule } from '@components/typography/typography.module';

@NgModule({
  declarations: [
    PropertySearchDestinationDialogComponent,
    PropertySearchCalendarDialogComponent,
  ],
  exports: [],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TypographyModule,
    DialogModule,
    ButtonModule,
    InputLocationModule,
    InputCalendarComponent,
  ],
})
export class PropertySearchDialogModule {}
