import { ConfirmationDialogData } from '@components/confirmation-dialog/confirmation-dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';

export function createBookingLockedDialogConfig(
  operationName: string,
): MatDialogConfig<ConfirmationDialogData> {
  return {
    data: {
      dialogHeader: 'Booking was locked by the admin',
      confirmationText: `Booking was locked and cannot be ${operationName} at the moment. If you think this is an error, please contact our support.`,
      confirmButton: {
        text: 'Ok',
      },
      cancelButton: null,
      dialogWidth: 'sm',
    },
  };
}
