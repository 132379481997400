import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';
import { Component, Input } from '@angular/core';
import {
  Booking,
  BookingInfo,
  BookingStatus,
} from '../../../../shared/models/booking';
import { environment } from 'src/environments/environment';
import { BreakpointService } from 'src/app/core/services/breakpoint.service';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { createBookingLockedDialogConfig } from '../../property-dashboard/utils/booking-locked-dialog.utils';
import { DialogService } from 'src/app/core/services/dialog.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-booking-payout-details',
  template: `
    <div class="d-flex justify-content-between align-items-center mb-16">
      <span typography variant="h5">{{ title }}</span>
      <a *ngIf="invoiceEnabled" button variant="inline" size="small"
        >View Invoice</a
      >
    </div>
    <app-booking-pricing
      [booking]="booking"
      [isPropertyOwner]="isPropertyOwner"
    ></app-booking-pricing>

    <div *ngIf="isPropertyOwner">
      <ng-container *ngIf="!booking.cancelable; else bookingCancelableTemplate">
        <div
          *ngIf="refundDisabled === false; else refundPeriodOver"
          class="d-flex align-items-center mt-16"
        >
          <button
            button
            variant="contained"
            color="secondary"
            data-test="issue-refund"
            [size]="(largeScreen$ | async) === true ? 'small' : 'normal'"
            class="w-100 mr-8"
            (click)="navigateRefund(booking)"
          >
            Issue a Refund
          </button>
          <a
            button
            variant="clear"
            data-test="how-refund-works"
            [size]="(largeScreen$ | async) === true ? 'small' : 'normal'"
            class="w-100 ml-8"
            href="https://help.vacaymyway.com/article/20-refunds"
            target="_blank"
            >How Refunds Work</a
          >
        </div>
        <ng-template #refundPeriodOver>
          <div *ngIf="booking.status === BookingStatus.APPROVED">
            <div class="divider my-8"></div>
            <div typography variant="body2" color="ink">
              The refund period for this Vacay is over.
              <a
                button
                variant="inline"
                size="small"
                href="https://help.vacaymyway.com/article/20-refunds"
                target="_blank"
                >How refunds work</a
              >
            </div>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #bookingCancelableTemplate>
        <div *ngIf="isPropertyOwner">
          <div class="divider my-8"></div>
          <div typography variant="body2" color="ink">
            Refunds for this booking will become available after
            <strong>{{
              booking.cancelDetail.cancelAllowedBefore.toDateString()
            }}</strong
            >.
            <a
              button
              variant="inline"
              color="primary"
              href="https://help.vacaymyway.com/article/20-refunds"
              target="_blank"
              >How refunds work</a
            >
          </div>
        </div>
      </ng-template>
    </div>
  `,
})
export class BookingPayoutDetailsComponent extends AbstractComponent {
  @Input()
  booking: BookingInfo;
  @Input()
  refundDisabled: boolean = true;
  @Input()
  isPropertyOwner: boolean = true;

  readonly invoiceEnabled = !!environment.enabledFeatures.invoice;
  readonly BookingStatus = BookingStatus;

  largeScreen$ = this.breakpointService.largeScreen$;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly breakpointService: BreakpointService,
    private readonly dialogService: DialogService,
  ) {
    super();
  }

  get title() {
    return this.isPropertyOwner ? 'Payout Details' : 'Payment details';
  }

  navigateRefund(booking: Booking): void {
    if (booking.locked) {
      this.dialogService.open(
        ConfirmationDialogComponent,
        createBookingLockedDialogConfig('refunded'),
      );
    } else {
      this.router.navigate([booking.id, 'refund'], { relativeTo: this.route });
    }
  }
}
