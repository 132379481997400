<div class="chat-context">
  <div
    *ngIf="(openedContext$| async) == null && (conversationIdContextLoaded$ | async) === (currentConversationId$ | async)">
    <div class="mx-16 my-24">
      <app-chat-recipient *ngIf="recipient$ | async as recipient" [recipient]="recipient"></app-chat-recipient>
    </div>
    <div class="mx-16 mt-24">
      <app-chat-context-list [conversationId]="conversation.id" [context]="conversation.context"
        [properties]="properties$ | async" [bookings]="bookings$ | async"></app-chat-context-list>
    </div>
  </div>

  <div>
    <div *ngIf="openedContext$ | async as openedContext" [ngSwitch]="openedContext.type">
      <app-chat-context-inquiry-detail *ngSwitchCase="ContextType.INQUIRY" [currentUser]="user$ | async"
        [recipient]="recipient$ | async" [conversationId]="conversation.id"
        [property]="openedContext.property"></app-chat-context-inquiry-detail>
      <app-chat-context-booking-detail *ngSwitchCase="ContextType.BOOKING" [currentUser]="user$ | async"
        [recipient]="recipient$ | async" [conversationId]="conversation.id" [booking]="openedContext.booking"
        [property]="openedContext.booking.property"></app-chat-context-booking-detail>
    </div>
  </div>
</div>