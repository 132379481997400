import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';
import { UserService } from '../../../../core/services/user.service';
import {
  MenuInfo,
  NavbarPosition,
  NavbarService,
} from '../../services/navbar.service';

/**
 * Main app menu
 *
 * @author Libor Staněk, Radek Chocholouš
 */
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent
  extends AbstractComponent
  implements OnInit, OnChanges
{
  readonly user$ = this.userService.getCurrentUser();
  menuInfo$: Observable<MenuInfo>;

  @Input()
  position: NavbarPosition;
  @Input()
  contentFullWidth: boolean;
  @Input()
  contentNoGutters: boolean;
  @Input()
  contentRef: TemplateRef<any>;
  @Input()
  tabsRef: TemplateRef<any>;

  contentEnabled: boolean;
  tabsEnabled: boolean;

  constructor(
    private readonly userService: UserService,
    private readonly navbarService: NavbarService,
    private readonly changeDetectionRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
    this.menuInfo$ = this.navbarService.menuInfo$;
    this.navbarService.navbarSettings$
      .pipe(this.untilDestroyed())
      .subscribe(menuSettings => {
        this.position = menuSettings.position;
        this.contentFullWidth = menuSettings.contentFullWidth;
        this.contentNoGutters = menuSettings.contentNoGutters;
        this.contentRef = menuSettings.contentRef;
        this.contentEnabled = !!this.contentRef;
        this.tabsRef = menuSettings.tabsRef;
        this.tabsEnabled = !!this.tabsRef;
        this.changeDetectionRef.detectChanges();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contentRef) {
      this.contentEnabled = !!this.contentRef;
    }
    if (changes.tabsRef) {
      this.tabsEnabled = !!this.tabsRef;
    }
  }

  openMobileMenu() {
    this.navbarService.openMobileMenu();
  }
}
