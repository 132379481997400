import { ConversationParticipant } from './conversation.model';

export enum MessageType {
  TEXT = 'text',
  INQUIRY = 'inquiry',
  BOOKING_CREATED = 'BookingCreated',
  BOOKING_AUTHORIZED = 'BookingAuthorized',
  BOOKING_APPROVED = 'BookingApproved',
  BOOKING_CANCELED = 'BookingCanceled',
  BOOKING_REFUNDED = 'BookingRefunded',
  BOOKING_DECLINED = 'BookingDeclined',
}

export interface Message {
  id: string;
  type: MessageType;
  senderId: string | null;
  sentAt: Date;
  replies: Message[];
}

export interface MessageData extends Message {
  sender: MessageSender;
  currentUserId: string;
}

export interface MessageSender extends ConversationParticipant {
  isCurrentUser: boolean;
}
