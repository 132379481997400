import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { compareDesc } from 'date-fns';
import {
  ConversationContext,
  ConversationContextDetail,
} from 'src/app/modules/chat/models/conversation.model';
import { Booking } from 'src/app/shared/models/booking';
import { Property } from 'src/app/shared/models/property';

@Component({
  selector: 'app-chat-context-list',
  templateUrl: 'chat-context-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatContextListComponent {
  @Input() conversationId: string;
  @Input() context: ConversationContext[];
  @Input() bookings: Booking[];
  @Input() properties: Property[];

  get contextDetails(): ConversationContextDetail[] {
    return [...this.context]
      .sort((a, b) => {
        if (!a.lastOpenedAt) {
          return 1;
        }
        if (!b.lastOpenedAt) {
          return -1;
        }

        return compareDesc(a.lastOpenedAt, b.lastOpenedAt);
      })
      .map(context => {
        const booking = context.bookingId
          ? this.bookings.find(b => b.id === context.bookingId)
          : null;

        let property: Property = null;
        if (booking) {
          property = booking.property;
        } else if (context.propertyId) {
          property = this.properties.find(p => p.id === context.propertyId);
        }

        return {
          lastOpenedAt: context.lastOpenedAt,
          property,
          booking,
        };
      })
      .filter(context => {
        return context.booking || context.property;
      });
  }
}
