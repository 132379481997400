import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToggleService } from './toggle.service';

function toggleServiceFactory(toggleService: ToggleService) {
  return () => toggleService.init();
}

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: toggleServiceFactory,
      multi: true,
      deps: [ToggleService],
    },
    ToggleService,
  ],
})
export class ToggleModule {}
