import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../../core/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyWizardService } from '../../../core/services/property-wizard.service';
import { AbstractComponent } from '../../../core/components/abstract/abstract.component';
import { NotificationService } from '../../../core/services/notification.service';
import { RouteService } from '../../../core/services/route.service';
import { BlockUiService } from '../../../core/services/block-ui.service';

/**
 * Handler component for email verifying
 */
@Component({
  selector: 'app-email-verifying',
  template: '',
})
export class EmailVerifyingHandlerComponent
  extends AbstractComponent
  implements OnInit, OnDestroy
{
  private redirectionTimeout;

  constructor(
    private readonly userService: UserService,
    private readonly route: ActivatedRoute,
    private readonly propertyWizardService: PropertyWizardService,
    private readonly notificationService: NotificationService,
    private readonly blockUi: BlockUiService,
    private readonly routeService: RouteService,
    private readonly router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    const email = this.route.snapshot.fragment;
    this.userService
      .updateEmailVerified(email)
      .pipe(this.untilDestroyed(), this.blockUi.blockPipe())
      .subscribe(() => {
        if (this.propertyWizardService.isPropertyWizardEmailRedirect()) {
          this.propertyWizardService.navigatePropertyWizardEmailRedirect();
        } else {
          this.redirectToHomepage();
        }
        this.notificationService.showNotification({
          text: 'Your email has been verified',
          color: 'success',
        });
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    clearTimeout(this.redirectionTimeout);
  }

  private redirectToHomepage() {
    this.redirectionTimeout = setTimeout(() => {
      this.router.navigate(['']);
    }, 5000);
  }
}
