import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';
import { LandingPageService } from '../../../../core/services/landing-page.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-home-inspiration',
  templateUrl: './home-inspiration.component.html',
  styleUrl: './home-inspiration.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeInspirationComponent extends AbstractComponent {
  readonly blogs$ = this.landingPageService.getBlogs(2).pipe(
    catchError(e => {
      console.warn('Cannot fetch blogs: ', e);
      return EMPTY;
    }),
    shareReplay(),
  );
  readonly isPlatformBrowser = isPlatformBrowser(this.platformId);

  constructor(
    private readonly landingPageService: LandingPageService,
    @Inject(PLATFORM_ID) private readonly platformId: any,
  ) {
    super();
  }
}
