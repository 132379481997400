import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  startOfDay,
  differenceInDays,
  differenceInHours,
  isAfter,
  isBefore,
} from 'date-fns';
import {
  Booking,
  BookingCancellationRefund,
} from '../../../../../shared/models/booking';

@Component({
  selector: 'app-cancellation-policy-chart',
  templateUrl: './cancellation-policy-chart.component.html',
  styleUrls: ['./cancellation-policy-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancellationPolicyChartComponent implements OnInit {
  @Input() booking: Booking;

  @Input() refundRule: BookingCancellationRefund;

  /** Value for refund part of chart - specifies length of this part in hours */
  amountOfRefundHours: number;

  /** Value for no refund part of chart - specifies length of this part in hours */
  amountOfNoRefundHours: number;

  /** Specifies position of pointer */
  space: number;

  refundable: boolean;
  today = new Date();

  ngOnInit(): void {
    this.refundable = !!isBefore(this.today, this.refundRule?.dateBefore);

    if (this.refundable) {
      this.amountOfRefundHours = differenceInHours(
        this.refundRule?.dateBefore,
        this.booking.authorizedAt,
      );
    } else {
      this.amountOfNoRefundHours = differenceInHours(
        this.booking.interval.checkIn,
        this.refundRule?.dateBefore,
      );
    }
    this.getPointerPosition();
  }

  getPointerPosition() {
    if (this.refundable) {
      if (isBefore(this.today, this.booking.authorizedAt)) {
        this.space = 0;
        return;
      }
      const pastHours = differenceInHours(
        this.today,
        this.booking.authorizedAt,
      );
      this.space = (pastHours / this.amountOfRefundHours) * 100;
    } else {
      if (isAfter(this.today, this.booking.interval.checkIn)) {
        this.space = 100;
        return;
      }
      const pastHours = differenceInHours(
        this.today,
        this.refundRule?.dateBefore,
      );

      this.space = (pastHours / this.amountOfNoRefundHours) * 100;
    }
  }

  getRefundLengthTitle() {
    switch (this.refundRule.name) {
      case 'Instant refund': {
        return '48 hours to refund';
      }
      case 'Full refund': {
        const days = differenceInDays(
          startOfDay(this.booking.interval.checkIn),
          startOfDay(this.refundRule.dateBefore),
        );
        return `${days} days prior`;
      }
    }
  }
}
