import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-login-forgotten-route',
  template: ` <div class="mx-16 mt-24 mt-md-64">
    <app-reset-password [isDialog]="false"></app-reset-password>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginForgottenRouteComponent {}
