import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@components/icon/icon.module';
import { TippyDirective } from '@ngneat/helipopper';
import { ChatStore } from '../../chat/services/chat.store';
import { Url } from '../../../url';
import { RouterLink } from '@angular/router';
import { ButtonModule } from '@components/button/button.module';
import { CoreModule } from '../../../core/core.module';
import { BadgeModule } from '@components/badge/badge.module';

@Component({
  standalone: true,
  selector: 'app-messages-count-icon',
  templateUrl: './messages-count-icon.component.html',
  styleUrls: ['./messages-count-icon.component.scss'],
  imports: [
    CommonModule,
    CoreModule,
    IconModule,
    TippyDirective,
    RouterLink,
    ButtonModule,
    BadgeModule,
  ],
})
export class MessagesCountIconComponent {
  readonly unreadConversations$ = this.chatStore.unreadConversationIds$;

  constructor(private readonly chatStore: ChatStore) {}

  getChatLink(unreadConversations: string[]) {
    if (unreadConversations?.length > 0) {
      return [Url.CHAT_(unreadConversations[0])];
    } else {
      return [Url.CHAT];
    }
  }
}
