<div class="conversations">
  <div class="conversations-header d-flex justify-content-between align-items-baseline">
    <h1 typography variant="h4">Inbox</h1>
    <button cdkOverlayOrigin button startIcon="filter" variant="inline" data-test="chat-filter" #filterTrigger="cdkOverlayOrigin" (click)="toggleFilter()">
      <span typography>Filter</span>
    </button>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="filterTrigger"
      [cdkConnectedOverlayOpen]="filterOpen"
      [cdkConnectedOverlayPositions]="[{originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top'}]"
      (overlayOutsideClick)="toggleFilter()"
    >
      <div class="dropdown-menu">
        <ng-container *ngFor="let type of filterOptions">
          <button class="menu-item d-block p-12" data-test="filter-option" (click)="setFilter(type)" [class.active]="filter === type">{{type | startCase}}</button>
        </ng-container>
      </div>
    </ng-template>
  </div>
  <div class="conversation-list" (scroll)="onListScroll($event)" #listRef>
    <ng-container *ngIf="conversations$ | async as conversations; else loading">
      <div class="conversation" data-test="conversation" *ngFor="let conversationItem of conversations" [class.active]="conversationItem.id === conversation?.id" >
        <a [routerLink]="[Url.CHAT_(conversationItem.id)]" queryParamsHandling="merge">
          <div class="d-flex">
            <avatar [src]="conversationItem.recipient.avatarUrl" class="conversation-avatar" [hoverDisabled]="true" [cursorPointer]="true">{{conversationItem.recipient | userInitials}}</avatar>
            <div class="conversation-context">
              <div class="d-flex">
                <span typography variant="em2" class="conversation-name">{{conversationItem.recipient | userFullName}}</span>
                <span class="read-indicator ml-auto" *ngIf="conversationItem.hasUnreadMessages"></span>
              </div>
              <div class="d-flex mt-4 justify-content-between">
                <span typography variant="body2" class="conversation-text">{{conversationItem.lastMessageText}}</span>
                <span typography variant="body2" class="conversation-date">{{conversationItem.lastMessageAt | formatConversationDate}}</span>
              </div>
              <div class="d-flex mt-4">
                <span typography variant="body2" [color]="conversationItem.infoColor">{{conversationItem.infoText}}</span>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div *ngIf="conversations.length <= 0">
        <p typography variant="body1" class="text-center">
          <ng-container *ngIf="filter === ConversationFilterType.ALL; else filtered">
            No conversations
          </ng-container>
          <ng-template #filtered>
            No conversations for selected filter
          </ng-template>
        </p>
      </div>
    </ng-container>
    <ng-template #loading>
      <div class="d-flex justify-content-center mt-20">
        <mat-spinner></mat-spinner>
      </div>
    </ng-template>
  </div>
</div>
