import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button/button.module';
import { TypographyModule } from '@components/typography/typography.module';
import { DialogModule } from '@components/dialog/dialog.module';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { IconModule } from '@components/icon/icon.module';

@NgModule({
  declarations: [ConfirmationDialogComponent],
  imports: [
    CommonModule,
    DialogModule,
    ButtonModule,
    IconModule,
    TypographyModule,
  ],
  exports: [ConfirmationDialogComponent],
})
export class ConfirmationDialogModule {}
