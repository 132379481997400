import {
  Inject,
  Injectable,
  Injector,
  OnDestroy,
  PLATFORM_ID,
} from '@angular/core';
import type * as SocketIoModule from 'ngx-socket-io';
import type { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { BehaviorSubject, from } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
} from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from './auth.service';
import { GatewayEvent } from '../model/gateway-event';
import { convertDatesInObject } from '../../utils/date.util';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class GatewayService implements OnDestroy {
  socketModule$: Observable<typeof SocketIoModule>;
  socket$: BehaviorSubject<Socket>;

  connectingSocket: Socket;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private readonly injector: Injector,
    private readonly authService: AuthService,
    private readonly userService: UserService,
  ) {
    this.socket$ = new BehaviorSubject<Socket>(null);
    if (isPlatformBrowser(this.platformId)) {
      this.socketModule$ = from(import('ngx-socket-io')).pipe(shareReplay());
      // Handle user login and logout, disconnect websocket, when user logouts
      this.authService.tokenChanges$
        .pipe(
          distinctUntilChanged(
            (prev, curr) => prev?.accessToken === curr?.accessToken,
          ),
        )
        .subscribe(tokenData => {
          if (tokenData) {
            this.connect(tokenData?.accessToken);
          } else {
            this.disconnect();
          }
        });
      this.userService
        .getCurrentUser()
        .pipe(
          distinctUntilChanged((prev, curr) => prev?.id === curr?.id),
          filter(user => !!user),
        )
        .subscribe(() => {
          const socket = this.socket$.getValue();
          const accessToken = this.authService.getRawTokenData()?.accessToken;
          if (!socket && accessToken) {
            this.connect(accessToken);
          }
        });
    }
  }

  ngOnDestroy() {
    this.disconnect();
  }

  /**
   * Create new connection to websocket
   * Also disconnects current active websocket
   */
  private connect(accessToken: string) {
    this.disconnect();
    this.socketModule$.pipe(take(1)).subscribe(module => {
      const socket = new module.Socket({
        url: undefined,
        options: {
          path: '/gateway/api/v1/socket.io/',
        },
      });
      this.connectingSocket = socket;
      // Wait for login response
      socket
        .fromEvent('subscribe-response')
        .pipe(take(1))
        .subscribe(data => {
          const response: { status: 'ok' | 'error' } = JSON.parse(
            data as string,
          );
          this.connectingSocket = null;
          if (response.status === 'ok') {
            this.setActiveSocket(socket);
          } else {
            socket.disconnect();
          }
        });
      // Send user login credentials to backend
      socket.emit('subscribe', JSON.stringify({ accessToken: accessToken }));
    });
  }

  /**
   * Disconnect current websocket
   */
  private disconnect() {
    if (this.connectingSocket) {
      this.connectingSocket.disconnect();
    }
    const socket = this.socket$.getValue();
    if (socket) {
      socket.disconnect();
    }
    this.setActiveSocket(null);
  }

  private setActiveSocket(socket: Socket) {
    this.socket$.next(socket);
  }

  /**
   * Listen for websocket events by type
   */
  fromEvent<T extends GatewayEvent>(eventType: new () => T): Observable<T> {
    const type = new eventType().type;
    if (!type) {
      throw new Error(
        `fromEvent object type ${eventType?.name} must have valid type property`,
      );
    }
    return this.socket$.pipe(
      filter(socket => !!socket),
      switchMap(socket => socket.fromEvent<T>('event')),
      map(event => convertDatesInObject(event)),
      filter(event => event.type === type),
    );
  }
}
