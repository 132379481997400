import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';

export function updateControls(form: UntypedFormGroup) {
  Object.keys(form.controls).forEach(control => {
    if (form.controls[control] instanceof UntypedFormGroup) {
      updateControls(form.controls[control] as UntypedFormGroup);
    } else {
      form.controls[control].updateValueAndValidity();
    }
  });
}

export function markAsSubmitted(form: UntypedFormGroup) {
  Object.keys(form.controls).forEach(control => {
    if (form.controls[control] instanceof UntypedFormGroup) {
      markAsSubmitted(form.controls[control] as UntypedFormGroup);
    } else {
      (form.controls[control] as SubmittableFormControl).submitted = true;
    }
  });
}

export function markControlAsSubmitted(control: UntypedFormControl) {
  (control as SubmittableFormControl).submitted = true;
}

export interface SubmittableFormControl extends UntypedFormControl {
  submitted: boolean;
}

export function updateValueAndValidity(element: AbstractControl) {
  if (element instanceof UntypedFormControl) {
    element.updateValueAndValidity();
    (element as SubmittableFormControl).submitted = true;
  } else if (element instanceof UntypedFormGroup) {
    element.markAllAsTouched();
    const controls = element.controls;
    Object.keys(controls).forEach(key => {
      updateValueAndValidity(controls[key] as AbstractControl);
    });
  }
}

export function sanitizeForm(element: AbstractControl): void {
  if (element instanceof UntypedFormControl) {
    const value = element.value;
    if (typeof value === 'string' && value.trim() === '') {
      element.setValue(null);
    }
  } else if (element instanceof UntypedFormGroup) {
    const controls = element.controls;
    Object.keys(controls).forEach(key => {
      sanitizeForm(controls[key]);
    });
  }
}
