<ng-container *ngIf="processedCategories$ | async as processedCategories">
<div class="picks" *ngFor="let category of processedCategories">
  <div class="picks-info">
    <app-home-section-header
      [title]="category.title"
      [subtitle]="category.subtitle"
      [description]="category.description">
    </app-home-section-header>
  </div>

  <div *ngFor="let row of category.rows" class="picks-container mt-20">
    <app-home-picks
      [picks]="row"
      [descriptionTemplate]="descriptionTemplate">
    </app-home-picks>
  </div>

    <a *ngIf="category.showMoreButton"
       button
       color="primary"
       [href]="category.showMoreButton.url"
       class="picks-more-button">
      {{ category.showMoreButton.text }}
    </a>
  </div>

  <ng-template #descriptionTemplate let-data="data">
    <div class="top-vacay-template">
      @if(data.isProperty){
        <p typography variant="h5" class="d-block">
          {{ data.label }}
        </p>
      } @else {
        <p typography variant="h5" color="primary" class="d-flex align-items-center">
          <span class="mr-8">{{ data.label }}</span>
          <icon variant="arrow_right"></icon>
        </p>
      }
      <div class="location" *ngIf="data.subLabel">
        {{ data.subLabel }}
      </div>
      <div typography variant="h5" class="mt-16" *ngIf="data.isProperty && data.price">
        from <b>${{ data.price }}</b> / night
      </div>
    </div>
  </ng-template>

</ng-container>
