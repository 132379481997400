import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';
import { debounceTime, startWith } from 'rxjs/operators';
import { HomePickInfo } from '../../../../core/services/content.service';

@Component({
  selector: 'app-home-picks',
  templateUrl: './home-picks.component.html',
  styleUrls: ['./home-picks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePicksComponent
  extends AbstractComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @ViewChild('scrollRef', { static: true })
  scrollRef: ElementRef<HTMLDivElement>;
  showLeftArrow = false;
  showRightArrow = false;
  private _picks: HomePickInfo[] = [];

  @Input() descriptionTemplate: TemplateRef<any>;

  @Input() set picks(value: HomePickInfo[]) {
    this._picks = value.map(pick => {
      const urlArr = pick.url.split('?');
      let queryParams = undefined;
      if (urlArr.length > 0 && urlArr[1]) {
        const query = decodeURI(urlArr[1]);
        queryParams = query.split('&').reduce((agg, qp) => {
          const [key, val] = qp.split('=');
          return { ...agg, [key]: val };
        }, {});
      }
      return { ...pick, url: urlArr[0], params: queryParams };
    });
  }
  get picks(): HomePickInfo[] {
    return this._picks;
  }

  constructor(private readonly changeDetectionRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    fromEvent(this.scrollRef.nativeElement, 'scroll')
      .pipe(startWith(), debounceTime(100), this.untilDestroyed())
      .subscribe(() => this.updateArrows());
  }

  ngOnChanges(): void {
    this.changeDetectionRef.detectChanges();
    this.updateArrows();
  }

  ngAfterViewInit() {
    // Force browser to calculate initial width of top picks
    this.scrollRef.nativeElement.scrollLeft = 1;
    this.scrollRef.nativeElement.scrollLeft = 0;
  }

  private updateArrows() {
    const element = this.scrollRef.nativeElement;
    const showLeftArrow = element.scrollLeft > 0;
    const showRightArrow =
      element.scrollLeft + window.innerWidth < element.scrollWidth;
    if (
      this.showLeftArrow !== showLeftArrow ||
      this.showRightArrow !== showRightArrow
    ) {
      this.showLeftArrow = showLeftArrow;
      this.showRightArrow = showRightArrow;
      this.changeDetectionRef.detectChanges();
    }
  }

  onLeftArrow() {
    const element = this.scrollRef.nativeElement;
    element.scrollTo({
      left: element.scrollLeft - window.innerWidth,
      behavior: 'smooth',
    });
  }

  onRightArrow() {
    const element = this.scrollRef.nativeElement;
    element.scrollTo({
      left: element.scrollLeft + window.innerWidth,
      behavior: 'smooth',
    });
  }
}
