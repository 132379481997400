import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Property } from 'src/app/shared/models/property';
import { User } from 'src/app/shared/models/user.model';

@Component({
  selector: 'app-chat-context-inquiry-detail',
  templateUrl: 'chat-context-inquiry-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatContextInquiryDetailComponent {
  @Input() currentUser: User;
  @Input() recipient: User;
  @Input() conversationId: string;
  @Input() property: Property;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {}

  get isPropertyOwner() {
    return this.property?.ownerId === this.currentUser.id;
  }

  get recipientTitle() {
    return this.recipient?.id === this.property.ownerId
      ? 'Your host'
      : 'Your guest';
  }

  closeDetail() {
    this.router.navigate(['.', this.conversationId], {
      relativeTo: this.route.parent,
    });
  }
}
