import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotificationColor } from '../../libs/component-lib/components/notification.component';
import { getUserFriendlyError } from '../../utils/errors';

export interface NotificationInfo {
  text: string;
  duration?: number;
  color?: NotificationColor;
  /** Additional styles applied to notification panel. */
  styleClasses?: string | string[] | Set<string>;
  /** Routing key - global notifications do not have a key, local do. */
  notificationKey?: string;
  /** Close button callback, if defined close button is visible */
  readonly onClose?: (event: MouseEvent) => void;
}

export class NotificationInstance {
  constructor(
    readonly text: string,
    readonly duration: number,
    readonly color: NotificationColor,
    readonly styleClasses?: string | string[] | Set<string>,
    readonly notificationKey?: string,
  ) {}

  onClose?: (event: MouseEvent) => void;
  remove: () => void;
}

/**
 * Service for displaying notifications inside notification provider
 *
 * @author Libor Staněk
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private readonly notificationsSubject$ = new BehaviorSubject<
    NotificationInstance[]
  >([]);
  readonly notifications$ = this.notificationsSubject$.asObservable();

  /**
   * Show app notification
   *
   * @param notification.text Notification text
   * @param notification.duration Notification time in ms (default 5000ms) use -1 for permanent notification
   * @param notification.color Notification color (default 'info')
   */
  public showNotification(
    notification: NotificationInfo,
  ): NotificationInstance {
    const text = notification.text;
    const duration = notification.duration || 5000;
    const color = notification.color || 'info';
    const notificationInstance = new NotificationInstance(
      text,
      duration,
      color,
      notification.styleClasses,
      notification.notificationKey,
    );

    // find duplicit notifications
    const duplicitNotifications: NotificationInstance[] =
      this.notificationsSubject$
        .getValue()
        .filter(
          duplicit =>
            duplicit.text === text &&
            duplicit.duration === duration &&
            duplicit.color === color &&
            duplicit.styleClasses === notification.styleClasses &&
            duplicit.notificationKey === notification.notificationKey &&
            duplicit.onClose === notification.onClose,
        );

    // remove duplicit notifications
    if (duplicitNotifications && duplicitNotifications.length > 0) {
      duplicitNotifications.forEach(notif => this.removeNotification(notif));
    }

    // remove callback
    if (duration !== -1) {
      setTimeout(() => {
        this.removeNotification(notificationInstance);
      }, duration);
    }

    if (typeof notification.onClose === 'function') {
      notificationInstance.onClose = (event: MouseEvent) => {
        notificationInstance.remove();
        notification.onClose(event);
      };
    }

    notificationInstance.remove = () => {
      this.removeNotification(notificationInstance);
    };
    const notifications = [
      ...this.notificationsSubject$.getValue(),
      notificationInstance,
    ];
    this.notificationsSubject$.next(notifications);
    return notificationInstance;
  }

  private removeNotification(notificationInstance: NotificationInstance) {
    const notifications = this.notificationsSubject$.value.filter(
      n => n !== notificationInstance,
    );
    this.notificationsSubject$.next(notifications);
  }

  public isNotificationVisible(): boolean {
    return this.notificationsSubject$.value.length > 0;
  }

  public showUserFriendlyErrorNotification(error: any) {
    this.showNotification({
      text: getUserFriendlyError(error),
      color: 'error',
      duration: 5000,
    });
  }
}
