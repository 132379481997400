import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { RedirectService } from '../services/redirect.service';
import { Url } from '../../url';
import { isPlatformServer } from '@angular/common';

/**
 * Authentication guard. Decides whether user is allowed to access protected routes.
 * @author Jakub Jilek, Jan Helbich
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private readonly router: Router,
    private readonly redirectService: RedirectService,
    private readonly userService: UserService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    if (isPlatformServer(this.platformId)) {
      // We don't want to allow any page under login for SSR mode
      return of(false);
    }

    return this.userService.isAuthenticated().pipe(
      take(1),
      tap(isAuthenticated => {
        if (!isAuthenticated) {
          this.redirectService.setUrl(state.url);
          this.router.navigate([Url.LOGIN]);
        }
      }),
    );
  }
}
