<header
  class="navbar"
  [class.navbar-custom-content]="contentEnabled"
  [class.navbar-custom-content-full-width]="contentFullWidth"
  [class.navbar-custom-tabs]="tabsEnabled"
  [class.navbar-position-fixed]="position === 'fixed'"
>
  <div class="navbar-area">
    <ng-container *ngTemplateOutlet="hamburger"></ng-container>
    <ng-container *ngTemplateOutlet="logo"></ng-container>
    <ng-container *ngIf="contentEnabled; else desktopContent">
      <div class="navbar-content" [class.navbar-content-no-gutters]="contentNoGutters">
        <ng-container *ngTemplateOutlet="contentRef"></ng-container>
      </div>
    </ng-container>
    <span class="navbar-hamburger-dummy"></span>
  </div>
  <div class="navbar-tabs" *ngIf="tabsEnabled">
    <ng-container *ngTemplateOutlet="tabsRef"></ng-container>
  </div>
</header>

<ng-template #hamburger>
  <button class="navbar-hamburger" (click)="openMobileMenu()">
    <icon variant="hamburger" color="inherit"></icon>
  </button>
</ng-template>
<ng-template #logo>
  <a class="navbar-logo" data-test="vacay-logo" routerLink="/">
    <img class="navbar-logo-image" src="./assets/img/vacay-125.svg" alt="">
  </a>
</ng-template>
<ng-template #desktopContent>
  <div class="navbar-content d-none d-md-flex w-100">
    <div *ngIf="menuInfo$ | async as menuInfo">
      <a
        *ngFor="let link of menuInfo.navbarDesktop; let i = index"
        typography
        variant="em1"
        class="mr-24"
        color="ink"
        [class.active]="link.active"
        [routerLink]="link.link"
      >{{ link.title }}</a>
    </div>
    <app-menu-user-panel class="ml-auto"></app-menu-user-panel>
  </div>
</ng-template>
