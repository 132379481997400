import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

export type StatusColor =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'success'
  | 'warning'
  | 'error'
  | 'gray';

@Component({
  selector: 'status',
  template: `<ng-content></ng-content>`,
})
export class StatusComponent implements OnInit, OnChanges {
  @Input()
  color: StatusColor = 'primary';

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.renderer.addClass(this.elementRef.nativeElement, 'status');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.color) {
      if (changes.color.previousValue) {
        this.renderer.removeClass(
          this.elementRef.nativeElement,
          `status-color-${changes.color.previousValue}`,
        );
      }
      this.renderer.addClass(
        this.elementRef.nativeElement,
        `status-color-${changes.color.currentValue}`,
      );
    }
  }
}
