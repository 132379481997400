import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GatewayService } from './gateway.service';
import { ChatConversationStatusGatewayEvent } from '../model/gateway-event';

@Injectable({
  providedIn: 'root',
})
export class UserConversationsService {
  private readonly unreadConversationIdsSubject: BehaviorSubject<string[]>;
  readonly unreadConversationIds$: Observable<string[]>;

  constructor(
    private readonly userService: UserService,
    private readonly gatewayService: GatewayService,
  ) {
    this.unreadConversationIdsSubject = new BehaviorSubject<string[]>([]);
    this.unreadConversationIds$ = this.unreadConversationIdsSubject
      .asObservable()
      .pipe(debounceTime(500)); // Do not show immediately unread conversations status on another tab
    this.userService
      .getCurrentUser()
      .pipe(distinctUntilChanged((u1, u2) => u1?.id === u2?.id))
      .subscribe(user => {
        if (!user) {
          this.unreadConversationIdsSubject.next([]);
        } else {
          this.userService
            .fetchCurrentUserUnreadConversations()
            .subscribe(userData => {
              this.unreadConversationIdsSubject.next(
                userData.unreadConversationIds,
              );
            });
        }
      });
    this.gatewayService
      .fromEvent(ChatConversationStatusGatewayEvent)
      .subscribe(event => {
        const unreadConversations = new Set(
          this.unreadConversationIdsSubject.getValue(),
        );
        if (event.read) {
          unreadConversations.delete(event.conversationId);
        } else {
          unreadConversations.add(event.conversationId);
        }
        this.unreadConversationIdsSubject.next(Array.from(unreadConversations));
      });
  }
}
