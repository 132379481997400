import { Property } from './property';

export type PropertyWizardSectionKey =
  | 'property-detail'
  | 'pricing'
  | 'personal-info'
  | 'pair-account'
  | 'setup-withdrawals'
  | 'bank-accounts'
  | 'availability'
  | 'amenities'
  | 'rules'
  | 'insurance-cancellation'
  | 'protection'
  | 'publish';

export interface PropertyWizardSection {
  id: PropertyWizardSectionKey;
  name: string;
  mandatoryProperties: (keyof Property)[];
}

export const PROPERTY_WIZARD_SECTIONS: PropertyWizardSection[] = [
  {
    id: 'property-detail',
    name: 'Property Detail',
    mandatoryProperties: [
      'address',
      'headline',
      'description',
      'propertyType',
      'bedrooms',
      'bathroomsFull',
      'guests',
      'beds',
      'propertyImages',
    ],
  },
  {
    id: 'amenities',
    name: 'Amenities',
    mandatoryProperties: [],
  },
  {
    id: 'rules',
    name: 'Rules and Instructions',
    mandatoryProperties: [],
  },
  {
    id: 'pricing',
    name: 'Rates and Taxes',
    mandatoryProperties: ['pricePerNight', 'cleaningFee'],
  },
  {
    id: 'personal-info',
    name: 'Personal Info',
    mandatoryProperties: [],
  },
  {
    id: 'pair-account',
    name: 'Pair Stripe account',
    mandatoryProperties: [],
  },
  {
    id: 'setup-withdrawals',
    name: 'Set up withdrawals',
    mandatoryProperties: [],
  },
  {
    id: 'bank-accounts',
    name: 'Bank Account',
    mandatoryProperties: [],
  },
  {
    id: 'availability',
    name: 'Availability and Booking Type',
    mandatoryProperties: [],
  },
  {
    id: 'insurance-cancellation',
    name: 'Cancellation',
    mandatoryProperties: ['cancellationPolicy'],
  },
  {
    id: 'protection',
    name: 'Protection',
    mandatoryProperties: [],
  },
  {
    id: 'publish',
    name: 'Publish',
    mandatoryProperties: [],
  },
];
