import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AmenityOption } from '../../../shared/models/amenity';

@Component({
  selector: 'radio-button-group',
  template: `
    <div (click)="$event.stopPropagation()">
      <div
        *ngFor="let option of options; index as i"
        class="radio-button-container"
      >
        <radio-button
          [value]="option.id"
          [label]="option.label"
          (changeValue)="onChangeValue($event)"
          [checked]="control.value === option.id"
        ></radio-button>
        <span
          class="default-text"
          typography
          variant="body2"
          color="ink-light"
          *ngIf="i === 0"
          >&nbsp;(default)</span
        >
      </div>
    </div>
  `,
  styles: [
    `
      .default-text {
        align-self: flex-end;
      }
      .radio-button-container {
        margin: 12px;
        display: flex;
      }
    `,
  ],
})
export class RadioButtonGroupComponent {
  @Input() options: AmenityOption[];
  @Input()
  control: UntypedFormControl;

  onChangeValue(value) {
    this.control.reset(value);
  }
}
