import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isEmpty, isNil } from 'lodash-es';
import {
  BehaviorSubject,
  Observable,
  of,
  ReplaySubject,
  Subject,
  combineLatest,
} from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { Property, PropertyUpdateDto } from '../../shared/models/property';
import {
  PROPERTY_WIZARD_SECTIONS,
  PropertyWizardSection,
  PropertyWizardSectionKey,
} from '../../shared/models/property-form.model';
import { isValidUserAddress } from '../../utils/address.util';
import { retryOnError } from '../../utils/error.util';
import { AbstractComponent } from '../components/abstract/abstract.component';
import { PropertyService } from './property.service';
import { UserService } from './user.service';
import { NotificationService } from './notification.service';
import { PropertyStatus } from '../../shared/models/property-status';
import { AppConstants } from '../../shared/enums/app-constants';
import { StorageService } from './storage.service';
import { StorageKey } from '../../shared/enums/local-storage-key.enum';
import { Url } from '../../url';
import { PropertyPricing } from '../../shared/models/property-pricing.model';
import { PropertyPricingService } from './property-pricing.service';
import { PropertyTaxService } from './property-tax.service';
import { IntegrationPartner } from '../../shared/models/integration.model';

interface PartialPropertyUpdate {
  pendingUpdate: PropertyUpdateDto;
  unsavedChanges: PropertyUpdateDto;
}

export enum SectionState {
  COMPLETED = 'completed',
  INVALID = 'invalid',
  NONE = 'empty',
  LOCKED = 'locked',
}

export enum SavingProgress {
  SAVING = 1,
  SAVED = 2,
  ERROR = 3,
}

export enum WizardSteps {
  PROPERTY_DETAIL = 'property-detail',
  AMENITIES = 'amenities',
  RULES = 'rules',
  PRICING = 'pricing',
  PERSONAL_INFO = 'personal-info',
  PAIR_ACCOUNT = 'pair-account',
  SETUP_WITHDRAWALS = 'setup-withdrawals',
  BANK_ACCOUNT = 'bank-accounts',
  AVAILABILITY = 'availability',
  INSURANCE_CANCELLATION = 'insurance-cancellation',
  PROTECTION = 'protection',
  PUBLISH = 'publish',
}

@Injectable({
  providedIn: 'root',
})
export class PropertyWizardService extends AbstractComponent {
  navigateForward$ = new Subject<void>();
  navigateBackward$ = new Subject<void>();
  emitSectionsUpdate$ = new Subject<void>();
  readonly sectionStateChanged$: Subject<void> = new Subject<void>();

  private propertyId: string;
  private property: Property;
  private property$ = new ReplaySubject<Property>(1);
  private partialUpdateData: PartialPropertyUpdate = {
    pendingUpdate: {},
    unsavedChanges: {},
  };
  readonly savingInfo$ = new BehaviorSubject<SavingProgress>(null);
  readonly updateError$ = new BehaviorSubject<string>(null);
  readonly updateInProgress$ = new BehaviorSubject<boolean>(false);

  private readonly currentSection$ =
    new BehaviorSubject<PropertyWizardSectionKey>(undefined);
  private readonly sectionIndication: Map<
    PropertyWizardSectionKey,
    SectionState
  > = new Map<PropertyWizardSectionKey, SectionState>();
  private imagesUploaded = true;

  // list of properties that can have value (number) 0
  private readonly ZERO_ALLOWED = [
    'bedrooms',
    'cleaningFee',
    'taxRatePercent',
    'bathroomsFull',
    'bathroomsHalf',
    'beds',
  ];
  private notificationEnabled = true;

  private visitedSections: string[] = [];

  constructor(
    private readonly propertyService: PropertyService,
    private readonly propertyPricingService: PropertyPricingService,
    private readonly propertyTaxService: PropertyTaxService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
    private readonly storageService: StorageService,
  ) {
    super();
  }

  updatePartially(propertyUpdateData: Partial<PropertyUpdateDto>) {
    this.partialUpdateData.unsavedChanges = {
      ...this.partialUpdateData.unsavedChanges,
      ...propertyUpdateData,
    };
    this.savePartialUpdate();
  }

  setImagesUploaded(uploaded: boolean) {
    this.imagesUploaded = uploaded;
    if (!uploaded) {
      this.savingInfo$.next(SavingProgress.SAVING);
    }
  }

  // Return boolean Subject, representing if update is in progress or not
  savePartialUpdate(): Subject<boolean> {
    if (!window.navigator.onLine) {
      if (this.notificationEnabled) {
        this.notificationService.showNotification({
          text: 'Oops, something went wrong. Please check your internet connection.',
          color: 'error',
          duration: 2000,
        });
        this.notificationEnabled = false;
        setTimeout(() => {
          this.notificationEnabled = true;
        }, 2000);
      }
    }
    if (
      isEmpty(this.partialUpdateData.unsavedChanges) ||
      !isEmpty(this.partialUpdateData.pendingUpdate)
    ) {
      return this.updateInProgress$;
    }

    this.updateInProgress$.next(true);

    this.partialUpdateData.pendingUpdate =
      this.partialUpdateData.unsavedChanges;
    this.partialUpdateData.unsavedChanges = {};

    this.savingInfo$.next(SavingProgress.SAVING);
    this.update(this.partialUpdateData.pendingUpdate)
      .pipe(retryOnError())
      .subscribe(
        () => {
          this.partialUpdateData.pendingUpdate = {};
          if (this.imagesUploaded) {
            this.savingInfo$.next(SavingProgress.SAVED);
          }

          this.updateError$.next(null);
          // Again call save - to save all changes that were made during this update call
          if (
            isEmpty(this.partialUpdateData.unsavedChanges) &&
            isEmpty(this.partialUpdateData.pendingUpdate)
          ) {
            this.updateInProgress$.next(false);
          } else {
            this.savePartialUpdate();
          }
          this.emitSectionsUpdate$.next();
        },
        (error: HttpErrorResponse) => {
          // In case of error that we dont want to retry, return data back to unsavedChanges and show error
          this.partialUpdateData.unsavedChanges = {
            ...this.partialUpdateData.pendingUpdate,
            ...this.partialUpdateData.unsavedChanges,
          };
          this.partialUpdateData.pendingUpdate = {};
          // Show only statusText, not whole message. Does end user need to know detailed error message (error.message)?
          this.savingInfo$.next(SavingProgress.ERROR);
          this.updateError$.next(error.statusText);
          this.updateInProgress$.next(false);
        },
      );
    return this.updateInProgress$;
  }

  update(propertyUpdateDto: PropertyUpdateDto) {
    let updatePropertyPricing$: Observable<PropertyPricing> = of(null);
    if (
      propertyUpdateDto.pricePerNight ||
      propertyUpdateDto.pricePerNightWeekends ||
      propertyUpdateDto.weeklyDiscountPercent >= 0 ||
      propertyUpdateDto.monthlyDiscountPercent >= 0 ||
      propertyUpdateDto.cleaningFee >= 0
    ) {
      // Update also new pricing in pricing-service (keep in mind, prices in property-service are updated as well here)
      updatePropertyPricing$ =
        this.propertyPricingService.updatePropertyPricing(this.propertyId, {
          pricePerNight: propertyUpdateDto.pricePerNight,
          pricePerNightWeekends: propertyUpdateDto.pricePerNightWeekends,
          weeklyDiscountPercent: propertyUpdateDto.weeklyDiscountPercent,
          monthlyDiscountPercent: propertyUpdateDto.monthlyDiscountPercent,
          cleaningFee: propertyUpdateDto.cleaningFee,
        });
    }
    return combineLatest([
      updatePropertyPricing$,
      this.propertyService.updateProperty(
        this.getCurrentProperty().id,
        propertyUpdateDto,
      ),
    ]).pipe(
      map(values => this.mergePropertyWithPricing(values[1], values[0])),
      tap(property => this.setProperty(property)),
    );
  }

  private mergePropertyWithPricing(
    property: Property,
    propertyPricing: PropertyPricing,
  ): Property {
    const updatedProperty: Property = property;
    if (propertyPricing) {
      if (propertyPricing.pricePerNight >= 0) {
        property.pricePerNight = propertyPricing.pricePerNight;
      }
      if (propertyPricing.pricePerNightWeekends >= 0) {
        property.pricePerNightWeekends = propertyPricing.pricePerNightWeekends;
      }
      if (propertyPricing.weeklyDiscountPercent >= 0) {
        property.weeklyDiscountPercent = propertyPricing.weeklyDiscountPercent;
      }
      if (propertyPricing.monthlyDiscountPercent >= 0) {
        property.monthlyDiscountPercent =
          propertyPricing.monthlyDiscountPercent;
      }
      if (propertyPricing.cleaningFee >= 0) {
        property.cleaningFee = propertyPricing.cleaningFee;
      }
    }
    return updatedProperty;
  }

  loadProperty(id: string) {
    this.visitedSections = [];
    this.propertyId = id;

    PROPERTY_WIZARD_SECTIONS.forEach(section => {
      this.updateSectionStateIfExist(section.id, SectionState.NONE);
    });
    this.propertyService.getProperty(this.propertyId).subscribe(property => {
      this.updateError$.next(null);
      this.savingInfo$.next(null);
      this.setProperty(property);
      this.checkPropertySections(property);
      this.updateCompleted(property);
    });
  }

  createProperty() {
    return this.propertyService
      .createProperty()
      .pipe(tap(property => this.setProperty(property)));
  }

  resetProperty() {
    this.property$ = new ReplaySubject<Property>(1);
  }

  deleteProperty(): Observable<void> {
    return this.propertyService
      .deleteProperty(this.property.id)
      .pipe(tap(() => this.setProperty(null)));
  }

  setProperty(property: Property) {
    this.property = property;
    this.property$.next(property);
  }

  getModel(): Observable<Property> {
    return this.property$.asObservable();
  }

  getCurrentProperty(): Property {
    return this.property;
  }

  setCurrentSection(currentSection: PropertyWizardSectionKey) {
    this.currentSection$.next(currentSection);
    if (!this.getCurrentProperty() || !this.visitedSections.length) {
      return;
    }
    if (this.visitedSections.indexOf(currentSection) < 0) {
      this.visitedSections.push(currentSection);

      this.saveNewSectionIntoProperty();
    }
    this.updateCompleted(this.getCurrentProperty());
  }

  getCurrentSection(): Observable<PropertyWizardSectionKey> {
    return this.currentSection$.asObservable();
  }

  getSectionStatus(section) {
    if (isNil(this.property?.address) && section !== 'property-detail') {
      return SectionState.LOCKED;
    }
    if (this.sectionIndication.has(section)) {
      return this.sectionIndication.get(section);
    }
    return SectionState.NONE;
  }

  getNextSection(
    currentSectionKey: PropertyWizardSectionKey,
  ): PropertyWizardSectionKey {
    const currentSection = PROPERTY_WIZARD_SECTIONS.filter(
      val => val.id === currentSectionKey,
    )[0];
    if (
      !currentSection ||
      PROPERTY_WIZARD_SECTIONS.indexOf(currentSection) ===
        PROPERTY_WIZARD_SECTIONS.length - 1
    ) {
      return currentSectionKey;
    }

    return PROPERTY_WIZARD_SECTIONS[
      PROPERTY_WIZARD_SECTIONS.indexOf(currentSection) + 1
    ].id;
  }

  getPreviousSection(
    currentSectionKey: PropertyWizardSectionKey,
  ): PropertyWizardSectionKey {
    const currentSection = PROPERTY_WIZARD_SECTIONS.filter(
      val => val.id === currentSectionKey,
    )[0];
    if (
      !currentSection ||
      PROPERTY_WIZARD_SECTIONS.indexOf(currentSection) === 0
    ) {
      return currentSectionKey;
    }

    return PROPERTY_WIZARD_SECTIONS[
      PROPERTY_WIZARD_SECTIONS.indexOf(currentSection) - 1
    ].id;
  }

  navigateNext(currentSection: PropertyWizardSectionKey) {
    return this.navigateSection(this.getNextSection(currentSection));
  }

  navigatePrevious(currentSection: PropertyWizardSectionKey) {
    return this.navigateSection(this.getPreviousSection(currentSection));
  }

  navigateSection(section: PropertyWizardSectionKey) {
    return this.router.navigate([
      Url.PROPERTY_WIZARD_(this.propertyId, section),
    ]);
  }

  setPropertyWizardEmailRedirect(value: boolean) {
    if (value) {
      this.storageService.setItem(
        StorageKey.PROPERTY_WIZARD_EMAIL_REDIRECT,
        this.propertyId,
      );
    } else {
      this.storageService.removeItem(StorageKey.PROPERTY_WIZARD_EMAIL_REDIRECT);
    }
  }

  isPropertyWizardEmailRedirect(): boolean {
    return !!this.storageService.getItem(
      StorageKey.PROPERTY_WIZARD_EMAIL_REDIRECT,
    );
  }

  navigatePropertyWizardEmailRedirect() {
    const propertyId = this.storageService.getItem(
      StorageKey.PROPERTY_WIZARD_EMAIL_REDIRECT,
    );
    this.storageService.removeItem(StorageKey.PROPERTY_WIZARD_EMAIL_REDIRECT);
    this.loadProperty(propertyId);
    this.navigateSection(WizardSteps.PERSONAL_INFO);
  }

  updateCompleted(property: Property) {
    // Only update states, that already exist in map
    if (!property) {
      return;
    }
    // Validate sections based on mandatory properties of Property object
    PROPERTY_WIZARD_SECTIONS.forEach(section => {
      this.updateSectionState(property, section);
    });

    if (
      !property.propertyImages ||
      property.propertyImages.length < AppConstants.PROPERTY_IMAGES_MIN ||
      property.propertyImages.length > AppConstants.PROPERTY_IMAGES_MAX
    ) {
      this.updateSectionStateIfExist(
        WizardSteps.PROPERTY_DETAIL,
        SectionState.INVALID,
      );
    }

    // Additional, more specific validations
    this.userService
      .getCurrentUser()
      .pipe(take(1))
      .subscribe(user => {
        if (
          user?.email?.verified &&
          user?.phone?.phoneNumber &&
          isValidUserAddress(user?.address)
        ) {
          this.setSectionState(
            WizardSteps.PERSONAL_INFO,
            SectionState.COMPLETED,
          );
        } else {
          this.updateSectionStateIfExist(
            WizardSteps.PERSONAL_INFO,
            SectionState.INVALID,
          );
        }
      });

    this.updateSectionStateIfExist('publish', SectionState.NONE);
  }

  updateSectionState(property: Property, section: PropertyWizardSection) {
    if (this.visitedSections.indexOf(section.id) < 0) {
      return;
    }
    if (section.mandatoryProperties) {
      let valid = true;
      for (const mandatoryPropertyKey of section.mandatoryProperties) {
        //Note: Some external integrations have different mandatory properties. Write here the exceptions
        if (
          mandatoryPropertyKey === 'cleaningFee' &&
          (property.integrationPartner === IntegrationPartner.BOOST ||
            property.integrationPartner === IntegrationPartner.STREAMLINE)
        ) {
          continue;
        }

        if (
          !property.hasOwnProperty(mandatoryPropertyKey) ||
          (!property[mandatoryPropertyKey] &&
            // additional check for allowed 0 value
            !(
              this.ZERO_ALLOWED.includes(mandatoryPropertyKey) &&
              property[mandatoryPropertyKey] === 0
            ))
        ) {
          valid = false;
          break;
        }
      }

      if (valid) {
        /*
        - After checking statically defined mandatory properties for pricing section, we need to check juridsictions
        - Tax jurisdictions are added dynamically and all of them have to be filled, so statically defined mandatory properties are useless
        */
        if (section.id === WizardSteps.PRICING) {
          const existingState = this.sectionIndication.get(WizardSteps.PRICING);
          if (existingState === SectionState.COMPLETED) {
            return;
          }

          this.propertyTaxService
            .getPropertyTaxJurisdictions(property.id)
            .pipe(
              map(jurisdictions =>
                jurisdictions.every(jurisdiction => {
                  return jurisdiction.taxRules.every(
                    taxRule => !isNaN(taxRule.amount),
                  );
                }),
              ),
            )
            .subscribe(jurisdictionsValid => {
              this.setSectionState(
                section.id,
                jurisdictionsValid
                  ? SectionState.COMPLETED
                  : SectionState.INVALID,
              );
            });
        } else {
          this.setSectionState(section.id, SectionState.COMPLETED);
        }
      } else {
        this.setSectionState(section.id, SectionState.INVALID);
      }
    }
  }

  updateSectionStateIfExist(
    sectionKey: PropertyWizardSectionKey,
    state: SectionState,
  ) {
    if (this.sectionIndication.has(sectionKey)) {
      this.setSectionState(sectionKey, state);
    }
  }

  setSectionState(sectionKey: PropertyWizardSectionKey, state: SectionState) {
    this.sectionIndication.set(sectionKey, state);
    this.sectionStateChanged$.next();
  }

  private checkPropertySections(property: Property) {
    if (
      property.status === PropertyStatus.UNPUBLISHED ||
      property.status === PropertyStatus.READY_FOR_PUBLISH
    ) {
      const wizardSections = Object.values(WizardSteps);
      if (
        !property.visitedWizardSections ||
        property.visitedWizardSections.length < wizardSections.length
      ) {
        this.updatePartially({
          visitedWizardSections: wizardSections as any,
        });
        this.visitedSections.push(...wizardSections);
      } else {
        this.visitedSections.push(...property.visitedWizardSections);
      }
    } else if (property.status === PropertyStatus.DRAFT) {
      if (
        !property.visitedWizardSections ||
        !property.visitedWizardSections.length
      ) {
        this.updatePartially({
          visitedWizardSections: ['property-detail'],
        });
        this.visitedSections.push('property-detail');
      } else {
        this.visitedSections.push(...property.visitedWizardSections);
      }
    } else {
      this.visitedSections.push(...property.visitedWizardSections);
    }
  }

  private saveNewSectionIntoProperty() {
    this.updatePartially({
      visitedWizardSections: [...this.visitedSections] as any,
    });
  }
}
