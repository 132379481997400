import { Component, Input } from '@angular/core';
import { User, VerificationStatus } from '../../../../shared/models/user.model';
import { TypographyVariant } from '@components/typography/typography.component';

@Component({
  selector: 'app-user-email-verification-info',
  template: `
    <div
      typography
      [variant]="variant"
      color="ink"
      class="text-overflow-ellipsis"
    >
      {{ user.email.emailAddress }}
    </div>
    <div *ngIf="user.verification?.status !== VerificationStatus.CONFIRMED">
      <p
        typography
        variant="body3"
        color="red"
        class="d-flex align-items-center"
      >
        <icon variant="warning" color="inherit" size="16" class="mr-4"></icon>
        Not Verified
      </p>
    </div>
  `,
})
export class UserEmailVerificationInfoComponent {
  @Input()
  user: User;
  @Input()
  variant: TypographyVariant;

  protected readonly VerificationStatus = VerificationStatus;
}
