import { rotateArray } from '@components/calendar/calendar.utils';
import {
  addDays,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';

type DayOfWeekId = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export class CalendarDayOfWeek {
  public static readonly SUNDAY = new CalendarDayOfWeek(0, 'Sunday');
  public static readonly MONDAY = new CalendarDayOfWeek(1, 'Monday');
  public static readonly TUESDAY = new CalendarDayOfWeek(2, 'Tuesday');
  public static readonly WEDNESDAY = new CalendarDayOfWeek(3, 'Wednesday');
  public static readonly THURSDAY = new CalendarDayOfWeek(4, 'Thursday');
  public static readonly FRIDAY = new CalendarDayOfWeek(5, 'Friday');
  public static readonly SATURDAY = new CalendarDayOfWeek(6, 'Saturday');

  public readonly shortName: string;

  constructor(
    public readonly id: DayOfWeekId,
    public readonly name: string,
  ) {
    this.shortName = name.substring(0, 3);
  }

  public static getDayOfWeeks(options?: {
    firstDayOfWeek?: CalendarDayOfWeek;
  }): CalendarDayOfWeek[] {
    const dayOfWeeks = Object.values(CalendarDayOfWeek) as CalendarDayOfWeek[];
    return rotateArray(dayOfWeeks, options.firstDayOfWeek?.id ?? 0);
  }

  public static valueOf(id: number): CalendarDayOfWeek {
    return Object.values(CalendarDayOfWeek)[id] as CalendarDayOfWeek;
  }
}

export class CalendarDay {
  readonly date: Date;
  readonly number: number;
  readonly month: Date;

  constructor(date: Date) {
    this.date = startOfDay(date);
    this.number = this.date.getDate();
    this.month = startOfMonth(this.date);
  }
}

export class CalendarWeek {
  readonly start: Date;
  readonly end: Date;
  readonly days: CalendarDay[];

  constructor(start: Date) {
    const weekStartsOn = start.getDay() as DayOfWeekId;
    this.start = startOfWeek(start, { weekStartsOn: weekStartsOn });
    this.end = endOfWeek(start, { weekStartsOn: weekStartsOn });
    this.days = this.generateDays();
  }

  private generateDays(): CalendarDay[] {
    const days: CalendarDay[] = [];
    for (let i = 0; i < 7; i++) {
      days.push(new CalendarDay(addDays(this.start, i)));
    }
    return days;
  }
}

export class Calendar {
  readonly start: Date;
  readonly end: Date;
  readonly daysInMonth: number;
  readonly dayOfWeeks: CalendarDayOfWeek[];
  readonly weeks: CalendarWeek[];

  constructor(date: Date, options?: { firstDayOfWeek?: CalendarDayOfWeek }) {
    this.start = startOfMonth(date);
    this.end = endOfMonth(date);
    this.daysInMonth = this.end.getDate();
    this.dayOfWeeks = CalendarDayOfWeek.getDayOfWeeks({
      firstDayOfWeek: options.firstDayOfWeek ?? CalendarDayOfWeek.MONDAY,
    });
    this.weeks = this.generateWeeks();
  }

  private generateWeeks(): CalendarWeek[] {
    const firstDayOfMonth = CalendarDayOfWeek.valueOf(this.start.getDay());
    const firstWeekFirstDate = addDays(
      this.start,
      -this.dayOfWeeks.indexOf(firstDayOfMonth),
    );
    const weeks: CalendarWeek[] = [];
    for (
      let date = firstWeekFirstDate;
      date < this.end;
      date = addDays(date, 7)
    ) {
      weeks.push(new CalendarWeek(date));
    }
    return weeks;
  }
}
