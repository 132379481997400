import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

type SeparatorVariant = 'line' | 'vertical' | 'dot' | 'dashed';
type SeparatorColor = 'light' | 'neutral' | 'dark' | 'green';

@Component({
  selector: 'separator',
  styleUrls: ['./separator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: ` <span></span> `,
})
export class SeparatorComponent implements OnInit, OnChanges {
  @Input() variant: SeparatorVariant = 'line';
  @Input() color: SeparatorColor = 'neutral';

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    const element = this.elementRef.nativeElement;
    this.renderer.addClass(element, `separator`);
    this.renderer.addClass(element, `separator-${this.variant}`);
    this.renderer.addClass(element, `separator-color-${this.color}`);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const element = this.elementRef.nativeElement;
    if (changes.color) {
      this.renderer.removeClass(
        element,
        `separator-color-${changes.color.previousValue}`,
      );
      this.renderer.addClass(
        element,
        `separator-color-${changes.color.currentValue}`,
      );
    }
    if (changes.variant) {
      this.renderer.removeClass(
        element,
        `separator-${changes.variant.previousValue}`,
      );
      this.renderer.addClass(
        element,
        `separator-${changes.variant.currentValue}`,
      );
    }
  }
}
