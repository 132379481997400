import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { convertDatesInObject } from '../../utils/date.util';

/**
 * Interceptor http response string to Date.
 *
 * {@link https://stackoverflow.com/questions/46559268/parse-date-with-angular-4-3-httpclient | source}
 */
@Injectable()
export class IsoDateHttpInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          convertDatesInObject(event.body);
        }
      }),
    );
  }
}
