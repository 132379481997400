import { Directive, OnDestroy } from '@angular/core';
import { MonoTypeOperatorFunction, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Url } from 'src/app/url';

@Directive()
export abstract class AbstractComponent implements OnDestroy {
  readonly Url = Url;

  protected readonly destroyedSubject = new Subject<void>();

  ngOnDestroy(): void {
    this.destroyedSubject.next();
    this.destroyedSubject.complete();
  }

  untilDestroyed<T>(): MonoTypeOperatorFunction<T> {
    return takeUntil(this.destroyedSubject);
  }
}
