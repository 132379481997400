import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Conversation } from '../../modules/chat/models/conversation.model';
import { PagedModel } from '../../shared/models/pagination';
import { AbstractApi } from './abstract.api';

@Injectable({ providedIn: 'root' })
export class UserActivityApi extends AbstractApi {
  constructor(injector: Injector) {
    super(injector);
    this.base = '/notification/api/v1/user-activity';
  }

  updateActivity(): Observable<PagedModel<Conversation>> {
    return this.http.post<PagedModel<Conversation>>(`${this.base}/update`, {});
  }
}
