import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../environments/environment';
import { UserService } from './user.service';
import { ToggleService } from '../modules/toggle/toggle.service';
import { distinctUntilChanged, filter, first, switchMap } from 'rxjs/operators';
import { from } from 'rxjs';
import SmartlookClient from 'smartlook-client';

/**
 * Smartlook is monitoring tool which can record user´s activity and actions in the application.
 */
@Injectable({
  providedIn: 'root',
})
export class SmartlookService {
  private readonly user$ = this.userService.getCurrentUser();
  private smartlook: typeof SmartlookClient;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private readonly userService: UserService,
    private readonly toggleService: ToggleService,
  ) {}

  /**
   * init() - Initialization of communication between app and Smartlook
   */
  init() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.toggleService.toggleReady$
      .pipe(
        first(),
        filter(() => !!environment.enabledFeatures.smartlook),
        switchMap(() => from(import('smartlook-client')).pipe(first())),
      )
      .subscribe(smartlookModule => {
        this.smartlook = smartlookModule.default;
        this.smartlook.init(environment.smartlook.initKey);

        this.identifyUser();
        this.subscribeToRecordingToggle();
      });
  }

  /**
   * identify() - User identification. If user is registered the public ID is used. Otherwise, smartlook recommends not modifying the user ID.
   */
  private identifyUser() {
    this.user$
      .pipe(
        distinctUntilChanged((u1, u2) => u1?.id === u2?.id),
        filter(user => !!user),
      )
      .subscribe(user => {
        this.smartlook.identify(user.id, undefined);
      });
  }

  private subscribeToRecordingToggle() {
    const enabled = environment.enabledFeatures.smartlookRecordAll;
    const smartlookRecordFeatures = {
      forms: enabled,
      ips: enabled,
      numbers: enabled,
      emails: false,
    };
    this.smartlook.record(smartlookRecordFeatures);
  }
}
