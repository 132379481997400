
<div class="conversation-content-layout" *ngIf="conversation">
  <div *ngIf="(largeScreen$ | async) === true || !isContextOpen" class="messages-panel">
    <app-chat-messages [conversation]="conversation" [maxHeight]="true"></app-chat-messages>
  </div>
  <div *ngIf="(largeScreen$ | async) === true || isContextOpen" class="context-panel">
    <app-chat-context
      [conversation]="conversation"
    ></app-chat-context>
  </div>
</div>
