import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';
import { Component, Input } from '@angular/core';
import { BookingInfo } from '../../../../shared/models/booking';
import { isBefore } from 'date-fns';
import { chooseCancellationRefundRule } from '../../../../utils/cancellation.util';

@Component({
  selector: 'app-booking-info',
  template: `<div>
      <p typography variant="body2">Booking ID: {{ booking.id }}</p>
    </div>
    <div class="d-flex justify-content-between mt-16 me-16">
      <div>
        <p typography variant="body2">Check In</p>
        <p typography variant="em2" class="mt-4">
          {{ booking.interval.checkIn.toDateString() }}
        </p>
      </div>
      <div>
        <p typography variant="body2">Check Out</p>
        <p typography variant="em2" class="mt-4">
          {{ booking.interval.checkOut.toDateString() }}
        </p>
      </div>
      <div>
        <p typography variant="body2">Guests</p>
        <p typography variant="em2" class="mt-4">
          {{ booking.guests
          }}<span class="pets" *ngIf="booking?.pets">(+ pet)</span>
          <span class="pets" *ngIf="booking?.numberOfPets"
            >(+
            {{
              booking?.numberOfPets > 1
                ? booking?.numberOfPets + ' pets'
                : 'pet'
            }})</span
          >
        </p>
      </div>
    </div>
    <div *ngIf="guestCanCancel" class="mt-16 me-16">
      <div class="divider my-8"></div>
      <p typography variant="body2">
        For this booking, the guest can cancel until
        <strong>{{ guestCancellationPeriod.toDateString() }}</strong> for full
        refund.
      </p>
    </div>`,
  styles: [
    `
      .pets {
        font-size: 10px;
        margin-left: 4px;
        font-weight: 600;
      }
    `,
  ],
})
export class BookingInfoComponent extends AbstractComponent {
  @Input()
  booking: BookingInfo;

  get guestCancellationPeriod(): Date | undefined {
    return chooseCancellationRefundRule(
      this.booking.cancellationPolicy?.refundRules,
    )?.dateBefore;
  }

  get guestCanCancel() {
    const today = new Date();
    return isBefore(today, this.guestCancellationPeriod);
  }
}
