import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

/**
 * Service for keeping current and previous url.
 */
@Injectable({
  providedIn: 'root',
})
export class RouteService {
  private previousUrl = '/';
  private currentUrl = '/';

  constructor(private readonly router: Router) {
    this.currentUrl = this.router.url;

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  public getPreviousUrl(): string {
    return this.previousUrl;
  }

  public getCurrent(): string {
    return this.currentUrl;
  }
}
