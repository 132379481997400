export interface PartnerInfo {
  name: string;
  img: string;
  link: string;
  description: string;
}

export const ALL_PARTNERS: PartnerInfo[] = [
  {
    name: 'SUPERHOG',
    img: 'home/partners/superhog.svg',
    link: 'https://superhog.com/?utm_source=vacaymyway&utm_medium=referral&utm_campaign=partner-page',
    description:
      'VacayMyWay has partnered with SUPERHOG, a digital trust platform that enables hosts and guests to confidently transact in the short-term rental space. Every booking comes with Guest ID verification and $5M in damage protection. SUPERHOG uses technology and data to create trust between strangers and de-risk their interactions.',
  },
  {
    name: 'Nextpax',
    img: 'home/partners/nextpax.svg',
    link: 'https://nextpax.com/?utm_source=vacaymyway&utm_medium=referral&utm_campaign=partner-page',
    description:
      'Founded in 2006, NextPax is the leading full service channel manager specializing in providing complex API-connectivity solutions that enable seamless two-way connections between property management systems and distribution channels worldwide. The extensive network of NextPax consists of 600,000+ vacation rental properties and over 500,000 hotel rooms and is continuously growing. The highly automated vacation rental distribution technology provided by NextPax, allows properties to be distributed via all major distribution channels and numerous niche channels. Connectivity includes availability, rates, inventory, bookings and content updates.',
  },
  {
    name: 'NOISEAWARE',
    img: 'home/partners/noiseaware.svg',
    link: 'https://noiseaware.com/?utm_source=vacaymyway&utm_medium=referral&utm_campaign=partner-page',
    description:
      '<p>You’re one step closer to peace of mind. NoiseAware gives you the tools to protect your rental and profits by preventing incidents with noise measurement technology. Whether you manage a single rental or a thousand, NoiseAware has a solution.</p> <p>VacayMyWay hosts receive a code for 33% off your NoiseAware purchase! This code will be given to you while creating your listing. Further integrations with NoiseAware and VacayMyWay coming soon!</p>',
  },
  {
    name: 'ASTRHO',
    img: 'home/partners/astrho.svg',
    link: 'https://astrho.org/?utm_source=vacaymyway&utm_medium=referral&utm_campaign=partner-page',
    description: `ASTRHO's community is built to bring short-term rental homeowners together to collaborate in new and better ways, learn from marketing and business experts in the vacation rental space, and to provide perks and benefits that are important to you, the homeowner. Whether you’re buying a home, currently self-manage, or you’re hiring a property manager, ASTRHO is your community.`,
  },
  {
    name: 'OKsystem',
    img: 'home/partners/oksystem.svg',
    link: 'https://www.oksystem.com/?utm_source=vacaymyway&utm_medium=referral&utm_campaign=partner-page',
    description:
      '<p>VacayMyWay has partnered with OKsystem, the IT company that designed and produced the website of <a href="https://vacaymyway.com" target="_blank">https://vacaymyway.com</a> and is now providing its further development and support. OKsystem delivers innovative, secure and reliable software solution tailored upon customers and their business needs. </p><p>For more information see the company website <a href="https://oksystem.com" target="_blank">https://oksystem.com</a></p>',
  },
  {
    name: 'Hostfully',
    img: 'home/partners/hostfully.svg',
    link: 'https://www.hostfully.com/?utm_source=vacaymyway&utm_medium=referral&utm_campaign=partner-page',
    description: `Hostfully's award-winning software helps vacation rental companies provide 5-star hospitality. With property management and guest experience software for short term rentals, Hostfully helps property managers automate, streamline, and grow their businesses. Hostfully's Property Management Platform is the most open-source software in the industry and includes dozens of integrations, pipeline management, upselling tools, owner reporting, a centralized calendar, and a platform for widespread channel distribution. Further, Hostfully's Guidebooks Platform also allows managers to supply travelers with mission-critical property information and local recommendations, with a custom welcome message featuring their unique brands.`,
  },
  {
    name: 'Hostaway',
    img: 'home/partners/hostaway.svg',
    link: 'https://www.hostaway.com/?utm_source=vacaymyway&utm_medium=referral&utm_campaign=partner-page',
    description:
      'Hostaway is known as the market leader in marketing, sales, management, reporting, and communication for vacation rental property managers. One of the largest marketplaces in the industry, property managers can increase exposure while saving time and money. Thousands of property managers around the world trust Hostaway, the fastest-growing vacation rental software in the world.',
  },
  {
    name: 'STREAMLINE',
    img: 'home/partners/streamline.png',
    link: 'https://www.streamlinevrs.com/?utm_source=vacaymyway&utm_medium=referral&utm_campaign=partner-page',
    description:
      'The All-In-One Vacation Rental Software built for Vacation Rental Industry Leaders. Our quality suite of programs has made it easier than ever before to manage a large volume of properties by providing property managers with every function they may need. As you’ll see, this is truly the all-in-one vacation rental software suite you have been looking for! ',
  },
  {
    name: 'TruPlace',
    img: 'home/partners/truplace.svg',
    link: 'https://go.truplace.com/vacaymyway/?utm_source=vacaymyway&utm_medium=referral&utm_campaign=partner-page',
    description:
      'VacayMyWay has partnered with TruPlace to offer our Hosts quality, professional real estate photography at a discounted rate. Place your order using this link and the discount will automatically be applied. Professional photos are an excellent way to give your listing a boost and help set you apart from the competition!',
  },
  {
    name: 'VRMA',
    img: 'home/partners/vrma.svg',
    link: 'https://www.vrma.org/?utm_source=vacaymyway&utm_medium=referral&utm_campaign=partner-page',
    description:
      'The Vacation Rental Management Association (VRMA) provides best-in-class education, networking, and professional development opportunities to make a difference for you and your company. VRMA works worldwide on behalf of our manager and supplier members to advance the vacation rental industry through education, information, networking, research, and advocacy.',
  },
  {
    name: 'BOOST',
    img: 'integration/boost-medium-plus-livelist.svg',
    link: 'https://www.bluetent.com/boost/?utm_source=vacaymyway&utm_medium=referral&utm_campaign=partner-page',
    description:
      'Better technology. More bookings. Exclusive, high-performance tools & a team to back you up. Acquire new guests and drive more revenue – and stay in control of your brand, pricing rules, and more. Custom pricing and availability settings by date. From implementation to success, we’re here to help. We are a group of travel enthusiasts who bring our passion for life to the work we do every day.',
  },
  {
    name: 'Casago',
    img: 'home/partners/casago.png',
    link: 'https://casago.com/rating/?utm_source=vacaymyway&utm_medium=referral&utm_campaign=partner-page',
    description:
      'With over 20 years of experience in the vacation rental market, Casago has taken property management and bookings to a higher level. With a exceptional rating system that is the Casago Rating, taking our once a year proprietary check point inspection in combination with the extensive guest review process that allows you to search only the best rated properties in our system. In addition, our Casago property management teams are dedicated to customer service ensuring your investment is cleaned, maintained and your guests are cared for with our concierge services.',
  },
];
