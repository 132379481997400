/**
 * @author Libor Staněk
 */
export enum PropertyStatus {
  DRAFT = 'draft',
  READY_FOR_PUBLISH = 'ready_for_publish',
  PUBLISHED = 'published',
  UNPUBLISHED = 'unpublished',
  ARCHIVED = 'archived',
}
