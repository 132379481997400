<dialog-wrapper width="sm" [position]="ls ? 'center' : 'bottom'">
  <dialog-header (dialogClose)="closeDialog()" title="Welcome to VacayMyWay"></dialog-header>
  <dialog-content>
    <app-notification-provider></app-notification-provider>
    <div *ngIf="ls || (!ls && !photoUploaded)">
      <p typography variant="body1">
        Personalize your experience by uploading a profile picture. You can always change it later.
      </p>
    </div>
    <div class="d-flex justify-content-center mt-16">
      <avatar
        class="upload-photo-avatar"
        [src]="(user$ | async)?.avatarUrl"
        [options]="true"
        (upload)="onAvatarUpload($event)"
        (delete)="onAvatarDelete()"
        (imageError)="onAvatarError($event)"
      >AR</avatar>
    </div>
  </dialog-content>
  <dialog-actions>
    <div class="d-flex justify-content-between">
      <button button variant="contained" data-test="button-continue" class="w-100" (click)="continue()">Continue</button>
    </div>
  </dialog-actions>
</dialog-wrapper>
