import { AbstractComponent } from '../../../../../core/components/abstract/abstract.component';
import { Observable } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  Booking,
  BookingAction,
  BookingStatus,
} from '../../../../../shared/models/booking';
import differenceInCalendarDays from 'date-fns/fp/differenceInCalendarDays';
import { TypographyVariant } from '@components/typography/typography.component';
import { calculateOriginalStayPrice } from '../../../../../utils/booking.util';
import { BreakpointService } from '../../../../../core/services/breakpoint.service';
import { BookingTax } from '../../../../../shared/models/booking-taxes';
import { BookingFee } from '../../../../../shared/models/booking-fees';

@Component({
  selector: 'app-booking-pricing',
  templateUrl: 'booking-pricing.component.html',
  styleUrls: ['booking-pricing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingPricingComponent
  extends AbstractComponent
  implements OnInit
{
  public readonly BookingStatus = BookingStatus;

  largeScreen$: Observable<boolean>;

  @Input()
  set booking(val: Booking) {
    this.currentBooking = val;
    this.initBookingData(val);
  }

  @Input()
  isPropertyOwner = true;

  @Input()
  showHints = false;

  @Input()
  showBookingLength = true;

  @Input()
  showSubtotal = true;

  @Input()
  priceVariant: TypographyVariant = 'h6';
  @Input()
  largePriceVariant: TypographyVariant = 'h6';

  // Helper variables
  currentBooking: Booking;
  isReservation: boolean;
  bookingLength = 0;
  payoutCanceled = false;
  bookingTaxes: { total: number; taxes: BookingTax[] };
  bookingFees: { total: number; fees: BookingFee[] };
  originalStayPrice: number;
  subtotal: number;
  popperPlacement: 'right' | 'bottom' = 'right';

  private readonly taxTypeNames = {
    vat: 'VAT',
    touristTax: 'Tourist Tax',
    federail: 'Federal Tax',
    state: 'State Tax',
    county: 'County Tax',
    city: 'City Tax',
    district: 'District Tax',
    other: 'Other tax',
  };

  private readonly feeTypeNames = {
    other: 'Other fee',
    cleaningFee: 'Cleaning fee',
  };

  constructor(private readonly bs: BreakpointService) {
    super();
  }

  ngOnInit(): void {
    this.bs.largeScreen$.pipe(this.untilDestroyed()).subscribe(ls => {
      this.popperPlacement = ls ? 'right' : 'bottom';
    });
  }

  private initBookingData(booking: Booking) {
    this.bookingLength = differenceInCalendarDays(
      booking.interval.checkIn,
      booking.interval.checkOut,
    );
    // Booking that was not PAID is only a reservation
    this.isReservation = !booking.history?.find(
      event => event.action === BookingAction.PAID,
    );

    if (
      booking.status === BookingStatus.CANCELED &&
      (booking.refundedAmount || this.isReservation)
    ) {
      const payoutAfterRefund = booking.totalRevenue - booking.refundedAmount;
      if (booking || payoutAfterRefund < 0) {
        this.payoutCanceled = true;
        // real payout: 0
      }
      // else real payout: payoutAfterRefund
    }

    this.bookingTaxes = this.getBookingTaxes(booking);
    this.bookingFees = this.getBookingFees(booking);
    this.originalStayPrice = calculateOriginalStayPrice(
      booking.clientPricing.stayPrice,
      booking.discounts,
    );
    this.subtotal = this.getSubtotal(booking);
  }

  private getBookingTaxes(booking: Booking): {
    total: number;
    taxes: BookingTax[];
  } {
    const allTaxes =
      booking?.taxesDetail
        ?.map(jurisdiction => jurisdiction.taxes)
        .reduce((acc, taxes) => acc.concat(taxes), []) ?? [];

    const total = allTaxes.reduce((acc, fee) => (acc += fee.amount), 0);

    return {
      total: Math.round((total + Number.EPSILON) * 100) / 100,
      taxes: allTaxes,
    };
  }

  private getBookingFees(booking: Booking): {
    total: number;
    fees: BookingFee[];
  } {
    const allFees = booking?.feesDetail.filter(fee => !fee.includedInStayPrice);

    if (booking.clientPricing.cleaningFee) {
      allFees.push({
        feeRuleId: 'cleaningFee',
        feeType: 'cleaningFee',
        name: 'Cleaning Service',
        amount: booking.clientPricing.cleaningFee,
      });
    }

    const total = allFees.reduce((acc, fee) => (acc += fee.amount), 0);

    return {
      total: Math.round((total + Number.EPSILON) * 100) / 100,
      fees: allFees,
    };
  }

  private getSubtotal(booking: Booking) {
    const totalFees =
      booking.feesDetail?.reduce((sum, fee) => sum + fee.amount, 0) ?? 0;
    const subtotal =
      booking.clientPricing.stayPrice +
      totalFees +
      booking.clientPricing.cleaningFee;
    return Math.trunc(subtotal * 100) / 100;
  }

  getTaxName(taxType: string): string {
    return this.taxTypeNames[taxType] || taxType;
  }

  getFeeName(feeType: string): string {
    return this.feeTypeNames[feeType] || feeType;
  }
}
