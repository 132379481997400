import { Component, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagesCountIconComponent } from '../messages-count-icon/messages-count-icon.component';
import { UserAvatarMenuComponent } from '../user-avatar-menu/user-avatar-menu.component';
import { ButtonModule } from '@components/button/button.module';
import { Url } from '../../../url';
import { LoginComponent } from '../../auth/components/login/dialog/login.component';
import { DialogService } from '../../../core/services/dialog.service';
import { UserService } from '../../../core/services/user.service';
import { Router } from '@angular/router';
import { AbstractComponent } from '../../../core/components/abstract/abstract.component';

@Component({
  standalone: true,
  selector: 'app-menu-user-panel',
  templateUrl: './menu-user-panel.component.html',
  imports: [
    CommonModule,
    MessagesCountIconComponent,
    UserAvatarMenuComponent,
    ButtonModule,
  ],
})
export class MenuUserPanelComponent extends AbstractComponent {
  readonly user$ = this.userService.getCurrentUser();

  @Input()
  variant: 'normal' | 'small' = 'normal';

  @ViewChild('userMenu', { static: false })
  protected userMenuRef: UserAvatarMenuComponent;

  constructor(
    private readonly dialogService: DialogService,
    private readonly userService: UserService,
    private readonly router: Router,
  ) {
    super();
  }

  openMenu() {
    this.userMenuRef?.openMenu();
  }

  closeMenu() {
    this.userMenuRef?.closeMenu();
  }

  get isOpen() {
    return this.userMenuRef?.open;
  }

  onSignIn() {
    this.dialogService.open(LoginComponent);
  }

  onListProperty() {
    this.router.navigate([Url.PROPERTY_WIZARD]);
  }
}
