import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {
  ExpiredTokenError,
  InvalidTokenError,
  NoInternetError,
} from '../../utils/errors';
import { SentryService } from '../services/sentry.service';
import { NotificationService } from '../services/notification.service';
import { Url } from '../../url';

export enum HttpStatus {
  NOT_FOUND = 404,
  FORBIDDEN = 403,
}

/**
 * Handler sends every not processed error to SentryService to logs them.
 * Handler works out of Angular
 */
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private readonly injector: Injector,
    private readonly zone: NgZone,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
  ) {}

  handleError(error: any): void {
    this.zone.run(() => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === HttpStatus.NOT_FOUND) {
          this.router.navigate([Url.NOT_FOUND], { replaceUrl: true });
        }
        if (error.status === HttpStatus.FORBIDDEN) {
          this.router.navigate([Url.FORBIDDEN], { replaceUrl: true });
        }
        return;
      }
      if (error instanceof ExpiredTokenError) {
        this.router.navigate([Url.INDEX]);
        return;
      }
      if (error instanceof InvalidTokenError) {
        this.router.navigate([Url.INDEX]);
        return;
      }
      if (error instanceof NoInternetError) {
        this.notificationService.showNotification({
          text: error.message,
          color: 'error',
          duration: 2000,
        });
        return;
      }
      this.injector.get<SentryService>(SentryService).handleError(error);
    });
  }
}
