import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UserService } from '../../../core/services/user.service';
import { Observable } from 'rxjs';
import { User } from '../../../shared/models/user.model';
import { BreakpointService } from '../../../core/services/breakpoint.service';
import { AbstractComponent } from '../../../core/components/abstract/abstract.component';
import { CommonModule } from '@angular/common';
import { AvatarModule } from '@components/avatar/avatar.module';
import { IconModule } from '@components/icon/icon.module';
import { CoreModule } from '../../../core/core.module';
import { NavigationMenuDesktopModule } from '../navigation-menu/desktop/navigation-menu-desktop.module';
import { OverlayModule } from '@angular/cdk/overlay';

@Component({
  standalone: true,
  selector: 'app-user-avatar-menu',
  templateUrl: './user-avatar-menu.component.html',
  styleUrls: ['./user-avatar-menu.component.scss'],
  imports: [
    CommonModule,
    CoreModule,
    OverlayModule,
    AvatarModule,
    IconModule,
    NavigationMenuDesktopModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarMenuComponent
  extends AbstractComponent
  implements OnInit
{
  @Input()
  hideArrow: boolean;

  open = false;
  user$: Observable<User> = this.userService.getCurrentUser();

  constructor(
    private readonly userService: UserService,
    private readonly breakpointService: BreakpointService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.breakpointService.largeScreen$
      .pipe(this.untilDestroyed())
      .subscribe(largeScreen => {
        if (!largeScreen) {
          this.closeMenu();
        }
      });
  }

  openMenu() {
    this.open = true;
    this.changeDetectorRef.detectChanges();
  }

  closeMenu() {
    this.open = false;
    this.changeDetectorRef.detectChanges();
  }
}
