import { NgModule } from '@angular/core';
import { NavigationMenuContentComponent } from './navigation-menu-content.component';
import { NavigationMenuLinkComponent } from './navigation-menu-link.component';
import { NavigationMenuAccountBalanceComponent } from './navigation-menu-account-balance.component';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { TypographyModule } from '@components/typography/typography.module';
import { IconModule } from '@components/icon/icon.module';
import { UserEmailVerificationInfoComponent } from './user-email-verification-info.component';

@NgModule({
  declarations: [
    NavigationMenuContentComponent,
    NavigationMenuLinkComponent,
    NavigationMenuAccountBalanceComponent,
    UserEmailVerificationInfoComponent,
  ],
  imports: [CommonModule, RouterLink, TypographyModule, IconModule],
  exports: [NavigationMenuContentComponent, UserEmailVerificationInfoComponent],
})
export class NavigationMenuContentModule {}
