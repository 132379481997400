import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import {
  CalendarDay,
  CalendarDayOfWeek,
  Calendar,
} from '@components/calendar/calendar';
import { Subject } from 'rxjs';
import { isSameDay, format } from 'date-fns';
import { getRelativeRect } from '@components/calendar/calendar.utils';

export interface CalendarDayClickEvent {
  day: CalendarDay;
}

export type CalendarDayTextDecoration = 'line-through';
export type CalendarDayTextColor = 'white' | 'gray';

export interface CalendarDaySetting {
  date: Date;
  textColor?: CalendarDayTextColor;
  textDecoration?: CalendarDayTextDecoration;
  price?: string;
  tooltip?: string;
}

export interface ViewDataDay {
  day: CalendarDay;
  rect: DOMRect;
}

export interface ViewData {
  days: ViewDataDay[];
}

@Component({
  selector: 'calendar-month',
  styleUrls: ['./calendar-month.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <table #wrapperRef>
      <thead>
        <tr>
          <th *ngFor="let day of calendar.dayOfWeeks">
            <div class="calendar-day">
              {{ day.shortName }}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let week of calendar.weeks">
          <td
            *ngFor="let day of week.days"
            role="button"
            (click)="onDayClick(day)"
          >
            <ng-container *ngIf="getDaySetting(day) as setting">
              <div
                class="calendar-day"
                [attr.data-test]="
                  'cal-' + (day.date.getMonth() + 1) + '-' + day.number
                "
                *ngIf="+day.month === +calendar.start"
                [ngClass]="getDayClasses(setting)"
                #dayRef
                #tooltip="tippy"
                [tp]="tooltipContent"
                [tpDuration]="0"
                [tpIsEnabled]="setting.tooltip"
                tpPlacement="top"
              >
                <span class="calendar-day-text">{{ day.number }}</span>
                <span *ngIf="setting.price" class="calendar-day-price">{{
                  setting.price
                }}</span>
                <ng-template #tooltipContent>
                  <div (click)="tooltip.hide()">{{ setting.tooltip }}</div>
                </ng-template>
              </div>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  `,
})
export class CalendarMonthComponent
  implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy
{
  @ViewChild('wrapperRef')
  readonly wrapperRef: ElementRef<HTMLTableElement>;
  @ViewChildren('dayRef')
  readonly dayRefs: QueryList<ElementRef<HTMLDivElement>>;

  @Input()
  date: Date = new Date();
  @Input()
  firstDayOfWeek: number = 0;
  @Input()
  daySettings: CalendarDaySetting[];

  @Output()
  dayClick = new EventEmitter<CalendarDayClickEvent>();

  calendar: Calendar;
  private readonly viewSubject = new Subject<ViewData>();
  readonly view$ = this.viewSubject.asObservable();

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    const element = this.elementRef.nativeElement;
    this.calendar = new Calendar(this.date, {
      firstDayOfWeek: CalendarDayOfWeek.SUNDAY,
    });
    this.renderer.addClass(element, `calendar-month`);
    if (this.dayClick.observed) {
      this.renderer.addClass(element, `calendar-month-pointer-enabled`);
    }
  }

  ngAfterViewInit(): void {
    this.refreshViewData();
  }

  ngAfterViewChecked(): void {
    this.refreshViewData();
  }

  ngOnDestroy(): void {
    this.viewSubject.complete();
  }

  getDaySetting(day: CalendarDay): CalendarDaySetting {
    return (
      this.daySettings?.find(setting => +setting.date === +day.date) ?? {
        date: day.date,
      }
    );
  }

  getDayClasses(setting: CalendarDaySetting) {
    return [
      ...(setting.textColor ? [`day-text-color-${setting.textColor}`] : []),
      ...(setting.textDecoration
        ? [`day-text-decoration-${setting.textDecoration}`]
        : []),
    ];
  }

  private refreshViewData(): void {
    const domRects = [...this.dayRefs].map(dayRef =>
      dayRef.nativeElement.getBoundingClientRect(),
    );
    const days = this.calendar.weeks.reduce(
      (acc: CalendarDay[], week) => [
        ...acc,
        ...week.days.filter(day => isSameDay(day.month, this.calendar.start)),
      ],
      [],
    );
    const wrapperRect = this.wrapperRef.nativeElement.getBoundingClientRect();
    const view = {
      days: days.map((day, i) => {
        return {
          day: day,
          rect: getRelativeRect(domRects[i], wrapperRect),
        } as ViewDataDay;
      }),
    };
    this.viewSubject.next(view);
  }

  onDayClick(day: CalendarDay) {
    if (!isSameDay(day.month, this.calendar.start)) {
      return;
    }
    this.dayClick.emit({
      day: day,
    });
  }
}
