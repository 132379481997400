import { ConversationParticipant } from '../modules/chat/models/conversation.model';
import { ClientInfo, User } from '../shared/models/user.model';

export function getUserAvatarInitials(
  user: User | ClientInfo | ConversationParticipant,
) {
  return (
    (user?.firstName?.charAt(0)?.toUpperCase() || '') +
    (user?.lastName?.charAt(0)?.toUpperCase() || '')
  );
}
