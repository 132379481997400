import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { env as environment } from '../../dynamic-environment';

@Injectable({
  providedIn: 'root',
})
export class SentryService {
  private readonly sentryErrorHandler: Sentry.SentryErrorHandler;

  constructor() {
    this.sentryErrorHandler = Sentry.createErrorHandler({
      logErrors: environment.sentry.logError,
      showDialog: false,
    });
  }
  init() {
    this.ifEnabledRun(() => {
      Sentry.init({
        release: 'vacay-ui@11ca1b7085660a304ec58ff1479c8012d872b7ab',
        environment:
          environment.name === 'staging'
            ? 'dev'
            : environment.name === 'production'
              ? 'prod'
              : environment.name,
        dsn: 'https://734a3ad86d794619b8d9da6f28abb032@o436266.ingest.sentry.io/5397076',
        // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
        // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
        // Please note that TryCatch configuration requires at least @sentry/browser v5.16.0.
        integrations: [
          Sentry.browserApiErrorsIntegration({
            setTimeout: true,
            setInterval: true,
            requestAnimationFrame: true,
            // disable XMLHttpRequest wrapping, as we are going to handle http module exceptions manually in
            // Angular's ErrorHandler, and we don't want it to capture the same error twice.
            XMLHttpRequest: false,
            eventTarget: true,
          }),
          Sentry.browserTracingIntegration(),
        ],
        tracePropagationTargets: [
          /^\//, // We call BE without full URL so this is the most important of all those
          /^https:\/\/dev\.vacaymyway\.com/,
          /^https:\/\/preprod\.vacaymyway\.com/,
          /^https:\/\/vacaymyway\.com/,
        ],
        tracesSampleRate: 0.05,
        ignoreErrors: ['Non-Error exception captured'],
      });
    });
  }

  /**
   * Process unhandled error.
   */
  handleError(error) {
    this.sentryErrorHandler.handleError(error);
  }

  /**
   * Report error message into Sentry manually.
   * @param msg custom message
   * @param err optional error details
   */
  reportMessage(msg: string, err?: Error) {
    this.handleErrorMessage(msg, err);
  }

  private handleErrorMessage(msg: string, err?: any): string {
    this.logErr(msg, err);
    return this.ifEnabledRun(() => {
      return Sentry.captureMessage(msg, {
        extra: { __serialized__: err },
        level: 'error',
      });
    });
  }

  private logErr(...args) {
    // If console error logging is enabled, log the error to console for immediate feedback.
    if (environment.sentry.logError) {
      console.error(...args);
    }
  }

  private ifEnabledRun(fn: () => void): any {
    if (environment.sentry.enabled) {
      return fn();
    }
  }
}
