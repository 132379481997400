import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypographyModule } from '@components/typography/typography.module';
import { AvatarComponent } from '@components/avatar/avatar.component';
import { AvatarPhotoService } from '@components/avatar/avatar-photo.service';
import { ButtonModule } from '@components/button/button.module';

@NgModule({
  declarations: [AvatarComponent],
  exports: [AvatarComponent],
  imports: [CommonModule, TypographyModule, ButtonModule],
  providers: [AvatarPhotoService],
})
export class AvatarModule {}
