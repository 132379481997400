import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControlledComponent } from '@components/abstract/abstract-controlled.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * @see https://www.figma.com/file/4URZYURZBcwfpYp3HvkSL6/Web-components-VMW?node-id=5102%3A21998
 * @author Libor Staněk
 */
@Component({
  selector: 'toggle',
  styleUrls: ['./toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ToggleComponent,
      multi: true,
    },
  ],
  template: ` <span class="toggle-container">
      <input
        type="checkbox"
        [formControl]="control"
        class="toggle-input"
        data-test="toggle"
      />
      <span class="toggle-box"></span>
      <span class="toggle-switch"></span>
    </span>
    <span class="toggle-label" *ngIf="label">{{ label }}</span>`,
})
export class ToggleComponent
  extends AbstractControlledComponent<boolean>
  implements OnInit
{
  @Input()
  label: string;

  constructor(
    injector: Injector,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    const element = this.elementRef.nativeElement;
    this.renderer.addClass(element, `toggle2`);
    this.value$.subscribe(value => {
      if (value) {
        this.renderer.addClass(element, `toggle-checked`);
      } else {
        this.renderer.removeClass(element, `toggle-checked`);
      }
    });
  }

  @HostListener('click')
  onClick() {
    this.value = !this.value;
  }
}
