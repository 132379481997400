import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export type SortDirection = 'asc' | 'desc';

export interface PageableQuery<S = any> {
  page?: number;
  size?: number;
  sortBy?: S;
  sortDirection?: SortDirection;
}

export class AbstractApi {
  protected readonly http: HttpClient;
  protected base: string;

  constructor(private readonly injector: Injector) {
    this.http = this.injector.get(HttpClient);
  }
}
