import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractComponent } from '../../../../../core/components/abstract/abstract.component';

@Component({
  selector: 'app-login-email-route',
  template: ` <div class="mx-16 mt-24 mt-md-64">
    <app-login-form [isDialog]="false"></app-login-form>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginEmailRouteComponent extends AbstractComponent {}
