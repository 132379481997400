import { Pipe, PipeTransform } from '@angular/core';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

@Pipe({
  name: 'formatDateToNow',
  pure: false,
})
export class FormatDateToNowPipe implements PipeTransform {
  transform(dateTime: Date, args?: any): string {
    return formatDateToNow(dateTime);
  }
}

export function formatDateToNow(dateTime: Date): string {
  if (!dateTime) {
    return '';
  }
  return formatDistanceToNow(dateTime, { addSuffix: true });
}
