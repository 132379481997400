<div class="d-flex align-items-center ml-auto" *ngIf="user$ | async; else noAuth">
  <app-messages-count-icon *ngIf="variant === 'normal'"></app-messages-count-icon>
  <app-user-avatar-menu #userMenu></app-user-avatar-menu>
</div>
<ng-template #noAuth>
  <div>
    <button
      button
      *ngIf="variant === 'normal'"
      variant="inline"
      color="secondary"
      endIcon="plus"
      data-test="list-property"
      (click)="onListProperty()"
    >
      List your property
    </button>
    <button
      button
      variant="contained"
      color="primary"
      endIcon="user_circle"
      class="ml-16"
      data-test="sign-in"
      (click)="onSignIn()"
    >
      Sign In
    </button>
  </div>
</ng-template>
