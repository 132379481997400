<dialog-wrapper width="sm" [position]="ls ? 'center' : 'bottom'" >
  <dialog-header title="Sign up for VacayMyWay" (dialogClose)="closeDialog()"></dialog-header>
  <form ngNoForm (submit)="submit($event)">
    <dialog-content>
      <div notification class="mb-16" *ngIf="error$ | async as error">
        {{error}}
      </div>

      <div class="signup-inputs-container">
        <div typography class="pb-16">Tell us about yourself.</div>
        <div *ngIf="signupType === 'phone'">
          <input-text [formControl]="form.controls.phone" [label]="'Phone'" class="my-8"></input-text>
        </div>
        <input-text [formControl]="form.controls.email" [label]="'Email'" type="email" inputMode="email" class="my-8"></input-text>
        <div class="row">
          <input-text [formControl]="form.controls.firstName" [label]="'First Name'" class="col-6"></input-text>
          <input-text [formControl]="form.controls.lastName" [label]="'Last Name'" class="col-6"></input-text>
        </div>
      </div>
      <div *ngIf="showConsentCheckboxes">
        <div class="d-flex mt-16">
          <checkbox data-test="terms" [formControl]="form.controls.agreeTermsAndConditions" class="mx-4"></checkbox>
          <span typography variant="body1">
            I have read and agree to
            <a link variant="link1" (click)="openTermsAndConditions()">
              Terms and Conditions
            </a>
          </span>
        </div>
        <div class="d-flex mt-8">
          <checkbox data-test="policy" [formControl]="form.controls.agreePrivacyPolicy" class="mx-4"></checkbox>
          <span typography variant="body1">
            I have read and agree to
            <a link variant="link1" (click)="openPrivacyPolicy()">
              Privacy Policy
            </a>
          </span>
        </div>
      </div>
    </dialog-content>
    <dialog-actions>
      <button button type="submit" color="primary" class="w-100" data-test="sign-up-button">Continue</button>
    </dialog-actions>
  </form>
</dialog-wrapper>
