import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-sign-up-route',
  template: `<div class="mx-16 mt-24 mt-md-64">
    <app-signup [isDialog]="false"></app-signup>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpRouteComponent {}
