import { NgModule } from '@angular/core';
import { ChipComponent } from './components/chip.component';
import { CommonModule } from '@angular/common';
import { IconModule } from '@components/icon/icon.module';
import { ButtonModule } from '@components/button/button.module';

@NgModule({
  declarations: [ChipComponent],
  imports: [CommonModule, IconModule, ButtonModule],
  exports: [ChipComponent],
})
export class ChipLegacyModule {}
