import { DOCUMENT } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeHtmlTags' })
export class RemoveHtmlTagsPipe implements PipeTransform {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  transform(text: string): string {
    let div = this.document.createElement('div');
    div.innerHTML = text;
    return div.textContent ?? div.innerText ?? '';
  }
}
