import {
  addDays,
  endOfWeek,
  startOfWeek,
  endOfMonth,
  startOfMonth,
} from 'date-fns';

export interface DateInterval {
  start: Date;
  end: Date;
}

/**
 * Utils for calendar components
 *
 * @author Libor Staněk
 */
export class CalendarUtils {
  /**
   * Split input interval into array of intervals for each week
   * @param options:
   *   - splitMonths: split week on end of mouth to 2 separate intervals
   * @example:
   *   {2021/01/01 10:00:00 - 2021/01/02 11:00:00} ->
   *     [{2021/01/01 10:00:00 - 2021/01/02 11:00:00}]
   *   {2021/01/01 10:00:00 - 2021/01/03 11:00:00} ->
   *     [{2021/01/01 10:00:00 - 2021/01/02 23:59:59}, {2021/01/03 00:00:00 - 2021/01/03 11:00:00}]
   *   {2021/01/01 10:00:00 - 2021/01/12 11:00:00} ->
   *     [{2021/01/01 10:00:00 - 2021/01/02 23:59:59}, {2021/01/03 00:00:00 - 2021/01/09 23:59:59}, {2021/01/10 00:00:00 - 2021/01/12 11:00:00}]
   */
  static splitIntervalIntoWeeks(
    interval: DateInterval,
    options: { splitMonths?: boolean } = {},
  ): DateInterval[] {
    const intervals: DateInterval[] = [];
    const start = interval.start;
    const end = interval.end;
    let currentWeekStart = start;
    while (true) {
      const weekEnd = endOfWeek(currentWeekStart);
      if (
        options.splitMonths &&
        weekEnd.getMonth() !== currentWeekStart.getMonth()
      ) {
        const monthEnd = endOfMonth(currentWeekStart);
        if (monthEnd > end) {
          intervals.push({ start: currentWeekStart, end });
          break;
        }
        intervals.push({
          start: currentWeekStart,
          end: monthEnd,
        });
        currentWeekStart = startOfMonth(weekEnd);
        continue;
      }
      if (weekEnd > end) {
        intervals.push({ start: currentWeekStart, end });
        break;
      }
      intervals.push({ start: currentWeekStart, end: weekEnd });
      currentWeekStart = startOfWeek(addDays(weekEnd, 1));
    }
    return intervals;
  }
}
