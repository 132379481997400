import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

export class ExpiredTokenError extends Error {}

export class InvalidTokenError extends Error {}

export class UserNotFoundError extends Error {}

export class AdditionalRegistrationClosedError extends Error {}

export class NoInternetError extends Error {}

export class InvalidVerificationSecret extends Error {}

export class InsufficientRewardPointError extends Error {}

export function getUserFriendlyError(error): string {
  if (error instanceof HttpErrorResponse) {
    return typeof error.error === 'string'
      ? error.error
      : error.error.payload?.message || error.error.message;
  }
  // Auth0 error handling, primary to inform logging in user about no Internet connection
  if (error.original) {
    if (error.original.message?.startsWith('Request has been terminated')) {
      return 'Oops, something went wrong. Please check your internet connection.';
    }
  }
  return error?.description || error?.message || error;
}

export function parseBlobError(err: HttpErrorResponse): Observable<any> {
  const reader: FileReader = new FileReader();

  const obs = new Observable((observer: any) => {
    reader.onloadend = e => {
      try {
        observer.error(JSON.parse(reader.result as string));
      } catch (error: any) {
        observer.error(reader.result);
      }
      observer.complete();
    };
  });
  reader.readAsText(err.error);
  return obs;
}
