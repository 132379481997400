
<div class="menu-footer">
  <ng-container *ngIf="user$ | async as user">
    <button
      button
      variant="contained"
      color="primary"
      class="menu-footer-button"
      (click)="onSignOut()"
    >
      Sign Out
    </button>
  </ng-container>
</div>
