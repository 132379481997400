import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthService } from '../../../../../core/services/auth.service';
import { BreakpointService } from '../../../../../core/services/breakpoint.service';
import { DialogService } from '../../../../../core/services/dialog.service';
import { NotificationService } from '../../../../../core/services/notification.service';
import {
  AdditionalRegistrationClosedError,
  getUserFriendlyError,
} from '../../../../../utils/errors';
import {
  markAsSubmitted,
  updateControls,
} from '../../../../../utils/form.util';
import { emailValidator } from '../../../../../utils/validators';
import { ParentAuthenticationDialog } from '../../parent-authentication-dialog';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { RedirectService } from '../../../../../core/services/redirect.service';
import { Url } from '../../../../../url';
import { WelcomeComponent } from '../../post-registration-dialogs/welcome/post-registration-dialogs/welcome/welcome.component';

/**
 * Component dialog for login via Auth0 with user´s email and password
 */
@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent
  extends ParentAuthenticationDialog
  implements OnInit, OnDestroy
{
  form: UntypedFormGroup;
  incorrectCredentialsNotificationText =
    'The email or password you entered is incorrect.';

  /** Large screen flag */
  ls: boolean;

  /** Subject for sign in errors */
  error$ = new Subject<string>();

  @Input() isDialog = true;
  // TODO HEL replace once authentication state is an observable
  constructor(
    injector: Injector,
    private readonly router: Router,
    private readonly redirectService: RedirectService,
    private readonly auth0: AuthService,
    private readonly notificationService: NotificationService,
    private readonly breakpointService: BreakpointService,
    private readonly changeDetection: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    readonly dialogService: DialogService,
    @Optional()
    private readonly dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) @Optional() private readonly data: any,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required,
        emailValidator(),
      ]),
      password: new UntypedFormControl('', Validators.required),
    });
    this.breakpointService.largeScreen$
      .pipe(this.untilDestroyed())
      .subscribe(isLargeScreen => {
        this.ls = isLargeScreen;
        this.changeDetection.detectChanges();
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.error$.complete();
  }

  submit(event: Event) {
    event.preventDefault();
    const controls = this.form.controls;
    if (this.form.invalid) {
      if (!this.ls) {
        this.notificationService.showNotification({
          text: this.incorrectCredentialsNotificationText,
          duration: 5000,
          color: 'error',
          styleClasses: 'notification-top-up',
        });
      }
      this.form.markAllAsTouched();
      markAsSubmitted(this.form);
      updateControls(this.form);
      return;
    }
    this.auth0
      .signInEmail(controls.email.value, controls.password.value)
      .pipe(this.untilDestroyed())
      .subscribe(
        user => {
          this.error$.next(null);
          if (
            !this.router.url.includes('bookings') ||
            this.router.url.includes('bookingId')
          ) {
            this.redirectService.navigate();
          }
          if (user.isNewUser) {
            this.dialogService.clearAndOpen(WelcomeComponent);
          }
          if (this.isDialog) {
            this.dialogRef.close();
          }
        },
        error => {
          if (error instanceof AdditionalRegistrationClosedError) {
            this.error$.next(null);
            return;
          }
          this.error$.next(getUserFriendlyError(error));
        },
      );
  }

  forgotPassword() {
    if (!this.isDialog) {
      this.router.navigate(['/login/forgotten'], {
        queryParamsHandling: 'preserve',
      });
    } else {
      this.dialogService.clearAndOpen(ResetPasswordComponent);
    }
  }
}

/**
 * Login form link, opens Login Form dialog.
 */
@Component({
  selector: 'app-login-form-link',
  template: `
    <button
      button
      type="button"
      variant="contained"
      color="secondary"
      class="w-100 mb-16"
      data-test="login-method-email"
      (click)="handleClick()"
    >
      <ng-content></ng-content>
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormLinkComponent {
  @Input() isDialog = true;

  constructor(
    private readonly router: Router,
    private readonly dialogService: DialogService,
  ) {}

  handleClick() {
    if (!this.isDialog) {
      this.router.navigate([Url.LOGIN_EMAIL], {
        queryParamsHandling: 'preserve',
      });
    } else {
      this.dialogService.clearAndOpen(LoginFormComponent);
    }
  }
}
