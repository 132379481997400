import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-login-route',
  template: ` <div class="mx-16 mt-24 mt-md-64">
    <app-login [isDialog]="false"></app-login>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginRouteComponent {}
