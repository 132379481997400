import { Component } from '@angular/core';
import { of } from 'rxjs';
import { StorageKey } from '../../../../shared/enums/local-storage-key.enum';
import { catchError } from 'rxjs/operators';
import { PhoneVerificationDialogComponent } from './phone-verification-dialog.component';

@Component({
  selector: 'app-p1h0m89-verification',
  templateUrl: './phone-verification-dialog.component.html',
  styleUrls: ['./phone-verification-dialog.component.scss'],
})
export class Auth0PhoneVerificationDialogComponent extends PhoneVerificationDialogComponent {
  onSubmitPhoneVerification(data: string) {
    const redirectUrl = this.router.url;
    if (redirectUrl.includes('bookings')) {
      this.storageService.setItem(
        StorageKey.BOOKING_SOCIAL_SIGN_UP,
        redirectUrl,
      );
    }
    this.authService
      .signInPhoneLogin(this.phoneNumber, data)
      .pipe(
        this.blockUiService.blockPipe(),
        catchError(error => {
          if (error.description.startsWith('Wrong phone')) {
            this.errors$.next(
              'The verification code is incorrect. Please enter the code from the text message',
            );
          } else {
            this.dialogRef.close(error.description);
          }
          return of(null);
        }),
      )
      .subscribe();
  }

  resendVerificationCodeSms() {
    this.authService.signInPhoneStart(this.phoneNumber).subscribe(
      () => {
        this.errors$.next(null);
        this.showResendCodeNotification();
      },
      error => {
        this.dialogRef.close(error.description);
      },
    );
  }
}
