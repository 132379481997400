import { Pipe, PipeTransform } from '@angular/core';
import { PaymentMethodCard } from '../../shared/models/payment-method';

@Pipe({ name: 'formatCardExpire' })
export class FormatCardExpirePipe implements PipeTransform {
  transform(card: PaymentMethodCard): string {
    return `${card.expireMonth.toString().padStart(2, '0')}/${card.expireYear
      .toString()
      .substring(2)}`;
  }
}
