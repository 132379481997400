<dialog-wrapper width="md" [position]="(largeScreen$ | async) === true ? 'center' : 'bottom'">
  <ng-container *ngIf="(largeScreen$ | async) === true; else mobileViewHeader">
    <dialog-header title="Cancel your Vacay" (dialogClose)="closeDialog()" [showClose]="true"></dialog-header>
  </ng-container>
  <ng-template #mobileViewHeader>
    <dialog-header title="Cancel your Vacay"></dialog-header>
  </ng-template>
  <dialog-content>
    <div class="row">
      <div class="col-12">
        <div class="d-flex">
          <h2 typography variant="h5" color="ink">Cancellation Policy</h2>
          <a button variant="inline" class="ms-8" href="https://help.vacaymyway.com/article/16-cancellation-policy">Read
            more</a>
        </div>
        <div class="d-flex mt-16" *ngIf="refundRule">
          <span typography variant="h6" color="ink">Full refund till</span>
          <span class="ml-4" typography variant="h6"
            [color]="isRefundRuleExpired(refundRule) ? 'red' : 'green'">{{refundRule.dateBefore | date: 'EEE h:mm a
            MM/dd/yyyy OOOO'}}</span>
        </div>
        <span typography variant="body2">
          <ng-container *ngIf="!isRefundRuleExpired(refundRule); else expiredRefundRule">
            Money will be returned to you credit card.
          </ng-container>
          <ng-template #expiredRefundRule>
            Your Vacay is no longer refundable.
          </ng-template>
        </span>
        <div class="booking-detail-delimiter"></div>
      </div>

      <div class="col-12" *ngIf="!isRefundRuleExpired(refundRule)">
        <h2 typography variant="h5" color="ink">Refund details</h2>
        <div class="d-flex justify-content-between align-items-center mt-16">
          <span typography variant="body2">{{property?.headline}}</span>
          <span typography variant="em1">{{refundRule.totalAmount | currency: 'USD'}}</span>
        </div>
        <div class="d-flex justify-content-between align-items-center refund-total-price mt-8">
          <span typography variant="body1">Total</span>
          <span typography variant="h3">{{refundRule.totalAmount | currency: 'USD'}}</span>
        </div>
        <div class="booking-detail-delimiter"></div>
      </div>

      <div [formGroup]="form" class="col-12">
        <h2 typography variant="h5" color="ink">What is the reason for cancelling</h2>
        <div class="mt-16">
          <span typography variant="body1">This is needed to complete your cancellation</span>
        </div>
        <div class="mt-16">
          <dropdown formControlName="reason" label="Select reason" data-test="cancelation-dropdown"
            [options]="reasonsOptions"></dropdown>
        </div>
        <div class="mt-16" *ngIf="currentReason === UserReservationCancelReasons.OTHER">
          <h2 typography variant="h6" color="ink">Reason</h2>
          <input-textarea formControlName="message" data-test='quick-note' label="Message" [autosize]="true"
            [hideLabel]="true" class="w-100"></input-textarea>
        </div>
      </div>
    </div>

  </dialog-content>
  <dialog-actions>
    <div class="d-flex">
      <ng-container *ngIf="(largeScreen$ | async) === true; else mobileViewActions">
        <div class="pr-8">
          <button button variant="contained" (click)="cancel()">
            I want to cancel this reservation
          </button>
        </div>
        <div class="pl-8">
          <button button variant="contained" color="secondary" (click)="closeDialog()">
            No, I don't want to cancel
          </button>
        </div>
      </ng-container>
      <ng-template #mobileViewActions>
        <div class="pr-8">
          <button button variant="contained" (click)="closeDialog()">
            Don’t cancel
          </button>
        </div>
        <div class="pl-8">
          <button button variant="contained" (click)="cancel()">
            Cancel booking
          </button>
        </div>
      </ng-template>
    </div>
  </dialog-actions>
</dialog-wrapper>