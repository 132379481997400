import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-home-section-header',
  templateUrl: './home-section-header.component.html',
  styleUrl: './home-section-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeSectionHeaderComponent {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() description = '';
  @Input() align: 'left' | 'center' = 'left';
}
