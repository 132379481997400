import { DestroyRef, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs';

/**
 * Service provide a simple way for customers to reach out to vacay team through Beacon
 *
 * Beacon is present only on landing page, properties list, property edit (preview) and property wizard.
 */
@Injectable({
  providedIn: 'root',
})
export class HelpscoutBeaconService {
  readonly beaconId = '8ed3decf-ad2c-49d2-9b91-e85ebb3317bb';
  readonly beaconUrlList: RegExp[] = [
    new RegExp('^/$'),
    new RegExp('^/properties/.*/edit.*'),
    new RegExp('^/property-wizard.*'),
  ];

  private beaconOn = false;

  constructor(
    private readonly router: Router,
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private readonly destroyRef: DestroyRef,
  ) {}

  init() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd,
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((event: NavigationEnd) => {
        const isMatched = this.isBeaconUrlMatched(event.url);
        if (isMatched && !this.beaconOn) {
          this.turnOnBeacon(this.beaconId);
        } else if (!isMatched && this.beaconOn) {
          this.turnOffBeacon();
        }
      });
  }

  private turnOnBeacon(beaconId: string) {
    (window as any).Beacon('init', beaconId);
    this.beaconOn = true;
  }

  private turnOffBeacon() {
    (window as any).Beacon('destroy');
    this.beaconOn = false;
  }

  private isBeaconUrlMatched(url: string): boolean {
    for (const beaconUrlRegExp of this.beaconUrlList) {
      if (beaconUrlRegExp.test(url)) {
        return true;
      }
    }
    return false;
  }
}
