import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_USER_BASE, Url } from '../../url';
import {
  VerificationForm,
  VerificationFormIdentityDocumentSide,
  VerificationFormUpdate,
  VerificationIdentityDocumentConfig,
} from '../../shared/models/verification.model';
import { User } from '../../shared/models/user.model';
import { catchError } from 'rxjs/operators';
import { parseBlobError } from '../../utils/errors';
import { Router } from '@angular/router';
import {
  QUERY_BOOKING_ID,
  QUERY_SOURCE,
  VerificationPageSourceType,
} from '../../modules/user/user-verification/services/verification-page.service';

const VERIFICATION_API_BASE = API_USER_BASE + '/verification';

@Injectable({
  providedIn: 'root',
})
export class VerificationService {
  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
  ) {}

  public getVerificationForm(secret?: string): Observable<VerificationForm> {
    return this.http.get<VerificationForm>(`${VERIFICATION_API_BASE}/form`, {
      params: secret
        ? {
            secret: secret,
          }
        : undefined,
    });
  }

  public updateVerificationForm(
    body: VerificationFormUpdate,
    secret?: string,
  ): Observable<VerificationForm> {
    return this.http.put<VerificationForm>(
      `${VERIFICATION_API_BASE}/form`,
      body,
      {
        params: secret
          ? {
              secret: secret,
            }
          : undefined,
      },
    );
  }

  public submitVerificationForm(): Observable<void> {
    return this.http.post<void>(`${VERIFICATION_API_BASE}/form/submit`, {});
  }

  public deleteVerificationForm(): Observable<void> {
    return this.http.delete<void>(`${VERIFICATION_API_BASE}/form`);
  }

  public sendVerificationLinkEmail(email: string): Observable<void> {
    return this.http.post<void>(`${VERIFICATION_API_BASE}/form/email-link`, {
      email: email,
    });
  }

  public uploadIdentityDocument(
    side: VerificationFormIdentityDocumentSide,
    file: File,
    secret?: string,
  ): Observable<Blob> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http
      .post(
        `${VERIFICATION_API_BASE}/form/identity-document/${side}`,
        formData,
        {
          responseType: 'blob',
          params: secret
            ? {
                secret: secret,
              }
            : undefined,
        },
      )
      .pipe(catchError(parseBlobError));
  }
  public uploadSelfie(file: File, secret: string): Observable<Blob> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http
      .post(`${VERIFICATION_API_BASE}/form/selfie`, formData, {
        responseType: 'blob',
        params: secret
          ? {
              secret: secret,
            }
          : undefined,
      })
      .pipe(catchError(parseBlobError));
  }

  public getIdentityDocumentConfigs(): Observable<
    VerificationIdentityDocumentConfig[]
  > {
    return this.http.get<VerificationIdentityDocumentConfig[]>(
      `${VERIFICATION_API_BASE}/identity-document-configs`,
    );
  }

  public openVerification(
    user: User,
    source: VerificationPageSourceType,
    bookingId?: string,
  ) {
    this.router.navigate([Url.USER_VERIFICATION], {
      queryParams: {
        [QUERY_SOURCE]: source,
        [QUERY_BOOKING_ID]: bookingId,
      },
    });
  }

  public triggerUpdate(): Observable<void> {
    return this.http.post<void>(`${VERIFICATION_API_BASE}/trigger-update`, {});
  }
}
