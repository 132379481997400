import { Pipe, PipeTransform } from '@angular/core';
import { Property } from '../../shared/models/property';
import { PropertyService } from '../services/property.service';
import { Observable } from 'rxjs';

@Pipe({
  name: 'propertyType',
})
export class PropertyTypePipe implements PipeTransform {
  constructor(private readonly propertyService: PropertyService) {}

  transform(property: Property): Observable<string> {
    return this.propertyService.getPropertyTypeLabel(
      property?.propertyType,
      'Unknown',
    );
  }
}
