<ng-container *ngIf="user$ | async as user">
  <div class="navbar-menu" *ngIf="user$ | async as user">
    <button
      class="menu-opener cursor-pointer"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      (click)="openMenu()"
    >
      <div *ngIf="user" class="avatar-container">
        <avatar [src]="user.avatarUrl" class="avatar" data-test="avatar-menu" [hoverDisabled]="true">{{ user | userInitials }}</avatar>
      </div>
      <icon *ngIf="!hideArrow" [variant]="open ? 'chevron_up' : 'chevron_down'" color="ink" class="ml-12"></icon>
    </button>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="open"
      (overlayOutsideClick)="$event.stopPropagation(); closeMenu()"
    >
      <app-navigation-menu-desktop (linkClicked)="closeMenu()"></app-navigation-menu-desktop>
    </ng-template>
  </div>
</ng-container>
