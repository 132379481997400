import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../core/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractComponent } from '../../../core/components/abstract/abstract.component';
import { take } from 'rxjs/operators';
import { Url } from '../../../url';

/**
 * Handler component for linking unassigned booking with registered user
 */
@Component({
  selector: 'app-login-with-booking',
  template: '',
})
export class LoginWithBookingHandlerComponent
  extends AbstractComponent
  implements OnInit
{
  constructor(
    private readonly userService: UserService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.userService
      .isAuthenticated()
      .pipe(take(1))
      .subscribe(isAuthenticated => {
        if (!isAuthenticated) {
          this.router.navigate(['/login'], {
            queryParamsHandling: 'preserve',
          });
        } else {
          this.router.navigate([Url.INDEX]);
        }
      });
  }
}
