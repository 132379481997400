import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import type { Auth0UserProfile } from 'auth0-js';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  of,
  throwError,
} from 'rxjs';
import {
  catchError,
  concatMap,
  first,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { AdditionFormComponent } from '../../modules/auth/components/signup/addition-form/addition-form.component';
import { StorageKey } from '../../shared/enums/local-storage-key.enum';
import { User } from '../../shared/models/user.model';
import {
  AdditionalRegistrationClosedError,
  UserNotFoundError,
} from '../../utils/errors';
import { blockUi, BlockUiService } from './block-ui.service';
import { DialogService } from './dialog.service';
import { UserService } from './user.service';
import { LoginComponent } from '../../modules/auth/components/login/dialog/login.component';
import { Auth0ApiService, Auth0TokenData } from './auth0-api.service';
import { RedirectService } from './redirect.service';
import { StorageService } from './storage.service';
import { Url } from '../../url';
import { PHONE_NUMBER_FORMAT } from '../../utils/validators';

export type AuthProviderType = 'db' | 'social' | 'sms' | null;

/**
 *  Service for handling authentication via auth0, facebook and google providers
 *
 *  @author Jakub Jílek, Libor Staněk
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly ssoCookieName = 'auth0.ssodata';
  private readonly ssoCookieExpired =
    'auth0.ssodata=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

  private readonly tokenChangesSubject: BehaviorSubject<Auth0TokenData | null>;
  tokenChanges$: Observable<Auth0TokenData | null>;

  refreshToken$: Subject<void> = new Subject<void>();

  constructor(
    private readonly auth0ApiService: Auth0ApiService,
    private readonly dialogService: DialogService,
    private readonly userService: UserService,
    private readonly blockUiService: BlockUiService,
    private readonly router: Router,
    private readonly redirectService: RedirectService,
    private readonly storageService: StorageService,
    @Inject(PLATFORM_ID) private readonly platformId: any,
  ) {
    this.tokenChangesSubject = new BehaviorSubject<Auth0TokenData | null>(
      this.getRawTokenData(),
    );
    this.tokenChanges$ = this.tokenChangesSubject.asObservable();

    this.refreshToken$
      .asObservable()
      .pipe(
        concatMap(() => {
          const rawToken = this.getRawTokenData();
          if (rawToken) {
            return this.refreshToken(rawToken);
          }

          return EMPTY;
        }),
      )
      .subscribe();
  }

  /** Init function for AuthService factory */
  init(): Observable<User> {
    if (!isPlatformBrowser(this.platformId)) {
      return of(null);
    }
    return this.auth0ApiService.init().pipe(
      switchMap(() => {
        const isSsoCookie = this.isSsoCookie();
        // Check user session
        return this.checkPageLoadSession().pipe(
          tap(user => {
            // If user is not logged in, check SSO cookie
            if (!user && isSsoCookie) {
              return this.checkPageLoadSso().subscribe(ssoUser => {
                if (ssoUser) {
                  // If SSO login is success, redirect user to main page
                  this.router.navigate([Url.INDEX], {
                    queryParamsHandling: 'preserve',
                  });
                }
              });
            }
          }),
        );
      }),
    );
  }

  /** Check browser user session on page load */
  private checkPageLoadSession(): Observable<User> {
    return this.getTokenData().pipe(
      switchMap(tokenData => {
        if (tokenData) {
          return this.userService.fetchCurrentUser().pipe(
            first(),
            catchError(error => {
              this.userService.logout();
              this.setTokenData(null);
              if (error instanceof UserNotFoundError) {
                this.router.navigate([Url.LOGIN]);
                return of(null);
              }
              return of(null);
            }),
          );
        } else {
          this.userService.logout();
          return of(null);
        }
      }),
    );
  }

  /** Check SSO cookie on page load */
  private checkPageLoadSso(): Observable<User> {
    if (!this.isSsoCookie()) {
      return of(null);
    }
    return this.checkSsoSession().pipe(
      tap(user => {
        this.clearSsoCookie();
      }),
      catchError(error => {
        return of(null);
      }),
    );
  }

  refreshToken(tokenData: Auth0TokenData): Observable<Auth0TokenData> {
    return this.auth0ApiService.refreshToken(tokenData.refreshToken).pipe(
      tap(refreshedTokenData => {
        this.setTokenData(refreshedTokenData);
      }),
    );
  }

  /**
   * Get access from local storage
   *
   * @return accessToken if session exists, otherwise null is returned
   */
  getAccessToken(): Observable<string | null> {
    return this.getTokenData().pipe(map(tokenData => tokenData?.accessToken));
  }

  /**
   * Get token data observable
   *
   * @return Auth0TokenData if session exists, otherwise null is returned
   */
  getTokenData(): Observable<Auth0TokenData | null> {
    const tokenData = this.getRawTokenData();
    if (!tokenData) {
      return of(null);
    }
    if (this.isExpired(tokenData)) {
      return this.refreshToken(tokenData).pipe(
        catchError(error => {
          // Secondary check, if the token has been modified
          const tokenDataCheck = this.getRawTokenData();
          if (tokenDataCheck && !this.isExpired(tokenDataCheck)) {
            return of(tokenDataCheck);
          }
          this.logout();
          return of(null);
        }),
      );
    }
    return of(tokenData);
  }

  /**
   * Get token data from local storage
   *
   * @return Auth0TokenData if session exists, otherwise null is returned
   */
  getRawTokenData(): Auth0TokenData | null {
    try {
      const rawData = this.storageService.getItem(StorageKey.TOKEN);
      if (!rawData) {
        return null;
      }
      return JSON.parse(this.storageService.getItem(StorageKey.TOKEN));
    } catch (e) {
      return null;
    }
  }

  /**
   * Set token data to local storage
   * If null is provided, session data will be cleared from local storage
   */
  setTokenData(tokenData: Auth0TokenData | null): void {
    this.tokenChangesSubject.next(tokenData);
    if (tokenData) {
      this.storageService.setItem(StorageKey.TOKEN, JSON.stringify(tokenData));
    } else {
      this.revokeRefreshToken();
      this.storageService.removeItem(StorageKey.TOKEN);
    }
  }

  /**
   * Revoke current refresh token
   */
  revokeRefreshToken() {
    const currentTokenData = this.getRawTokenData();
    if (currentTokenData?.refreshToken) {
      this.auth0ApiService
        .revokeRefreshToken(currentTokenData.refreshToken)
        .subscribe({
          error: () => {
            // Ignore errors
          },
        });
    }
  }

  /** Sign in (login) via email */
  signInEmail(email: string, password: string): Observable<User> {
    return this.auth0ApiService.login(email, password).pipe(
      blockUi(this.blockUiService),
      switchMap(tokenData => {
        this.setTokenData(tokenData);
        return this.userService.fetchCurrentUser().pipe(
          blockUi(this.blockUiService),
          tap(() => {
            this.userService.updateTimeZone();
          }),
          catchError(error => {
            return this.handleFetchUserError(tokenData, error);
          }),
        );
      }),
    );
  }

  /** Sign in via google provider, Auth0 redirect user to social login handler */
  signInGoogle(): void {
    this.clearSsoCookie();
    return this.auth0ApiService.authorize('google-oauth2');
  }

  /** Sign up (register) via email */
  signUpEmail(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
  ): Observable<User> {
    return this.userService
      .registerUser(email, password, firstName, lastName)
      .pipe(
        switchMap(user => this.signInEmail(email, password)),
        map(user => ({
          ...user,
          isNewUser: true,
        })),
        blockUi(this.blockUiService),
      );
  }

  /**
   * Should send verification code to users phone.
   * @param phoneNumber number to send verification code to
   * @returns auth0 data
   */
  signInPhoneStart(phoneNumber: string): Observable<any> {
    return this.auth0ApiService.passwordlessStart(phoneNumber).pipe(
      catchError(error => {
        error.status = error.statusCode;
        error.error = {
          message: PHONE_NUMBER_FORMAT,
        };
        throw error;
      }),
    );
  }

  /**
   * Verify phone number with auth0 database
   * If everything goes right, user will be redirected to
   * redirectUri.
   * @param phoneNumber phone number to verify
   * @param verificationCode code entered by user
   */
  signInPhoneLogin(
    phoneNumber: string,
    verificationCode: string,
  ): Observable<Auth0TokenData> {
    return this.auth0ApiService.passwordlessLogin(
      phoneNumber,
      verificationCode,
    );
  }

  /*** Logout current Auth0 session from the app */
  logout(options: { redirect: boolean } = { redirect: false }) {
    this.setTokenData(null);
    this.clearSsoCookie();
    this.userService.logout();
    this.redirectService.resetUrl();
    if (options.redirect) {
      this.router.navigate([Url.LOGIN]);
    }
  }

  /** Get if SSO cookie exists */
  private isSsoCookie() {
    return document.cookie.indexOf(this.ssoCookieName) >= 0;
  }

  /** Remove SSO from cookies storage */
  private clearSsoCookie() {
    document.cookie = this.ssoCookieExpired;
  }

  /** Handle redirect from signInGoogle, signInFacebook or signInPhone */
  handleSocialLogin(): Observable<User> {
    // Find authorization code in url query params
    const code = window.location.search.split('code=')[1]?.split('&')[0];
    return this.auth0ApiService.authorizeCode(code).pipe(
      blockUi(this.blockUiService),
      switchMap(tokenData => {
        this.clearSsoCookie();
        this.setTokenData(tokenData);
        return this.userService.fetchCurrentUser().pipe(
          blockUi(this.blockUiService),
          tap(() => {
            this.userService.updateTimeZone();
          }),
          catchError(error => {
            // Could not log in, register required
            const url =
              this.storageService.getItem(StorageKey.BOOKING_SOCIAL_SIGN_UP) ||
              this.redirectService.getUrlOrFallback();
            const isSmsSignUp = this.getIdentityProvider() === 'sms';
            if (isSmsSignUp && RegExp('.*/bookings/.*/summary.*').test(url)) {
              // Skipping fetching user and opening registration dialogs as this is SMS signup
              return of(null);
            }
            return this.handleFetchUserError(tokenData, error);
          }),
        );
      }),
    );
  }

  /** Check if SSO session exists and is valid user */
  checkSsoSession(): Observable<User> {
    return this.auth0ApiService.checkSession().pipe(
      switchMap(tokenData => {
        this.setTokenData(tokenData);
        return this.userService.fetchCurrentUser().pipe(
          catchError(error => {
            return this.handleFetchUserError(tokenData, error);
          }),
        );
      }),
    );
  }

  /** If userInfo request is success, open additional register form */
  private requestProfileAndOpenAdditionalRegister(
    tokenData: Auth0TokenData,
  ): Observable<User> {
    return this.auth0ApiService.userInfo(tokenData.accessToken).pipe(
      blockUi(this.blockUiService),
      switchMap(profileUser => {
        return this.openAdditionalRegisterForm(profileUser);
      }),
    );
  }

  /** Open first name and last name user prompt */
  private openAdditionalRegisterForm(
    profile: Auth0UserProfile,
  ): Observable<User> {
    return this.dialogService
      .clearAndOpen(AdditionFormComponent, {
        disableClose: true,
        closeOnNavigation: false,
        data: {
          profile,
        },
      })
      .afterClosed()
      .pipe(
        map(result => {
          if (!result) {
            // Clear token because additional registration not succeeded
            this.setTokenData(null);
            throw new AdditionalRegistrationClosedError();
          }
          const user = result.user;
          user.isNewUser = true;
          return user;
        }),
      );
  }

  /**
   * Naive extraction of user's identity provider from access token.
   * Return null if token is not present
   */
  public getIdentityProvider(): AuthProviderType {
    const accessToken = this.getRawTokenData()?.accessToken;
    if (!accessToken) {
      return null;
    }
    const parsedAccessToken =
      this.auth0ApiService.parseAccessToken(accessToken);
    if (
      parsedAccessToken.sub &&
      parsedAccessToken.sub.startsWith('auth0') &&
      parsedAccessToken.gty === 'password'
    ) {
      return 'db';
    }
    if (parsedAccessToken.sub && parsedAccessToken.sub.startsWith('sms')) {
      return 'sms';
    }
    return 'social';
  }

  private handleFetchUserError(tokenData, error: any) {
    if (error instanceof UserNotFoundError) {
      return this.requestProfileAndOpenAdditionalRegister(tokenData);
    }
    this.setTokenData(null);
    return throwError(error);
  }

  /** Open login dialog */
  requestLogin(returnTo?: string) {
    this.redirectService.setUrl(returnTo ?? window.location.pathname);
    this.dialogService.open(LoginComponent);
  }

  private isExpired(tokenData: Auth0TokenData): boolean {
    return tokenData.expires - Date.now() < 10_000;
  }
}
