<ng-container *ifEnabled="'chat'">
  <a [routerLink]="getChatLink(conversations.unreadConversations)"
     *ngIf="{unreadConversations: unreadConversations$ | async} as conversations"
     class="messages-count-icon mr-20">
    <icon
      variant="mail"
      size="normal"
      data-test="inbox"
    ></icon>
    <span class="messages-badge"
          data-test="new-message"
          *ngIf="conversations.unreadConversations?.length as messagesCount">
      {{messagesCount > 99 ? '99+' : messagesCount}}
    </span>
  </a>
</ng-container>
