import { NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { env as environment } from '../dynamic-environment';

// gtag script is imported in index.html -> just reference as global var
// eslint-disable-next-line
declare let gtag: Function;

export function initGtm(
  router: Router,
  ngZone: NgZone,
  gtmService: GoogleTagManagerService,
) {
  if (typeof gtag === 'undefined') {
    // Most likely running in SSR
    return;
  }

  // google analytics & tag manager must be enabled in environment
  // register page events, run outside angular zone
  ngZone.runOutsideAngular(() => {
    router.events.subscribe(routerEvent => {
      if (routerEvent instanceof NavigationEnd) {
        gtag('config', environment.ga4.ga4Id, {
          page_path: routerEvent.urlAfterRedirects,
        });
        gtag('config', environment.ga4.awConversionId, {
          page_path: routerEvent.urlAfterRedirects,
        });
        if (environment.ga.enabled) {
          const gtmTag = {
            event: 'page',
            pageName: routerEvent.urlAfterRedirects,
          };
          gtmService.pushTag(gtmTag).catch(() => {});
          //
          gtag('config', environment.ga.gaId, {
            page_path: routerEvent.urlAfterRedirects,
          });
        }
      }
    });
  });
}
