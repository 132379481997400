import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractComponent } from 'src/app/core/components/abstract/abstract.component';
import { User, VerificationStatus } from 'src/app/shared/models/user.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chat-recipient',
  templateUrl: 'chat-recipient.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatRecipientComponent extends AbstractComponent {
  @Input() recipient: User;
  @Input() hideBio = false;
  @Input() title: string = '';

  readonly VerificationStatus = VerificationStatus;
  readonly ratingVisible = !!environment.enabledFeatures.reviews;
}
