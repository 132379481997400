<div class="menu-desktop" data-test="user-menu">
  <a
    *ngIf="user$ | async as user"
    class="menu-item spacer-bottom cursor-pointer"
    [routerLink]="[Url.SETTINGS]"
    (click)="onLinkClicked()"
  >
    <app-user-email-verification-info [user]="user" variant="em2"></app-user-email-verification-info>
  </a>
  <app-navigation-menu-content (linkClicked)="onLinkClicked()"></app-navigation-menu-content>
</div>
