import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { StorageKey } from '../../shared/enums/local-storage-key.enum';
import { NotificationService } from './notification.service';
import { isPlatformBrowser } from '@angular/common';

interface StorageStatus {
  cookies: boolean;
  localStorage: boolean;
  sessionStorage: boolean;
}

/**
 * Wrapper around localStorage and sessionStorage API in browser
 * Use this service instead of localStorage or sessionStorage
 *
 * If user has deactivated storage in browser, in memory storage will be used
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private readonly TEST_KEY = 'test';

  private readonly status: StorageStatus;

  // If browser has disabled storage, use in memory storage
  private inMemoryLocalStorage = new Map<string, string>();
  private inMemorySessionStorage = new Map<string, string>();

  constructor(
    private readonly notificationService: NotificationService,
    @Inject(PLATFORM_ID) private readonly platformId: any,
  ) {
    // Load and save storage status in current browser
    this.status = this.getStorageStatus();

    // Display cookies disabled error
    if (isPlatformBrowser(this.platformId)) {
      if (!this.status.cookies || !this.status.localStorage) {
        this.notificationService.showNotification({
          text: 'Cookies are disabled in your browser. Please enable cookies in browser settings.',
          color: 'error',
          duration: -1,
          onClose: () => {
            // Just to show close button
          },
        });
      }
    }
  }

  /**
   * Save item to local storage
   * Wrapper around: localStorage.setItem function
   */
  setItem(key: StorageKey, value: string): void {
    if (!this.status.localStorage) {
      this.inMemoryLocalStorage.set(key, value);
      return;
    }
    localStorage.setItem(key, value);
  }

  /**
   * Get item from local storage
   * Wrapper around: localStorage.getItem function
   */
  getItem(key: StorageKey): string | null {
    if (!this.status.localStorage) {
      return this.inMemoryLocalStorage.get(key) ?? null;
    }
    return localStorage.getItem(key);
  }

  /**
   * Delete item from local storage
   * Wrapper around: localStorage.removeItem function
   */
  removeItem(key: StorageKey): void {
    if (!this.status.localStorage) {
      this.inMemoryLocalStorage.delete(key);
      return;
    }
    localStorage.removeItem(key);
  }

  /**
   * Save item to session storage
   * Wrapper around: sessionStorage.setItem function
   */
  setSessionItem(key: StorageKey, value: string): void {
    if (!this.status.sessionStorage) {
      this.inMemorySessionStorage.set(key, value);
      return;
    }
    sessionStorage.setItem(key, value);
  }

  /**
   * Save item to session storage
   * Wrapper around: sessionStorage.setItem function
   */
  getSessionItem(key: StorageKey): string | null {
    if (!this.status.sessionStorage) {
      return this.inMemorySessionStorage.get(key) ?? null;
    }
    return sessionStorage.getItem(key);
  }

  /**
   * Delete item from session storage
   * Wrapper around: sessionStorage.removeItem function
   */
  removeSessionItem(key: StorageKey): void {
    if (!this.status.sessionStorage) {
      this.inMemorySessionStorage.delete(key);
      return;
    }
    sessionStorage.removeItem(key);
  }

  /**
   * Get status of storage features in browser
   */
  private getStorageStatus(): StorageStatus {
    const status = {} as StorageStatus;
    try {
      if (navigator.cookieEnabled) {
        status.cookies = true;
      } else {
        document.cookie = `${this.TEST_KEY}=1`;
        status.cookies = document.cookie.indexOf(`${this.TEST_KEY}=`) !== -1;
        document.cookie = `${this.TEST_KEY}=1; expires=Thu, 01-Jan-1970 00:00:01 GMT`;
      }
    } catch (e) {
      status.cookies = false;
    }
    try {
      localStorage.setItem(this.TEST_KEY, 'test');
      localStorage.removeItem(this.TEST_KEY);
      status.localStorage = true;
    } catch (e) {
      status.localStorage = false;
    }
    try {
      sessionStorage.setItem(this.TEST_KEY, 'test');
      sessionStorage.removeItem(this.TEST_KEY);
      status.sessionStorage = true;
    } catch (e) {
      status.sessionStorage = false;
    }
    return status;
  }
}
