<p typography *ngIf="showEffectiveDate" class="mb-32">Effective Date: May 31, 2021</p>
<p typography>
  At <strong>VacayMyWay</strong>, integrity, trust and transparency are core principles of our company. We
  recognize that your privacy is particularly important, and we take it seriously. Please read this
  <strong>Privacy Statement</strong> carefully as it explains who we are, how and why we collect, store, use, and
  share your personal information. It also explains your rights in relation to your personal
  information and how to contact us.
</p>
<p typography>
  <strong>IMPORTANT</strong>: BY USING OUR WEBSITE, MOBILE APPLICATION, AND/OR SERVICES
  AVAILABLE ON THE WEBSITE OR MOBILE APPLICATION, YOU GIVE YOUR CONSENT
  THAT ALL PERSONAL DATA THAT YOU SUBMIT MAY BE PROCESSED BY US IN THE
  MANNER AND FOR THE PURPOSES DESCRIBED BELOW. IF YOU DO NOT AGREE TO
  THESE TERMS AND CONDITIONS, DO NOT USE VACAYMYWAY.
</p>
<h2 typography variant="h4" id="overview">1. Overview</h2>
<p typography>
  Vaycaymyway, Inc. d/b/a VacayMyWay, a Delaware Corporation (“we”, “us”,
  “our”) collects, uses and is responsible for certain personal information about you. When we do
  so, we are subject to various laws in the United States depending on where you reside and the
  <strong>General Data Protection Regulation (“GDPR”)</strong> if you reside in the European Union (including in
  the United Kingdom).
</p>
<p typography>
  When you create an account on VacayMyWay, list a property, make a booking, or sign up to
  receive information and special offers, you provide us your personal information. We also use
  automated technology like cookies to collect your information when you visit our website or use
  our mobile application. We may also receive your information from our business partners and
  other third parties to help us improve VacayMyWay and our services.
</p>
<p typography>
  Your personal information may be used or shared to assist you in making a booking, provide
  assistance during your stay, communicate with you (including communications with property
  owners and property managers through the platform), or our compliance with laws and
  regulations that apply to VacayMyWay.
</p>
<h2 typography variant="h4" id="personal-information-we-collect-about-you">2. Personal Information We Collect About
  You</h2>
<p typography>
  When you use our platform, mobile
  application, or our services, we may collect and use the following kinds of personal information
  that identifies, relates to, describes, is reasonable capable of being associated with, or could
  reasonably be linked, directly or indirectly, with a particular consumer or household:
</p>
<table aria-label="Personal information we collect">
  <thead>
  <tr>
    <th scope="col">Categories of Personal Information</th>
    <th scope="col">Specific Types of Personal Information Collected</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td>Identifiers</td>
    <td>Real name, alias, home, business and billing
      addresses, unique personal identifier, online
      identifier, Internet Protocol address, email
      address, account name, social security
      number, tax identification number, driver’s
      license number, passport number, or other
      similar identifiers. Loyalty program and
      membership information.
    </td>
  </tr>
  <tr>
    <td>Information that identifies, relates to,
      describes, or is capable of being associated
      with, a particular individual.
    </td>
    <td>Name, signature, social security number,
      physical characteristics or description, birth
      date, gender, address, telephone number,
      passport number, driver’s license or state
      identification card number as required for
      bookings or to verify identity, insurance policy
      number, bank account number, credit card
      number, debit card number, or any other
      financial information like expiration date and
      billing address. Images, videos, and other
      recordings. Social media account IDs and
      login information. Communications with us.
      Other communications that occur through our
      platform among partners, owners, and
      travelers. Any data that you provide us about
      other people such as travel companions.
    </td>
  </tr>
  <tr>
    <td>Commercial information.</td>
    <td>Real property records to verify ownership.
      Records of personal property, products or
      services purchased, obtained, or considered,
      or other purchasing or consuming histories or
      tendencies.
    </td>
  </tr>
  <tr>
    <td>Internet or other electronic network activity
      information.
    </td>
    <td>Browsing history, search history, and
      information regarding a consumer’s
      interaction with an Internet Web site,
      application, or advertisement including pages
      accessed, links clicked, trips viewed, features
      used, and the corresponding date and time.
      IP address, device type, unique device
      identification number, internet browser type,
      internet service provider, operating system,
      mobile carrier,
    </td>
  </tr>
  <tr>
    <td>Geolocation data</td>
    <td>General geographic location data such as
      country, state, and city level information.
    </td>
  </tr>
  <tr>
    <td>Mobile Application data</td>
    <td>Device connectivity information such as
      carrier, network, operator, SIM operator, and
      SIM country. Operating system, operating
      version, and device model. Performance and
      data usage. Device settings selected or
      enabled such as Wi-Fi, GPS, and Bluetooth.
      Device settings. Usage data concerning your
      interaction with our mobile application.
    </td>
  </tr>
  <tr>
    <td>Inferences drawn from any of the information
      identified above to create a profile about a
      consumer reflecting the consumer’s
      preferences, characteristics, psychological
      trends, predispositions, behavior, attitudes,
      intelligence, abilities, and aptitudes.
    </td>
    <td>Travel related preferences such as
      accessibility requirements, destinations, price
      point, type of accommodation, dietary needs,
      and wish lists
    </td>
  </tr>
  </tbody>
</table>
<p typography>This personal information is required to provide services to you. If you do not provide personal
  information we ask for, it may delay or prevent us from providing services to you.
</p>
<h2 typography variant="h4" id="how-your-personal-information-is-collected">3. How Your Personal Information is
  Collected</h2>
<p typography>We collect most of this personal information
  directly from you: in person, by telephone, text, email, and/or via our website and apps.
  However, we may also collect information:</p>
<ul>
  <li>From publicly accessible sources (e.g., property records);</li>
  <li>Directly from a third party (e.g., our partners, insurance agencies, credit reporting
    agencies, or customer due diligence providers);
  </li>
  <li>From a third party with your consent (e.g., your bank);</li>
  <li>From cookies on our website; and</li>
  <li>Via our IT systems, including automated monitoring of our website, mobile application,
    and other technical systems, such as our computer networks and connections, access
    control systems, communications systems, and email and instant messaging systems.
  </li>
</ul>
<p typography>Our Cookie Statement expands upon how we use cookies and other similar tracking
  technologies.
</p>
<p typography>Permissions for Location Based Services: Depending on your mobile device’s settings and
  permissions and your choice in whether to participate in certain services and programs offered
  by VacayMyWay, we may collect the location of your device by using GPS signals, cell phone
  towers, Wi-Fi signals, Bluetooth or other technologies. We will collect this information to enable
  certain location-based services available within the mobile application. To disable location
  capabilities of the app, you can log off or change your mobile device’s settings to turn off
  location-based services.
</p>
<h2 typography variant="h4" id="how-and-why-we-use-your-personal-information">4. How and Why We Use Your Personal
  Information</h2>
<p typography>Under data protection law, we can
  only use your personal information if we have a proper reason for doing so, e.g.:</p>
<ul>
  <li>To comply with our legal and regulatory obligations;</li>
  <li>For the performance of our services for you or to take steps at your request before
    providing the services;
  </li>
  <li>For our legitimate interests or those of a third party; or</li>
  <li>Where you have given consent.</li>
</ul>
<p typography>A legitimate interest is when we have a business or commercial reason to use your
  information, so long as this is not overridden by your own rights and interests.
</p>
<p typography>The table below explains what we use your personal information for and our reasons for doing
  so:</p>
<table aria-label="What we use your personal information for">
  <thead>
  <tr>
    <th scope="col">What we use your personal information for</th>
    <th scope="col">Our reasons</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td>To provide the website, mobile application,
      and services to you.
    </td>
    <td>To enable and process your property
      booking. To provide services related to your
      account or a booking. To create and update
      your account. To maintain your search and
      travel history, preferences, and similar
      information about your use of VacayMyWay.
      To facilitate processing of payments and
      related transactions. To administer loyalty
      and reward programs. To facilitate property
      and user reviews. To allow you to use
      VacayMyWay faster by using third-party
      sign-in functionality.
    </td>
  </tr>
  <tr>
    <td>Communications and marketing.</td>
    <td>To respond to your inquiries and
      information requests. To process your
      information choices. To facilitate
      communications between travelers and
      owners, property managers, and suppliers
      such as insurance providers. To contact
      you to provide booking confirmations,
      updates, for marketing purposes and for
      other purposes that are described in this
      Privacy Statement. To market VacayMyWay
      and our services, optimize our marketing to
      make it more relevant to you, and to
      analyze the effectiveness of our marketing.
      To administer promotions.
    </td>
  </tr>
  <tr>
    <td>To prevent and detect fraud and unauthorized
      activities against you or VacayMyWay.
    </td>
    <td>For our legitimate interests or those of a
      third party, i.e. to minimize fraud that could
      be damaging for us and for you.
    </td>
  </tr>
  <tr>
    <td>Conducting checks to identify our customers
      and verify their identity.
    </td>
    <td>To promote security and to comply with our
      legal and regulatory obligations.
    </td>
  </tr>
  <tr>
    <td>Compliance.</td>
    <td>To comply with all laws applicable to our
      business, protect our and our users’ rights,
      defend ourselves, and respond to legal
      process by law enforcement and other
      agencies that are part of the legal process.
      To comply with applicable security and
      anti-terrorism, anti-bribery, customs,
      immigration, and related due-diligence
      requirements.
    </td>
  </tr>
  <tr>
    <td>Ensuring business policies are adhered to,
      e.g. policies covering security and internet
      use
    </td>
    <td>For our legitimate interests or those of a
      third party, i.e. to make sure we are
      following our own internal procedures so
      we can deliver the best service to you. To
      defend against claims and other liabilities.
      To manage other risks.
    </td>
  </tr>
  <tr>
    <td>Operational reasons, such as improving
      efficiency, training, quality control, and dispute
      resolution.
    </td>
    <td>For our legitimate interests or those of a
      third party, i.e. to be as efficient as we can
      so we can deliver the best service for you
      at the best price
    </td>
  </tr>
  <tr>
    <td>Ensuring the confidentiality of commercially
      sensitive information
    </td>
    <td>For our legitimate interests or those of a
      third party, i.e. to protect trade secrets and
      other commercially valuable information. To
      comply with our legal and regulatory
      obligations.
    </td>
  </tr>
  <tr>
    <td>Statistical analysis to help us manage our
      business, e.g. in relation to our financial
      performance, customer base, product range
      or other efficiency measures.
    </td>
    <td>For our legitimate interests or those of a
      third party, i.e. to be as efficient as we can
      so we can deliver the best service for you
      at the best price.
    </td>
  </tr>
  <tr>
    <td>Preventing unauthorized access and
      modifications to systems
    </td>
    <td>For our legitimate interests or those of a
      third party, i.e. to prevent and detect
      criminal activity that could be damaging for
      us and for you. To comply with our legal
      and regulatory obligations.
    </td>
  </tr>
  <tr>
    <td>Updating and enhancing customer records.</td>
    <td>For the performance of our services
      through the platform or to take steps at your
      request before facilitating a booking. To
      comply with our legal and regulatory
      obligations. For our legitimate interests or
      those of a third party, e.g. making sure that
      we can keep in touch with our customers
      about existing orders and new products.
    </td>
  </tr>
  <tr>
    <td>Statutory returns.</td>
    <td>To comply with our legal and regulatory
      obligations.
    </td>
  </tr>
  <tr>
    <td>Ensuring safe working practices, staff
      administration and assessments.
    </td>
    <td>To comply with our legal and regulatory
      obligations. For our legitimate interests or
      those of a third party, e.g. to make sure we
      are following our own internal procedures
      and working efficiently so we can deliver
      the best service to you.
    </td>
  </tr>
  <tr>
    <td>Marketing our services and those of selected
      third parties to existing and former customers;
      third parties who have previously expressed
      an interest in our services; and third parties
      with whom we have had no previous dealings.
    </td>
    <td>For our legitimate interests or those of a
      third party, i.e. to promote our business to
      existing and former customers.
    </td>
  </tr>
  <tr>
    <td>Credit reference checks via external credit
      reference agencies
    </td>
    <td>For our legitimate interests or those of a
      third party, i.e. to ensure our customers are
      likely to be able to pay for our services,
      booking, and any property damage.
    </td>
  </tr>
  <tr>
    <td>External audits, quality checks, and the audit
      of our accounts
    </td>
    <td>For our legitimate interests or those of a
      third party, i.e. to maintain our
      accreditations so we can demonstrate we
      operate at the highest standards. To comply
      with our legal and regulatory obligations
    </td>
  </tr>
  <tr>
    <td>To operate our business as permitted by law.</td>
    <td>For our legitimate interests. To create
      aggregated or otherwise anonymized or
      de-identified data for use and disclosure
      without restriction, where permissible.
    </td>
  </tr>
  </tbody>
</table>
<p typography>The above table does not apply to special category personal information, which we will only
  process with your explicit consent.
</p>
<h2 typography variant="h4" id="promotional-communications">5. Promotional Communications</h2>
<p typography>We may use your personal information to send you
  updates (by email, text message, telephone, or mail) about VacayMyWay’s services, including
  exclusive offers, promotions or new products and services.</p>
<p typography>We have a legitimate interest in processing your personal information for promotional purposes
  (see above “How and why we use your personal information”). This means we do not usually
  need your consent to send you promotional communications. However, where consent is
  needed, we will ask for this consent separately and clearly.
</p>
<p typography>We will always treat your personal information with the utmost respect and never sell it to other
  organizations outside of VacayMyWay for marketing purposes.
</p>
<p typography>You have the right to opt out of receiving promotional communications at any time by:</p>
<ul>
  <li>Contacting us at <a typography variant="link1" href="mailto:info@vacaymyway.com">info&#64;vacaymyway.com</a>; or</li>
  <li>Using the “unsubscribe” link in emails or “STOP” number in texts.</li>
</ul>
<p typography>We may ask you to confirm or update your marketing preferences if you instruct us to provide
  further services in the future, or if there are changes in the law, regulation, or the structure of
  our business.</p>
<h2 typography variant="h4" id="who-we-share-your-personal-information-with">6. Who We Share Your Personal Information
  With</h2>
<p typography>As described in this Privacy Statement
  and where permitted by law, we routinely share personal information with:</p>
<ul>
  <li><strong>Our affiliates, including companies within the VacayMyWay group.</strong> We share your
    personal information with companies within the VacayMyWay group. They may access,
    use, and share your personal information as described in this Privacy Statement.
  </li>
  <li><strong>Service providers.</strong> We share your personal information with third-party service providers
    that we use to help deliver our services to you and to operate our business. This includes,
    by way of example, payment service providers, customer service representatives, business
    analytics services, website hosts, compliance and fraud prevention services, marketing and
    targeted advertising services, credit reporting services, and our financial institutions. We
    require that third-party service providers protect personal information shared with them.
    Additionally, third-party service providers are prohibited from using directly identifying
    personal information for any purpose other than providing the services that we contracted
    with them to provide.
  </li>
  <li><strong>Travel-Related services providers.</strong> We share your personal information with
    travel-related service providers who assist in the facilitation of your booking. These include
    property owners, property managers, insurance brokers, and where available, activity
    providers. Travel-related service providers may contact you directly for additional
    information where required to facilitate your booking or to otherwise provide their services.
  </li>
  <li><strong>Third parties approved by you.</strong> We share your personal information with third parties
    that you have approved. This includes third-party payment providers and social media sites
    you choose to link your account to and use as a single sign-on to login to VacayMyWay
    using your social media credentials. While using single sign-on, you will share information
    with the social media company, or other provider (such as Google), information such as the
    fact that you have visited and interacted with VacayMyWay. These third parties may
    combine the information provided with other information that they already have about you.
    All information shared with these third parties shall be governed by their own privacy policy
    and they can inform you how to modify your privacy settings on their platforms.
  </li>
  <li><strong>Business Partners.</strong> We will share your information to third party business partners if we
    offer a service or product in conjunction with a business partner. Your information will be
    provided to the business partner for the purpose of assisting with marketing or providing the
    service or product. In most cases, the special offer or program will be presented in the
    name of the business partner, either individually or combined with VacayMyWay. You may
    also be redirected from VacayMyWay to the website of the business partner with notice.
  </li>
  <li><strong>Legal and Regulatory Obligations.</strong> We may disclose your personal information to
    enforce our contractual rights, contracts, and policies, or where we are otherwise required
    to do so by applicable law. This may arise in connection with responding to a request by
    law enforcement or other governmental authority in connection with actual or proposed
    litigation or regulatory actions. It may also arise where necessary, in our good faith opinion,
    to protect and defend our property, people, or other rights and interests. We may also
    disclose your personal information in response to a valid subpoena or other legal request or
    where required to remit taxes in the course of processing bookings and payments.
  </li>
  <li><strong>Our Corporate Transactions.</strong> We may also need to share some personal information
    with other parties, such as potential buyers of some or all of our business or during a
    restructuring, divestiture, asset sale, or merger. We will typically anonymize information, but
    this may not always be possible. The recipient of the information will be bound by
    confidentiality obligations.
  </li>
  <li><strong>Payment Processing.</strong> We use Stripe for payment, analytics, and other business
    services. Stripe collects identifying information about the devices that connect to its
    services. Stripe uses this information to operate and improve the services it provides to us,
    including for fraud detection. You can learn more about Stripe and read its privacy policy at
    <a typography variant="link1" href="https://stripe.com/privacy" target="_blank">https://stripe.com/privacy</a>.
  </li>
</ul>
<h2 typography variant="h4" id="personal-information-we-sold-or-disclosed-for-a-business-purpose">7. Personal
  Information We Sold or Disclosed for a Business Purpose</h2>
<p typography>We have not sold
  to a third party any personal information that identifies, relates to, describes, is capable of
  being associated with, or could reasonably be linked, directly or indirectly, with a particular
  consumer or household.
</p>
<p typography>In the preceding 12 months, we have disclosed for a business purpose to one or more third
  parties the following categories of personal information that identifies, relates to, describes, is
  capable of being associated with, or could reasonably be linked, directly or indirectly, with a
  particular consumer or household:
</p>
<ul>
  <li>Identifiers (e.g., a real name, alias, postal address, unique personal identifier, online
    identifier, Internet Protocol address, email address, account name, social security number,
    driver’s license number, passport number, or other similar identifiers);
  </li>
  <li>Information that identifies, relates to, describes, or is capable of being associated with, a
    particular individual, including, but not limited to, his or her name, signature, social security
    number, physical characteristics or description, address, telephone number, passport
    number, driver’s license or state identification card number, insurance policy number,
    education, employment, employment history, bank account number, credit card number,
    debit card number, or any other financial information, medical information, or health
    insurance information;
  </li>
  <li>Commercial information (e.g., records of personal property, products or services
    purchased, obtained, or considered, or other purchasing or consuming histories or
    tendencies);
  </li>
  <li>Internet or other electronic network activity information (e.g., browsing history, search
    history, and information regarding a consumer’s interaction with an Internet Web site,
    application, or advertisement);
  </li>
  <li>Geolocation data; and
  </li>
  <li>Inferences drawn from any of the information identified above to create a profile about a
    consumer reflecting the consumer’s preferences, characteristics, psychological trends,
    predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.
  </li>
</ul>
<h2 typography variant="h4" id="where-your-personal-information-is-held">8. Where Your Personal Information is Held</h2>
<p typography>The personal information that we process may
  be held at our offices and those of our VacayMyWay third party agencies, service providers,
  representatives and agents as described above (see above: “Who We Share Your Personal
  Information with”). The personal information may be transferred or transmitted to countries
  other that the country in which you reside. Those countries may have laws that treat data
  protection differently that the laws of your country
</p>
<p typography>The servers for VacayMyWay’s platform are located within the United States. Some of the third
  parties that we share personal information with, in accordance with the terms of this Privacy
  Statement, may be based outside of the United States and will process your information in the
  country where they are based.
</p>
<p typography>We have taken reasonable and appropriate steps to enact safeguards to ensure that your
  personal information will remain protected in accordance with the terms of this Privacy
  Statement. For instance, VacayMyWay has certified to the EU-U.S. and Swiss-U.S. Privacy
  Shield frameworks and adheres to the Privacy Shield Principals of Notice, Choice,
  Accountability for Onward Transfers, Security, Data Integrity and Purpose Limitation, Access,
  Recourse, Enforcement, and liability for personal information from the EU, Switzerland, and
  the United Kingdom. For more information about the Privacy Shield principals, please visit:
  <a typography variant="link1" href="https://www.privacyshield.gov" target="_blank">www.privacyshield.gov</a>.
  Additionally, we require that all third-party service providers to whom
  we transfer data have enacted appropriate safeguards in compliance with applicable data
  protection laws.
</p>
<h2 typography variant="h4" id="how-long-your-personal-information-will-be-kept">9. How Long Your Personal Information
  Will Be Kept</h2>
<p typography>We will keep your personal
  information while you have an account with us or while we are providing services to you.
  Thereafter, we will keep your personal information for as long as is necessary:</p>
<ul>
  <li>To respond to any questions, complaints or claims made by you or on your behalf</li>
  <li>To show that we treated you fairly;</li>
  <li>To fulfill the purposes set forth in this Privacy Statement; or</li>
  <li>To keep records required by law.</li>
</ul>
<p typography>We will not retain your personal information for longer than necessary for the purposes set out
  in this policy. Different retention periods apply for different types of personal information. We
  will de-identify, aggregate, or anonymize your personal information if we intend to use it for
  analytical purposes over a longer period of time. When it is no longer necessary to retain your
  personal information, we will delete or anonymize it.
</p>
<h2 typography variant="h4" id="your-rights-under-the-gdpr">10. Your Rights Under the GDPR</h2>
<p typography>If you reside in the European Union or the United Kingdom, you have the following rights under
  the GDPR:</p>
<table aria-label="GDPR">
  <thead class="d-none">
    <tr>
      <th scope="col">Right</th>
      <th scope="col">Description</th>
    </tr>
  </thead>
  <tbody>
  <tr>
    <td>Right to Access</td>
    <td>The right to be provided with a copy of your
      personal information (the right of access)
    </td>
  </tr>
  <tr>
    <td>Right to Rectification</td>
    <td>The right to require us to correct any
      mistakes in your personal information
    </td>
  </tr>
  <tr>
    <td>Right to be Forgotten</td>
    <td>The right to require us to delete your
      personal information—in certain situations
    </td>
  </tr>
  <tr>
    <td>Right to Restriction of Processing</td>
    <td>The right to require us to restrict processing
      of your personal information—in certain
      circumstances, e.g. if you contest the
      accuracy of the data
    </td>
  </tr>
  <tr>
    <td>Right to Data Portability</td>
    <td>The right to receive the personal
      information you provided to us, in a
      structured, commonly used and
      machine-readable format and/or transmit
      that data to a third party—in certain
      situations
    </td>
  </tr>
  <tr>
    <td>Right to Object</td>
    <td>The right to object: i) at any time to your
      personal information being processed for
      direct marketing (including profiling); ii) in
      certain other situations to our continued
      processing of your personal information,
      e.g. processing carried out for the purpose
      of our legitimate interests.
    </td>
  </tr>
  <tr>
    <td>Right Not to be Subject to Automated
      Individual Decision-Making
    </td>
    <td>The right not to be subject to a decision
      based solely on automated processing
      (including profiling) that produces legal
      effects concerning you or similarly
      significantly affects you
    </td>
  </tr>
  </tbody>
</table>
<p typography>For further information on each of those rights, including the circumstances in which they
  apply, see the guidance from the UK Information Commissioner's Office (ICO) on individual
  rights under the General Data Protection Regulation.</p>
<h2 typography variant="h4" id="your-rights-under-the-ccpa">11. Your Rights Under the CCPA</h2>
<p typography>You have the right under the California Consumer Privacy
  Act of 2018 (CCPA) and certain other privacy and data protection laws, as applicable, to
  exercise free of charge:
</p>
<table aria-label="ccpa">
  <thead class="d-none">
    <tr>
      <th scope="col">Item</th>
      <th scope="col">Description</th>
    </tr>
  </thead>
  <tbody>
  <tr>
    <td><strong>Disclosure of Personal Information We
      Collect About You
    </strong></td>
    <td>
      <p typography>You have the right to know:</p>
      <ul>
        <li>The categories of personal information
          we have collected about you;
        </li>
        <li>The categories of sources from which the
          personal information is collected;
        </li>
        <li>Our business or commercial purpose for
          collecting or selling personal information;</li>
        <li>The categories of third parties with whom
          we share personal information, if any; and</li>
        <li>The specific pieces of personal
          information we have collected about you.
        </li>
      </ul>
      <p typography class="mt-16">Please note that we are not required to:</p>
      <ul>
        <li>Retain any personal information about
          you that was collected for a single
          one-time transaction if, in the ordinary
          course of business, that information about
          you is not retained;</li>
        <li>Reidentify or otherwise link any data that,
          in the ordinary course of business, is not
          maintained in a manner that would be
          considered personal information; or</li>
        <li>Provide the personal information to you
          more than twice in a 12-month period.
        </li>
      </ul>
    </td>
  </tr>
  <tr>
    <td><strong>Personal Information Sold or Used for a
      Business Purpose
    </strong></td>
    <td>
      <p typography>In connection with any personal information
        we may sell or disclose to a third party for a
        business purpose, you have the right to
        know:</p>
      <ul>
        <li>
          The categories of personal information
          about you that we sold and the categories
          of third parties to whom the personal
          information was sold; and
        </li>
        <li>The categories of personal information
          that we disclosed about you for a business
          purpose.
        </li>
      </ul>
      <p typography class="mt-16">You have the right under the California
        Consumer Privacy Act of 2018 (CCPA) and
        certain other privacy and data protection
        laws, as applicable, to opt-out of the sale of
        your personal information. If you exercise
        your right to opt-out of the sale of your
        personal information, we will refrain from
        selling your personal information, unless you
        subsequently provide express authorization
        for the sale of your personal information.
        To
        opt-out of the sale of your personal
        information, contact us or our Data
        Protection Officer (see “How
        To Contact Us”
        below).
      </p>
    </td>
  </tr>
  <tr>
    <td><strong>Right to Deletion</strong></td>
    <td>
      <p typography>Subject to certain exceptions set out below,
        on receipt of a verifiable request from you,
        we will:</p>
      <ul>
        <li>Delete your personal information from
          our records; and</li>
        <li>Direct any service providers to delete
          your personal information from their
          records.
        </li>
      </ul>
      <p typography class="mt-16">Please note that we may not delete your
        personal information if it is necessary to:</p>
      <ul>
        <li>Complete the transaction for which the
          personal information was collected, fulfill
          the terms of a written warranty or product
          recall conducted in accordance with
          federal law, provide a good or service
          requested by you, or reasonably
          anticipated within the context of our
          ongoing business relationship with you, or
          otherwise perform a contract between you
          and us;
        </li>
        <li>Detect security incidents, protect against
          malicious, deceptive, fraudulent, or illegal
          activity; or prosecute those responsible for
          that activity;
        </li>
        <li>Debug to identify and repair errors that
          impair existing intended functionality;</li>
        <li>Exercise free speech, ensure the right of
          another consumer to exercise his or her
          right of free speech, or exercise another
          right provided for by law;</li>
        <li>Comply with the California Electronic
          Communications Privacy Act;
        </li>
        <li>Engage in public or peer-reviewed
          scientific, historical, or statistical research
          in the public interest that adheres to all
          other applicable ethics and privacy laws,
          when our deletion of the information is
          likely to render impossible or seriously
          impair the achievement of such research,
          provided we have obtained your informed
          consent;</li>
        <li>Enable solely internal uses that are
          reasonably aligned with your expectations
          based on your relationship with us;</li>
        <li>Comply with an existing legal obligation;
          or</li>
        <li>Otherwise use your personal information,
          internally, in a lawful manner that is
          compatible with the context in which you
          provided the information.
        </li>
      </ul>
    </td>
  </tr>
  <tr>
    <td><strong>Protection Against Discrimination</strong></td>
    <td>
      <p typography>You have the right to not be discriminated
        against by us because you exercised any of
        your rights under the CCPA. This means we
        cannot, among other things:</p>
      <ul>
        <li>Deny goods or services to you;
        </li>
        <li>Charge different prices or rates for goods
          or services, including through the use of
          discounts or other benefits or imposing
          penalties;
        </li>
        <li>Provide a different level or quality of
          goods or services to you; or</li>
        <li>Suggest that you will receive a different
          price or rate for goods or services or a
          different level or quality of goods or
          services.
        </li>
      </ul>
      <p typography class="mt-16">Please note that we may charge a different
        price or rate or provide a different level or
        quality of goods and/or services to you, if
        that difference is reasonably related to the
        value provided to our business by your
        personal information.
      </p>
    </td>
  </tr>
  </tbody>
</table>
<h2 typography variant="h4" id="coppa">12. COPPA</h2>
<p typography>VacayMyWay is intended for a general adult audience and is not directed to
  children under the age of thirteen (13). We do not knowingly collect personal information from
  children under the age of thirteen (13).
</p>
<h2 typography variant="h4" id="keeping-your-personal-information-secure">13. Keeping Your Personal Information Secure</h2>
<p typography>We have enacted reasonable and
  appropriate security measures to prevent personal information from being accidentally lost or
  used or accessed in an unauthorized way. We limit access to your personal information to
  those who have a genuine business need to access it. Those processing your information will
  do so only in an authorized manner and are subject to a duty of confidentiality. We also have
  procedures in place to deal with any suspected data security breach. We will notify you and
  any applicable regulator of a suspected data security breach where we are legally required to
  do so. While no company can guarantee absolute security, we have implemented physical,
  technological, and companywide processes to ensure your confidence in using the
  VacayMyWay platform.</p>
<h2 typography variant="h4" id="your-rights-and-choices">14. Your Rights and Choices</h2>
<p typography>You have certain rights and choices concerning your personal
  information as described in this Privacy Statement, including:</p>
<ul>
  <li>If you have established an account with VacayMyWay, you may change your
    communication choices by <strong>(1)</strong> logging into your account and changing your choices, or <strong>(2)</strong>
    contacting us as shown below in <strong>‘How to Contact Us.’</strong>
  </li>
  <li>You can update your information at any time by logging into your account or by contacting
    us.
  </li>
  <li>If you do not want to receive promotional and marketing emails from us, you can
    unsubscribe at any time by clicking on the link identified as “Unsubscribe” in the email. You
    can also log into your account and change your communication settings. Even if you
    unsubscribe or opt out, you may still receive important messages from us related to your
    account or transactions. You will not be able to unsubscribe from these messages.
  </li>
  <li>You can control our use of some cookies by following the information contained in our
    Cookie Statement. Please keep in mind that you may not be able to use some of all of the
    features of VacayMyWay if cookies are disabled.</li>
  <li>For VacayMyWay’s mobile application, you can view, control, and manage the
    notifications settings, and your preferences un the ‘Settings’ menu of the application and of
    your device’s operating system.
  </li>
  <li>If VacayMyWay processes your information based on your express consent, you have the
    right to withdraw your consent at any time. You can withdraw your consent by contacting
    us. If you withdraw your consent, it will not affect the lawfulness of processing that occurred
    before the withdrawal of consent. It will also not affect our processing of you personal
    information where it is based on a legal basis other than your consent.</li>
</ul>
<h2 typography variant="h4" id="how-to-exercise-your-rights">15. How to Exercise Your Rights</h2>
<p typography>If you would like to exercise any of your rights as described
  in this Privacy Policy, please contact us in one of the methods shown under ‘How to Contact
  Us’.
</p>
<p typography>Please note that you may only make a CCPA-related data access or data portability disclosure
  request twice within a 12-month period.</p>
<p typography>You will need to provide us with:</p>
<ul>
  <li>Enough information to identify you (e.g., your full name, address and customer or account
    number);</li>
  <li>Proof of your identity and address (e.g., a copy of your driving license or passport and a
  recent utility or credit card bill); and</li>
  <li>A description of what right you want to exercise and the information to which your request
    relates.</li>
</ul>
<p typography>We are not obligated to make a data access or data portability disclosure if we cannot verify
  that the person making the request is the person about whom we collected information, or is
  someone authorized to act on such person’s behalf.
</p>
<p typography>Any personal information we collect from you to verify your identity in connection with you
  request will be used solely for the purposes of verification.
</p>
<h2 typography variant="h4" id="changes-to-this-privacy-statement">16. Changes to This Privacy Statement</h2>
<p typography>This Privacy Statement was published on the
  Effective Date set forth at the beginning of the Privacy Statement.
</p>
<p typography>We may change this Privacy Statement from time to time. When we do, we will inform you by
  posting a conspicuous notice concerning the changes to the website and by posting the new
  Privacy Statement.
</p>
<h2 typography variant="h4" id="How to Contact Us">17. How to Contact Us</h2>
<p typography>Please contact us or our Data Protection Officer by post, email or
  telephone if you have any questions about this privacy policy or the information we hold about
  you.</p>
<p typography>Our contact details are shown below:</p>
<div class="row">
  <div class="col col-md-6 mt-8">
    Our contact details:<br>
    VacayMyWay<br>
    608 E. Hickory Street #128<br>
    Denton, Texas 76205<br>
    <a typography variant="link1" href="mailto:info@vacaymyway.com">info&#64;vacaymyway.com</a><br>
    <a typography variant="link1" href="tel:(877) 822-2946">(877) 822-2946</a><br>
  </div>
  <div class="col col-md-6 mt-8">
    Our Data Protection Officer’s contact details:<br>
    VacayMyWay<br>
    Attn: Data Protection Officer<br>
    608 E. Hickory Street #128<br>
    Denton, Texas 76205<br>
    <a typography variant="link1" href="mailto:info@vacaymyway.com">info&#64;vacaymyway.com</a><br>
  </div>
</div>
<h2 typography variant="h4" id="do-you-need-extra-help">18. Do You Need Extra Help?</h2>
<p typography>If you would like this notice in another format (for example:
  audio, large print, braille) please contact us (see “How to contact us” above).</p>
<p typography></p>
<p typography></p>
<p typography></p>
