import { Money } from 'src/app/shared/models/money.model';
import { Message, MessageData, MessageType } from './message.model';

export enum BookingDeclinedReason {
  EXPIRED = 'expired',
  PROPERTY_OWNER = 'property_owner',
}
export interface BookingMessagePayload {
  bookingId: string;
  propertyId: string;
  propertyOwnerId: string;
  guestUserId: string;
  text: string;
}

export interface BookingMessage extends Message {
  payload: BookingMessagePayload;
}

export interface BookingAuthorizedPayload extends BookingMessagePayload {
  instantApprove: boolean;
}

export interface BookingCanceledPayload extends BookingMessagePayload {
  canceledByUserId: string;
  isPropertyOwner: boolean;
  canceledReason: string;
  refund: Money;
}

export interface BookingDeclinedPayload extends BookingMessagePayload {
  declinedReason: BookingDeclinedReason;
  text: string;
  declinerUserId: string;
}

export interface BookingRefundedPayload extends BookingMessagePayload {
  refund: Money;
  text: string;
}

export interface BookingMessageData extends MessageData {
  payload: BookingMessagePayload;
}

export interface BookingCreatedMessage extends BookingMessageData {
  type: MessageType.BOOKING_CREATED;
  payload: BookingMessagePayload;
}

export interface BookingAuthorizedMessage extends BookingMessageData {
  type: MessageType.BOOKING_AUTHORIZED;
  payload: BookingAuthorizedPayload;
}

export interface BookingApprovedMessage extends BookingMessageData {
  type: MessageType.BOOKING_APPROVED;
  payload: BookingMessagePayload;
}

export interface BookingCanceledMessage extends BookingMessageData {
  type: MessageType.BOOKING_CANCELED;
  payload: BookingCanceledPayload;
}

export interface BookingRefundedMessage extends BookingMessageData {
  type: MessageType.BOOKING_REFUNDED;
  payload: BookingRefundedPayload;
}

export interface BookingDeclinedMessage extends BookingMessageData {
  type: MessageType.BOOKING_DECLINED;
  payload: BookingDeclinedPayload;
}
