import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';

@Component({
  selector: 'app-home-host-benefits',
  templateUrl: './home-host-benefits.component.html',
  styleUrls: ['./home-host-benefits.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeHostBenefitsComponent extends AbstractComponent {
  constructor() {
    super();
  }
}
