<div class="h-100">
  <div class="h-50 d-flex w-100">
    <div class="flex-grow-1 w-100">
      <div [style.padding-left.%]="space">
        <app-today-pointer-image *ngIf="refundable"></app-today-pointer-image>
      </div>
    </div>
    <div class="flex-grow-1 w-100">
      <div [style.padding-right.%]="space === 100 ? 0 : 100 - space">
        <app-today-pointer-image *ngIf="!refundable" [refundable]="refundable" ></app-today-pointer-image>
      </div>
    </div>
  </div>
  <div class="h-50">
    <div class="chart-container">
      <div class="flex-grow-1 w-100">
        <div class="refund-part d-flex align-items-center">
          <div class="w-100 mr-16">
            <div *ngIf="refundable" class="refund-pointer" [style.margin-left.%]="space"></div>
          </div>
        </div>
      </div>

      <div
        class="boundary">
      </div>

      <div class="flex-grow-1 w-100">
        <div class="no-refund-part d-flex align-items-center">
          <div class="w-100 mr-16">
            <div *ngIf="!refundable" class="refund-pointer" [style.margin-left.%]="space"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
