import {
  Translation,
  TranslocoLoader,
  TranslocoModule,
  provideTransloco,
} from '@jsverse/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { from, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  getTranslation(lang: string): Observable<Translation> {
    return from(import(`../assets/i18n/${lang}.json`).then(res => res.default));
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTranslocoMessageformat({
      locales: 'en-US',
    }),
    provideTransloco({
      config: {
        availableLangs: ['en'],
        defaultLang: 'en',
        fallbackLang: 'en',
        // reRenderOnLangChange: true, // Remove this option if your application doesn't support changing language in runtime.
        prodMode: environment.production,
        flatten: {
          aot: environment.production,
        },
      },
      loader: TranslocoHttpLoader,
    }),
  ],
})
export class TranslocoRootModule {}
