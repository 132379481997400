import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  OnInit,
} from '@angular/core';
import { ParentAuthenticationDialog } from '../../parent-authentication-dialog';
import { NotificationService } from '../../../../../core/services/notification.service';
import { BreakpointService } from '../../../../../core/services/breakpoint.service';
import { Observable } from 'rxjs';
import { User } from '../../../../../shared/models/user.model';

/**
 * Component dialog for upload user´s profile photo
 */
@Component({
  selector: 'app-upload-photo',
  templateUrl: './upload-photo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadPhotoComponent
  extends ParentAuthenticationDialog
  implements OnInit
{
  /** Large screen flag */
  ls = false;

  user$: Observable<User>;
  photoUploaded = false;

  constructor(
    injector: Injector,
    private readonly notificationService: NotificationService,
    private readonly breakpointService: BreakpointService,
    private readonly changeDetection: ChangeDetectorRef,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.breakpointService.largeScreen$
      .pipe(this.untilDestroyed())
      .subscribe(isLargeScreen => {
        this.ls = isLargeScreen;
        this.changeDetection.detectChanges();
      });
    this.user$ = this.userService.getCurrentUser();
  }

  onAvatarUpload(file: File) {
    this.userService.updateAvatar(file);
    this.photoUploaded = true;
  }

  onAvatarDelete() {
    this.userService.updateAvatar();
    this.photoUploaded = false;
  }

  onAvatarError(event) {
    this.notificationService.showNotification({
      text: event,
      duration: 4000,
      color: 'error',
    });
  }

  continue() {
    this.closeDialog();
  }
}
