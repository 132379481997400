import { isNil } from 'lodash-es';
import type { Map } from 'mapbox-gl';
import { LngLatBounds } from 'mapbox-gl';
import { MapAddress } from '../shared/models/map-address';

/**
 * Function to handle crawler bots invalid events for mapbox map
 */
export function handleMapboxBotError(map: Map) {
  try {
    const listener = event => {
      try {
        // Facebook crawler bot doesn't send clientX and clientY
        // So if important data are missing, cancel event propagation
        if (isNil(event.clientX) || isNil(event.clientY)) {
          event.preventDefault();
          event.stopPropagation();
          event.stopImmediatePropagation();
        }
      } catch (e) {
        // We dont need to log this error
      }
    };
    // Start listening for invalid events
    [
      'mousedown',
      'mouseup',
      'mouseover',
      'mouseout',
      'mousemove',
      'mouseenter',
      'mouseleave',
    ].forEach(eventName => {
      map.getCanvasContainer().addEventListener(eventName, listener, true);
    });
  } catch (e) {
    // Inform about fail, for better debugging
    console.warn('Unable to handle mapbox error', e);
  }
}

export function loadMapboxCss() {
  const id = 'mapbox-css';
  if (document.getElementById(id)) {
    return;
  }
  const link = document.createElement('link');
  link.id = id;
  link.href = 'https://api.mapbox.com/mapbox-gl-js/v2.3.0/mapbox-gl.css';
  link.rel = 'stylesheet';
  document.head.appendChild(link);
}

export function mapAddressToBounds(address: MapAddress): LngLatBounds {
  let bounds: LngLatBounds;
  if (address.bbox) {
    bounds = new LngLatBounds(address.bbox);
  } else if (address.center) {
    bounds = new LngLatBounds([
      address.center[0] - 0.05,
      address.center[1] - 0.05,
      address.center[0] + 0.05,
      address.center[1] + 0.05,
    ]);
  }
  return bounds;
}
