import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Conversation } from '../../../models/conversation.model';
import { UserService } from '../../../../../core/services/user.service';
import { Observable } from 'rxjs';
import { User } from '../../../../../shared/models/user.model';
import { ChatWindowService } from '../../../services/chat-window.service';
import { Router } from '@angular/router';
import { Url } from '../../../../../url';
import { ChatService } from '../../../services/chat.service';

@Component({
  selector: 'app-chat-window',
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatWindowComponent implements OnInit {
  user$: Observable<User>;

  @Input()
  conversation: Conversation;

  constructor(
    private readonly userService: UserService,
    private readonly chatWindowsService: ChatWindowService,
    private readonly router: Router,
    private readonly chatService: ChatService,
  ) {}

  ngOnInit(): void {
    this.user$ = this.userService.getCurrentUser();
  }

  getRecipient(conversation: Conversation, currentUser: User) {
    // Find first participant who are not the current user
    let recipient = conversation.participants.find(
      p => p.userId !== currentUser?.id,
    );
    // If there is only current user in participants array, take them
    if (!recipient) {
      recipient = conversation.participants[0];
    }
    return recipient;
  }

  onClose() {
    this.chatService.clearInquiryInfo();
    this.chatWindowsService.closeConversation();
  }

  onMaximize() {
    // TODO: redirect to chat conversation url
    this.chatWindowsService.closeConversation();
    if (this.conversation) {
      this.router.navigate([Url.CHAT_(this.conversation.id)]);
    } else {
      this.router.navigate([Url.CHAT]);
    }
  }
}
