import { Injectable, OnDestroy } from '@angular/core';
import { MonoTypeOperatorFunction, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export abstract class AbstractService implements OnDestroy {
  protected readonly destroyedSubject = new Subject<boolean>();

  ngOnDestroy(): void {
    this.destroyedSubject.next(true);
    this.destroyedSubject.complete();
  }

  untilDestroyed<T>(): MonoTypeOperatorFunction<T> {
    return takeUntil(this.destroyedSubject);
  }
}
