import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-today-pointer-image',
  template: `
    <div class="w-100">
      <div
        class="d-flex align-items-start"
        [class.flex-row-reverse]="!refundable"
      >
        <img
          [class.no-refundable]="!refundable"
          src="./assets/img/cancellation-policy/today_pointer.svg"
          alt="Today pointer"
        />
        <img
          [ngClass]="refundable ? 'ml-12' : 'mr-12'"
          src="./assets/img/cancellation-policy/today.svg"
          alt="Today title"
        />
      </div>
    </div>
  `,
  styles: [
    `
      .no-refundable {
        transform: scaleX(-1);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodayPointerImageComponent {
  @Input() refundable = true;
}
