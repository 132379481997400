<dialog-wrapper
  width="sm"
  [position]="ls ? 'center' : 'bottom'"
  [class.h-100]="!isDialog"
>
  <dialog-header
    (dialogClose)="closeDialog()"
    [showClose]="isDialog"
    title="Sign up for VacayMyWay"
  ></dialog-header>
  <dialog-content
    [class.pt-32]="!isDialog"
    [class.no-overflow]="ls || !isDialog"
  >
    <div class="d-flex flex-column">
      <app-signup-form-link data-test="login-method-email" [isDialog]="isDialog"
        >Continue with E-mail</app-signup-form-link
      >
      <app-login-google data-test="login-method.svg-google"
        >Continue with Google</app-login-google
      >
    </div>
    <app-text-separator text="or" />
    <app-p1h0m89-input authType="signup"></app-p1h0m89-input>
    <div class="mt-24 mb-8 mb-md-0">
      <span typography variant="body1"> Already have an account? </span>
      <app-login-link [isDialog]="isDialog">Sign in</app-login-link>
    </div>
  </dialog-content>
</dialog-wrapper>
