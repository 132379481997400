import {
  AfterContentInit,
  Directive,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  NgControl,
  FormControl,
  FormGroup,
} from '@angular/forms';
import {
  BehaviorSubject,
  MonoTypeOperatorFunction,
  Observable,
  Subject,
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual } from 'lodash-es';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class AbstractControlledComponent<T>
  implements ControlValueAccessor, OnInit, OnDestroy, AfterContentInit
{
  private readonly valueSubject: BehaviorSubject<T>;
  public readonly value$: Observable<T>;
  private readonly disabledSubject: Subject<boolean>;
  protected readonly disabled$: Observable<boolean>;
  private onChangeFn: (value: T) => void;
  private emitValueOnChangeFnRegistration: boolean;

  public control: FormControl | FormGroup;

  protected readonly destroyedSubject = new Subject<boolean>();

  @Input()
  label: string;

  constructor(private readonly injector: Injector) {
    this.valueSubject = new BehaviorSubject<T>(undefined);
    this.value$ = this.valueSubject.asObservable();
    this.disabledSubject = new Subject<boolean>();
    this.disabled$ = this.disabledSubject.asObservable();
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {}

  public get value(): T {
    return this.valueSubject.getValue();
  }

  public set value(value: T) {
    if (this.control?.disabled) {
      return;
    }
    if (isEqual(this.value, value)) {
      return;
    }
    this.valueSubject.next(value);
    if (this.onChangeFn) {
      this.onChangeFn(value);
    } else {
      this.emitValueOnChangeFnRegistration = true;
    }
  }

  ngAfterContentInit() {
    const ngControl = this.injector.get(NgControl, null);
    if (!ngControl) {
      throw new Error(
        `Missing form control binding on ${this.constructor.name}`,
      );
    }
    const control = ngControl.control as UntypedFormControl;
    if (!(control instanceof AbstractControl)) {
      throw new Error(
        `Unknown form control binding '${control}' on ${this.constructor.name}`,
      );
    }
    this.control = control;
    this.disabledSubject.next(this.control.disabled);
    if (this.control instanceof FormControl) {
      this.control.registerOnDisabledChange((disabled: boolean) => {
        this.disabledSubject.next(disabled);
      });
    }
  }

  ngOnDestroy(): void {
    this.valueSubject.complete();
    this.disabledSubject.complete();
    this.destroyedSubject.next(true);
    this.destroyedSubject.complete();
  }

  registerOnChange(fn: (value: T) => void): void {
    this.onChangeFn = fn;
    if (this.emitValueOnChangeFnRegistration) {
      this.onChangeFn(this.valueSubject.getValue());
    }
  }

  registerOnTouched(fn: (touched: boolean) => void): void {
    // Empty method
  }

  writeValue(value: T): void {
    this.valueSubject.next(value);
  }

  public untilDestroyed<U>(): MonoTypeOperatorFunction<U> {
    return takeUntil(this.destroyedSubject);
  }
}
