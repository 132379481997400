import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-day-cost-breakdown',
  template: `
    <div *ngIf="groupedPrices?.length">
      <div
        class="day-row d-flex justify-content-between"
        *ngFor="let priceGroup of groupedPrices"
      >
        <div>
          {{ priceGroup.priceGroup | currency: 'USD' }} X
          {{ priceGroup.count }} {{ priceGroup.count > 1 ? 'nights' : 'night' }}
        </div>
        <div class="price-column ml-32 fw-bold">
          {{ priceGroup.totalPrice | currency: 'USD' }}
        </div>
      </div>
      <div>
        The rate per night is rounded. Final calculated Price Per Stay rate may
        include some additional fees from the owner or property manager.
      </div>
    </div>
  `,
})
export class DayCostBreakdownComponent {
  @Input()
  groupedPrices: {
    priceGroup: number;
    count: number;
    totalPrice: number;
  }[];
}
