<div class="d-flex justify-content-between">
  <single-number
    #n1
    [formControl]="phoneVerificationForm.controls.number1"
    (multipleInput)="onMultipleInput($event)"
    [focusNext]="n2"
    autocomplete="one-time-code"
    class="mr-8"
  ></single-number>
  <single-number
    #n2
    [formControl]="phoneVerificationForm.controls.number2"
    (multipleInput)="onMultipleInput($event)"
    [focusPrev]="n1" [focusNext]="n3"
    autocomplete="one-time-code"
    class="mr-8"
  ></single-number>
  <single-number
    #n3
    [formControl]="phoneVerificationForm.controls.number3"
    (multipleInput)="onMultipleInput($event)"
    [focusPrev]="n2"
    [focusNext]="n4"
    autocomplete="one-time-code"
    class="mr-8"
  ></single-number>
  <single-number
    #n4
    [formControl]="phoneVerificationForm.controls.number4"
    (multipleInput)="onMultipleInput($event)"
    [focusPrev]="n3"
    [focusNext]="n5"
    autocomplete="one-time-code"
    class="mr-8"
  ></single-number>
  <single-number
    #n5
    [formControl]="phoneVerificationForm.controls.number5"
    (multipleInput)="onMultipleInput($event)"
    [focusPrev]="n4"
    [focusNext]="n6"
    autocomplete="one-time-code"
    class="mr-8"
  ></single-number>
  <single-number
    #n6
    [formControl]="phoneVerificationForm.controls.number6"
    (multipleInput)="onMultipleInput($event)"
    [focusPrev]="n5"
  ></single-number>
</div>
