import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageKey } from '../../shared/enums/local-storage-key.enum';
import { StorageService } from './storage.service';

/**
 * Service for handling after login redirect to url
 */
@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  private readonly FALLBACK_URL = '/';

  constructor(
    private readonly router: Router,
    private readonly storageService: StorageService,
  ) {}

  /**
   * Set next after login redirect url
   */
  setUrl(url: string) {
    return this.storageService.setSessionItem(StorageKey.REDIRECT_URL, url);
  }

  /**
   * Reset redirect url, after login homepage will be opened
   */
  resetUrl() {
    return this.storageService.removeSessionItem(StorageKey.REDIRECT_URL);
  }

  /**
   * Get current redirect url
   */
  getUrl(): string {
    return this.storageService.getSessionItem(StorageKey.REDIRECT_URL);
  }

  /**
   * Get current redirect url, if not set, return /
   */
  getUrlOrFallback() {
    return this.getUrl() ?? this.FALLBACK_URL;
  }

  /**
   * Navigate to last redirect url, and reset it
   */
  navigate() {
    const url = this.getUrlOrFallback();
    this.router.navigateByUrl(url);
    this.resetUrl();
  }
}
