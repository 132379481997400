import { Injectable, Injector } from '@angular/core';
import { PagedModel } from '../../shared/models/pagination';
import { plainToParams, processSortQuery } from '../util/api.util';
import { AbstractApi, PageableQuery } from './abstract.api';
import {
  PropertiesBulkActionResult,
  Property,
  PropertyPreview,
} from '../../shared/models/property';
import { PropertyStatus } from '../../shared/models/property-status';
import { IntegrationPartner } from '../../shared/models/integration.model';

export interface PropertiesFilter {
  status?: PropertyStatus[];
  integrationPartner?: (IntegrationPartner | 'none')[];
  instantBookingAllowed?: boolean;
  vacayStandard?: boolean;
  searchText?: string;
  searchIn?: GetMyPropertiesSearchIn;
}

export enum GetMyPropertiesSortBy {
  STATUS = 'status',
  RESERVATIONS = 'reservations',
  LOCATION = 'location',
  LAST_MODIFIED = 'lastModified',
}

export enum GetMyPropertiesSearchIn {
  ID = 'id',
  HEADLINE = 'headline',
  ADDRESS = 'address',
  STATE = 'state',
}

export interface GetMyPropertiesOptions
  extends PageableQuery<GetMyPropertiesSortBy>,
    PropertiesFilter {}

export interface GetPublicPropertiesOptions extends PageableQuery {
  userId: string;
}

export enum PropertyBulkAction {
  PUBLISH = 'publish',
  UNPUBLISH = 'unpublish',
  ARCHIVE = 'archive',
  DELETE = 'delete',
}

export interface BulkActionPropertyTarget {
  selectedAll: boolean;
  selectedProperties: string[];
  unselectedProperties: string[];
}

export interface BulkActionPropertyOptions {
  filter: PropertiesFilter;
  action: PropertyBulkAction;
  body: BulkActionPropertyTarget;
}

@Injectable({ providedIn: 'root' })
export class PropertyApi extends AbstractApi {
  constructor(injector: Injector) {
    super(injector);
    this.base = '/property/api/v1/properties';
  }

  getMyProperties(options: GetMyPropertiesOptions) {
    return this.http.get<PagedModel<Property>>(`${this.base}`, {
      params: plainToParams(processSortQuery(options)),
    });
  }

  getPublicProperties(options: GetPublicPropertiesOptions) {
    return this.http.get<PagedModel<Property>>(`${this.base}/public`, {
      params: plainToParams(processSortQuery(options)),
    });
  }

  applyBulkAction(options: BulkActionPropertyOptions) {
    return this.http.post<PropertiesBulkActionResult>(
      `${this.base}/bulk-action/${options.action}`,
      options.body,
      {
        params: plainToParams(processSortQuery(options)),
      },
    );
  }

  getByIds(list: string[]) {
    return this.http.post<PropertyPreview[]>(`${this.base}/getByIds`, list);
  }
}
