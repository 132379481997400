import { Pipe, PipeTransform } from '@angular/core';
import { ClientInfo, User } from '../../shared/models/user.model';
import { getUserAvatarInitials } from '../../utils/user.util';
import { ConversationParticipant } from '../../modules/chat/models/conversation.model';

@Pipe({
  name: 'userInitials',
  pure: false,
})
export class UserInitialsPipe implements PipeTransform {
  transform(
    user: User | ClientInfo | ConversationParticipant,
    args?: any,
  ): string {
    return getUserAvatarInitials(user);
  }
}
