import { Injectable, OnDestroy } from '@angular/core';
import { AbstractService } from '../../../../core/services/abstract.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Url } from '../../../../url';
import { combineLatest, Observable } from 'rxjs';
import { BreakpointService } from '../../../../core/services/breakpoint.service';
import { map } from 'rxjs/operators';

export const QUERY_SECRET = 'secret';
export const QUERY_STEP = 'step';
export const QUERY_SOURCE = 'source';
export const QUERY_BOOKING_ID = 'bookingId';

export enum VerificationPageSourceType {
  LINK = 'link',
  LOGIN = 'login',
  USER_SETTINGS = 'user-settings',
  BOOKING = 'booking',
}

export interface VerificationPageSource {
  type: VerificationPageSourceType;
  bookingId?: string;
}

@Injectable()
export class VerificationPageService
  extends AbstractService
  implements OnDestroy
{
  public readonly mobileDevice$: Observable<boolean>;
  private readonly previousPath?: string;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly breakpointService: BreakpointService,
  ) {
    super();
    this.previousPath = this.router
      .getCurrentNavigation()
      ?.previousNavigation?.initialUrl?.toString();
    this.mobileDevice$ = combineLatest([
      breakpointService.screenBreakpoint$.pipe(
        map(breakpoint =>
          breakpointService.isBreakpointDownOrSame(breakpoint, 'sm'),
        ),
      ),
      route.queryParamMap.pipe(map(params => params.has(QUERY_SECRET))),
    ]).pipe(map(values => values.some(value => !!value)));
  }

  private getSource(): VerificationPageSource {
    const queryParams = this.route.snapshot.queryParamMap;
    return {
      type: queryParams.get(QUERY_SOURCE) as VerificationPageSourceType,
      bookingId: queryParams.get(QUERY_BOOKING_ID),
    };
  }

  public isBookingSource() {
    const source = this.getSource();
    return source.type === VerificationPageSourceType.BOOKING;
  }

  private getRedirectPath(): string[] {
    const source = this.getSource();
    switch (source.type) {
      case VerificationPageSourceType.LINK:
      case VerificationPageSourceType.LOGIN:
        if (this.previousPath) {
          return [this.previousPath];
        }
        return [Url.INDEX];
      case VerificationPageSourceType.USER_SETTINGS:
        return [Url.SETTINGS_GENERAL];
      case VerificationPageSourceType.BOOKING:
        return [Url.BOOKINGS_(source.bookingId, 'summary')];
      default:
        if (this.previousPath) {
          return [this.previousPath];
        }
        return [Url.SETTINGS_GENERAL];
    }
  }

  public redirectAfterExit() {
    this.router.navigate(this.getRedirectPath());
  }

  public redirectAfterComplete() {
    this.router.navigate(this.getRedirectPath());
  }
}
