import { Pipe, PipeTransform } from '@angular/core';
import { PaymentMethodCard } from '../../shared/models/payment-method';
import { CreditCardIcon } from '../../modules/property/property-booking/enums/credit-card-icon';

@Pipe({ name: 'cardIcon' })
export class CardIconPipe implements PipeTransform {
  transform(card: PaymentMethodCard): string {
    let icon = CreditCardIcon[card.brand.toUpperCase()];
    if (!icon) {
      icon = CreditCardIcon.OTHER;
    }
    return `assets/icon/credit-card/${icon}.svg`;
  }
}
