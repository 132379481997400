import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

interface UploadedImageModel {
  href: string;
  resourceKey: string;
  file: File;
}

/** Blobstore API endpoint base path. */
const BLOBSTORE_API_BASE = '/blobstore/api/v1/objects';

/**
 * Service responsible for managing file persistence in blobstore.
 */
@Injectable({
  providedIn: 'root',
})
export class FileStorageService {
  // Set of uploaded images that were HEIC/HEIF format. They are cashed so they won't be uploaded again;
  uploadedFiles: Set<UploadedImageModel> = new Set<UploadedImageModel>();

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private readonly http: HttpClient,
  ) {}

  uploadPropertyImage(file: File) {
    let foundData: UploadedImageModel;
    this.uploadedFiles.forEach(value => {
      if (value.file === file) {
        foundData = value;
      }
    });
    if (foundData) {
      const imageData = {
        href: foundData.href,
        resourceKey: foundData.resourceKey,
      };
      return of(imageData);
    }
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<UploadPropertyImageResponse>(
      `${BLOBSTORE_API_BASE}/property-images`,
      formData,
    );
  }

  deletePropertyImage(resourceKey: string) {
    this.uploadedFiles.forEach(value => {
      if (value.resourceKey === resourceKey) {
        this.uploadedFiles.delete(value);
      }
    });

    return this.http.delete(
      `${BLOBSTORE_API_BASE}/property-images/${resourceKey}`,
    );
  }

  uploadUserAvatar(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<{ href: string }>(
      `${BLOBSTORE_API_BASE}/user-avatars`,
      formData,
    );
  }

  deleteUserAvatar(resourceKey: string) {
    return this.http.delete(
      `${BLOBSTORE_API_BASE}/user-avatars/${resourceKey}`,
    );
  }

  convertHeicToJpeg(file: File): Observable<File> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http
      .post(`${BLOBSTORE_API_BASE}/convert`, formData, {
        responseType: 'arraybuffer',
      })
      .pipe(
        map(convertedDataBuffer => {
          const blobToFile: any = new Blob([convertedDataBuffer], {
            type: 'image/jpeg',
          });
          blobToFile.name = file.name.slice(0, -4).concat('jpg');
          blobToFile.lastModifiedDate = new Date();
          return blobToFile as File;
        }),
      );
  }
}

export function parseResourceKeyFromUrl(url: string): string {
  return url.substring(url.lastIndexOf('/') + 1, url.length);
}

export interface UploadPropertyImageResponse {
  eTag: string;
  href: string;
  resourceKey: string;
}
