import {
  AfterContentInit,
  Directive,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  FormGroupDirective,
  NgForm,
  FormControl,
} from '@angular/forms';
import { EMPTY, merge, Observable, of } from 'rxjs';
import { ErrorStateMatcher } from '@angular/material/core';
import { map } from 'rxjs/operators';
import { getErrorMessage } from '../../../utils/error.util';
import { AbstractControlledComponent } from '@components/abstract/abstract-controlled.component';
import { SubmittableFormControl } from '../../../utils/form.util';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class AbstractInputComponent<T>
  extends AbstractControlledComponent<T>
  implements ControlValueAccessor, OnInit, OnDestroy, AfterContentInit
{
  public readonly errorStateMatcher: ErrorStateMatcher;
  public errorMessage$: Observable<string>;

  @Input()
  label: string;

  constructor(injector: Injector) {
    super(injector);
    this.errorStateMatcher = {
      isErrorState(
        control: UntypedFormControl | null,
        form: FormGroupDirective | NgForm | null,
      ): boolean {
        return !!(
          control &&
          control.invalid &&
          (control.touched || (control as SubmittableFormControl).submitted)
        );
      },
    };
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterContentInit() {
    super.ngAfterContentInit();
    if (this.control) {
      this.errorMessage$ = merge(of([]), this.control.statusChanges).pipe(
        map(() => {
          return getErrorMessage(this.control as FormControl, {
            label: this.label,
          });
        }),
      );
    } else {
      this.errorMessage$ = EMPTY;
    }
  }
}
