import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  Conversation,
  ConversationParticipant,
} from '../../models/conversation.model';
import { ChatWindowService } from '../../services/chat-window.service';
import { ChatService } from '../../services/chat.service';
import { ParamMapService } from '../../../../core/services/param-map.service';
import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';
import { Router } from '@angular/router';
import {
  catchError,
  filter,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import { BreakpointService } from '../../../../core/services/breakpoint.service';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '../../../../core/services/notification.service';
import { UserService } from '../../../../core/services/user.service';
import { ChatStore } from '../../services/chat.store';
import { Url } from '../../../../url';

@Component({
  selector: 'app-chat-layout',
  templateUrl: './chat-layout.component.html',
  styleUrls: ['./chat-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatLayoutComponent
  extends AbstractComponent
  implements OnInit, OnDestroy
{
  isContextOpen = false;

  constructor(
    private readonly chatService: ChatService,
    private readonly chatStore: ChatStore,
    private readonly chatWindowService: ChatWindowService,
    private readonly paramMapService: ParamMapService,
    private readonly router: Router,
    private readonly bs: BreakpointService,
    private readonly notificationService: NotificationService,
    private readonly userService: UserService,
  ) {
    super();
  }

  readonly largeScreen$ = this.bs.largeScreen$;
  conversation$: BehaviorSubject<Conversation>;
  recipient$: Observable<ConversationParticipant>;

  ngOnInit(): void {
    // TODO: move user to active conversation
    this.conversation$ = new BehaviorSubject<Conversation>(null);
    this.chatWindowService.closeConversation();

    const conversationId$ = this.paramMapService.paramMap.pipe(
      this.untilDestroyed(),
      map(params => params.get('conversationId')),
    );
    combineLatest([conversationId$, this.largeScreen$])
      .pipe(
        switchMap(([conversationId, largeScreen]) => {
          if (conversationId) {
            return this.chatService.getConversation(conversationId).pipe(
              catchError((error: HttpErrorResponse) => {
                this.notificationService.showUserFriendlyErrorNotification(
                  error,
                );
                return this.selectFirstConversation();
              }),
            );
          } else if (largeScreen) {
            return this.selectFirstConversation();
          } else {
            return of(null);
          }
        }),
        shareReplay(),
      )
      .pipe(this.untilDestroyed())
      .subscribe(conversation => {
        this.chatStore.loadContextInfomations(conversation);
        this.conversation$.next(conversation);
      });
    this.recipient$ = combineLatest([
      this.conversation$,
      this.userService.getCurrentUser(),
    ]).pipe(
      filter(([conversation, user]) => !!conversation && !!user),
      map(([conversation, user]) => {
        return conversation.participants.find(
          participant => participant.userId !== user.id,
        );
      }),
    );
    // Listen for conversation changes
    this.chatStore.conversationChanges$
      .pipe(
        this.untilDestroyed(),
        filter(
          changedConversation =>
            this.conversation$.getValue()?.id === changedConversation.id,
        ),
      )
      .subscribe(conversation => {
        this.conversation$.next(conversation);
      });
  }

  ngOnDestroy() {
    this.chatService.clearInquiryInfo();
    super.ngOnDestroy();
    this.conversation$.complete();
  }

  private selectFirstConversation(): Observable<Conversation> {
    return this.chatService.getConversations({ size: 1, archived: false }).pipe(
      map(conversations =>
        conversations.totalCount > 0 ? conversations.data[0] : null,
      ),
      tap(conversation => {
        if (conversation) {
          this.router.navigate([Url.CHAT_(conversation.id)]);
        }
      }),
    );
  }

  toggleContext() {
    this.isContextOpen = !this.isContextOpen;
  }
}
