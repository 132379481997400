export const environment = {
  production: true,
  name: 'staging',
  baseUrl: 'https://dev.vacaymyway.com',
  auth0: {
    domain: 'vacay-staging.us.auth0.com',
    clientId: 'ZGRKU8Mo1CqiNYHTQAKfL8ACLVD3sQwN',
    responseType: 'token',
    audience: 'https://api.vacaymyway.net/',
    requestedScopes: 'openid email profile offline_access',
    database: 'Username-Password-Authentication',
  },
  unleash: {
    clientKey: 'secret',
    url: '/proxy',
    unleashEnabled: true,
  },
  mapbox: {
    accessToken:
      'pk.eyJ1IjoidmFjYXlteXdheSIsImEiOiJja2V2ZTF1bjcwY205MnRxb2JqaGQ4MHgyIn0.3LtqHk1pGG8eBOf525X3Rg',
  },
  content: {
    url: 'https://vmw-dev-content-bucket.s3.us-west-2.amazonaws.com/',
  },
  wordpress: {
    baseUrl: 'https://blog.vacaymyway.com/',
  },
  stripe: {
    publishableKey:
      'pk_test_51HCVXyGBLBiSxSLhd6fdqOk0um4EolbMa4V2ONzfjDUCFqWQSN4e5aaRJkNv6apQMxqcOcpLxNXMTYaETMHvAJmX00KZFeZ8D1',
    mock: false,
  },
  ga: {
    enabled: true,
    gtmId: 'GTM-MLJJ373',
    gaId: 'UA-180936694-2',
  },
  ga4: {
    ga4Id: 'G-NFEBF2DEGD',
    awConversionId: 'AW-467079275',
  },
  sentry: {
    enabled: true,
    logError: true,
  },
  superhog: {
    incidentFormUrl:
      '//forms.zohopublic.eu/guardhog/form/SUPERHOGPMCclaimform/formperma/vmlmCh2VxQgMjNqbn2ncwp3tREsEK5gyiY6BC8Q8I3s',
  },
  impact: {
    campaignId: 13766,
    eventTypeId: 25494,
  },
  smartlook: {
    initKey: '101b8a73c80f5453b5ae0e6365ec994e6e693c2f',
  },
  ipApi: {
    key: '6tCcKdVLmbXwytY',
  },
  propertyServiceUrl: {
    url: 'http://vacay-property.default.svc.cluster.local:3000',
  },
  noiseaware: {
    promoCode: 'VMW33',
  },
  metaPixel: {
    id: '375678944150786',
  },
  integrations: {
    hostfully: {
      clientId: 'U7qhfxwTUCS95MPRYDFqbIBqaJLvgOSE',
      auth0Url: 'https://sandbox.hostfully.com/api/auth/oauth/authorize',
      redirectUri: 'https://dev.vacaymyway.com/integration/hostfully',
    },
  },
  enabledFeatures: {
    propertyPublish: true,
    chatContextDetail: false,
    devPages: false,
    propertyBooking: true,
    reviews: true,
    invoice: true,
    bookingChangeDates: true,
    reportingUser: true,
    integration: true,
    hostfully: true,
    nextpax: false,
    hostaway: false,
    streamline: false,
    boost: false,
    smartlook: false,
    impact: false,
    chat: true,
    ga4: true,
    ga4ConversionReport: false,
    accountWithdrawal: true,
    noiseaware: true,
    christmasTheme: false,
    snowflakes: false,
    searchExactPrices: false,
    propertyTaxes2: false,
    pricingV2: true,
    pricingV2updates: true,
    instantApprove: false,
    weeklyAndMonthlyDiscount: true,
    rewardPointDiscount: true,
    rewardPointEarning: true,
    metaPixel: false,
    propertySearchV2: false,
    expressCheckout: false,
    smartlookRecordAll: false,
    signUpRewardPoints: {
      enabled: true,
      value: 10000,
    },
    travelProtection: true,
    reviewSubmittedRewardPoints: {
      enabled: true,
      value: {
        baseAmount: 500,
        highlightAmount: 250,
        textAmount: 1000,
      },
    },
    signUpRewardPointsTopBanner: false,
    review: true,
  },
};
