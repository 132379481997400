import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';
import { Component, inject, Input } from '@angular/core';
import { Booking } from '../../../../shared/models/booking';
import { addDays } from 'date-fns';
import { map } from 'rxjs/operators';
import { UserService } from '../../../../core/services/user.service';
import { LoginComponent } from '../../../auth/components/login/dialog/login.component';
import { StorageKey } from '../../../../shared/enums/local-storage-key.enum';
import { DialogService } from '../../../../core/services/dialog.service';
import { StorageService } from '../../../../core/services/storage.service';

@Component({
  selector: 'app-booking-reward-card',
  templateUrl: './booking-reward-card.component.html',
  styleUrls: ['booking-details.component.scss'],
})
export class BookingRewardCardComponent extends AbstractComponent {
  @Input()
  booking?: Booking;

  @Input()
  showHints = true;

  @Input()
  hintText = 'Book to get reward points! ';

  @Input()
  hintAction = 'Learn More';
  private dialogService = inject(DialogService);
  private storageService = inject(StorageService);
  private userService = inject(UserService);
  readonly isNotLoggedIn$ = this.userService
    .getCurrentUser()
    .pipe(map(user => user == null));

  isBeforeCheckIn() {
    // End of check in day. Should be specific time of checkin property timezone, but currently is midnight UTC (and we want end of that day)
    return addDays(this.booking.interval.checkIn, 1) > new Date();
  }
  openSignIn() {
    this.dialogService
      .clearAndOpen(LoginComponent)
      .afterOpened()
      .subscribe(() => {
        this.storageService.setItem(StorageKey.BOOKING_SIGN_UP, 'true');
      });
  }
}
