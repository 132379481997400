import { Injectable } from '@angular/core';
import { Conversation } from '../models/conversation.model';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { ChatStore } from './chat.store';

@Injectable({
  providedIn: 'root',
})
export class ChatWindowService {
  private readonly conversationSubject: BehaviorSubject<Conversation>;
  conversation$: Observable<Conversation>;

  constructor(private readonly chatStore: ChatStore) {
    this.conversationSubject = new BehaviorSubject<Conversation>(null);
    this.conversation$ = this.conversationSubject.asObservable();
  }

  openConversation(conversation: Conversation) {
    this.chatStore.loadContextInfomations(conversation);
    this.conversationSubject.next(conversation);
  }

  closeConversation() {
    this.conversationSubject.next(null);
  }
}
