import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Injector,
  Input,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControlledComponent } from '@components/abstract/abstract-controlled.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * @see https://www.figma.com/file/4URZYURZBcwfpYp3HvkSL6/Web-components-VMW?node-id=5102%3A22243
 */
@Component({
  selector: 'stepper',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: StepperComponent,
      multi: true,
    },
  ],
  template: `
    <button
      type="button"
      class="stepper-button"
      [disabled]="isMinusDisabled()"
      (click)="onMinusClick()"
      data-test="icon-minus"
    >
      <icon variant="minus" size="medium" color="inherit"></icon>
    </button>
    <span class="stepper-value" data-test="icon-number">
      {{ value$ | async }}
    </span>
    <button
      type="button"
      class="stepper-button"
      [disabled]="isPlusDisabled()"
      (click)="onPlusClick()"
      data-test="icon-plus"
    >
      <icon variant="plus" size="medium" color="inherit"></icon>
    </button>
  `,
})
export class StepperComponent
  extends AbstractControlledComponent<number>
  implements OnInit
{
  @Input()
  min: number = 1;
  @Input()
  max: number = 99;

  constructor(
    injector: Injector,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    const element = this.elementRef.nativeElement;
    this.renderer.addClass(element, `stepper-container2`);
  }

  onMinusClick() {
    const value = this.value - 1;
    if (value < this.min) {
      return;
    }
    this.value = value;
  }

  onPlusClick() {
    const value = this.value + 1;
    if (value > this.max) {
      return;
    }
    this.value = value;
  }

  isMinusDisabled() {
    return this.value <= this.min || this.control.disabled;
  }
  isPlusDisabled() {
    return this.value >= this.max || this.control.disabled;
  }
}
