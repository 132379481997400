import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DayCostBreakdownComponent } from './day-cost-breakdown.component';
import { BookingPricingComponent } from './booking-pricing/booking-pricing.component';
import { BookingRewardCardComponent } from './booking-reward-card.component';
import { TypographyModule } from '@components/typography/typography.module';
import { IconModule } from '@components/icon/icon.module';
import { TippyDirective } from '@ngneat/helipopper';
import initTooltip from 'src/app/libs/component-lib/utils/init-tooltip';
import { LineItemComponent } from './booking-pricing/line-item.component';
import { CoreModule } from '../../../../core/core.module';
import { LinkModule } from '@components/link/link.module';
import { ButtonModule } from '@components/button/button.module';
import { CollapsibleLineItemComponent } from './booking-pricing/collapsible-line-item.component';

@NgModule({
  declarations: [
    DayCostBreakdownComponent,
    BookingPricingComponent,
    BookingRewardCardComponent,
    LineItemComponent,
    CollapsibleLineItemComponent,
  ],
  imports: [
    CommonModule,
    TippyDirective,
    TypographyModule,
    IconModule,
    CoreModule,
    LinkModule,
    ButtonModule,
  ],
  exports: [BookingPricingComponent, BookingRewardCardComponent],
  providers: [initTooltip()],
})
export class PropertyBookingPricingModule {}
