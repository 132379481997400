import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ChatService } from '../../../services/chat.service';
import {
  Message,
  MessageData,
  MessageType,
} from '../../../models/message.model';
import { AbstractComponent } from '../../../../../core/components/abstract/abstract.component';
import { Observable } from 'rxjs';
import { PagedModel } from '../../../../../shared/models/pagination';
import { UserService } from '../../../../../core/services/user.service';
import { User } from '../../../../../shared/models/user.model';
import { ChatStore } from '../../../services/chat.store';
import { Conversation } from '../../../models/conversation.model';
import { Property } from 'src/app/shared/models/property';
import { Booking } from 'src/app/shared/models/booking';

@Component({
  selector: 'app-chat-messages',
  templateUrl: './chat-messages.component.html',
  styleUrls: ['./chat-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessagesComponent
  extends AbstractComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  /** CONFIG */
  private readonly firstLoadMessagesAmount = 20;
  private readonly nextLoadMessagesAmount = 10;

  @ViewChild('messagesRef', { static: true })
  messagesRef: ElementRef<HTMLDivElement>;

  @Input()
  conversation: Conversation;
  @Input()
  maxHeight: boolean;

  cursor: Date;

  private messagesSubject: BehaviorSubject<MessageData[]>;
  messages$: Observable<MessageData[]>;

  currentUser: User;
  currentUser$: Observable<User>;

  conversationIdContextLoaded$: Observable<string>;
  properties$: Observable<Property[]>;
  bookings$: Observable<Booking[]>;

  oldestMessage: Message;
  loadingMessages: boolean;
  finalMessageLoaded = false;
  messagesSubscription: Subscription;
  messageType = MessageType;

  constructor(
    private readonly chatService: ChatService,
    private readonly userService: UserService,
    private readonly chatStore: ChatStore,
  ) {
    super();
  }

  ngOnInit(): void {
    this.properties$ = this.chatStore.properties$;
    this.bookings$ = this.chatStore.bookings$;
    this.currentUser$ = this.userService
      .getCurrentUser()
      .pipe(this.untilDestroyed());
    this.currentUser$.subscribe(user => {
      this.currentUser = user;
    });
    this.chatStore.onConversationOpen(this.conversation?.id);
    this.conversationIdContextLoaded$ =
      this.chatStore.conversationIdContextLoaded$;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.messagesSubject) {
      this.messagesSubject.complete();
    }
    this.chatStore.onConversationClose(this.conversation?.id);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.conversation) {
      const conversationChange = changes.conversation;
      this.onConversationChange(
        conversationChange.previousValue,
        conversationChange.currentValue,
      );
      this.chatStore.onConversationClose(
        changes.conversation.previousValue?.id,
      );
      this.chatStore.onConversationOpen(changes.conversation.currentValue?.id);
    }
  }

  ngAfterViewInit() {
    this.scrollToLastMessage();
  }

  onConversationChange(prev: Conversation, current: Conversation) {
    if (prev?.id === current?.id) {
      return;
    }
    if (this.messagesSubject) {
      this.messagesSubject.complete();
    }
    // Reset previous conversation messages
    this.messagesSubject = new BehaviorSubject<MessageData[]>([]);
    this.messages$ = this.messagesSubject.asObservable();
    this.oldestMessage = undefined;
    this.loadingMessages = false;
    this.finalMessageLoaded = false;
    // Load latest messages from conversation
    this.loadMessages();
    // Unsubscribe previous conversation subscription
    if (this.messagesSubscription) {
      this.messagesSubscription.unsubscribe();
    }
    // Subscribe to new messages using socket.io
    this.messagesSubscription = this.chatService
      .subscribeMessages(this.conversation.id)
      .pipe(this.untilDestroyed())
      .subscribe(message => {
        this.onNewMessage(message);
      });
  }

  onMessagesScroll() {
    if (this.finalMessageLoaded) {
      return;
    }
    const messagesElement = this.messagesRef.nativeElement;
    if (messagesElement.scrollTop < 200) {
      this.loadMessages();
    }
  }

  /**
   * Load next amount history messages
   */
  loadMessages() {
    const conversationId = this.conversation.id;
    if (this.loadingMessages) {
      return;
    }
    this.loadingMessages = true;
    const oldestMessage = this.oldestMessage;
    const firstLoad = !oldestMessage;
    const amount = firstLoad
      ? this.firstLoadMessagesAmount
      : this.nextLoadMessagesAmount;
    const sentBefore = oldestMessage?.sentAt ?? undefined;
    this.chatService
      .getMessages({
        conversationId: this.conversation.id,
        sentBefore: sentBefore,
        size: amount,
      })
      .pipe(this.untilDestroyed())
      .subscribe(result => {
        if (this.conversation?.id !== conversationId) {
          return;
        }
        this.loadingMessages = false;
        this.onMessagesLoad(result, firstLoad);
      });
  }

  onMessagesLoad(result: PagedModel<Message>, firstLoad: boolean) {
    if (result.last) {
      this.finalMessageLoaded = true;
    }
    this.addMessages(result.data);
    if (firstLoad) {
      // When first messages loads, scroll to last message
      setTimeout(() => {
        this.scrollToLastMessage();
      }, 0);
    } else {
      // Keep same scroll height when messages loads
      const element = this.messagesRef.nativeElement;
      const currentScrollHeight = element.scrollHeight;
      const scrollTop = element.scrollTop;
      setTimeout(() => {
        const newScrollHeight = element.scrollHeight;
        const heightDifference = Math.max(
          0,
          newScrollHeight - currentScrollHeight,
        );
        element.scrollTop = scrollTop + heightDifference;
      }, 0);
    }
  }

  onNewMessage(message: Message) {
    this.addMessages([message]);
    setTimeout(() => {
      this.scrollToLastMessage();
    }, 0);
  }

  /**
   * Add messages to view, remove duplicates and sort by createdAt
   */
  addMessages(messages: Message[]) {
    this.chatStore.readConversation(this.conversation);
    const currentMessages = this.messagesSubject.getValue();
    // Remove duplicated messages
    messages = messages.filter(
      message =>
        !currentMessages.some(
          currentMessage => currentMessage.id === message.id,
        ),
    );
    const messagesData = messages.map(message => {
      // Get user name from conversation participants
      const sender = message.senderId
        ? this.conversation.participants.find(
            p => p.userId === message.senderId,
          )
        : undefined;
      return {
        ...message,
        sender: {
          ...sender,
          isCurrentUser: this.currentUser?.id === message.senderId,
        },
        currentUserId: this.currentUser?.id,
      } as MessageData;
    });
    let newMessages = [...currentMessages, ...messagesData];
    newMessages = newMessages.sort(
      (a, b) => a.sentAt.getTime() - b.sentAt.getTime(),
    );
    this.messagesSubject.next(newMessages);
    this.oldestMessage = newMessages[0];
  }

  scrollToLastMessage() {
    const messagesElement = this.messagesRef.nativeElement;
    if (!messagesElement) {
      return;
    }
    const height = messagesElement.scrollHeight - messagesElement.clientHeight;
    messagesElement.scrollTop = height;
  }
}
