import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-collapsible-line-item',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <div class="d-flex d-flex-space-between">
      <app-line-item
        class="w-100"
        [labelTemplate]="labelButtonTemplate"
        [valueTemplate]="valueTemplate"
        [hintTemplate]="hintTemplate"
      >
        <ng-template #labelButtonTemplate>
          <button
            type="button"
            class="button-reset cursor-pointer"
            [class.container-open]="open"
            (click)="onToggle()"
          >
            <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>
          </button>
        </ng-template>
      </app-line-item>
    </div>
    <div *ngIf="open" class="mx-8">
      <ng-content></ng-content>
    </div>
  `,
})
export class CollapsibleLineItemComponent {
  @Input() labelTemplate: TemplateRef<any>;
  @Input() valueTemplate: TemplateRef<any>;
  @Input() hintTemplate?: TemplateRef<any>;
  @Input() open: boolean = false;

  onToggle() {
    this.open = !this.open;
  }
}
