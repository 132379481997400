import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { ToggleName, ToggleService } from './toggle.service';
import { tap } from 'rxjs/operators';
import { Url } from '../../../url';

export interface ToggleGuardData {
  toggleName: ToggleName;
}

/**
 * Add toggle guard to route for exact toggle name
 */
export function useToggleGuard(toggleName: ToggleName, route: Route): Route {
  route.data = { ...route.data, toggleName: toggleName } as ToggleGuardData;
  route.canActivate = [...(route.canActivate ?? []), ToggleGuard];
  return route;
}

/**
 * Toggle guard. Decides whether user is allowed to access toggleable routes.
 * @author Libor Staněk
 */
@Injectable({
  providedIn: 'root',
})
export class ToggleGuard {
  constructor(
    private readonly router: Router,
    private readonly toggleService: ToggleService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    const data = next.data as ToggleGuardData;
    if (!data?.toggleName) {
      console.warn(`Missing toggle name for ToggleGuard url ${next.url}`);
      return of(false);
    }
    return this.toggleService.isEnabled$(data.toggleName).pipe(
      tap(isEnabled => {
        if (!isEnabled) {
          this.router.navigate([Url.NOT_FOUND]);
        }
      }),
    );
  }
}
