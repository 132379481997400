import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavbarService } from '../../../navbar/services/navbar.service';
import { BreakpointService } from '../../../../core/services/breakpoint.service';
import { Observable } from 'rxjs';
import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';

@Component({
  selector: 'app-navigation-menu-mobile',
  templateUrl: './navigation-menu-mobile.component.html',
  styleUrls: ['./navigation-menu-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationMenuMobileComponent
  extends AbstractComponent
  implements OnInit
{
  open$: Observable<boolean> = this.navbarService.mobileMenuOpen$;
  largeScreen$: Observable<boolean> = this.breakpointService.largeScreen$;

  @ViewChild('menuContentRef', { static: false })
  menuContentRef: ElementRef<HTMLDivElement>;

  constructor(
    private readonly navbarService: NavbarService,
    private readonly breakpointService: BreakpointService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.largeScreen$.pipe(this.untilDestroyed()).subscribe(largeScreen => {
      if (largeScreen) {
        this.navbarService.closeMobileMenu();
      }
    });
  }

  closeMobileMenu() {
    if (this.menuContentRef?.nativeElement) {
      this.menuContentRef.nativeElement.scrollTop = 0;
    }
    this.navbarService.closeMobileMenu();
  }
}
