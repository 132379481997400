import { Component, Input } from '@angular/core';
import { TypographyModule } from '@components/typography/typography.module';

@Component({
  selector: 'app-text-separator',
  standalone: true,
  imports: [TypographyModule],
  template: `<p typography variant="body1" class="text-separator mt-16 mb-16">
    {{ text }}
  </p>`,
  styleUrl: './text-separator.component.scss',
})
export class TextSeparatorComponent {
  @Input() text: string;
}
