import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { Subscription, timer } from 'rxjs';

/**
 * Directive that emits event while user holds mouse click over an element
 *
 * @author Radek Chocholouš
 */
@Directive({ standalone: true, selector: '[appMouseHold]' })
export class MouseHoldDirective {
  /** Delay between every mousehold event in ms */
  @Input()
  delay = 50;

  @Output()
  mousehold: EventEmitter<any> = new EventEmitter();

  subscription: Subscription;

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    // don't do right/middle clicks
    if (event.button !== 0) {
      return;
    }
    if (!this.subscription || this.subscription.closed) {
      this.subscription = timer(0, this.delay).subscribe(() => {
        this.mousehold.emit(true);
      });
    }
  }

  @HostListener('mouseup')
  @HostListener('mouseout')
  onMouseOut() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
