import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Host,
  HostListener,
  OnDestroy,
  OnInit,
  Optional,
  SkipSelf,
} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GeocodingService } from '../../../../core/services/geocoding.service';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { ParentComponent } from '../parent.component';
import { COUNTRIES, Country } from '../../../../utils/country.util';

export interface PhoneNumber {
  dialCode: string;
  number: string;
  e164Number: string;
}

/**
 * Component to handle international phone number inputs
 *
 * @author Tomas Sevcik
 */
@Component({
  selector: 'p1h0m89-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: [
    './styles/phone-number-input.component.scss',
    './styles/flag-positions-01.scss',
    './styles/flag-positions-02.scss',
    './styles/flag-positions-03.scss',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneNumberInputComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneNumberInputComponent
  extends ParentComponent
  implements OnInit, OnDestroy
{
  /** Observables */
  selectedCountry$ = new BehaviorSubject<Country>({
    code: 'US',
    name: 'United States of America',
    prefix: '1',
  });

  /** Data */
  options: Country[];
  isDropdownOpen: boolean;
  dialCode: string;

  /** Last valid input value as string. */
  lastInputValue = '';

  constructor(
    private readonly geoService: GeocodingService,
    private readonly elementRef: ElementRef,
    @Host() @SkipSelf() @Optional() parentControlContainer: ControlContainer,
  ) {
    super(parentControlContainer);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.isDropdownOpen = false;
    this.options = COUNTRIES.sort((a, b) => {
      return a.name.localeCompare(b.name, 'us');
    });
    const usIndex = this.options.findIndex(option => option.code === 'US');
    if (usIndex > 0) {
      this.options.unshift(this.options.splice(usIndex, 1)[0]);
    }

    this.geoService
      .getDataByClientIp()
      .pipe(this.untilDestroyed(), first())
      .subscribe({
        next: response => {
          this.selectedCountry$.next(
            COUNTRIES.find(c => c.code === response.countryCode),
          );
        },
        error: () => {
          // Ignore this error
        },
      });

    this.selectedCountry$.pipe(this.untilDestroyed()).subscribe(country => {
      this.dialCode = `+${country.prefix}`;
    });
  }

  @HostListener('document:mousedown', ['$event'])
  onDocumentClick(event): void {
    // Ignore closed overlay
    if (!this.isDropdownOpen) {
      return;
    }
    // Ignore click inside input
    if (this.elementRef.nativeElement.contains(event.target)) {
      return;
    }
    this.isDropdownOpen = false;
  }

  toggleMenu() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  selectDialCode(country: Country) {
    this.selectedCountry$.next(country);
    // Update control output
    this.setInputValue(this.lastInputValue);
    // Close dropdown
    this.isDropdownOpen = false;
  }

  onInput(event: any) {
    const newValue = event.target.value;
    if (
      event.inputType === 'deleteContentBackward' ||
      event.inputType === 'deleteContentForward'
    ) {
      this.setInputValue(newValue);
      return;
    }
    // Phone number RegExp
    const regExp: RegExp = new RegExp(`[(]?[(0-9]{1,4}[)]?[-\\s.\\/0-9]*$`);
    if (!newValue.match(regExp)) {
      // invalid value, keep old one
      this.setInputValue(this.lastInputValue);
    } else {
      this.setInputValue(newValue);
    }
  }

  /**
   * Memorize last value and update control and form value accordingly.
   * @param value new field value
   */
  setInputValue(value: string) {
    this.lastInputValue = value;
    const processed = this.processValue(value);
    // silently update control to desired state
    this.formControl.setValue(processed.number, {
      onlySelf: true,
      emitEvent: false,
    });
    this.writeValue(processed.e164Number);
  }

  private processValue(inputNumber: string): PhoneNumber {
    if (
      inputNumber === undefined ||
      inputNumber === null ||
      inputNumber === ''
    ) {
      return {
        dialCode: this.dialCode,
        number: undefined,
        e164Number: undefined,
      } as PhoneNumber;
    }
    return {
      dialCode: this.dialCode,
      number: inputNumber,
      e164Number: `${this.dialCode}${inputNumber.replace(/\D+/g, '')}`,
    } as PhoneNumber;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.selectedCountry$.complete();
  }
}
