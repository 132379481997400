import BigNumber from 'bignumber.js';
import { BookingDiscounts } from '../shared/models/booking';

/**
 * Method to calculate stay price before the discounts were applied
 */
export function calculateOriginalStayPrice(
  clientStayPrice: number,
  discounts: BookingDiscounts,
): number {
  return new BigNumber(clientStayPrice ?? 0)
    .plus(discounts?.weeklyDiscount?.discountAmount ?? 0)
    .plus(discounts?.monthlyDiscount?.discountAmount ?? 0)
    .toNumber();
}
