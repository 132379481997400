import {
  addDays,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { CalendarDayOfWeek } from '@components/calendar/calendar';

export class DateInterval {
  public start: Date | null;
  public end: Date | null;

  constructor(start: Date, end: Date) {
    this.start = start;
    this.end = end;
  }

  public equals(obj: DateInterval) {
    if (this.start?.getTime() !== obj?.start?.getTime()) {
      return false;
    }
    if (this.end?.getTime() !== obj?.end?.getTime()) {
      return false;
    }
    return true;
  }

  public clone(): DateInterval {
    return new DateInterval(this.start, this.end);
  }

  public static ofDate(date: Date) {
    return new DateInterval(date, date);
  }

  public static empty() {
    return new DateInterval(null, null);
  }

  public isEmpty(): boolean {
    return this.start === null && this.end === null;
  }
}

export function rotateArray<T>(arr: T[], count: number): T[] {
  count -= arr.length * Math.floor(count / arr.length);
  arr.push(...arr.splice(0, count));
  return arr;
}

export function getRelativeRect(rect: DOMRect, parent: DOMRect): DOMRect {
  return new DOMRect(
    rect.x - parent.x,
    rect.y - parent.y,
    rect.width,
    rect.height,
  );
}

/**
 * Split input interval into array of intervals for each week
 * @param interval
 * @param options:
 *   - splitMonths: split week on end of mouth to 2 separate intervals
 *   - weekStartsOn: starting day of the week, default: SUNDAY
 * @example:
 *   {2021/01/01 10:00:00 - 2021/01/02 11:00:00} ->
 *     [{2021/01/01 10:00:00 - 2021/01/02 11:00:00}]
 *   {2021/01/01 10:00:00 - 2021/01/03 11:00:00} ->
 *     [{2021/01/01 10:00:00 - 2021/01/02 23:59:59}, {2021/01/03 00:00:00 - 2021/01/03 11:00:00}]
 *   {2021/01/01 10:00:00 - 2021/01/12 11:00:00} ->
 *     [{2021/01/01 10:00:00 - 2021/01/02 23:59:59}, {2021/01/03 00:00:00 - 2021/01/09 23:59:59}, {2021/01/10 00:00:00 - 2021/01/12 11:00:00}]
 */
export function splitIntervalIntoWeeks(
  interval: DateInterval,
  options: { splitMonths?: boolean; weekStartsOn?: CalendarDayOfWeek } = {},
): DateInterval[] {
  const weekStartsOn = (options?.weekStartsOn ?? CalendarDayOfWeek.SUNDAY).id;
  const intervals: DateInterval[] = [];
  const start = interval.start;
  const end = interval.end;
  let currentWeekStart = start;
  while (true) {
    const weekEnd = endOfWeek(currentWeekStart, { weekStartsOn: weekStartsOn });
    if (
      options.splitMonths &&
      weekEnd.getMonth() !== currentWeekStart.getMonth()
    ) {
      const monthEnd = endOfMonth(currentWeekStart);
      if (monthEnd > end) {
        intervals.push(new DateInterval(currentWeekStart, end));
        break;
      }
      intervals.push(new DateInterval(currentWeekStart, monthEnd));
      currentWeekStart = startOfMonth(weekEnd);
      continue;
    }
    if (weekEnd > end) {
      intervals.push(new DateInterval(currentWeekStart, end));
      break;
    }
    intervals.push(new DateInterval(currentWeekStart, weekEnd));
    currentWeekStart = startOfWeek(addDays(weekEnd, 1), {
      weekStartsOn: weekStartsOn,
    });
  }
  return intervals;
}
