import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  CalendarMonthComponent,
  ViewData,
} from '@components/calendar/calendar-month/calendar-month.component';
import {
  DateInterval,
  splitIntervalIntoWeeks,
} from '@components/calendar/calendar.utils';
import { isSameDay, isSameMonth } from 'date-fns';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';

export class CalendarSelection {
  interval: DateInterval;
  color: CalendarSelectionColor;
}

export enum CalendarSelectionColor {
  NEUTRAL = 'neutral',
  PRIMARY = 'primary',
  YELLOW = 'yellow',
  GREEN = 'green',
  BLUE = 'blue',
  GRAY = 'gray',
}

interface CalendarHighlightRow {
  rect: DOMRect;
  borderLeft: boolean;
  borderRight: boolean;
  variant: CalendarSelectionColor;
}

@Component({
  selector: 'calendar-month-overlay',
  styleUrls: ['./calendar-month-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <div class="calendar-month-overlay-layer">
      <span
        *ngFor="let highlight of highlights$ | async"
        [style.top]="highlight.rect.top + 'px'"
        [style.left]="highlight.rect.left + 'px'"
        [style.width]="highlight.rect.width + 'px'"
        [style.height]="highlight.rect.height + 'px'"
        class="calendar-month-overlay-highlight"
        [class.highlight-border-left]="highlight.borderLeft"
        [class.highlight-border-right]="highlight.borderRight"
        [ngClass]="['highlight-variant-' + highlight.variant]"
      ></span>
    </div>
    <div class="calendar-month-overlay-content">
      <ng-content></ng-content>
    </div>
  `,
})
export class CalendarMonthOverlayComponent
  implements OnInit, AfterContentInit, OnChanges, OnDestroy
{
  @ContentChild(CalendarMonthComponent)
  readonly calendarComponent: CalendarMonthComponent;

  @Input()
  readonly selections: CalendarSelection[] = [];

  private readonly selectionsSubject = new ReplaySubject<CalendarSelection[]>(
    1,
  );
  readonly selections$ = this.selectionsSubject.asObservable();
  private readonly highlightsSubject = new BehaviorSubject<
    CalendarHighlightRow[]
  >([]);
  readonly highlights$ = this.highlightsSubject.asObservable();

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    const element = this.elementRef.nativeElement;
    this.renderer.addClass(element, `calendar-month-overlay`);
    this.selectionsSubject.next(this.selections);
  }

  ngAfterContentInit(): void {
    combineLatest([this.selections$, this.calendarComponent.view$]).subscribe(
      ([selections, view]) => {
        const highlights = this.generateHighlights(selections, view);
        this.highlightsSubject.next(highlights);
        this.changeDetectorRef.detectChanges();
      },
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selections) {
      this.selectionsSubject.next(changes.selections.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.selectionsSubject.complete();
    this.highlightsSubject.complete();
  }

  private generateHighlights(
    selections: CalendarSelection[],
    view: ViewData,
  ): CalendarHighlightRow[] {
    const highlights: CalendarHighlightRow[] = [];
    for (const selection of selections) {
      highlights.push(...this.generateHighlightForSelection(selection, view));
    }
    return highlights;
  }

  private generateHighlightForSelection(
    selection: CalendarSelection,
    view: ViewData,
  ): CalendarHighlightRow[] {
    const currentMonth = this.calendarComponent.calendar.start;
    const weekStatsOn = this.calendarComponent.calendar.dayOfWeeks[0];
    const weekIntervals = splitIntervalIntoWeeks(selection.interval, {
      weekStartsOn: weekStatsOn,
      splitMonths: true,
    }).filter(interval => isSameMonth(interval.start, currentMonth));
    return weekIntervals.map(weekInterval =>
      this.generateHighlightPerSelectionWeek(selection, view, weekInterval),
    );
  }

  private generateHighlightPerSelectionWeek(
    selection: CalendarSelection,
    view: ViewData,
    interval: DateInterval,
  ): CalendarHighlightRow {
    const start = view.days[interval.start.getDate() - 1];
    const end = view.days[interval.end.getDate() - 1];
    const selectionStartsThisWeek = isSameDay(
      interval.start,
      selection.interval.start,
    );
    const selectionEndsThisWeek = isSameDay(
      interval.end,
      selection.interval.end,
    );
    return {
      rect: new DOMRect(
        start.rect.x,
        start.rect.y,
        end.rect.right - start.rect.left,
        start.rect.height,
      ),
      variant: selection.color,
      borderLeft: selectionStartsThisWeek,
      borderRight: selectionEndsThisWeek,
    };
  }
}
