import { Component } from '@angular/core';
import { PromoBannerService } from '../../services/promo-banner.service';
import {
  trigger,
  state,
  style,
  transition,
  animate,
  AnimationEvent,
} from '@angular/animations';

@Component({
  selector: 'app-promo-banner',
  templateUrl: './promo-banner.component.html',
  styleUrl: './promo-banner.component.scss',
  animations: [
    trigger('closeAnimation', [
      state('open', style({ height: '*', opacity: 1, margin: '*' })),
      state('closed', style({ height: '0px', opacity: 0, margin: '0px' })),
      transition('open => closed', [animate('150ms ease-in-out')]),
    ]),
  ],
})
export class PromoBannerComponent {
  banners$ = this.promoBannerService.getBanners$();

  constructor(private readonly promoBannerService: PromoBannerService) {}

  onAnimationDone(event: AnimationEvent, id: string) {
    if (event.toState === 'closed') {
      this.promoBannerService.closeBanner(id);
    }
  }

  getAnimationState(id: string) {
    return this.promoBannerService.closingBanners.has(id) ? 'closed' : 'open';
  }
}
