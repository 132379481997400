import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

type HintVariant = 'none' | 'default' | 'valid' | 'invalid';

@Component({
  selector: 'hint',
  styleUrls: ['hint.component.scss'],
  template: `
    <span class="hint-indicator"></span>
    <ng-content></ng-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HintComponent implements OnInit, OnChanges {
  @Input() variant: HintVariant = 'default';

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(this.elementRef.nativeElement, `hint2`);
    this.reloadVariant();
  }

  reloadVariant() {
    this.renderer.removeClass(
      this.elementRef.nativeElement,
      `hint-variant-none`,
    );
    this.renderer.removeClass(
      this.elementRef.nativeElement,
      `hint-variant-default`,
    );
    this.renderer.removeClass(
      this.elementRef.nativeElement,
      `hint-variant-valid`,
    );
    this.renderer.removeClass(
      this.elementRef.nativeElement,
      `hint-variant-invalid`,
    );

    this.renderer.addClass(
      this.elementRef.nativeElement,
      `hint-variant-${this.variant}`,
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.reloadVariant();
  }
}
