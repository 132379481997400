import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ToggleService } from 'src/app/core/modules/toggle/toggle.service';

@Component({
  selector: 'app-home-offer',
  templateUrl: './home-offer.component.html',
  styleUrls: ['./home-offer.component.scss'],
})
export class HomeOfferComponent {
  @Input()
  open: boolean;
  @Output()
  closeButton = new EventEmitter<void>();

  constructor(private readonly toggleService: ToggleService) {}

  onClose() {
    this.closeButton.emit();
  }

  get getSaveAmount() {
    const points =
      this.toggleService.getVariantValue<number>('signUpRewardPoints') ?? 0;
    return Math.round(points / 100);
  }
}
