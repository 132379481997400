import { Component, Input } from '@angular/core';
import { TypographyModule } from '@components/typography/typography.module';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule, TypographyModule],
  selector: 'app-privacy-policy-content',
  templateUrl: './privacy-policy-content.component.html',
  styleUrls: ['./privacy-policy-content.component.scss'],
})
export class PrivacyPolicyContentComponent {
  @Input()
  showEffectiveDate = false;
}
