import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Breakpoint, BreakpointService } from '../services/breakpoint.service';
import { Subscription } from 'rxjs';

/**
 * Directive for render component content, if screen width is larger than breakpoint
 *
 * @author Libor Staněk
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[breakpointUp]',
})
export class BreakpointUpDirective implements OnInit, OnDestroy {
  @Input()
  breakpointUp: Breakpoint;

  breakpointSubscription: Subscription;
  show: boolean;

  constructor(
    private readonly element: ElementRef,
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly breakpointService: BreakpointService,
  ) {}

  ngOnInit(): void {
    this.breakpointSubscription =
      this.breakpointService.screenBreakpoint$.subscribe(b => {
        const show = this.breakpointService.isBreakpointUpOrSame(
          b,
          this.breakpointUp,
        );
        if (this.show === show) {
          return;
        }
        if (show) {
          this.viewContainer
            .createEmbeddedView(this.templateRef)
            .detectChanges();
        } else {
          this.viewContainer.clear();
        }
        this.show = show;
      });
  }

  ngOnDestroy(): void {
    if (this.breakpointSubscription) {
      this.breakpointSubscription.unsubscribe();
    }
  }
}
