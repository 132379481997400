import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { env } from '../../dynamic-environment';
import { map, tap } from 'rxjs/operators';
import { Auth0UserProfile, Authentication, WebAuth } from 'auth0-js';
import { Router } from '@angular/router';

export interface Auth0TokenData {
  /** Access token as raw string. */
  accessToken?: string;
  /** Refresh token as raw string. */
  refreshToken?: string;
  /** Token expiry in milliseconds - same format as `Date.time()` */
  expires?: number;
  /** Token scopes */
  scopes?: string;
}

@Injectable({
  providedIn: 'root',
})
export class Auth0ApiService {
  private readonly domain: string;
  private readonly clientId: string;

  /** Variable for auth0 instance */
  private webAuth: WebAuth;
  private authentication: Authentication;

  constructor(private readonly router: Router) {
    this.domain = env.auth0.domain;
    this.clientId = env.auth0.clientId;
  }

  init(): Observable<void> {
    return from(import('auth0-js')).pipe(
      tap(auth0 => {
        // Initialize Auth0 client
        this.webAuth = new auth0.WebAuth({
          domain: this.domain,
          clientID: this.clientId,
          redirectUri: window.location.origin,
          responseType: env.auth0.responseType,
          audience: env.auth0.audience,
          scope: env.auth0.requestedScopes,
        });
        this.authentication = new auth0.Authentication({
          domain: this.domain,
          clientID: this.clientId,
        });
      }),
      map(() => undefined),
    );
  }

  /**
   * Observable version of Auth0 login method
   *
   * @see https://auth0.github.io/auth0.js/global.html#login
   */
  login(email: string, password: string): Observable<Auth0TokenData> {
    return new Observable<Auth0TokenData>(observer => {
      this.webAuth.client.login(
        {
          realm: env.auth0.database,
          username: email,
          password,
        },
        (error, data) => {
          if (error) {
            observer.error(error);
          } else {
            observer.next(this.mapAuth0DataToTokenData(data));
          }
          observer.complete();
        },
      );
    });
  }

  /**
   * Observable version of Auth0 authorize method
   *
   * @see https://auth0.github.io/auth0.js/global.html#authorize
   */
  authorize(connection: 'google-oauth2' | 'facebook'): void {
    this.webAuth.authorize({
      domain: env.auth0.domain,
      responseType: 'code',
      connection,
      redirectUri: `${window.location.origin}/social/login`,
    });
  }

  /**
   * Get new access and refresh token from current refresh token
   */
  refreshToken(refreshToken: string): Observable<Auth0TokenData> {
    return new Observable<any>(observer => {
      this.authentication.oauthToken(
        {
          grantType: 'refresh_token',
          refreshToken: refreshToken,
        },
        (error, data) => {
          if (error) {
            observer.error(error);
          } else {
            observer.next(this.mapAuth0DataToTokenData(data));
          }
          observer.complete();
        },
      );
    });
  }

  /**
   * Authorize code for auth0 social login
   */
  authorizeCode(code: string): Observable<Auth0TokenData> {
    return new Observable<any>(observer => {
      this.authentication.oauthToken(
        {
          grantType: 'authorization_code',
          code: code,
          redirectUri: `${window.location.origin}/social/login`,
        },
        (error, data) => {
          if (error) {
            observer.error(error);
          } else {
            observer.next(this.mapAuth0DataToTokenData(data));
          }
          observer.complete();
        },
      );
    });
  }

  /**
   * Revoke auth0's refresh token
   * After calling this method, the refresh token can no longer be used
   */
  revokeRefreshToken(refreshToken: string) {
    const body = JSON.stringify({
      client_id: this.clientId,
      token: refreshToken,
    });
    return from(
      fetch(`https://${this.domain}/oauth/revoke`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body,
      }),
    );
  }

  passwordlessStart(phoneNumber: string): Observable<any> {
    return new Observable<any>(observer => {
      this.webAuth.passwordlessStart(
        {
          connection: 'sms',
          send: 'code',
          phoneNumber,
        },
        (error, data) => {
          if (error) {
            observer.error(error);
          } else {
            observer.next(data);
          }
          observer.complete();
        },
      );
    });
  }

  passwordlessLogin(
    phoneNumber: string,
    verificationCode: string,
  ): Observable<Auth0TokenData> {
    return new Observable<Auth0TokenData>(observer => {
      this.webAuth.passwordlessLogin(
        {
          responseType: 'code',
          redirectUri: `${window.location.origin}/social/login`,
          connection: 'sms',
          phoneNumber,
          verificationCode,
        },
        (error, data) => {
          if (error) {
            observer.error(error);
          } else {
            observer.next(data);
          }
          observer.complete();
        },
      );
    });
  }

  /**
   * Observable version of Auth0 checkSession method
   *
   * @see https://auth0.github.io/auth0.js/global.html#checkSession
   */
  checkSession(): Observable<Auth0TokenData> {
    return new Observable<Auth0TokenData>(observer => {
      this.webAuth.checkSession(
        {
          redirectUri: `${window.location.origin}/social/login`,
        },
        (error, data) => {
          if (error) {
            observer.error(error);
          } else {
            observer.next(this.mapAuth0DataToTokenData(data));
          }
          observer.complete();
        },
      );
    });
  }

  /**
   * Observable version of Auth0 userInfo method
   *
   * @see https://auth0.github.io/auth0.js/global.html#userInfo
   */
  userInfo(accessToken: string): Observable<Auth0UserProfile> {
    return new Observable<Auth0UserProfile>(subscriber => {
      this.webAuth.client.userInfo(accessToken, (err, profile) => {
        if (err) {
          subscriber.error(err);
        } else {
          subscriber.next(profile);
          subscriber.complete();
        }
      });
    });
  }

  /**
   * Get parsed access token object from its opaque representation.
   */
  parseAccessToken(accessToken: string): any {
    try {
      return JSON.parse(atob(accessToken.split('.')[1]));
    } catch (e) {
      return undefined;
    }
  }

  /** Map raw Auth0 data to Auth0TokenData */
  private mapAuth0DataToTokenData(data): Auth0TokenData {
    const parsed = this.parseAccessToken(data.accessToken);
    return {
      accessToken: data.accessToken,
      refreshToken: data.refreshToken,
      expires: parsed.exp * 1000,
      scopes: parsed.scope,
    };
  }
}
