import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BookingApprovedMessage } from 'src/app/modules/chat/models/booking-message.model';
import { Booking } from 'src/app/shared/models/booking';

@Component({
  selector: 'app-chat-message-booking-approved',
  templateUrl: './chat-message-booking-approved.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageBookingApprovedComponent {
  @Input() conversationId: string;
  @Input() message: BookingApprovedMessage;

  booking: Booking;
  @Input() set bookings(value: Booking[]) {
    this.booking = value.find(v => v.id === this.message.payload.bookingId);
  }
}
