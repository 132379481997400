<dialog-wrapper [position]="largeScreen ?  'center' : 'bottom'">
  <dialog-header (dialogClose)="closeDialog()" title={{title}}></dialog-header>
  <dialog-content>
    <ng-container *ngIf="largeScreen; else mobileTitle">
      <p typography variant="body1" [color]="(errors$ | async) ? 'red' : 'ink'">
        We’ve sent a 6-digit verification code to {{phoneNumber}}
      </p>
    </ng-container>
    <ng-template #mobileTitle>
      <div class="mt-32">
        <h2 typography variant="h5" color="ink">Enter the 6 digit verification code</h2>
        <p typography variant="body1" [color]="(errors$ | async) ? 'red' : 'ink'">
          We sent you a text message to {{phoneNumber}}
        </p>
      </div>
    </ng-template>
    <div class="mt-16">
      <app-phone-number-verification
        (submitPhoneVerification)="onSubmitPhoneVerification($event)"
        [resetAndUpdateValidity]="resetAndUpdateValidity">
      </app-phone-number-verification>
      <p typography variant="body2" color="red" class="mt-8" *ngIf="errors$ | async as errors">
        {{ errors }}
      </p>
    </div>
  </dialog-content>
  <dialog-actions>
    <button link variant="link1" color="primary" (click)="resendPhoneVerification()" [disabled]="resendButtonCountdown > 0">{{ getResendButtonString() }}</button>
  </dialog-actions>
</dialog-wrapper>
