import { Pipe, PipeTransform } from '@angular/core';
import { InvalidPropertyReasonType } from 'src/app/shared/models/property';

@Pipe({
  name: 'formatInvalidReason',
})
export class FormatInvalidReason implements PipeTransform {
  transform(type: string): string {
    if (!type) {
      return '';
    }

    switch (type) {
      case InvalidPropertyReasonType.NO_USD_PRICE:
        return 'The property lacks a valid USD pricing model.';
      case InvalidPropertyReasonType.UNSUPPORTED_CLASSIFICATION:
        return 'Property classification is not supported.';
      case InvalidPropertyReasonType.UNSUPPORTED_TAX_TYPE:
        return 'Property contains unsupported taxes.';
      case InvalidPropertyReasonType.UNSUPPORTED_FEE_TYPE:
        return 'Property contains unsupported fees.';
      case InvalidPropertyReasonType.UNSUPPORTED_PROPERTY_TYPE:
        return 'The property type is not supported.';
      case InvalidPropertyReasonType.HIDDEN_FEE_PRESENT:
        return 'Property contains hidden fees.';
      case InvalidPropertyReasonType.INCOMPLETE_ADDRESS:
        return 'Address is incomplete.';
      case InvalidPropertyReasonType.UNSUPPORTED_CANCELLATION_POLICY:
        return 'The cancellation policy is not supported.';
      case InvalidPropertyReasonType.ERROR_DURING_IMPORT:
        return 'An error occurred during import.';
      case InvalidPropertyReasonType.UNSUPPORTED_DAMAGE_DEPOSIT:
        return 'Refundable damage deposit is currently not supported.';
      case InvalidPropertyReasonType.UNSUPPORTED_BOOKING_POLICY_TYPE:
        return 'Property has "Quotehold" booking policy, which is currently not supported.';
      case InvalidPropertyReasonType.UNSUPPORTED_FEATURE:
        return 'Some features are not supported.';
      default:
        return 'Unknown error.';
    }
  }
}
