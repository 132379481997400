import { Component, OnInit } from '@angular/core';
import { BlockUiService } from 'src/app/core/services/block-ui.service';

@Component({
  selector: 'app-forbidden-page',
  templateUrl: './forbidden-page.component.html',
  styleUrls: ['./forbidden-page.component.scss'],
})
export class ForbiddenPageComponent implements OnInit {
  constructor(private readonly blockUiService: BlockUiService) {}

  ngOnInit(): void {
    this.blockUiService.unblockAll();
  }
}
