import {
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { dateToString } from '../../../../utils/date.util';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LocationDataType } from '../../../../shared/models/map-address';
import { Router } from '@angular/router';
import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Url } from '../../../../url';
import { startOfDay } from 'date-fns';
import { DateInterval } from '@components/calendar/calendar.utils';
import { PropertySearchDestinationDialogComponent } from '@components/property-search-dialog/property-search-destination-dialog.component';
import { DialogService } from '../../../../core/services/dialog.service';
import { PropertySearchCalendarDialogComponent } from '@components/property-search-dialog/property-search-calendar-dialog.component';
import { InputLocationComponent } from '@components/input-location/input-location.component';
import { mapAddressToBounds } from '../../../../utils/mapbox.util';

@Component({
  selector: 'app-home-property-search',
  templateUrl: './home-property-search.component.html',
  styleUrls: ['./home-property-search.component.scss'],
})
export class HomePropertySearchComponent
  extends AbstractComponent
  implements OnInit
{
  readonly today = startOfDay(new Date());

  @Input()
  modal: boolean;
  @Input()
  open: boolean;

  form: UntypedFormGroup;
  loading = false;

  @ViewChild(InputLocationComponent, { static: false })
  destinationInputRef: InputLocationComponent;

  // TODO: constant
  locationTypes = [
    LocationDataType.PLACE,
    LocationDataType.REGION,
    LocationDataType.COUNTRY,
    LocationDataType.LOCALITY,
  ];

  constructor(
    private readonly router: Router,
    private readonly dialogService: DialogService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      destination: new UntypedFormControl(),
      interval: new UntypedFormControl(DateInterval.empty()),
      guests: new UntypedFormControl(),
    });
  }

  onWhereAreYouGoing() {
    this.dialogService
      .clearAndOpen(PropertySearchDestinationDialogComponent)
      .afterClosed()
      .subscribe(destinationData => {
        if (!destinationData) {
          return;
        }
        this.dialogService
          .clearAndOpen(PropertySearchCalendarDialogComponent, {
            data: {
              interval: new DateInterval(undefined, undefined),
            },
          })
          .afterClosed()
          .subscribe(calendarData => {
            if (!calendarData) {
              this.onWhereAreYouGoing();
              return;
            }
            const queryParams: { [k: string]: any } = {};
            queryParams.destination = destinationData.text;
            queryParams.checkIn = calendarData.start
              ? dateToString(calendarData.start)
              : null;
            queryParams.checkOut = calendarData.end
              ? dateToString(calendarData.end)
              : null;
            if (destinationData.address) {
              const bounds = mapAddressToBounds(destinationData.address);
              queryParams.n = bounds.getNorth();
              queryParams.w = bounds.getWest();
              queryParams.s = bounds.getSouth();
              queryParams.e = bounds.getEast();
            }

            this.router.navigate([Url.SEARCH], {
              queryParams: queryParams,
            });
          });
      });
  }

  onOpen() {
    this.open = true;
    // Wait for component repaint
    of(null)
      .pipe(this.untilDestroyed(), delay(1))
      .subscribe(() => {
        this.destinationInputRef.focus({ preventScroll: true });
      });
  }

  onClose() {
    this.open = false;
  }

  onFindVacay() {
    if (this.loading) {
      return;
    }
    const form = this.form.value;
    const queryParams: { [k: string]: any } = {};
    queryParams.destination = form.destination?.text;
    queryParams.guests = form.guests?.guests;
    queryParams.maxPets = form.guests?.pets ?? null;
    queryParams.checkIn = form.interval?.start
      ? dateToString(form.interval.start)
      : null;
    queryParams.checkOut = form.interval?.end
      ? dateToString(form.interval.end)
      : null;
    if (form.destination?.address) {
      const bounds = mapAddressToBounds(form.destination.address);
      queryParams.n = bounds.getNorth();
      queryParams.w = bounds.getWest();
      queryParams.s = bounds.getSouth();
      queryParams.e = bounds.getEast();
    }
    this.router.navigate([Url.SEARCH], {
      queryParams: queryParams,
    });
    this.loading = true;
  }

  @HostListener('window:keyup', ['$event'])
  onWindowKeyUp(event: KeyboardEvent) {
    if (this.modal && this.open && event.key === 'Escape') {
      this.onClose();
    }
  }
}
