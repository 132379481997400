<div class="section-wrapper">
  <app-home-section-header
    title="How We Work"
    subtitle="Why Choose Us?"
    align="center"
  ></app-home-section-header>

  <div class="nouns" *transloco="let t">
    <div appChristmasThemeIcon class="noun">
      <div class="noun-image">
        <img
          loading="lazy"
          [src]="'assets/img/home/nouns/no-fees.svg'"
          alt="No Hidden Fees"
        />
      </div>
      <h2>{{ t('home-page.NoHiddenFees') }}</h2>
      <p>
        Transparent pricing without hidden fees. Our straightforward budget
        pricing makes choosing your vacation rental easy.
      </p>
    </div>
    <div appChristmasThemeIcon class="noun">
      <div class="noun-image">
        <img
          loading="lazy"
          [src]="'assets/img/home/nouns/save-money.svg'"
          alt="Save Money"
        />
      </div>
      <h2>Save Money</h2>
      <p>
        With VacayMyWay’s wholesale pricing model, travelers can save up to 20%
        on every booking.
      </p>
    </div>
    <div appChristmasThemeIcon class="noun">
      <div class="noun-image">
        <img
          loading="lazy"
          [src]="'assets/img/home/nouns/open-communication.svg'"
          alt="Open communication"
        />
      </div>
      <h2>Open Communication</h2>
      <p>
        We connect hosts and travelers with clear, unfiltered communication on
        your schedule.
      </p>
    </div>
  </div>

  <ng-container *ifEnabled="'rewardPointEarning'">
    <div class="reward-card">
      <icon variant="reward_point" class="reward-card-icon"></icon>
      <div class="reward-card-title">Save and get rewards on every Vacay!</div>
      <div class="reward-card-text">
        You make an account - you get reward points. You book with us - you get
        reward points. You write a review - you get reward points (coming soon).
        Spend your reward points to get an even bigger discount!
      </div>
      <button *ngIf="(isLoggedIn$ | async) === false" button variant="contained" class="reward-card-button" (click)="onSignIn()">
        <span class="text-nowrap">Sign up & grab your points</span>
      </button>
      <button *ngIf="isLoggedIn$ | async" button variant="contained" class="reward-card-button" (click)="onViewBalance()">
        <span class="text-nowrap">View balance</span>
      </button>
    </div>
  </ng-container>
</div>
