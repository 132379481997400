import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: '[card]',
  template: '<div class="card-content"><ng-content></ng-content></div>',
})
export class CardComponent implements OnInit {
  @Input()
  elevation: number; // TODO

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(this.elementRef.nativeElement, 'card');
  }
}
