import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

export type TypographyVariant =
  | 'inherit'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'h7'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'body4'
  | 'em1'
  | 'em2'
  | 'em3'
  | 'em4';
export type TypographyColor =
  | 'inherit'
  | 'primary'
  | 'ink'
  | 'ink-light'
  | 'red'
  | 'green'
  | 'blue'
  | 'white'
  | 'yellow';

/**
 * @see https://www.figma.com/file/EzdNTVM6tvmDBlbQhk3t22/Foundations-VMW?node-id=13%3A1931
 * @author Libor Staněk
 */
@Component({
  selector: '[typography]',
  styleUrls: ['./typography.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: '<ng-content></ng-content>',
})
export class TypographyComponent implements OnInit, OnChanges {
  @Input()
  variant: TypographyVariant = 'inherit';
  @Input()
  color: TypographyColor = 'inherit';

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    const element = this.elementRef.nativeElement;
    this.renderer.addClass(element, `typography2`);
    this.renderer.addClass(element, `typography-variant-${this.variant}`);
    this.renderer.addClass(element, `typography-color-${this.color}`);
  }

  ngOnChanges(changes: SimpleChanges) {
    const element = this.elementRef.nativeElement;
    if (changes.variant) {
      this.renderer.removeClass(
        element,
        `typography-variant-${changes.variant.previousValue}`,
      );
      this.renderer.addClass(
        element,
        `typography-variant-${changes.variant.currentValue}`,
      );
    }
  }
}
