import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../../../../shared/models/user.model';
import { ParentAuthenticationDialog } from '../../parent-authentication-dialog';
import { UserService } from '../../../../../core/services/user.service';
import { VerificationService } from '../../../../../core/services/verification.service';
import { BreakpointService } from '../../../../../core/services/breakpoint.service';
import { VerificationPageSourceType } from '../../../../user/user-verification/services/verification-page.service';

/**
 * Component dialog for intro superhog verification
 */
@Component({
  selector: 'app-superhog-verification',
  templateUrl: './superhog-verification.component.html',
  styleUrls: ['./superhog-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuperhogVerificationComponent
  extends ParentAuthenticationDialog
  implements OnInit
{
  user$: Observable<User>;

  /** Large screen flag */
  ls = false;

  constructor(
    injector: Injector,
    readonly userService: UserService,
    private readonly verificationService: VerificationService,
    private readonly breakpointService: BreakpointService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.user$ = this.userService.getCurrentUser();
    this.breakpointService.largeScreen$
      .pipe(this.untilDestroyed())
      .subscribe(isLargeScreen => {
        this.ls = isLargeScreen;
      });
  }

  verifyUser(user: User) {
    this.verificationService.openVerification(
      user,
      VerificationPageSourceType.LOGIN,
    );
    this.closeDialog();
  }
}
