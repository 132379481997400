import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  ParamMap,
  PRIMARY_OUTLET,
  Router,
  RouterEvent,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
/** Source: https://gist.github.com/m-spyratos/5fa0deedc173458d673df7e4c82f3e49 */
/**
 * Service to get all the application's route parameters, as ParamMap.
 * https://angular.io/guide/router#parammap-api
 *
 * Usage:
 *
 * this.paramMapService.paramMap.subscribe(paramMap => {
 *    paramMap.get('id');
 * });
 *
 * Note: ES6 Map is being used.
 * To support older browsers, do one of the following:
 * - Uncomment the "import 'core-js/es6/map';" line in polyfills.ts, or
 * - Convert all Map occurrences to a simple object.
 */
export class ParamMapService {
  paramMap: Observable<ParamMap>;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {
    const paramMapSubject = new ReplaySubject<ParamMap>(1);
    this.getParamMapObservable().subscribe(paramMap => {
      paramMapSubject.next(paramMap);
    });
    this.paramMap = paramMapSubject.asObservable();
  }

  private getParamMap(route: ActivatedRoute): ParamMap {
    const m: Map<string, string | string[]> = new Map();

    while (route) {
      route.snapshot.paramMap.keys.forEach(key => {
        m.set(key, this.getParamMapValue(route.snapshot.paramMap, key));
      });
      route = route.firstChild;
    }

    return {
      keys: this.getParamMapKeys(m),
      has: this.getParamMapMethodHas(m),
      get: this.getParamMapMethodGet(m),
      getAll: this.getParamMapMethodGetAll(m),
    };
  }

  private getParamMapMethodGet(
    m: Map<string, string | string[]>,
  ): (name: string) => string | null {
    return (name: string): string | null => {
      const value = m.get(name);
      if (typeof value === 'string') {
        return value;
      }
      if (Array.isArray(value) && value.length) {
        return value[0];
      }
      return null;
    };
  }

  private getParamMapMethodGetAll(
    m: Map<string, string | string[]>,
  ): (name: string) => string[] {
    return (name: string): string[] => {
      const value = m.get(name);
      if (typeof value === 'string') {
        return [value];
      }
      if (Array.isArray(value)) {
        return value;
      }
      return [];
    };
  }

  private getParamMapMethodHas(
    m: Map<string, string | string[]>,
  ): (name: string) => boolean {
    return (name: string): boolean => m.has(name);
  }

  private getParamMapKeys(m: Map<string, string | string[]>): string[] {
    return Array.from(m.keys());
  }

  private getParamMapObservable(): Observable<ParamMap> {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      filter((route: ActivatedRoute) => route.outlet === PRIMARY_OUTLET),
      map((route: ActivatedRoute) => this.getParamMap(route)),
    );
  }

  private getParamMapValue(paramMap: ParamMap, key: string): string | string[] {
    return paramMap.getAll(key).length > 1
      ? paramMap.getAll(key)
      : paramMap.get(key);
  }
}
