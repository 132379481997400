<div *ngIf="property && recipient">
    <div class="d-flex justify-content-between align-items-center mx-16 my-24">
        <app-property-booking-header></app-property-booking-header>
        <a link (click)="closeDetail()">
            <icon size="20" variant="cross"></icon>
        </a>
    </div>

    <!-- Property info START -->
    <div card class="mx-16 my-24">
        <app-property-info [property]="property" [isPropertyOwner]="isPropertyOwner"></app-property-info>
    </div>
    <!-- Property info END -->

    <!-- GUEST/HOST info START -->
    <div class="mx-16 my-24">
        <app-chat-recipient [title]="recipientTitle" [hideBio]="true" [recipient]="recipient"></app-chat-recipient>
    </div>
    <!-- GUEST/HOST info END -->

    <!-- HOUSE RULES START -->
    <div card class="mx-16 my-24" *ngIf="!isPropertyOwner">
        <app-property-house-rules [property]="property"></app-property-house-rules>
    </div>
    <!-- HOUSE RULES END -->

    <!-- ARRIVAL INSTRUCTIONS -->
    <div card class="mx-16 my-24" *ngIf="!isPropertyOwner && property?.instructions">
        <app-property-arrival-instructions [instructions]="property.instructions"></app-property-arrival-instructions>
    </div>
    <!-- ARRTIVAL INSTRUCTIONS END -->

    <!-- Property avaibility START -->
    <div class="mx-16 my-24" *ngIf="isPropertyOwner" card>
        <app-property-availability [property]="property"></app-property-availability>
    </div>
    <!-- Property avaibility END -->
</div>
