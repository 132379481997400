import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AmenityOption } from '../../../../shared/models/amenity';

@Component({
  selector: 'amenity-menu',
  template: `
    <span class="menu-icon"
      ><icon
        [variant]="menuOpen ? 'chevron_up' : 'chevron_down'"
        (click)="onClick()"
      ></icon
    ></span>
    <div *ngIf="menuOpen" class="amenity-menu-container" #menu>
      <ng-container *ngIf="multiple; else one">
        <div
          *ngFor="let option of options; index as i"
          (click)="$event.stopPropagation()"
          class="amenity-checkbox"
        >
          <checkbox
            class="checkbox-container"
            [formControl]="control.at(i)"
            [label]="option.label"
          ></checkbox>
        </div>
      </ng-container>
      <ng-template #one>
        <radio-button-group [control]="control" [options]="options">
        </radio-button-group>
      </ng-template>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AmenityMenuComponent implements OnInit {
  @Input()
  options: AmenityOption[];

  @Input()
  multiple = false;

  @Input()
  control: any;

  @ViewChild('menu') menu: ElementRef;

  menuOpen = false;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(this.elementRef.nativeElement, 'menu');
  }

  onClick() {
    this.menuOpen = !this.menuOpen;
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.menuOpen = false;
    }
  }
}
