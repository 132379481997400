import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { DialogService } from './core/services/dialog.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StorageKey } from './shared/enums/local-storage-key.enum';
import { addMinutes, isAfter } from 'date-fns';
import { StorageService } from './core/services/storage.service';
import { Url } from './url';

@Component({
  selector: 'app-root',
  template: `
    <app-promo-banner></app-promo-banner>
    <ng-container *ngIf="showMenu$ | async">
      <app-navbar></app-navbar>
    </ng-container>
    <app-notification-provider
      *ngIf="!wizardNotificationEnabled"
      class="notification-main"
      [class.notification-main-top]="(openDialogsCount$ | async) > 0"
    ></app-notification-provider>
    <router-outlet></router-outlet>
    <app-navigation-menu-mobile></app-navigation-menu-mobile>
    <app-block-ui-provider></app-block-ui-provider>
    <app-chat-window-provider></app-chat-window-provider>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  wizardNotificationEnabled = false;

  readonly openDialogsCount$ = this.dialogService.openDialogsCount$;
  showMenu$: Observable<boolean>;

  constructor(
    private readonly router: Router,
    private readonly dialogService: DialogService,
    private readonly storageService: StorageService,
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.wizardNotificationEnabled = event.url.includes('property-wizard');
      }
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationError) {
        if (event.error?.name.startsWith('ChunkLoadError')) {
          if (this.isTimeToRefresh()) {
            const nextRefreshIn = addMinutes(new Date(), 5);
            this.storageService.setItem(
              StorageKey.NEXT_REFRESH,
              String(nextRefreshIn),
            );
            window.location.href = event.url;
          }
        }
      }
    });

    this.showMenu$ = this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => {
        if (/^\/([?#].*)?$/.test(event.url)) {
          // Homepage
          return false;
        }
        if (event.url.startsWith(Url.PROPERTY_WIZARD)) {
          return false;
        }
        if (event.url.startsWith(Url.VERIFICATION_REDIRECT)) {
          return false;
        }
        return true;
      }),
    );
  }

  private isTimeToRefresh() {
    const lastRefreshed = this.storageService.getItem(StorageKey.NEXT_REFRESH);
    if (!lastRefreshed) {
      return true;
    }
    return isAfter(new Date(), new Date(lastRefreshed));
  }
}
