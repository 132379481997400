import { Pipe, PipeTransform } from '@angular/core';
import { differenceInDays } from 'date-fns';
import { DateInterval } from '../../libs/component-lib/utils/calendar.utils';

@Pipe({
  name: 'differenceInDays',
})
export class DifferenceInDaysPipe implements PipeTransform {
  transform(value: DateInterval, ...args: unknown[]): unknown {
    return differenceInDays(value.end, value.start);
  }
}
