import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TypographyModule } from '@components/typography/typography.module';
import { HintComponent } from '@components/input-password/hint.component';
import { InputPasswordComponent } from '@components/input-password/input-password.component';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LinkModule } from '@components/link/link.module';

@NgModule({
  declarations: [HintComponent, InputPasswordComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    TypographyModule,
    LinkModule,
  ],
  exports: [InputPasswordComponent],
})
export class InputPasswordModule {}
