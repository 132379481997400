import { NgModule } from '@angular/core';
import { PhoneNumberInputComponent } from './components/phone-number-input/phone-number-input.component';
import { IconModule } from '@components/icon/icon.module';
import { TypographyModule } from '@components/typography/typography.module';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [PhoneNumberInputComponent],
  imports: [CommonModule, ReactiveFormsModule, IconModule, TypographyModule],
  exports: [PhoneNumberInputComponent],
})
export class PhoneInputObfuscatedLegacyModule {}
