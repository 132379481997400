<div class="calendar">
  <div class="month-title">
    <button button variant="inline" color="secondary" icon="chevron_left" (click)="onPrevMonth()"></button>
    <h2 typography variant="em2" class="month-name">{{view.month | date:'MMMM yyyy'}}</h2>
    <button button variant="inline" color="secondary" icon="chevron_right" (click)="onNextMonth()"></button>
  </div>
  <div class="month-header">
    <div class="month-header-column" *ngFor="let day of dayNamesSingle">
      {{day}}
    </div>
  </div>
  <div class="month" #monthRef>

    <div class="event-overlay">
      <span
        class="event"
        *ngFor="let event of view.events"
        [style.left]="event.x + 'px'"
        [style.top]="event.y + 'px'"
        [style.width]="event.width + 'px'"
        [style.height]="event.height + 'px'"
        [class.event-start]="event.isStart"
        [class.event-end]="event.isEnd"
        [ngClass]="'color-' + event.color"
      ></span>
    </div>
    <div class="week" *ngFor="let week of view.weeks">
      <div
        (click)="onDayClick(day)"
        class="day"
        *ngFor="let day of week.days"
        [class.current-month]="day.isCurrentMonth"
        [class.today]="day.isToday"
        [ngClass]="'color-' + day.color"
      >
        <span class="day-number">{{day.number}}</span>
      </div>
    </div>
  </div>
</div>
