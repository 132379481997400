import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { ExpiredTokenError, InvalidTokenError } from '../../utils/errors';

export const SKIP_AUTHORIZATION_HEADER = 'X-Skip-Interceptor';

/**
 * Adding a header "Authorization" with Bearer access token as a value to requests.
 * TODO JJ check auth token is added to our API calls, not external services (except Auth0)
 */
@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const response = this.authService.getAccessToken().pipe(
      switchMap(accessToken => {
        const skipAuthorization = req.headers.has(SKIP_AUTHORIZATION_HEADER);
        if (skipAuthorization) {
          const headers = req.headers.delete(SKIP_AUTHORIZATION_HEADER);
          req = req.clone({
            headers: headers,
          });
        } else if (accessToken) {
          req = req.clone({
            setHeaders: { Authorization: `Bearer ${accessToken}` },
          });
        }
        return next.handle(req);
      }),
    );
    return response.pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        const errorMessage = errorResponse?.error?.message;
        if (errorMessage) {
          if (errorMessage === 'Invalid Token') {
            // Logout user with invalid token
            this.authService.logout();
            return throwError(() => new InvalidTokenError('Invalid Token'));
          }
          if (errorMessage === 'Expired Token') {
            // Logout user with expired token
            this.authService.logout();
            return throwError(() => new ExpiredTokenError('Expired Token'));
          }
        }
        return throwError(() => errorResponse);
      }),
    );
  }
}
