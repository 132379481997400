<a
  *ngIf="menuLink.link"
  typography
  variant="body1"
  color="ink"
  class="menu-item d-block"
  [class.active]="menuLink.active"
  [routerLink]="menuLink.link"
  (click)="onLinkClick()"
>
  <div *ngIf="menuLink.template !== MenuTemplate.MY_ACCOUNT; else myAccountTemplate">
    <div class="d-flex align-items-center">
      <icon *ngIf="menuLink.icon" [variant]="menuLink.icon" color="inherit" class="mr-12"></icon>
      <span>{{menuLink.title}}</span>
    </div>
  </div>
</a>

<a
  *ngIf="menuLink.href"
  typography
  variant="body1"
  color="ink"
  class="menu-item d-block"
  [class.active]="menuLink.active"
  [href]="menuLink.href"
  (click)="onLinkClick()"
>
  <div class="d-flex align-items-center">
    <icon *ngIf="menuLink.icon" [variant]="menuLink.icon" color="inherit" class="mr-12"></icon>
    <span>{{menuLink.title}}</span>
  </div>
</a>

<button
  *ngIf="menuLink.onClick"
  typography
  variant="body1"
  color="ink"
  class="menu-item d-block button-reset cursor-pointer w-100"
  data-test="sign-out"
  [class.active]="menuLink.active"
  (click)="menuLink.onClick()"
>
  <!-- TODO: Warning - no icon here -->
  <span>{{menuLink.title}}</span>
</button>

<ng-template #myAccountTemplate>
  <div class="d-flex align-items-center menu-item-account">
    <icon *ngIf="menuLink.icon" [variant]="menuLink.icon" color="inherit" class="mr-12"></icon>
    <div class="column">
      <div>{{menuLink.title}}</div>
      <app-navigation-menu-account-balance></app-navigation-menu-account-balance>
    </div>
  </div>
</ng-template>
