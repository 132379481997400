import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  PropertyTax,
  PropertyTaxJurisdiction,
  TaxType,
} from '../../shared/models/pricing';
import { Observable } from 'rxjs';
import { PropertyType } from '../../shared/models/property';
import { first, map, shareReplay } from 'rxjs/operators';
import { isNil } from 'lodash-es';

/** Pricing API endpoint base path. */
const PRICING_API_BASE = '/pricing/api/v1';

/**
 * Property pricing (taxes) service communicating with Taxes API endpoints.
 */
@Injectable({
  providedIn: 'root',
})
export class PropertyTaxService {
  private taxTypes$: Observable<TaxType[]>;

  constructor(private readonly http: HttpClient) {
    this.initialization();
  }

  private initialization() {
    this.taxTypes$ = this.http
      .get<PropertyType[]>(`${PRICING_API_BASE}/tax-types`)
      .pipe(shareReplay());
  }

  getTaxTypeLabel(
    key: string,
    defaultValue = 'Unknown type',
  ): Observable<string> {
    // Wait until types are initialized
    return this.taxTypes$.pipe(
      first(value => !isNil(value)),
      map(value => {
        return value.find(type => type.key === key)?.label || defaultValue;
      }),
    );
  }

  getTaxTypes(): Observable<TaxType[]> {
    return this.taxTypes$;
  }

  getPropertyTaxJurisdictions(
    propertyId: string,
  ): Observable<PropertyTaxJurisdiction[]> {
    return this.http.get<PropertyTaxJurisdiction[]>(
      `${PRICING_API_BASE}/property/${propertyId}/tax/jurisdiction`,
      {},
    );
  }

  updateTaxJurisdiction(
    propertyId: string,
    templateId: string,
    taxJurisdiction: Partial<PropertyTaxJurisdiction>,
  ): Observable<PropertyTaxJurisdiction[]> {
    return this.http.put<PropertyTaxJurisdiction[]>(
      `${PRICING_API_BASE}/property/${propertyId}/tax/jurisdiction/${templateId}`,
      taxJurisdiction,
    );
  }

  getPropertyTaxes(propertyId: string): Observable<PropertyTax[]> {
    return this.http.get<PropertyTax[]>(
      `${PRICING_API_BASE}/property/${propertyId}/tax`,
      {},
    );
  }

  addPropertyTax(
    propertyId: string,
    taxRule: PropertyTax,
  ): Observable<PropertyTax> {
    return this.http.post<PropertyTax>(
      `${PRICING_API_BASE}/property/${propertyId}/tax`,
      taxRule,
    );
  }

  updatePropertyTax(
    propertyId: string,
    taxRuleId: string,
    tax: Partial<PropertyTax>,
  ) {
    return this.http.put<PropertyTax>(
      `${PRICING_API_BASE}/property/${propertyId}/tax/${taxRuleId}`,
      tax,
    );
  }

  updateSpecificPropertyTax(
    propertyId: string,
    taxRuleId: string,
    tax: Partial<PropertyTax>,
    id: string,
  ) {
    return this.http.put<PropertyTax>(
      `${PRICING_API_BASE}/property/${propertyId}/tax/${taxRuleId}/${id}`,
      tax,
    );
  }

  removePropertyTax(propertyId: string, taxRuleId: string) {
    return this.http.delete<void>(
      `${PRICING_API_BASE}/property/${propertyId}/tax/${taxRuleId}`,
    );
  }

  removeSpecificPropertyTax(propertyId: string, taxRuleId: string, id: string) {
    return this.http.delete<void>(
      `${PRICING_API_BASE}/property/${propertyId}/tax/${taxRuleId}/${id}`,
    );
  }
}
