<div class="home-partners">
  <app-home-section-header
    title="Our Partners"
    subtitle="Meet Our Partners"
    align="center"
  ></app-home-section-header>

  <div class="partners-list">
    <button *ngFor="let partner of partners" (click)="openDialogInfo(partner)">
      <img loading="lazy" [src]="'./assets/img/' + partner.img" [alt]="partner.name">
    </button>
  </div>
</div>
