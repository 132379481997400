import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  TypographyColor,
  TypographyVariant,
} from '@components/typography/typography.component';

@Component({
  selector: 'radio-button',
  template: `
    <label class="radio-button-label" [class.radio-button-disabled]="disabled">
      <span
        *ngIf="!labelTmpl; else labelTmpl"
        typography
        [variant]="labelVariant"
        [color]="labelColor"
      >
        {{ label }}
      </span>
      <input
        #radioButtonInput
        class="radio-button-input"
        type="radio"
        [name]="name"
        [value]="value"
        (change)="onChange(value)"
        [checked]="checked"
        [disabled]="disabled"
      />
      <span class="radio-button-mark"></span>
    </label>
  `,
})
export class RadioButtonComponent implements OnInit {
  @ViewChild('radioButtonInput') radioButtonInput: ElementRef;

  @Input() labelVariant: TypographyVariant;
  @Input() labelColor: TypographyColor;
  @Input() labelTmpl?: TemplateRef<any>;
  @Input() value: string;
  @Input() label: string;
  @Input() checked: boolean;
  @Input() disabled: boolean;
  @Input() name?: string;
  @Output() changeValue = new EventEmitter();

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(this.elementRef.nativeElement, 'radio-button');
  }

  onChange(value) {
    this.changeValue.emit(value);
  }

  click() {
    this.radioButtonInput.nativeElement.click();
  }
}
