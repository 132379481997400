import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IndividualLengthOfStay } from '../../shared/models/property';
import {
  PropertyPricing,
  PropertyPricingUpdateDto,
} from '../../shared/models/property-pricing.model';
import {
  BookingAveragePriceResponse,
  PropertyPublicPricing,
} from '../../shared/models/booking';

const PRICING_API_BASE = '/pricing/api/v1';

@Injectable({
  providedIn: 'root',
})
export class PropertyPricingService {
  constructor(private readonly http: HttpClient) {}

  getPropertyIndividualLengthsOfStay(
    id: string,
  ): Observable<IndividualLengthOfStay[]> {
    return this.http.get<IndividualLengthOfStay[]>(
      `${PRICING_API_BASE}/individual-length-of-stay/${id}`,
    );
  }

  updatePropertyPricing(
    propertyId: string,
    updatePricing: PropertyPricingUpdateDto,
  ): Observable<PropertyPricing> {
    return this.http.put<PropertyPricing>(
      `${PRICING_API_BASE}/property/${propertyId}/pricing`,
      updatePricing,
    );
  }

  getPropertyPricing(propertyId: string): Observable<PropertyPricing> {
    return this.http.get<PropertyPricing>(
      `${PRICING_API_BASE}/property/${propertyId}/pricing`,
    );
  }

  /**
   * Fetches avarage price per night.
   * If input param is provided, price is calculated only by provided values
   * Otherwise, price is calculated based on propertyId
   * @param propertyId id of property to search by
   * @param input ( pricePerNight, pricePerNightWeekend, taxRatePercent )
   * @returns Observable<BookingAveragePriceResponse>
   */
  getPropertyAverageNightlyPrice(
    propertyId: string,
    input?: PropertyPricing,
  ): Observable<BookingAveragePriceResponse> {
    return this.http.post<BookingAveragePriceResponse>(
      `${PRICING_API_BASE}/property/${propertyId}/price/average`,
      input,
    );
  }

  /**
   * Get minimal price per night and discounts for a property by property ID.
   * @param propertyId id of property to search by
   * @returns Observable<PropertyPublicPricing>
   */
  getPropertyPublicPricing(
    propertyId: string,
  ): Observable<PropertyPublicPricing> {
    return this.http.get<PropertyPublicPricing>(
      `${PRICING_API_BASE}/property/${propertyId}/pricing/public`,
    );
  }
}
