import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { AbstractComponent } from '../../../../../core/components/abstract/abstract.component';
import {
  Conversation,
  ConversationParticipantType,
} from '../../../models/conversation.model';
import { Booking } from '../../../../../shared/models/booking';
import { UserService } from '../../../../../core/services/user.service';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { User } from '../../../../../shared/models/user.model';
import { Property } from 'src/app/shared/models/property';
import { ChatStore } from '../../../services/chat.store';
import { ActivatedRoute, Router } from '@angular/router';
import { ParamMapService } from 'src/app/core/services/param-map.service';

enum ContextType {
  INQUIRY,
  BOOKING,
}

interface OpenedContext {
  property?: Property;
  booking?: Booking;
  type: ContextType;
}

@Component({
  selector: 'app-chat-context',
  templateUrl: 'chat-context.component.html',
  styleUrls: ['chat-context.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatContextComponent
  extends AbstractComponent
  implements OnDestroy, OnChanges
{
  @Input()
  conversation: Conversation;

  readonly ContextType = ContextType;

  private recipientSubject: BehaviorSubject<User>;
  recipient$: Observable<User>;
  user$: Observable<User>;
  conversationIdContextLoaded$: Observable<string>;
  openedContext$: Observable<OpenedContext>;
  properties$: Observable<Property[]>;
  bookings$: Observable<Booking[]>;

  currentConversationId$: Observable<string>;
  currentConversationIdSubject: BehaviorSubject<string>;

  readonly ConversationParticipantType = ConversationParticipantType;

  constructor(
    private readonly userService: UserService,
    private readonly chatStore: ChatStore,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly paramMapService: ParamMapService,
  ) {
    super();
    this.recipientSubject = new BehaviorSubject<User>(null);
    this.recipient$ = this.recipientSubject.asObservable();
    this.user$ = this.userService.getCurrentUser();
    this.conversationIdContextLoaded$ =
      this.chatStore.conversationIdContextLoaded$;
    this.properties$ = this.chatStore.properties$;
    this.bookings$ = this.chatStore.bookings$;
    this.currentConversationIdSubject = new BehaviorSubject<string>(null);
    this.currentConversationId$ =
      this.currentConversationIdSubject.asObservable();

    this.openedContext$ = combineLatest([
      this.paramMapService.paramMap,
      this.conversationIdContextLoaded$,
      this.properties$,
      this.bookings$,
    ]).pipe(
      map(([paramMap, conversationIdContextLoaded, properties, bookings]) => {
        const conversationId = paramMap.get('conversationId');
        this.currentConversationIdSubject.next(conversationId);
        if (conversationIdContextLoaded !== conversationId) {
          return null;
        }

        const openedBookingId = paramMap.get('bookingId');
        const openedPropertyId = paramMap.get('propertyId');

        if (openedBookingId) {
          const booking = bookings.find(v => v.id === openedBookingId);
          return {
            booking,
            type: ContextType.BOOKING,
          };
        } else if (openedPropertyId) {
          return {
            property: properties.find(v => v.id === openedPropertyId),
            type: ContextType.INQUIRY,
          };
        }
        return null;
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.conversation) {
      this.user$.pipe(first()).subscribe(user => {
        const recipient = this.conversation.participants.find(participant => {
          if (this.conversation.participants.length === 1) {
            return participant.userId;
          }
          return participant.userId !== user.id;
        });
        this.userService
          .getUser(recipient.userId)
          .pipe(first())
          .subscribe(userData => {
            this.recipientSubject.next(userData);
          });
      });
    }
  }

  navigateTo(subpath: string, id: string) {
    this.router.navigate(['.', this.conversation.id, subpath, id], {
      relativeTo: this.route.parent,
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.recipientSubject.complete();
  }
}
