import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[inputTrim]',
})
export class TrimDirective {
  constructor(private control: NgControl) {}

  @HostListener('focusout', ['$event.target.value'])
  onBlur(value: string): void {
    const trimmedValue = value.trim();
    this.control.control?.setValue(trimmedValue, { emitEvent: false });
  }
}
