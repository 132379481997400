import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';
import { Component, Input } from '@angular/core';
import { Property } from 'src/app/shared/models/property';

@Component({
  selector: 'app-property-house-rules',
  template: `<p typography variant="h5" color="ink">House rules</p>
    <div class="mt-8" *ngIf="property.houseRules; else emptyHouseRules">
      <multi-paragraph-text
        [contentText]="property.houseRules"
        contentLinesLimit="10"
      ></multi-paragraph-text>
    </div>
    <ng-template #emptyHouseRules>
      <div typography variant="body1" class="mt-8">
        House rules for this property have not been set.
      </div>
    </ng-template>`,
})
export class PropertyHouseRulesComponent extends AbstractComponent {
  @Input()
  property: Property;
}
