import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe used for abbreviating US states.
 * Source - https://www.ssa.gov/international/coc-docs/states.html
 * @author - David Fencl
 */
@Pipe({ name: 'abbreviateState' })
export class StateToAbbreviationPipe implements PipeTransform {
  transform(state: string): string {
    if (!state) {
      // State not recognized - return original text
      return state;
    }
    const lowerCaseState = state.toLowerCase();
    // TODO - We probably should create enum for states in future.
    //  Change this once it happens.
    switch (lowerCaseState) {
      case 'alabama': {
        return 'AL';
      }
      case 'alaska': {
        return 'AK';
      }
      case 'american samoa': {
        return 'AS';
      }
      case 'arizona': {
        return 'AZ';
      }
      case 'arkansas': {
        return 'AR';
      }
      case 'california': {
        return 'CA';
      }
      case 'colorado': {
        return 'CO';
      }
      case 'connecticut': {
        return 'CT';
      }
      case 'delaware': {
        return 'DE';
      }
      case 'district of columbia': {
        return 'DC';
      }
      case 'florida': {
        return 'FL';
      }
      case 'georgia': {
        return 'GA';
      }
      case 'guam': {
        return 'GU';
      }
      case 'hawaii': {
        return 'HI';
      }
      case 'idaho': {
        return 'ID';
      }
      case 'illinois': {
        return 'IL';
      }
      case 'indiana': {
        return 'IN';
      }
      case 'iowa': {
        return 'IA';
      }
      case 'kansas': {
        return 'KS';
      }
      case 'kentucky': {
        return 'KY';
      }
      case 'louisiana': {
        return 'LA';
      }
      case 'maine': {
        return 'ME';
      }
      case 'maryland': {
        return 'MD';
      }
      case 'massachusetts': {
        return 'MA';
      }
      case 'michigan': {
        return 'MI';
      }
      case 'minnesota': {
        return 'MN';
      }
      case 'mississippi': {
        return 'MS';
      }
      case 'missouri': {
        return 'MO';
      }
      case 'montana': {
        return 'MT';
      }
      case 'nebraska': {
        return 'NE';
      }
      case 'nevada': {
        return 'NV';
      }
      case 'new hampshire': {
        return 'NH';
      }
      case 'new jersey': {
        return 'NJ';
      }
      case 'new mexico': {
        return 'NM';
      }
      case 'new york': {
        return 'NY';
      }
      case 'north carolina': {
        return 'NC';
      }
      case 'northern mariana islands': {
        return 'MP';
      }
      case 'ohio': {
        return 'OH';
      }
      case 'oklahoma': {
        return 'OK';
      }
      case 'oregon': {
        return 'OR';
      }
      case 'pennsylvania': {
        return 'PA';
      }
      case 'puerto rico': {
        return 'PR';
      }
      case 'rhode island': {
        return 'RI';
      }
      case 'south carolina': {
        return 'SC';
      }
      case 'south dakota': {
        return 'SD';
      }
      case 'tennessee': {
        return 'TN';
      }
      case 'texas': {
        return 'TX';
      }
      case 'utah': {
        return 'UT';
      }
      case 'vermont': {
        return 'VT';
      }
      case 'virginia': {
        return 'VA';
      }
      case 'virgin islands': {
        return 'VI';
      }
      case 'washington': {
        return 'WA';
      }
      case 'west virginia': {
        return 'WV';
      }
      case 'wisconsin': {
        return 'WI';
      }
      case 'wyoming': {
        return 'WY';
      }

      // State not recognized - return original text
      default: {
        return state;
      }
    }
  }
}
