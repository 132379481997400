import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypographyModule } from '@components/typography/typography.module';
import { ButtonModule } from '@components/button/button.module';
import { MultiParagraphTextComponent } from './multi-paragraph-text.component';

@NgModule({
  declarations: [MultiParagraphTextComponent],
  imports: [CommonModule, TypographyModule, ButtonModule],
  exports: [MultiParagraphTextComponent],
})
export class MultiParagraphModule {}
