import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Host,
  Input,
  Optional,
  Output,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ParentComponent } from '../../component-lib/components/parent.component';

/**
 * Input component for single number. Used primarily as verification code input field.
 * @author Jakub Jílek, Jan Helbich
 */
@Component({
  selector: 'single-number',
  template: `
    <input
      #inputField
      [formControl]="formControl"
      type="text"
      (keydown)="onKeyDown($event)"
      (input)="onInput($event)"
      class="single-number"
      [autocomplete]="autocomplete"
      inputmode="numeric"
    />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SingleNumberComponent),
      multi: true,
    },
  ],
})
export class SingleNumberComponent extends ParentComponent {
  @Input() maxLength = 1;
  @Input() autocomplete?: string;
  @Input() focusPrev?: SingleNumberComponent;
  @Input() focusNext?: SingleNumberComponent;
  @Output() multipleInput = new EventEmitter<string>();
  @ViewChild('inputField') inputField: ElementRef;

  constructor(
    @Host() @SkipSelf() @Optional() parentControlContainer: ControlContainer,
  ) {
    super(parentControlContainer);
  }

  onKeyDown(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    switch (event.key) {
      case 'ArrowLeft':
        this.focusPrevInput();
        break;
      case 'ArrowRight':
        this.focusNextInput();
        break;
      case 'Backspace':
        target.value = '';
        this.focusPrevInput();
        break;
      case 'Delete':
        target.value = '';
        this.focusNextInput();
        break;
    }
  }

  onInput(event: Event) {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    const inputData = (event as InputEvent).data;
    // In input longer than 2 characters, fire multipleInput event
    if (value.length > 2) {
      target.value = '';
      this.multipleInput.emit(value);
      return;
    }
    // Check if input value contains only single digit
    if (/^[\d]$/.test(value)) {
      this.focusNextInput();
      return;
    }
    // If user try to enter new digit, allow this
    if (/^[\d]$/.test(inputData)) {
      target.value = inputData;
      this.focusNextInput();
      return;
    }
    // Reset value if user input non-numeric character
    target.value = '';
    return;
  }

  private focusPrevInput() {
    if (this.focusPrev) {
      this.focusPrev.focus();
    }
  }

  private focusNextInput() {
    if (this.focusNext) {
      this.focusNext.focus();
    }
  }

  focus() {
    const element = this.inputField.nativeElement;
    element.focus();
  }
}
