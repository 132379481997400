import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { IconSize } from '@components/icon/icon.component';

@Component({
  selector: 'stars',
  template: `
    <div class="stars-icons">
      <icon
        variant="star_f"
        [color]="star ? 'yellow' : 'ink'"
        [class.grey-star]="!star"
        [size]="size"
        *ngFor="let star of stars"
      ></icon>
    </div>
    <span
      typography
      variant="body2"
      class="stars-text"
      *ngIf="showPercentage"
      >{{ getPercentageText() }}</span
    >
  `,
})
export class StarsComponent implements OnInit {
  @Input()
  value = 0;

  @Input()
  showPercentage = false;

  @Input()
  size: IconSize = 'inherit';

  numberOfStars = 5;

  stars!: boolean[];

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.stars = Array.from(
      Array(this.numberOfStars),
      (_, i) => i / this.numberOfStars < this.value,
    );
    this.renderer.addClass(this.elementRef.nativeElement, `stars`);
  }

  getPercentageText() {
    return `${Math.round(this.limitPercentage(this.value) * 100)}%`;
  }

  limitPercentage(value: number) {
    return Math.min(1, Math.max(0, value));
  }
}
