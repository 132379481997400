import differenceInCalendarDays from 'date-fns/fp/differenceInCalendarDays';
import { parse } from 'date-fns';
import { formatDate } from '@angular/common';
import { isNil } from 'lodash-es';

/** Default machine date format. */
export const DATE_FORMAT = 'yyyy-MM-dd';
/** Default machine date time format. */
export const DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.sssZ";

/** Backup date for parsing dates using date-fns. */
const backupDate = new Date();

const UTC_DATE_TIME_REGEX =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/;
const UTC_DATE_REGEX = /^(\d{4})-(\d{2})-(\d{2})$/;

/**
 * Parses date from string using {@link DATE_FORMAT}.
 * @param dateString value to be parsed
 */
export const parseDate = (dateString: string): Date | undefined => {
  try {
    return parse(dateString, DATE_FORMAT, backupDate);
  } catch (e) {
    console.warn(`Cannot parse '${dateString}' as date.`);
    return undefined;
  }
};
/**
 * Parses date from string using {@link DATE_TIME_FORMAT}.
 * @param dateString value to be parsed
 */
export const parseDateTime = (dateString: string): Date | undefined => {
  try {
    return parse(dateString, DATE_TIME_FORMAT, backupDate);
  } catch (e) {
    console.warn(`Cannot parse '${dateString}' as date.`);
    return undefined;
  }
};

export const dateToString = (date: Date): string => {
  return formatDate(date, DATE_FORMAT, 'en-US');
};

export const dateTimeToString = (date: Date): string => {
  return formatDate(date, DATE_TIME_FORMAT, 'en-US');
};

/**
 * Return number of each day of week for given period of time
 */
export function countDaysOfWeek(startDate: Date, endDate: Date): number[] {
  const numOfDays = differenceInCalendarDays(startDate, endDate);
  const fullWeeks = Math.floor(numOfDays / 7);

  const daysOfWeek: number[] = Array.from({ length: 7 }).map(() => fullWeeks);
  const remainingDays = numOfDays % 7;

  const day = new Date(startDate);
  for (let i = 0; i < remainingDays; i++) {
    daysOfWeek[day.getDay()]++;
    day.setDate(day.getDate() + 1);
  }

  return daysOfWeek;
}

export function isToday(date: Date): boolean {
  const today = new Date();
  return (
    today.getFullYear() === date.getFullYear() &&
    today.getMonth() === date.getMonth() &&
    today.getDate() === date.getDate()
  );
}

export function isTomorrow(date: Date): boolean {
  const today = new Date();
  return (
    today.getFullYear() === date.getFullYear() &&
    today.getMonth() === date.getMonth() &&
    today.getDate() + 1 === date.getDate()
  );
}

/**
 * Compare two dates for equality
 * @param date1 Date for comparison
 * @param date2 Date for comparison
 * @returns true, if both dates are Nil or if they are equal,
 *  false otherwise
 */
export function isDateEqual(date1: Date, date2: Date): boolean {
  if ((isNil(date1) && !isNil(date2)) || (!isNil(date1) && isNil(date2))) {
    return false;
  }
  return (isNil(date1) && isNil(date2)) || !(date1 < date2 || date1 > date2);
}

/**
 * Get number of days of specific month
 */
export function numberOfDaysInMonth(year, month) {
  return new Date(year, month + 1, 0).getDate();
}

export function monthDifference(dateFrom, dateTo) {
  return (
    dateTo.getMonth() -
    dateFrom.getMonth() +
    12 * (dateTo.getFullYear() - dateFrom.getFullYear())
  );
}

export function convertDatesInObject(object: any): any {
  if (!object || !(object instanceof Object)) {
    return object;
  }
  if (object instanceof Array) {
    for (const item of object) {
      convertDatesInObject(item);
    }
  }
  for (const key of Object.keys(object)) {
    const value = object[key];

    if (value instanceof Array) {
      for (const item of value) {
        convertDatesInObject(item);
      }
    }
    if (value instanceof Object) {
      convertDatesInObject(value);
    }
    if (typeof value === 'string') {
      if (UTC_DATE_TIME_REGEX.test(value)) {
        object[key] = new Date(value);
      }
      if (UTC_DATE_REGEX.test(value)) {
        // Convert date to current timezone date
        object[key] = parse(value, DATE_FORMAT, new Date());
      }
    }
  }
  return object;
}
