import { Injectable } from '@angular/core';
import { env as environment } from '../../dynamic-environment';
import { from, Observable } from 'rxjs';
import {
  IpApiResponse,
  LocationDataType,
  MapAddress,
} from '../../shared/models/map-address';
import { map } from 'rxjs/operators';
import mapboxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
/**
 * Service to send queries to MapBox
 * Documentation:
 * https://docs.mapbox.com/help/how-mapbox-works/geocoding/
 * https://docs.mapbox.com/api/search/geocoding/
 * @author Radek Chocholouš
 */
export class GeocodingService {
  geocodingClient: any;

  constructor(private readonly http: HttpClient) {
    this.geocodingClient = mapboxGeocoding({
      accessToken: environment.mapbox.accessToken,
    });
  }

  /**
   * Method search places by a string value
   * @param queryText Value to be searched
   * @param dataTypes Array of allowed return location types
   * @param limit max number of results. Default 5, maximum 10
   * @param countries array of ISO 3166-1 alpha-2 country codes
   */
  forwardGeocode(
    queryText: string,
    dataTypes: LocationDataType[],
    limit?: number,
    countries?: string[],
  ): Observable<MapAddress[]> {
    // replace all semicolons so Mapbox doesn't go into batch mode
    // see Github issue https://github.com/alexreisner/geocoder/issues/1299
    queryText = queryText.replace(/;/g, ' ');
    return from(
      this.geocodingClient
        .forwardGeocode({
          query: queryText,
          mode: 'mapbox.places',
          types: dataTypes,
          limit,
          countries,
        })
        .send(),
    ).pipe(map((response: any) => response.body.features));
  }

  /**
   * Get geolocation data by users ip address
   * @returns json containing various informations,
   *  like country, city, long and lat
   */
  getDataByClientIp(): Observable<IpApiResponse> {
    return this.http.get<IpApiResponse>(
      'https://pro.ip-api.com/json/?key=' + environment.ipApi.key,
    );
  }
}
