<ng-container *ngIf="!modal; else modalRef">
  <div class="d-none d-md-block">
    <ng-container *ngTemplateOutlet="formRef"></ng-container>
  </div>
  <div class="d-md-none">
    <button
      class="landing-search-mobile"
      (click)="onWhereAreYouGoing()"
    >
      <icon variant="search" color="primary"></icon>
      <span typography variant="body2" color="ink" class="ml-12">Where are you going?</span>
      <icon variant="chevron_right" color="ink" class="ml-auto"></icon>
    </button>
  </div>
</ng-container>
<ng-template #modalRef>
  <div class="landing-search-modal-wrapper" [class.open]="open">
    <div class="landing-search-modal-backdrop" (click)="onClose()"></div>
    <div class="landing-search-modal">
      <ng-container *ngTemplateOutlet="formRef"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #formRef>
  <form
    [formGroup]="form"
    class="landing-search"
    [class.landing-search-large]="!modal"
    (ngSubmit)="onFindVacay()"
  >
    <input-location
      class="search-destination"
      label="Destination"
      data-test="destination"
      formControlName="destination"
      [locationTypes]="locationTypes"
    ></input-location>
    <input-calendar
      class="search-datepicker"
      formControlName="interval"
      data-test="range-datepicker"
      labelStart="Check in"
      labelEnd="Check out"
      [min]="today"
    ></input-calendar>
    <input-guests
      formControlName="guests"
      label="Guests"
      class="search-guests"
    ></input-guests>
    <button
      button
      type="button"
      variant="outlined"
      color="primary"
      class="search-cancel"
      (click)="onClose()"
    >
      Cancel
    </button>
    <button
      button
      type="submit"
      variant="contained"
      color="primary"
      class="search-submit"
      data-test="find-your-vacay"
    >
      <span class="d-none d-md-inline">Find Your Vacay</span>
      <span class="d-md-none">Find</span>
    </button>
  </form>
</ng-template>
