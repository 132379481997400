import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbstractComponent } from '../../../../../core/components/abstract/abstract.component';
import { ChatService } from '../../../services/chat.service';
import { NotificationService } from '../../../../../core/services/notification.service';
import { DialogService } from '../../../../../core/services/dialog.service';
import { ChatUtils } from '../../../utils/chat.utils';
import { UserService } from '../../../../../core/services/user.service';
import { Conversation } from '../../../models/conversation.model';
import { MessageType } from '../../../models/message.model';
import { InquiryInfo } from '../../../models/text-message.model';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from '@components/confirmation-dialog/confirmation-dialog.component';
import { Url } from '../../../../../url';
import { getUserFriendlyError } from '../../../../../utils/errors';
import { ChatStore } from '../../../services/chat.store';
import { Router } from '@angular/router';
import { BlockUiService } from '../../../../../core/services/block-ui.service';

@Component({
  selector: 'app-chat-input',
  templateUrl: './chat-input.component.html',
  styleUrls: ['./chat-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatInputComponent
  extends AbstractComponent
  implements OnInit, OnDestroy
{
  /** Show send icon in view */
  sendIcon$: Observable<boolean>;
  formGroup: UntypedFormGroup;
  sendingMessage = false;

  inquiryInfoSubscriber: Subscription;
  inquiryInfo: InquiryInfo = null;

  @Input()
  conversation: Conversation;

  moreMenuOpen = false;

  isArchived = ChatUtils.isArchived;

  user$ = this.userService.getCurrentUser();

  constructor(
    private readonly chatService: ChatService,
    private readonly userService: UserService,
    private readonly dialogService: DialogService,
    private readonly notificationService: NotificationService,
    private readonly chatStore: ChatStore,
    private readonly router: Router,
    private readonly blockUiService: BlockUiService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({
      text: new UntypedFormControl(undefined),
    });
    this.inquiryInfoSubscriber = this.chatService.inquiryInfo$.subscribe(
      val => {
        this.inquiryInfo = val;
      },
    );

    this.sendIcon$ = this.formGroup.controls.text.valueChanges.pipe(
      map(text => this.isValidText(text)),
    );
  }

  ngOnDestroy(): void {
    this.inquiryInfoSubscriber.unsubscribe();
  }

  onTextKeyDown(event: any) {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      const modificationKey = event.ctrlKey || event.shiftKey || event.altKey;
      if (!modificationKey) {
        event.preventDefault();
        this.onTextSubmit();
      }
      if (event.ctrlKey || event.altKey) {
        // If browser supports `setRangeText` function, allow user to create new line using CTRL or ALT key
        const caret = event.target.selectionStart;
        if (typeof event.target.setRangeText === 'function') {
          event.target.setRangeText('\n', caret, caret, 'end');
        }
      }
    }
  }

  onTextSubmit() {
    if (!this.conversation.id || this.sendingMessage) {
      return;
    }
    const text = this.formGroup.controls.text.value as string;
    // Do not send empty text
    if (!this.isValidText(text)) {
      return;
    }
    this.sendingMessage = true;

    this.chatService
      .sendTextMessage(
        this.conversation.id,
        this.inquiryInfo ? MessageType.INQUIRY : MessageType.TEXT,
        text,
        this.inquiryInfo,
      )
      .subscribe({
        next: () => {
          this.sendingMessage = false;
          this.formGroup.controls.text.reset();
        },
        error: err => {
          this.sendingMessage = false;
          this.notificationService.showNotification({
            text: 'Failed to send message.',
            color: 'error',
            duration: 4000,
          });
        },
      });
  }

  isValidText(text: string): boolean {
    if (!text) {
      return false;
    }
    if (!text.trim()) {
      return false;
    }
    return true;
  }

  toggleMoreMenu() {
    this.moreMenuOpen = !this.moreMenuOpen;
  }

  openArchiveConversationDialog(conversation: Conversation) {
    this.toggleMoreMenu();
    this.dialogService
      .open(ConfirmationDialogComponent, {
        data: {
          dialogWidth: 'sm',
          dialogHeader: 'Archiving chat',
          confirmationText: 'Do you really want to archive this chat?',
          confirmButton: {
            text: 'Yes, archive',
          },
          cancelButton: {
            text: 'Cancel',
          },
        } as ConfirmationDialogData,
      })
      .afterClosed()
      .pipe(this.untilDestroyed())
      .subscribe(result => {
        if (result) {
          this.archiveConversation(conversation);
        }
      });
  }

  private archiveConversation(conversation: Conversation) {
    this.chatStore
      .archiveConversation(conversation)
      .pipe(this.untilDestroyed(), this.blockUiService.blockPipe())
      .subscribe({
        next: () => {
          this.notificationService.showNotification({
            text: 'Chat was successfully archived',
            color: 'success',
          });
          this.router.navigate([Url.CHAT]);
        },
        error: err => {
          this.notificationService.showNotification({
            text: getUserFriendlyError(err),
            color: 'error',
          });
        },
      });
  }
}
