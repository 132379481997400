import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogWrapperComponent } from '@components/dialog/dialog-wrapper.component';
import { DialogHeaderComponent } from '@components/dialog/dialog-header.component';
import { DialogContentComponent } from '@components/dialog/dialog-content.component';
import { DialogActionsComponent } from '@components/dialog/dialog-actions.component';
import { ButtonModule } from '@components/button/button.module';
import { TypographyModule } from '@components/typography/typography.module';

@NgModule({
  declarations: [
    DialogWrapperComponent,
    DialogHeaderComponent,
    DialogContentComponent,
    DialogActionsComponent,
  ],
  imports: [CommonModule, ButtonModule, TypographyModule],
  exports: [
    DialogWrapperComponent,
    DialogHeaderComponent,
    DialogContentComponent,
    DialogActionsComponent,
  ],
})
export class DialogModule {}
