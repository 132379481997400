import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInputComponent } from '@components/abstract/abstract-input.component';
import { COUNTRIES } from '../../../../utils/country.util';
import { Option } from '@components/input/dropdown/dropdown.component';

@Component({
  selector: 'input-country',
  styleUrls: ['../dropdown.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputCountryComponent,
      multi: true,
    },
  ],
  template: `
    <mat-form-field>
      <mat-label *ngIf="label">{{ label }}{{ required ? '*' : '' }}</mat-label>
      <mat-select
        [formControl]="control"
        [errorStateMatcher]="errorStateMatcher"
      >
        <mat-option
          *ngFor="let option of options"
          [value]="option.value"
          class="not-empty-select"
        >
          <span typography color="inherit">{{ option.name }}</span>
        </mat-option>
      </mat-select>
      <mat-error *ngIf="errorMessage$ | async as errorMessage">
        {{ errorMessage }}
      </mat-error>
    </mat-form-field>
  `,
})
export class InputCountryComponent
  extends AbstractInputComponent<string | number>
  implements OnInit, OnChanges
{
  options: Option[];
  @Input()
  required: boolean;
  @Input()
  countries?: string[];

  constructor(
    injector: Injector,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.renderer.addClass(this.elementRef.nativeElement, 'dropdown2');
    this.applyOptions(this.countries);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.countries) {
      this.applyOptions(this.countries);
    }
  }

  private applyOptions(allowedCountries?: string[]) {
    const countries = COUNTRIES.filter(
      country => !allowedCountries || allowedCountries.includes(country.code),
    );
    const options = countries
      .map(c => ({
        value: c.code,
        name: c.name,
      }))
      .sort((a, b) => {
        return a.name.localeCompare(b.name, 'us');
      });
    const usIndex = options.findIndex(option => option.value === 'US');
    if (usIndex > 0) {
      options.unshift(options.splice(usIndex, 1)[0]);
    }
    this.options = options;
  }
}
