import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { BreakpointService } from '../../../../core/services/breakpoint.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { PartnerInfo } from '../../partners.model';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../../../core/core.module';
import { DialogModule } from '@components/dialog/dialog.module';
import { IconModule } from '@components/icon/icon.module';
import { TypographyModule } from '@components/typography/typography.module';
import { LinkModule } from '@components/link/link.module';

@Component({
  standalone: true,
  selector: 'app-home-partners-dialog',
  templateUrl: './partners-dialog.component.html',
  styleUrls: ['./partners-dialog.component.scss'],
  imports: [
    CommonModule,
    CoreModule,
    DialogModule,
    IconModule,
    TypographyModule,
    LinkModule,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class PartnersDialogComponent {
  largeScreen$: Observable<boolean>;
  partner: string;

  partnerInfo: PartnerInfo;

  constructor(
    private readonly breakpointService: BreakpointService,
    private readonly dialogRef: MatDialogRef<PartnersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: { partner: PartnerInfo },
  ) {
    this.largeScreen$ = this.breakpointService.largeScreen$;
    this.partnerInfo = data.partner;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
