import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { AbstractComponent } from '../../../core/components/abstract/abstract.component';
import {
  TypographyColor,
  TypographyVariant,
} from '@components/typography/typography.component';
@Component({
  selector: 'multi-paragraph-text',
  template: `
    <ng-container *ngFor="let paragraph of getParagraphs(contentText ?? '')">
      <p
        typography
        [variant]="typographyVariant"
        [color]="typographyColor"
        style="word-wrap: break-word;"
        *ngIf="paragraph; else emptyLine"
      >
        {{ paragraph }}
      </p>
      <ng-template #emptyLine>
        <p typography [variant]="typographyVariant" [color]="typographyColor">
          &nbsp;
        </p>
      </ng-template>
    </ng-container>
    <button
      *ngIf="isContentOverLimit(contentText ?? '')"
      button
      variant="inline"
      color="primary"
      class="mt-4 p-0"
      data-test="read-more"
      (click)="toggleReadMore()"
    >
      {{ readMore ? 'Read less' : 'Read more' }}
    </button>
  `,
})
export class MultiParagraphTextComponent
  extends AbstractComponent
  implements OnInit
{
  @Input() contentText: string;
  @Input() contentTextLengthLimit: number; // limiting number of displayed characters
  @Input() contentLinesLimit: number; // limiting number of displayed lines

  @Input() typographyVariant: TypographyVariant = 'body1';
  @Input() typographyColor: TypographyColor = 'inherit';
  readMore = false;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {
    super();
  }

  ngOnInit(): void {
    this.renderer.addClass(this.elementRef.nativeElement, `multi-line-text`);
  }

  getParagraphs(text: string): string[] {
    let paragraphs = text.split('\n');
    if (!this.readMore) {
      if (
        this.contentTextLengthLimit &&
        text.length > this.contentTextLengthLimit
      ) {
        text = text.slice(0, this.contentTextLengthLimit);
        text = text.slice(0, text.lastIndexOf(' ')) + ' ...';
        paragraphs = text.split('\n');
      }
      if (this.contentLinesLimit) {
        paragraphs = paragraphs.slice(0, this.contentLinesLimit);
      }
    }
    paragraphs = paragraphs.map(paragraph => paragraph.trim());
    return paragraphs;
  }

  isContentOverLimit(text: string): boolean {
    if (
      this.contentTextLengthLimit &&
      text.length > this.contentTextLengthLimit
    ) {
      return true;
    }
    if (
      this.contentLinesLimit &&
      text.split('\n').length > this.contentLinesLimit
    ) {
      return true;
    }
    return false;
  }

  toggleReadMore(): void {
    this.readMore = !this.readMore;
  }
}
