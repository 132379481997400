<div
  class="header"
  [ngClass]="minimalStandardGroup ? 'header-minimal-standard-group' : 'header-other-group'"
  [class.accordion-opened]="open"
  (click)="toggleOpen()">
    <span class="title">
      <icon
        *ngIf="labelTooltip"
        color="inherit"
        variant="info"
        [tp]="labelTooltip"
        tooltipPosition="after"
      ></icon>
      <span>{{ label }}</span>
    </span>
    <ng-container *ngIf="!selectAll; else checkboxAll">
      <div class="icon">
        <icon
          [class.icon-opened]="open"
          color="inherit"
          variant="chevron_down"
        ></icon>
      </div>
    </ng-container>
  <ng-template #checkboxAll>
    <checkbox [formControl]="selectAllControl"></checkbox>
  </ng-template>
</div>
<div *ngIf="open" [ngClass]="minimalStandardGroup ? 'minimal-standard-group-container' : 'other-group-container'">
  <div class="content">
    <div
      *ngFor="let option of visibleOptions; index as i"
      class="option-layout">
      <div>
            <span class="sub-option-menu"
            >{{ option.label }}
              <ng-container *ngIf="option.options?.length > 0">
                <amenity-menu
                  [options]="option.options"
                  [multiple]="option.type === 'multiple'"
                  [control]="option.optionControl"
                >
                </amenity-menu>
              </ng-container>
            </span>
        <ng-container
          *ngIf="option.optionControl && option.optionControl.value"
        >
          <div
            *ngFor="
                  let suboption of getSelectedAmenityLabels(option);
                  let isLast = last
                "
          >
                <span class="sub-option-label">
                  {{ suboption }}
                  <ng-container *ngIf="!isLast">,</ng-container>
                </span>
          </div>
        </ng-container>
      </div>
      <checkbox
        [formControl]="option.control"
        class="mb-20"
      ></checkbox>
    </div>
  </div>
</div>
