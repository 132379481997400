<dialog-wrapper [width]="'sm'" [position]="(largeScreen$ | async) === true ? 'center' : 'bottom'">
  <dialog-content>
    <icon variant="cross" class="mt-16 button" style="float:right;" (click)="closeDialog()"></icon>
    <div class="d-flex flex-column justify-content-center align-items-center mb-32 mb-md-0">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <a [href]="partnerInfo.link" target="_blank" style="width: 70%">
          <img src="./assets/img/{{partnerInfo.img}}" [alt]="partnerInfo.name" class="my-24"
               style="width: 100%">
        </a>
        <p typography variant="body1" class="partners-description" [innerHTML]="partnerInfo.description"></p>
        <a [href]="partnerInfo.link"
           target="_blank"
           *ngIf="partnerInfo.name !== 'OKsystem'"
           link variant="link1"
           class="d-flex align-items-center mt-16">Visit web
          <icon variant="arrow_right" color="primary" size="16" class="ml-12"></icon>
        </a>
      </div>
    </div>
  </dialog-content>
</dialog-wrapper>
