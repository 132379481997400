import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

type LinkVariant = 'inherit' | 'link1' | 'link2' | 'link3' | 'link4';
type LinkColor =
  | 'inherit'
  | 'primary'
  | 'ink'
  | 'ink-light'
  | 'red'
  | 'green'
  | 'blue'
  | 'yellow';

/**
 * @see https://www.figma.com/file/4URZYURZBcwfpYp3HvkSL6/Web-components-VMW?node-id=4904%3A19882
 * @author Libor Staněk
 */
@Component({
  selector: '[link]',
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: '<ng-content></ng-content>',
})
export class LinkComponent implements OnInit, OnChanges {
  @Input()
  variant: LinkVariant = 'inherit';
  @Input()
  color: LinkColor = 'primary';

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    const element = this.elementRef.nativeElement;
    this.renderer.addClass(element, `link2`);
    this.renderer.addClass(element, `link-variant-${this.variant}`);
    this.renderer.addClass(element, `link-color-${this.color}`);
  }

  ngOnChanges(changes: SimpleChanges) {
    const element = this.elementRef.nativeElement;
    if (changes.variant) {
      this.renderer.removeClass(
        element,
        `link-variant-${changes.variant.previousValue}`,
      );
      this.renderer.addClass(
        element,
        `link-variant-${changes.variant.currentValue}`,
      );
    }
    if (changes.color) {
      this.renderer.removeClass(
        element,
        `link-color-${changes.color.previousValue}`,
      );
      this.renderer.addClass(
        element,
        `link-color-${changes.color.currentValue}`,
      );
    }
  }
}
