import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { IconVariant } from '@components/icon/icon.component';

export type BadgeVariant = 'clear' | 'light' | 'dark';
export type BadgeColor = 'blue' | 'yellow' | 'green' | 'red' | 'gray';
export type BadgeSize = 'small' | 'normal';

/**
 * @see https://www.figma.com/file/4URZYURZBcwfpYp3HvkSL6/Web-components-VMW?node-id=4992%3A19438
 */
@Component({
  selector: 'badge',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <icon
      *ngIf="icon"
      [variant]="icon"
      color="inherit"
      size="inherit"
      class="mr-4"
    ></icon>
    <ng-content></ng-content>
  `,
})
export class BadgeComponent implements OnInit, OnChanges {
  @Input() icon: IconVariant = undefined;
  @Input() variant: BadgeVariant = 'clear';
  @Input() color: BadgeColor = 'blue';
  @Input() size: BadgeSize = 'small';

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    const element = this.elementRef.nativeElement;
    this.renderer.addClass(element, `badge2`);
    this.renderer.addClass(element, `badge-variant-${this.variant}`);
    this.renderer.addClass(element, `badge-color-${this.color}`);
    this.renderer.addClass(element, `badge-size-${this.size}`);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const element = this.elementRef.nativeElement;
    if (changes.variant) {
      this.renderer.removeClass(
        element,
        `badge-variant-${changes.variant.previousValue}`,
      );
      this.renderer.addClass(
        element,
        `badge-variant-${changes.variant.currentValue}`,
      );
    }
    if (changes.color) {
      this.renderer.removeClass(
        element,
        `badge-color-${changes.color.previousValue}`,
      );
      this.renderer.addClass(
        element,
        `badge-color-${changes.color.currentValue}`,
      );
    }
    if (changes.size) {
      this.renderer.removeClass(
        element,
        `badge-size-${changes.size.previousValue}`,
      );
      this.renderer.addClass(
        element,
        `badge-size-${changes.size.currentValue}`,
      );
    }
  }
}
