<dialog-wrapper width="sm" [position]="ls ? 'center' : 'bottom'" [class.h-100]="!isDialog"
                xmlns="http://www.w3.org/1999/html">
  <dialog-header (dialogClose)="closeDialog()" [showClose]="isDialog" title="Sign up using email"></dialog-header>
  <dialog-content [class.pt-32]="!isDialog">
    <div notification class="mb-16" *ngIf="error$ | async as error">
      {{error}}
    </div>

    <form ngNoForm (submit)="submit($event)">
      <div class="signup-inputs-container">
        <input-text data-test="signup-email" [formControl]="form.controls.email" [label]="'Email'" class="my-8"></input-text>
        <input-password
          data-test="signup-password"
          [formControl]="form.controls.password"
          showToggleButton="true"
          hintsActive="true"
          class="my-8 d-flex flex-column"
          label="Password">
        </input-password>

        <div class="row my-8">
          <input-text data-test="signup-name" [formControl]="form.controls.firstName" [label]="'First name'" class="col-6"></input-text>
          <input-text data-test="signup-surname" [formControl]="form.controls.lastName" [label]="'Last name'" class="col-6"></input-text>
        </div>
      </div>
      <div class="d-flex mt-16">
        <checkbox [formControl]="form.controls.agreeTermsAndConditions" class="mx-4"></checkbox>
        <span typography variant="body1" color="inherit">
          I have read and agree to
          <a link variant="link1" color="primary"
             (click)="openTermsAndConditions()">
            Terms and Conditions
          </a>
        </span>

      </div>
      <div class="d-flex mt-8 mb-24">
        <checkbox [formControl]="form.controls.agreePrivacyPolicy" class="mx-4"></checkbox>
        <span typography variant="body1" color="inherit">
          I have read and agree to
          <a link variant="link1" color="primary"
             (click)="openPrivacyPolicy()">
            Privacy Policy
          </a>
        </span>
      </div>
      <button button type="submit" color="primary" data-test="button-signup" class="w-100">Continue</button>
    </form>

    <div class="divider mt-16 mb-16"></div>

    <div class="mb-8 mb-md-0">
      <div>
        <app-signup-link [isDialog]="isDialog">
          Use your social accounts
        </app-signup-link>
      </div>
      <div>
        <span typography variant="body1">
          Already have an account?
        </span>
        <app-signin-link [isDialog]="isDialog">Sign in</app-signin-link>
      </div>
    </div>
  </dialog-content>
</dialog-wrapper>
