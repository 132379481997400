import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from 'src/app/modules/account/models/virtual-account';
import { Money } from 'src/app/shared/models/money.model';

@Pipe({
  name: 'formatMoney',
})
export class FormatMoneyPipe implements PipeTransform {
  transform(value: Money, ...args: any[]): unknown {
    switch (value.currency) {
      case Currency.USD:
        return `$${value.amount ?? 0}`;
      default:
        return value.amount;
    }
  }
}
