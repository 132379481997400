import { NgModule } from '@angular/core';
import { SingleNumberComponent } from './single-number.component';
import { PhoneNumberVerificationComponent } from './phone-number-verification.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [SingleNumberComponent, PhoneNumberVerificationComponent],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [PhoneNumberVerificationComponent],
})
export class PhoneNumberVerificationModule {}
