import {
  AbstractControl,
  FormArray,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { convertMapAddressToPropertyAddress } from '../shared/models/map-address';
import { isNil } from 'lodash-es';
import { isValid, parse } from 'date-fns';

export const EMAIL_REGEX =
  /^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+$/;
export const EMAIL_INVALID_MESSAGE = 'Please enter a valid email';
export const EMAIL_ALREADY_USED =
  'Email is already used. Please sign in or use different email address';

export const MIN_LENGTH_INVALID_MESSAGE =
  'Input must be at least {0} characters long.';
export const MAX_LENGTH_INVALID_MESSAGE =
  'Input must be at most {0} characters long.';

export const PASSWORD_UPPERCASE_TEST = /[A-Z]/;
export const PASSWORD_UPPERCASE_MESSAGE =
  'Password must contain at least one uppercase letter.';
export const PASSWORD_LOWERCASE_TEST = /[a-z]/;
export const PASSWORD_LOWERCASE_MESSAGE =
  'Password must contain at least one lowercase letter.';
export const PASSWORD_SPECIAL_CHAR_TEST = /[\W_]/;
export const PASSWORD_SPECIAL_CHAR_MESSAGE =
  'Password must contain at least one special character letter.';
export const PASSWORD_DIGIT_TEST = /[0-9]/;
export const PASSWORD_DIGIT_MESSAGE =
  'Password must contain at least one digit.';
export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MIN_LENGTH_MESSAGE = `The password must be at least ${PASSWORD_MIN_LENGTH} characters long.`;
export const PASSWORD_CHANGE_SAME = 'New password must be different.';
export const PASSWORD_REPEAT_NOT_MATCHING = 'New passwords do not match.';
export const PHONE_NUMBER_FORMAT =
  'Invalid phone number. Please provide a valid phone number in international format.';
export const URL_REGEX =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
export const URL_INVALID_MESSAGE = 'Please enter valid URL.';
export const DATE_INVALID_MESSAGE = 'Invalid Date';
// prettier-ignore
export const ADDRESS_INVALID_MESSAGE =
  'We\'re having trouble finding the address you provided. Please enter it below.';
export const MAX_DECIMAL_MESSAGE = `Input must be at most {0}.`;

/**
 * Validation messages for Angular's default validators.
 */
export const defaultValidators = {
  required: (label: string) => `${label} is required`,
  min: (label: string, errObj: any) =>
    `${label} must be at least ${errObj.min}.`,
  max: (label: string, errObj: any) =>
    `${label} must be at most ${errObj.max}.`,
  pattern: (label: string, errObj: any) =>
    `${label} must match pattern ${errObj.requiredPattern}.`,
  minlength: (label: string, errObj: any) =>
    `${label} must be at least ${errObj.requiredLength} characters long.`,
  maxlength: (label: string, errObj: any) =>
    `${label} must be at most ${errObj.requiredLength} characters long.`,
  email: (label: string) => EMAIL_INVALID_MESSAGE,
  url: (label: string) => URL_INVALID_MESSAGE,
};

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = EMAIL_REGEX.test(control.value);
    return valid
      ? null
      : {
          email: {
            value: control.value,
            message: EMAIL_INVALID_MESSAGE,
          },
        };
  };
}

export function minLengthValidator(min: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (typeof value === 'string' && value.length < min) {
      return {
        minLength: {
          value: control.value,
          message: MIN_LENGTH_INVALID_MESSAGE.replace('{0}', '' + min),
        },
      };
    }
    return null;
  };
}

export function maxLengthValidator(max: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (typeof value === 'string' && value.length > max) {
      return {
        minLength: {
          value: control.value,
          message: MAX_LENGTH_INVALID_MESSAGE.replace('{0}', '' + max),
        },
      };
    }
    return null;
  };
}

export function addressValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control.value === 'object' && control.value) {
      const address = convertMapAddressToPropertyAddress(control.value);
      if (
        isNil(address.city) ||
        isNil(address.postalCode) ||
        isNil(address.state)
      ) {
        return {
          invalidAddress: {
            value: control.value,
            message: ADDRESS_INVALID_MESSAGE,
          },
        };
      }
    }
    return null;
  };
}

export function regexValidator(
  pattern: RegExp,
  errorMessage: string,
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = pattern.test(control.value);
    return valid
      ? null
      : {
          regex: {
            value: control.value,
            message: errorMessage,
          },
        };
  };
}

export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value as string;
    if (!value) {
      return null;
    }
    let errors = null;

    if (!value.match(PASSWORD_UPPERCASE_TEST)) {
      errors = {
        ...errors,
        upperCaseError: {
          value: control.value,
          message: PASSWORD_UPPERCASE_MESSAGE,
        },
      };
    }
    if (!value.match(PASSWORD_LOWERCASE_TEST)) {
      errors = {
        ...errors,
        lowerCaseError: {
          value: control.value,
          message: PASSWORD_LOWERCASE_MESSAGE,
        },
      };
    }
    if (!value.match(PASSWORD_DIGIT_TEST)) {
      errors = {
        ...errors,
        numberError: {
          value: control.value,
          message: PASSWORD_DIGIT_MESSAGE,
        },
      };
    }
    if (!value.match(PASSWORD_SPECIAL_CHAR_TEST)) {
      errors = {
        ...errors,
        specialCharacterError: {
          value: control.value,
          message: PASSWORD_SPECIAL_CHAR_MESSAGE,
        },
      };
    }
    if (value.length < PASSWORD_MIN_LENGTH) {
      errors = {
        ...errors,
        lengthError: {
          value: control.value,
          message: PASSWORD_MIN_LENGTH_MESSAGE,
        },
      };
    }
    return errors;
  };
}

export function birthDateValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const dateStr = control.value;
    const parsedDate = parse(dateStr, 'P', new Date());
    if (!isValid(parsedDate)) {
      return {
        invalidDate: {
          value: control.value,
          message: 'Please check if the date corresponds to MM/DD/YYYY',
        },
      };
    }
    return null;
  };
}

/**
 * Form validator checking provided other value is equal to control's own value.
 * @param otherValueFn other value accessor fn
 */
export function passwordRepeatValidator(
  otherValueFn: () => string,
): ValidatorFn {
  return (control: AbstractControl) => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const otherValue = otherValueFn();
    return value === otherValue
      ? null
      : {
          passwordRepeat: {
            value,
            message: PASSWORD_REPEAT_NOT_MATCHING,
          },
        };
  };
}

/**
 * Form validator checking provided value is not same to control's own value
 * @param otherValueFn other value accessor fn
 */
export function passwordNotSameValidator(
  otherValueFn: () => string,
): ValidatorFn {
  return (control: AbstractControl) => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const otherValue = otherValueFn();
    return value !== otherValue
      ? null
      : {
          passwordRepeat: {
            value,
            message: PASSWORD_CHANGE_SAME,
          },
        };
  };
}

/**
 * Form validator checking if phone number contains only digits, +, - or space
 */
export function validPhoneNumberFormatValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const controlValue = control.value as string;
    const regExp = /[^0-9+ -]/g;
    if (regExp.test(controlValue)) {
      return {
        invalidCharacters: {
          value: control.value,
          message: PHONE_NUMBER_FORMAT,
        },
      };
    } else {
      return null;
    }
  };
}

export function urlValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = URL_REGEX.test(control.value);
    return valid
      ? null
      : {
          url: {
            value: control.value,
            message: URL_INVALID_MESSAGE,
          },
        };
  };
}

export function dateValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid =
      control.value instanceof Date && !Number.isNaN(control.value.getTime());
    return valid
      ? null
      : {
          email: {
            value: control.value,
            message: DATE_INVALID_MESSAGE,
          },
        };
  };
}
export function maxNumberDecimalValidator(max: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (!value) {
      return null;
    }

    const integerPart = Math.trunc(+value) ?? 0;
    const fractionalPart = parseInt(value.split('.')[1] ?? 0, 10);
    if (integerPart > max || fractionalPart > max) {
      return {
        maxDecimal: {
          value: control.value,
          message: MAX_DECIMAL_MESSAGE.replace('{0}', max + '.' + max),
        },
      };
    }
    return null;
  };
}

export function uniqueBedTypeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formArray = control as FormArray;
    const types = formArray.controls.map(control => control.value.type);
    // Check for duplicate types
    const hasDuplicates = types.some(
      (type, index) => types.indexOf(type) !== index,
    );

    return hasDuplicates ? { duplicateType: true } : null;
  };
}
