import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConversationContextDetail } from 'src/app/modules/chat/models/conversation.model';
import { BookingStatus } from 'src/app/shared/models/booking';

@Component({
  selector: 'app-chat-context-list-item',
  templateUrl: 'chat-context-list-item.component.html',
  styleUrls: ['chat-context-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatContextListItemComponent {
  @Input() conversationId: string;
  @Input() contextDetailInfo: ConversationContextDetail;

  readonly BookingStatus = BookingStatus;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {}

  get property() {
    return this.contextDetailInfo.property;
  }

  get bookingInterval() {
    return this.contextDetailInfo.booking?.interval;
  }

  get bookingStatus() {
    return this.contextDetailInfo.booking?.status;
  }

  redirect(): void {
    const subpath = this.contextDetailInfo.booking ? 'booking' : 'property';
    const id = this.contextDetailInfo.booking
      ? this.contextDetailInfo.booking.id
      : this.contextDetailInfo.property?.id;

    this.router.navigate(['.', this.conversationId, subpath, id], {
      relativeTo: this.route.parent,
    });
  }
}
