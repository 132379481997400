import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SingleNumberComponent } from './single-number.component';
import { Observable } from 'rxjs';
import { AbstractComponent } from '../../../core/components/abstract/abstract.component';

@Component({
  selector: 'app-phone-number-verification',
  templateUrl: './phone-number-verification.component.html',
})
export class PhoneNumberVerificationComponent
  extends AbstractComponent
  implements OnInit, AfterViewInit
{
  /** Phone number verification code form */
  phoneVerificationForm: UntypedFormGroup;

  @Input() resetAndUpdateValidity: Observable<void>;
  @Output() submitPhoneVerification = new EventEmitter<string>();

  @ViewChild('n1', { static: false })
  n1: SingleNumberComponent;

  constructor() {
    super();
    this.phoneVerificationForm = new UntypedFormGroup({
      number1: new UntypedFormControl(),
      number2: new UntypedFormControl(),
      number3: new UntypedFormControl(),
      number4: new UntypedFormControl(),
      number5: new UntypedFormControl(),
      number6: new UntypedFormControl(),
    });
  }

  ngOnInit() {
    this.phoneVerificationForm.controls.number6.valueChanges.subscribe(_ => {
      if (
        this.phoneVerificationForm.controls.number1.value &&
        this.phoneVerificationForm.controls.number2.value &&
        this.phoneVerificationForm.controls.number3.value &&
        this.phoneVerificationForm.controls.number4.value &&
        this.phoneVerificationForm.controls.number5.value &&
        this.phoneVerificationForm.controls.number6.value
      ) {
        let agg = '';
        for (let i = 1; i <= 6; i++) {
          agg += this.phoneVerificationForm.controls['number' + i].value;
        }
        this.submitPhoneVerification.emit(agg);
      }
    });

    this.resetAndUpdateValidity
      .pipe(this.untilDestroyed())
      .subscribe(() => this.reset());
  }

  ngAfterViewInit() {
    this.n1.focus();
  }

  onMultipleInput(value: string) {
    if (!value) {
      return;
    }
    const valueNoDigits = value.replace(/\D/g, '');
    if (valueNoDigits.length !== 6) {
      return;
    }
    for (let i = 0; i < 6; i++) {
      this.phoneVerificationForm.controls[`number${i + 1}`].reset(
        value.charAt(i),
      );
    }
  }

  reset() {
    this.phoneVerificationForm.reset();
    this.phoneVerificationForm.updateValueAndValidity();
  }
}
