export enum AmenityType {
  SINGLE = 'single',
  SELECT = 'select',
  MULTIPLE = 'multiple',
}

export type AmenityValue = boolean | number | number[];

export interface PropertyAmenity {
  id: number;
  value: AmenityValue;
}

export class AmenityOption {
  id: number;
  label: string;
}

export class Amenity {
  id: number;
  label: string;
  group: string;
  defaultOption?: number;
  options: AmenityOption[];
  type: AmenityType;
}
