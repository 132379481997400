<dialog-wrapper width="sm" [position]="ls ? 'center' : 'bottom'">
  <dialog-header (dialogClose)="closeDialog()" title="Please verify your identity"></dialog-header>
  <dialog-content>
    <div typography variant="body1">
      <p>Verifying your identity is our simplified way to ensure hosts that you are a legitimate guest. After completing the simple process, you will not require future verification before booking a Vacay.</p>
      <p>While not all VacayMyWay properties require Guest ID Verification, most of our hosts utilize this security feature, and bookings are not allowed until you are verified. If you choose to skip this step now, you will be prompted to finish verification before completing a booking.</p>
    </div>
  </dialog-content>
  <dialog-actions>
    <div class="d-flex justify-content-between" *ngIf="user$ | async as user">
      <button button variant="outlined" data-test="button-skip" (click)="closeDialog()">Skip</button>
      <button button variant="contained" class="ml-16" data-test="button-verify" (click)="verifyUser(user)">Verify</button>
    </div>
  </dialog-actions>
</dialog-wrapper>
