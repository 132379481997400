import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Blog, Subscription } from '../../shared/models/landing-page';
import { API_USER_BASE } from '../../url';

const USER_COMMON_API_BASE = API_USER_BASE + '/landing-page';

@Injectable({
  providedIn: 'root',
})
export class LandingPageService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Fetches most recent blogs, that are published on wordpress site.
   * @returns Observable<Blog[]> array containing blog posts
   */
  getBlogs(count: number): Observable<Blog[]> {
    return this.http.get<Blog[]>(`${USER_COMMON_API_BASE}/blogs`, {
      params: { count },
    });
  }

  /**
   * Sends request for email subscription to backend service, which
   * acts as a relay. Subscription is handled on wordpress site.
   * @param email to subscribe to newsletter
   * @returns Observable<boolean> true, if email has been subsribed
   *  false otherwise
   */
  subcribeToNewsletter(email: string): Observable<boolean> {
    const subscription: Subscription = { email };
    return this.http.post<boolean>(
      `${USER_COMMON_API_BASE}/subscription`,
      subscription,
    );
  }
}
