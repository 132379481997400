import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@components/icon/icon.module';
import { BadgeComponent } from '@components/badge/badge.component';

@NgModule({
  declarations: [BadgeComponent],
  exports: [BadgeComponent],
  imports: [CommonModule, IconModule],
})
export class BadgeModule {}
