import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { ICON_REGISTRY } from '@components/icon/icon-registry';
import { SafeHtml } from '@angular/platform-browser';

export type IconVariant = keyof typeof ICON_REGISTRY;
export type IconSize = 'inherit' | 'normal' | '20' | '16' | '50';
export type IconColor =
  | 'inherit'
  | 'ink'
  | 'red'
  | 'yellow'
  | 'neutral'
  | 'white'
  | 'green'
  | 'blue'
  | 'primary';

/**
 * @see https://www.figma.com/file/EzdNTVM6tvmDBlbQhk3t22/Foundations-VMW?node-id=13%3A2181
 * @author Libor Staněk
 */
@Component({
  selector: 'icon',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: ``,
})
export class IconComponent implements OnInit, OnChanges {
  @Input()
  variant: IconVariant;
  @Input()
  size: IconSize = 'normal';
  @Input()
  color: IconColor = 'ink';

  svg!: SafeHtml;

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    const element = this.elementRef.nativeElement;
    this.renderer.addClass(element, `icon2`);
    this.refreshVariant();
    this.renderer.addClass(element, `icon-size-${this.size}`);
    this.renderer.addClass(element, `icon-color-${this.color}`);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const element = this.elementRef.nativeElement;
    if (changes.variant) {
      this.refreshVariant();
    }
    if (changes.size) {
      this.renderer.removeClass(
        element,
        `icon-size-${changes.size.currentValue}`,
      );
      this.renderer.addClass(element, `icon-size-${changes.size.currentValue}`);
    }
    if (changes.color) {
      this.renderer.removeClass(
        element,
        `icon-color-${changes.color.previousValue}`,
      );
      this.renderer.addClass(
        element,
        `icon-color-${changes.color.currentValue}`,
      );
    }
  }

  refreshVariant() {
    const element = this.elementRef.nativeElement;
    element.innerHTML = ICON_REGISTRY[this.variant];
  }
}
