<ng-template #descriptionTemplateDefault let-data="data">
  <p typography variant="h5" color="primary" class="pick-label-picks">
    <span class="mr-8">{{ data.label }}</span>
    <icon variant="arrow_right"></icon>
  </p>
</ng-template>

<div class="picks-area">
  <div class="pick-scroll" #scrollRef>
    <div class="pick-list">
      <a
        *ngFor="let pick of picks"
        class="pick"
        [routerLink]="pick.url"
        [queryParams]="pick.params"
        rel="noopener"
        target="_blank"
      >
        <div class="pick-image-container">
          <img
            loading="lazy"
            [src]="pick.image"
            class="pick-image"
            [alt]="pick.label"
          />
          <div class="pick-image-badge">
            <icon variant="star_f" color="yellow"></icon>
            <span typography variant="h7" class="ml-4">Top pick</span>
          </div>
        </div>
        <ng-container 
        [ngTemplateOutlet]="descriptionTemplate ? descriptionTemplate : descriptionTemplateDefault" 
        [ngTemplateOutletContext]="{ data: pick }"
        ></ng-container>
      </a>
      <div class="pick-list-end"></div>
    </div>
    <button
      *ngIf="showLeftArrow"
      class="pick-arrow pick-arrow-left"
      (click)="onLeftArrow()"
      aria-label="Scroll top picks left"
    >
      <icon variant="chevron_left" color="inherit"></icon>
    </button>
    <button
      *ngIf="showRightArrow"
      class="pick-arrow pick-arrow-right"
      (click)="onRightArrow()"
      aria-label="Scroll top picks right"
    >
      <icon variant="chevron_right" color="inherit"></icon>
    </button>
  </div>
</div>
