<div class="home-background">
  <app-home-property-search
    [modal]="true"
    *ngIf="stickyMenu"
    #searchMenu
  ></app-home-property-search>
  <!-- STICKY MENU-->
  <div class="d-none d-md-block">
    <div
      class="sticky-menu"
      [class.sticky-menu-visible]="stickyMenu"
      [class.sticky-menu-animation]="stickyMenuAnimation"
    >
      <a href="/" class="logo-link" data-test="vacay-logo">
        <img
          loading="lazy"
          src="assets/img/vacay-125.svg"
          alt="VacayMyWay logo"
          class="logo"
        />
      </a>
      <button
        class="open-menu-search mx-16"
        data-test="find-your-vacay-scrollbar"
        (click)="onFindYourVacay()"
      >
        <span typography variant="h6">Find Your Vacay</span>
        <icon variant="chevron_right"></icon>
      </button>
      <div class="actions" *ngIf="isPlatformBrowser()">
        <app-menu-user-panel
          #userMenuSticky
          variant="small"
        ></app-menu-user-panel>
      </div>
    </div>
  </div>
  <div class="d-md-none">
    <div
      class="sticky-menu-mobile"
      [class.sticky-menu-mobile-visible]="stickyMenu"
      [class.sticky-menu-mobile-animation]="stickyMenuAnimation"
    >
      <button
        button
        variant="inline"
        icon="hamburger"
        iconColor="ink"
        (click)="openMobileMenu()"
        class="hamburger"
      ></button>
      <button class="open-menu-search" (click)="onWhereAreYouGoing()">
        <icon variant="search" color="ink"></icon>
        <span typography variant="body2" color="ink" class="ml-12"
         >Where are you going?</span
        >
        <icon variant="chevron_right" color="ink" class="ml-auto"></icon>
      </button>
    </div>
  </div>
  <!-- END OF STICKY MENU-->
  <ng-template #landingMenu>
    <div class="landing-menu">
      <button
        button
        variant="inline"
        icon="hamburger"
        iconColor="ink"
        (click)="openMobileMenu()"
        class="hamburger"
      ></button>
      <a href="/" class="logo-link" data-test="vacay-logo">
        <img
          loading="lazy"
          src="assets/img/vacay-125.svg"
          alt="VacayMyWay logo"
          class="logo"
        />
      </a>
      <span class="hamburger"><!-- dummy element --></span>
      <div class="actions" *ngIf="isPlatformBrowser()">
        <app-menu-user-panel #userMenu></app-menu-user-panel>
      </div>
    </div>
  </ng-template>

  <div>
    <div class="landing">
      <ng-container [ngTemplateOutlet]="landingMenu"></ng-container>
      <div
        class="landing-container"
        id="mainImg"
        [ngStyle]="{
          backgroundImage:
            'url(' +
            environment.content.url +
            'main-home.jpg?version=' +
            buildNumber +
            '), url(' +
            environment.content.url +
            'main-home-low.jpg?version=' +
            buildNumber +
            ')'
        }"
      >
        <h1>
          <img
            loading="lazy"
            src="assets/img/vacay-white.svg"
            alt="VacayMyWay"
            class="heading-logo"
          />
        </h1>
        <h2>Find your way</h2>
        <p>
          Every stay starts with savings. Thousands of getaways to choose from. Let's find yours.
        </p>
        <div class="landing-search-container">
          <app-home-property-search #search></app-home-property-search>
        </div>
      </div>
    </div>
    @if (isPlatformBrowser()&&(picks$ | async)?.['position1']?.categories; as categories) {
      <app-picks-block *ngIf="categories.length > 0" [categories]="categories"></app-picks-block>
    }
    <app-home-nouns></app-home-nouns>
    @if (isPlatformBrowser()&&(picks$ | async)?.['position2']?.categories; as categories) {
      <app-picks-block *ngIf="categories.length > 0" [categories]="categories"></app-picks-block>
    }
    <app-home-get-started></app-home-get-started>
    @if (isPlatformBrowser()&&(picks$ | async)?.['position3']?.categories; as categories) {
      <app-picks-block *ngIf="categories.length > 0" [categories]="categories"></app-picks-block>
    }
    @if (isPlatformBrowser()&&(picks$ | async)?.['position4']?.categories; as categories) {
      <app-picks-block *ngIf="categories.length > 0" [categories]="categories"></app-picks-block>
    }
    <app-home-inspiration></app-home-inspiration>
    @if (isPlatformBrowser()&&(picks$ | async)?.['position5']?.categories; as categories) {
      <app-picks-block *ngIf="categories.length > 0" [categories]="categories"></app-picks-block>
    }
    <app-home-partners></app-home-partners>
    @if (isPlatformBrowser()&&(picks$ | async)?.['position6']?.categories; as categories) {
      <app-picks-block *ngIf="categories.length > 0" [categories]="categories"></app-picks-block>
    }
    <app-footer></app-footer>
    @if(!user) {
        <app-home-offer
        *ifEnabled="'signUpRewardPoints'"
        [open]="offerOpen"
        (closeButton)="onOfferClose()"
        ></app-home-offer>
    }
  </div>
</div>
