import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@components/icon/icon.module';
import { StepperComponent } from '@components/stepper/stepper.component';

@NgModule({
  declarations: [StepperComponent],
  exports: [StepperComponent],
  imports: [CommonModule, IconModule],
})
export class StepperModule {}
