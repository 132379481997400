import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluralize',
})
export class PluralizePipe implements PipeTransform {
  transform(value: string, variableValue: number): string {
    const wordForm = variableValue > 1 ? `${value}s` : value;
    return `${variableValue} ${wordForm}`;
  }
}
