import { Role } from './role';
import { Address } from './address.model';

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email?: EmailInfo;
  phone?: UserPhone;
  description?: string;
  created?: Date;
  roles?: Role[];
  avatarUrl?: string;
  address?: Address;
  verification?: Verification;
  timeZone?: string;

  // only available in sign in / sign up response
  isNewUser: boolean;
}

export interface UserUnreadConversations {
  unreadConversationIds: string[];
}

export interface ClientInfo {
  userId?: string;
  clientId?: string;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  phoneNumber?: string;
  phoneIntentId?: string;
  verification?: Verification;
}

export interface HostInfo {
  userId: string;
  avatarUrl?: string;
  created: Date;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface EmailInfo {
  emailAddress: string;
  verified: boolean;
  // emailHistory: EmailHistory;
}

export interface EmailHistory {
  emailAddress: string;
  validFrom: Date;
  validTo: Date;
}

export interface PhoneHistory {
  phoneNumber: string;
  validFrom: Date;
  validTo: Date;
}

export interface PhoneNumberDetail {
  prefix: string;
  number: string;
  nationalNumber: string;
  country: string;
}

export interface UserPhone {
  phoneNumber: string;
  phoneNumberDetail?: PhoneNumberDetail;
  validFrom?: Date;
  history?: PhoneHistory[];
}

export interface UserPasswordChange {
  oldPassword: string;
  newPassword: string;
}

export interface Verification {
  status: VerificationStatus;
  superHogVerificationUrl?: string;
}

export enum VerificationStatus {
  INCOMPLETE = 'Incomplete',
  CONFIRMED = 'Confirmed',
  FAILED = 'Failed',
}
