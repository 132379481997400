<div class="chat" [class.chat-max-height]="maxHeight">
  <div [class.invisible]="(conversationIdContextLoaded$ | async) === null" class="chat-messages" #messagesRef
    (scroll)="onMessagesScroll()">
    <div class="chat-messages-spacer"></div>
    <ng-container *ngFor="let message of messages$ | async">
      <div class="mt-16">
        <app-chat-message-text *ngIf="message.type === messageType.TEXT" [message]="message"></app-chat-message-text>
        <app-chat-message-inquiry *ngIf="message.type === messageType.INQUIRY" [conversationId]="conversation.id"
          [message]="message" [properties]="properties$ | async"></app-chat-message-inquiry>
        <app-chat-message-booking-authorized *ngIf="message.type === messageType.BOOKING_AUTHORIZED"
          [conversationId]="conversation.id" [message]="message"
          [bookings]="bookings$ | async"></app-chat-message-booking-authorized>
        <app-chat-message-booking-approved *ngIf="message.type === messageType.BOOKING_APPROVED"
          [conversationId]="conversation.id" [message]="message"
          [bookings]="bookings$ | async"></app-chat-message-booking-approved>
        <app-chat-message-booking-canceled *ngIf="message.type === messageType.BOOKING_CANCELED"
          [conversationId]="conversation.id" [message]="message"
          [bookings]="bookings$ | async"></app-chat-message-booking-canceled>
        <app-chat-message-booking-declined *ngIf="message.type === messageType.BOOKING_DECLINED"
          [conversationId]="conversation.id" [message]="message"
          [bookings]="bookings$ | async"></app-chat-message-booking-declined>
        <app-chat-message-booking-refunded *ngIf="message.type === messageType.BOOKING_REFUNDED"
          [conversationId]="conversation.id" [message]="message" [bookings]="bookings$ | async"
          w></app-chat-message-booking-refunded>
      </div>
    </ng-container>
  </div>
  <app-chat-input [conversation]="conversation" *ngIf="!!conversation"></app-chat-input>
</div>
