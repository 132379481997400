import { EnvironmentProviders } from '@angular/core';
import {
  popperVariation,
  provideTippyConfig,
  tooltipVariation,
} from '@ngneat/helipopper';

export default (): EnvironmentProviders => {
  return provideTippyConfig({
    defaultVariation: 'tooltip',
    variations: {
      tooltip: {
        ...tooltipVariation,
        arrow: true,
        interactive: true,
        hideOnClick: true,
      },
      popper: popperVariation,
    },
  });
};
