export interface Country {
  code: string;
  name: string;
  prefix: string;
}

/**
 * Source:
 * https://github.com/OpenBookPrices/country-data
 * https://en.wikipedia.org/wiki/List_of_country_calling_codes
 */
export const COUNTRIES: Country[] = [
  { code: 'AD', name: 'Andorra', prefix: '376' },
  { code: 'AE', name: 'United Arab Emirates', prefix: '971' },
  { code: 'AF', name: 'Afghanistan', prefix: '93' },
  { code: 'AG', name: 'Antigua and Barbuda', prefix: '1 268' },
  { code: 'AI', name: 'Anguilla', prefix: '1 264' },
  { code: 'AL', name: 'Albania', prefix: '355' },
  { code: 'XK', name: 'Kosovo', prefix: '383' },
  { code: 'AM', name: 'Armenia', prefix: '374' },
  { code: 'AO', name: 'Angola', prefix: '244' },
  { code: 'AQ', name: 'Antarctica', prefix: '672' },
  { code: 'AR', name: 'Argentina', prefix: '54' },
  { code: 'AS', name: 'American Samoa', prefix: '1 684' },
  { code: 'AT', name: 'Austria', prefix: '43' },
  { code: 'AU', name: 'Australia', prefix: '61' },
  { code: 'AW', name: 'Aruba', prefix: '297' },
  { code: 'AX', name: 'Åland Islands', prefix: '358' },
  { code: 'AZ', name: 'Azerbaijan', prefix: '994' },
  { code: 'BA', name: 'Bosnia and Herzegovina', prefix: '387' },
  { code: 'BB', name: 'Barbados', prefix: '1 246' },
  { code: 'BD', name: 'Bangladesh', prefix: '880' },
  { code: 'BE', name: 'Belgium', prefix: '32' },
  { code: 'BF', name: 'Burkina Faso', prefix: '226' },
  { code: 'BG', name: 'Bulgaria', prefix: '359' },
  { code: 'BH', name: 'Bahrain', prefix: '973' },
  { code: 'BI', name: 'Burundi', prefix: '257' },
  { code: 'BJ', name: 'Benin', prefix: '229' },
  { code: 'BL', name: 'Saint Barthélemy', prefix: '590' },
  { code: 'BM', name: 'Bermuda', prefix: '1 441' },
  { code: 'BN', name: 'Brunei Darussalam', prefix: '673' },
  { code: 'BO', name: 'Bolivia, Plurinational State of', prefix: '591' },
  { code: 'BQ', name: 'Bonaire, Sint Eustatius and Saba', prefix: '599' },
  { code: 'BR', name: 'Brazil', prefix: '55' },
  { code: 'BS', name: 'Bahamas', prefix: '1 242' },
  { code: 'BT', name: 'Bhutan', prefix: '975' },
  { code: 'BW', name: 'Botswana', prefix: '267' },
  { code: 'BY', name: 'Belarus', prefix: '375' },
  { code: 'BZ', name: 'Belize', prefix: '501' },
  { code: 'CA', name: 'Canada', prefix: '1' },
  { code: 'CC', name: 'Cocos (Keeling) Islands', prefix: '61' },
  { code: 'CD', name: 'Congo, Democratic Republic of the', prefix: '243' },
  { code: 'CF', name: 'Central African Republic', prefix: '236' },
  { code: 'CG', name: 'Congo', prefix: '242' },
  { code: 'CH', name: 'Switzerland', prefix: '41' },
  { code: 'CI', name: `Ivory Coast`, prefix: '225' },
  { code: 'CK', name: 'Cook Islands', prefix: '682' },
  { code: 'CL', name: 'Chile', prefix: '56' },
  { code: 'CM', name: 'Cameroon', prefix: '237' },
  { code: 'CN', name: 'China', prefix: '86' },
  { code: 'CO', name: 'Colombia', prefix: '57' },
  { code: 'CR', name: 'Costa Rica', prefix: '506' },
  { code: 'CU', name: 'Cuba', prefix: '53' },
  { code: 'CV', name: 'Cape Verde', prefix: '238' },
  { code: 'CW', name: 'Curaçao', prefix: '599' },
  { code: 'CX', name: 'Christmas Island', prefix: '61' },
  { code: 'CY', name: 'Cyprus', prefix: '357' },
  { code: 'CZ', name: 'Czechia', prefix: '420' },
  { code: 'DE', name: 'Germany', prefix: '49' },
  { code: 'DJ', name: 'Djibouti', prefix: '253' },
  { code: 'DK', name: 'Denmark', prefix: '45' },
  { code: 'DM', name: 'Dominica', prefix: '1 767' },
  { code: 'DO', name: 'Dominican Republic', prefix: '1' }, // +1 809, +1 829, +1 849)
  { code: 'DZ', name: 'Algeria', prefix: '213' },
  { code: 'EC', name: 'Ecuador', prefix: '593' },
  { code: 'EE', name: 'Estonia', prefix: '372' },
  { code: 'EG', name: 'Egypt', prefix: '20' },
  { code: 'EH', name: 'Western Sahara', prefix: '212' },
  { code: 'ER', name: 'Eritrea', prefix: '291' },
  { code: 'ES', name: 'Spain', prefix: '34' },
  { code: 'ET', name: 'Ethiopia', prefix: '251' },
  { code: 'FI', name: 'Finland', prefix: '358' },
  { code: 'FJ', name: 'Fiji', prefix: '679' },
  { code: 'FK', name: 'Falkland Islands (Malvinas)', prefix: '500' },
  { code: 'FM', name: 'Micronesia, Federated States of', prefix: '691' },
  { code: 'FO', name: 'Faroe Islands', prefix: '298' },
  { code: 'FR', name: 'France', prefix: '33' },
  { code: 'GA', name: 'Gabon', prefix: '241' },
  {
    code: 'GB',
    name: 'United Kingdom of Great Britain and Northern Ireland',
    prefix: '44',
  },
  { code: 'GD', name: 'Grenada', prefix: '1 473' },
  { code: 'GE', name: 'Georgia', prefix: '995' },
  { code: 'GF', name: 'French Guiana', prefix: '594' },
  { code: 'GG', name: 'Guernsey', prefix: '44' },
  { code: 'GH', name: 'Ghana', prefix: '233' },
  { code: 'GI', name: 'Gibraltar', prefix: '350' },
  { code: 'GL', name: 'Greenland', prefix: '299' },
  { code: 'GM', name: 'Gambia', prefix: '220' },
  { code: 'GN', name: 'Guinea', prefix: '224' },
  { code: 'GP', name: 'Guadeloupe', prefix: '590' },
  { code: 'GQ', name: 'Equatorial Guinea', prefix: '240' },
  { code: 'GR', name: 'Greece', prefix: '30' },
  {
    code: 'GS',
    name: 'South Georgia and the South Sandwich Islands',
    prefix: '500',
  },
  { code: 'GT', name: 'Guatemala', prefix: '502' },
  { code: 'GU', name: 'Guam', prefix: '1 671' },
  { code: 'GW', name: 'Guinea-Bissau', prefix: '245' },
  { code: 'GY', name: 'Guyana', prefix: '592' },
  { code: 'HK', name: 'Hong Kong', prefix: '852' },
  { code: 'HN', name: 'Honduras', prefix: '504' },
  { code: 'HR', name: 'Croatia', prefix: '385' },
  { code: 'HT', name: 'Haiti', prefix: '509' },
  { code: 'HU', name: 'Hungary', prefix: '36' },
  { code: 'ID', name: 'Indonesia', prefix: '62' },
  { code: 'IE', name: 'Ireland', prefix: '353' },
  { code: 'IL', name: 'Israel', prefix: '972' },
  { code: 'IM', name: 'Isle of Man', prefix: '44' },
  { code: 'IN', name: 'India', prefix: '91' },
  { code: 'IQ', name: 'Iraq', prefix: '964' },
  { code: 'IR', name: 'Iran, Islamic Republic of', prefix: '98' },
  { code: 'IS', name: 'Iceland', prefix: '354' },
  { code: 'IT', name: 'Italy', prefix: '39' },
  { code: 'JE', name: 'Jersey', prefix: '44' },
  { code: 'JM', name: 'Jamaica', prefix: '1 876' },
  { code: 'JO', name: 'Jordan', prefix: '962' },
  { code: 'JP', name: 'Japan', prefix: '81' },
  { code: 'KE', name: 'Kenya', prefix: '254' },
  { code: 'KG', name: 'Kyrgyzstan', prefix: '996' },
  { code: 'KH', name: 'Cambodia', prefix: '855' },
  { code: 'KI', name: 'Kiribati', prefix: '686' },
  { code: 'KM', name: 'Comoros', prefix: '269' },
  { code: 'KN', name: 'Saint Kitts and Nevis', prefix: '1 869' },
  { code: 'KP', name: `Korea, Democratic People's Republic of`, prefix: '850' },
  { code: 'KR', name: 'Korea, Republic of', prefix: '82' },
  { code: 'KW', name: 'Kuwait', prefix: '965' },
  { code: 'KY', name: 'Cayman Islands', prefix: '1 345' },
  { code: 'KZ', name: 'Kazakhstan', prefix: '7' },
  { code: 'LA', name: `Lao People's Democratic Republic`, prefix: '856' },
  { code: 'LB', name: 'Lebanon', prefix: '961' },
  { code: 'LC', name: 'Saint Lucia', prefix: '1 758' },
  { code: 'LI', name: 'Liechtenstein', prefix: '423' },
  { code: 'LK', name: 'Sri Lanka', prefix: '94' },
  { code: 'LR', name: 'Liberia', prefix: '231' },
  { code: 'LS', name: 'Lesotho', prefix: '266' },
  { code: 'LT', name: 'Lithuania', prefix: '370' },
  { code: 'LU', name: 'Luxembourg', prefix: '352' },
  { code: 'LV', name: 'Latvia', prefix: '371' },
  { code: 'LY', name: 'Libya', prefix: '218' },
  { code: 'MA', name: 'Morocco', prefix: '212' },
  { code: 'MC', name: 'Monaco', prefix: '377' },
  { code: 'MD', name: 'Moldova, Republic of', prefix: '373' },
  { code: 'ME', name: 'Montenegro', prefix: '382' },
  { code: 'MF', name: 'Saint Martin, (French part)', prefix: '590' },
  { code: 'MG', name: 'Madagascar', prefix: '261' },
  { code: 'MH', name: 'Marshall Islands', prefix: '692' },
  { code: 'MK', name: 'North Macedonia', prefix: '389' },
  { code: 'ML', name: 'Mali', prefix: '223' },
  { code: 'MM', name: 'Myanmar', prefix: '95' },
  { code: 'MN', name: 'Mongolia', prefix: '976' },
  { code: 'MO', name: 'Macao', prefix: '853' },
  { code: 'MP', name: 'Northern Mariana Islands', prefix: '1 670' },
  { code: 'MQ', name: 'Martinique', prefix: '596' },
  { code: 'MR', name: 'Mauritania', prefix: '222' },
  { code: 'MS', name: 'Montserrat', prefix: '1 664' },
  { code: 'MT', name: 'Malta', prefix: '356' },
  { code: 'MU', name: 'Mauritius', prefix: '230' },
  { code: 'MV', name: 'Maldives', prefix: '960' },
  { code: 'MW', name: 'Malawi', prefix: '265' },
  { code: 'MX', name: 'Mexico', prefix: '52' },
  { code: 'MY', name: 'Malaysia', prefix: '60' },
  { code: 'MZ', name: 'Mozambique', prefix: '258' },
  { code: 'NA', name: 'Namibia', prefix: '264' },
  { code: 'NC', name: 'New Caledonia', prefix: '687' },
  { code: 'NE', name: 'Niger', prefix: '227' },
  { code: 'NF', name: 'Norfolk Island', prefix: '672' },
  { code: 'NG', name: 'Nigeria', prefix: '234' },
  { code: 'NI', name: 'Nicaragua', prefix: '505' },
  { code: 'NL', name: 'Netherlands', prefix: '31' },
  { code: 'NO', name: 'Norway', prefix: '47' },
  { code: 'NP', name: 'Nepal', prefix: '977' },
  { code: 'NR', name: 'Nauru', prefix: '674' },
  { code: 'NU', name: 'Niue', prefix: '683' },
  { code: 'NZ', name: 'New Zealand', prefix: '64' },
  { code: 'OM', name: 'Oman', prefix: '968' },
  { code: 'PA', name: 'Panama', prefix: '507' },
  { code: 'PE', name: 'Peru', prefix: '51' },
  { code: 'PF', name: 'French Polynesia', prefix: '689' },
  { code: 'PG', name: 'Papua New Guinea', prefix: '675' },
  { code: 'PH', name: 'Philippines', prefix: '63' },
  { code: 'PK', name: 'Pakistan', prefix: '92' },
  { code: 'PL', name: 'Poland', prefix: '48' },
  { code: 'PM', name: 'Saint Pierre and Miquelon', prefix: '508' },
  { code: 'PN', name: 'Pitcairn Islands', prefix: '64' },
  { code: 'PR', name: 'Puerto Rico', prefix: '1 787' },
  { code: 'PS', name: 'Palestine, State of', prefix: '970' },
  { code: 'PT', name: 'Portugal', prefix: '351' },
  { code: 'PW', name: 'Palau', prefix: '680' },
  { code: 'PY', name: 'Paraguay', prefix: '595' },
  { code: 'QA', name: 'Qatar', prefix: '974' },
  { code: 'RE', name: 'Réunion', prefix: '262' },
  { code: 'RO', name: 'Romania', prefix: '40' },
  { code: 'RS', name: 'Serbia', prefix: '381' },
  { code: 'RU', name: 'Russian Federation', prefix: '7' },
  { code: 'RW', name: 'Rwanda', prefix: '250' },
  { code: 'SA', name: 'Saudi Arabia', prefix: '966' },
  { code: 'SB', name: 'Solomon Islands', prefix: '677' },
  { code: 'SC', name: 'Seychelles', prefix: '248' },
  { code: 'SD', name: 'Sudan', prefix: '249' },
  { code: 'SE', name: 'Sweden', prefix: '46' },
  { code: 'SG', name: 'Singapore', prefix: '65' },
  {
    code: 'SH',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    prefix: '290',
  },
  { code: 'SI', name: 'Slovenia', prefix: '386' },
  { code: 'SJ', name: 'Svalbard and Jan Mayen', prefix: '47' },
  { code: 'SK', name: 'Slovakia', prefix: '421' },
  { code: 'SL', name: 'Sierra Leone', prefix: '232' },
  { code: 'SM', name: 'San Marino', prefix: '378' },
  { code: 'SN', name: 'Senegal', prefix: '221' },
  { code: 'SO', name: 'Somalia', prefix: '252' },
  { code: 'SR', name: 'Suriname', prefix: '597' },
  { code: 'SS', name: 'South Sudan', prefix: '211' },
  { code: 'ST', name: 'Sao Tome and Principe', prefix: '239' },
  { code: 'SV', name: 'El Salvador', prefix: '503' },
  { code: 'SX', name: 'Sint Maarten, (Dutch part)', prefix: '1 721' },
  { code: 'SY', name: 'Syrian Arab Republic', prefix: '963' },
  { code: 'SZ', name: 'Eswatini', prefix: '268' },
  { code: 'TC', name: 'Turks and Caicos Islands', prefix: '1 649' },
  { code: 'TD', name: 'Chad', prefix: '235' },
  { code: 'TF', name: 'French Southern Territories', prefix: '262' },
  { code: 'TG', name: 'Togo', prefix: '228' },
  { code: 'TH', name: 'Thailand', prefix: '66' },
  { code: 'TJ', name: 'Tajikistan', prefix: '992' },
  { code: 'TK', name: 'Tokelau', prefix: '690' },
  { code: 'TL', name: 'Timor-Leste', prefix: '670' },
  { code: 'TM', name: 'Turkmenistan', prefix: '993' },
  { code: 'TN', name: 'Tunisia', prefix: '216' },
  { code: 'TO', name: 'Tonga', prefix: '676' },
  { code: 'TR', name: 'Turkey', prefix: '90' },
  { code: 'TT', name: 'Trinidad and Tobago', prefix: '1 868' },
  { code: 'TV', name: 'Tuvalu', prefix: '688' },
  { code: 'TW', name: 'Taiwan, Province of China', prefix: '886' },
  { code: 'TZ', name: 'Tanzania, United Republic of', prefix: '255' },
  { code: 'UA', name: 'Ukraine', prefix: '380' },
  { code: 'UG', name: 'Uganda', prefix: '256' },
  { code: 'UM', name: 'United States Minor Outlying Islands', prefix: '246' },
  { code: 'IO', name: 'British Indian Ocean Territory', prefix: '246' },
  { code: 'US', name: 'United States of America', prefix: '1' },
  { code: 'UY', name: 'Uruguay', prefix: '598' },
  { code: 'UZ', name: 'Uzbekistan', prefix: '998' },
  { code: 'VA', name: 'Vatican City State', prefix: '379' },
  { code: 'VC', name: 'Saint Vincent and the Grenadines', prefix: '1 784' },
  { code: 'VE', name: 'Venezuela, Bolivarian Republic of', prefix: '58' },
  { code: 'VG', name: 'Virgin Islands, British', prefix: '1 284' },
  { code: 'VI', name: 'Virgin Islands, U.S.', prefix: '1 340' },
  { code: 'VN', name: 'Vietnam', prefix: '84' },
  { code: 'VU', name: 'Vanuatu', prefix: '678' },
  { code: 'WF', name: 'Wallis and Futuna', prefix: '681' },
  { code: 'WS', name: 'Samoa', prefix: '685' },
  { code: 'YE', name: 'Yemen', prefix: '967' },
  { code: 'YT', name: 'Mayotte', prefix: '262' },
  { code: 'ZA', name: 'South Africa', prefix: '27' },
  { code: 'ZM', name: 'Zambia', prefix: '260' },
  { code: 'ZW', name: 'Zimbabwe', prefix: '263' },
];

export function getCountryByCode(code: string): Country {
  return COUNTRIES.find(country => country.code === code);
}
