import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputLocationComponent } from './input-location.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TypographyModule } from '@components/typography/typography.module';
import { IconModule } from '@components/icon/icon.module';

@NgModule({
  declarations: [InputLocationComponent],
  exports: [InputLocationComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    TypographyModule,
    IconModule,
  ],
})
export class InputLocationModule {}
