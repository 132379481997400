import {
  CancellationPolicy,
  CancellationRule,
} from '../shared/models/property';
import { BookingCancellationRefund } from '../shared/models/booking';
import { isAfter } from 'date-fns';

export function getDaysBeforeFeeAppliesLabel(p: CancellationPolicy): string {
  const daysBeforeFeeApplies = [];
  for (const policyRule of p.rules) {
    const refund = policyRule.refunds[0];
    if (!refund) {
      continue;
    }
    daysBeforeFeeApplies.push(refund.minHoursBeforeStay / 24);
  }
  return daysBeforeFeeApplies.join('/');
}

export function getMinStayRangeLabel(
  rules: CancellationRule[],
  selectedIdx: number,
): string {
  if (selectedIdx === 0) {
    return `${rules[0].minLengthOfStay}+`;
  }
  if (selectedIdx === rules.length - 1) {
    return `1 - ${rules[selectedIdx - 1].minLengthOfStay - 1}`;
  }
  return `${rules[selectedIdx].minLengthOfStay} - ${
    rules[selectedIdx - 1].minLengthOfStay - 1
  }`;
}

export function chooseCancellationRefundRule(
  refundRules: BookingCancellationRefund[],
): BookingCancellationRefund | undefined {
  if (!refundRules?.length) {
    return undefined;
  }
  if (refundRules.length === 1) {
    return refundRules[0];
  }
  const instantRefund = refundRules.find(
    rule => rule.name === 'Instant refund',
  );
  const fullRefund = refundRules.find(rule => rule.name === 'Full refund');
  if (!!instantRefund || !!fullRefund) {
    return isAfter(fullRefund?.dateBefore, instantRefund?.dateBefore)
      ? fullRefund
      : instantRefund;
  }
  return undefined;
}
