<ng-container *ngIf="user$ | async as user">
  <div class="messages-input">
    <form [formGroup]="formGroup" (ngSubmit)="onTextSubmit()">
      <div class="d-flex">
        <div class="input-text-wrapper">
        <textarea
          placeholder="Send a message"
          formControlName="text"
          class="input-text"
          data-test="chat-input"
          cdkTextareaAutosize
          (keydown)="onTextKeyDown($event)"
          [maxLength]="1000"
        ></textarea>
        </div>
        <ng-container *ngIf="sendIcon$ | async; else iconMore">
          <button button type="submit" icon="send" variant="inline" class="ml-12" [disabled]="sendingMessage">
            <!--todo <icon variant="send" color="primary"  data-test="chat-send" tooltip="Send a Message" tooltipPosition="top"></icon>-->
          </button>
        </ng-container>
        <ng-template #iconMore>
          <button button type="button" cdkOverlayOrigin icon="dots_v" variant="inline" class="ml-12" #moreMenuTrigger="cdkOverlayOrigin" data-test="archive-menu" (click)="toggleMoreMenu()" [disabled]="isArchived(conversation, user)"></button>
          <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="moreMenuTrigger"
            [cdkConnectedOverlayOpen]="moreMenuOpen"
            (overlayOutsideClick)="toggleMoreMenu()"
          >
            <div class="dropdown-menu">
              <button
                class="menu-item d-block p-12"
                data-test-="archive-conversation"
                (click)="openArchiveConversationDialog(conversation)"
              >Archive</button>
            </div>
          </ng-template>
        </ng-template>
      </div>
    </form>
  </div>
</ng-container>
