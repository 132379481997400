import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  Property,
  PropertyStatistics,
  PropertyType,
  PropertyUpdateDto,
} from '../../shared/models/property';
import { first, map, shareReplay } from 'rxjs/operators';
import { isNil } from 'lodash-es';
import {
  BulkActionPropertyOptions,
  GetMyPropertiesOptions,
  GetPublicPropertiesOptions,
  PropertyApi,
} from '../api/property.api';

/** Property API endpoint base path. */
const PROPERTY_API_BASE = '/property/api/v1/properties';
const STATISTICS_API_BASE = '/property/api/v1/statistics';

/**
 * Property service communicating with Users API endpoints.
 */
@Injectable({
  providedIn: 'root',
})
export class PropertyService {
  private propertyTypes$: Observable<PropertyType[]>;

  constructor(
    private readonly http: HttpClient,
    private readonly propertyApi: PropertyApi,
  ) {
    this.initialization();
  }

  private initialization() {
    this.propertyTypes$ = this.http
      .get<PropertyType[]>(`${PROPERTY_API_BASE}/propertyTypes`)
      .pipe(shareReplay());
  }

  getPropertyTypeLabel(
    key: string,
    defaultValue = 'Unknown type',
  ): Observable<string> {
    // Wait until types are initialized
    return this.propertyTypes$.pipe(
      first(value => !isNil(value)),
      map(value => {
        return value.find(type => type.key === key)?.label || defaultValue;
      }),
    );
  }

  getPropertyTypes(sort = false): Observable<PropertyType[]> {
    return this.propertyTypes$.pipe(
      map(propertyTypes => {
        if (!sort) {
          return propertyTypes;
        }
        return propertyTypes.sort((l, r) =>
          ('' + l.label).localeCompare(r.label),
        );
      }),
    );
  }

  getMyProperties(options: GetMyPropertiesOptions) {
    return this.propertyApi.getMyProperties(options);
  }

  getPublicProperties(options: GetPublicPropertiesOptions) {
    return this.propertyApi.getPublicProperties(options);
  }

  applyBulkAction(options: BulkActionPropertyOptions) {
    return this.propertyApi.applyBulkAction(options);
  }

  createProperty(): Observable<Property> {
    return this.http.post<Property>(`${PROPERTY_API_BASE}`, {});
  }

  deleteProperty(id: string): Observable<void> {
    return this.http.delete<void>(`${PROPERTY_API_BASE}/${id}`);
  }

  getProperty(id: string): Observable<Property> {
    return this.http.get<Property>(`${PROPERTY_API_BASE}/${id}`);
  }

  viewProperty(id: string): Observable<void> {
    return this.http.post<void>(`${STATISTICS_API_BASE}/${id}/view`, {});
  }

  getPropertyStatistics(id: string): Observable<PropertyStatistics> {
    return this.http.get<PropertyStatistics>(`${STATISTICS_API_BASE}/${id}`);
  }

  updateProperty(
    id: string,
    property: PropertyUpdateDto,
  ): Observable<Property> {
    return this.http.put<Property>(`${PROPERTY_API_BASE}/${id}`, property);
  }

  publishProperty(id: string): Observable<Property> {
    return this.http.post<Property>(`${PROPERTY_API_BASE}/${id}/publish`, {});
  }

  archiveProperty(id: string): Observable<Property> {
    return this.http.post<Property>(`${PROPERTY_API_BASE}/${id}/archive`, {});
  }

  unpublishProperty(id: string): Observable<Property> {
    return this.http.post<Property>(`${PROPERTY_API_BASE}/${id}/unpublish`, {});
  }

  /**
   * Get link of iCal ics file for property
   * @param id id of property to get iCal for
   * @returns string containing URL of ics file
   */
  getiCalLink(id: string): Observable<{ url: string }> {
    return this.http.get<{ url: string }>(`${PROPERTY_API_BASE}/${id}/iCal`);
  }

  /**
   * Generate link of tinyUrl for property
   * @param id id of property to get tinyUrl for
   * @returns string containing tinyURL for property
   */
  generateTinyUrl(id: string): Observable<{ tinyUrl: string }> {
    return this.http.post<{ tinyUrl: string }>(
      `${PROPERTY_API_BASE}/${id}/generate-tinyurl`,
      {},
    );
  }
}
