import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewEncapsulation,
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { env as environment } from '../../../dynamic-environment';
import { emailValidator } from '../../../utils/validators';
import { LandingPageService } from '../../../core/services/landing-page.service';
import { BlockUiService } from '../../../core/services/block-ui.service';
import { NotificationService } from '../../../core/services/notification.service';
import { getUserFriendlyError } from '../../../utils/errors';
import { AbstractComponent } from '../../../core/components/abstract/abstract.component';
import { ContentService } from '../../../core/services/content.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { TypographyModule } from '@components/typography/typography.module';
import { InputModule } from '@components/input/input.module';
import { ButtonModule } from '@components/button/button.module';
import { RouterModule } from '@angular/router';

interface Link {
  text: string;
  category: string;
  url: string;
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TypographyModule,
    ReactiveFormsModule,
    InputModule,
    ButtonModule,
    RouterModule,
  ],
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent extends AbstractComponent implements OnInit {
  readonly copyrightYear = new Date().getFullYear();
  readonly wpBaseUrl = environment.wordpress.baseUrl;
  vacayHosts: Link[] = [];
  vacayLinks: Link[] = [];
  vacayDestinations: Link[] = [];
  readonly form = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, emailValidator()]),
  });

  constructor(
    private readonly landingPageService: LandingPageService,
    private readonly blockUiService: BlockUiService,
    private readonly notificationService: NotificationService,
    private readonly content: ContentService,
    @Inject(PLATFORM_ID) private readonly platformId: any,
  ) {
    super();
  }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.content.getLinks().subscribe(data => {
      data = data.map(
        (link: { category: string; text: string; url: string }) => {
          if (link.url.startsWith('http')) {
            return link;
          } else {
            link.url = this.wpBaseUrl + link.url;
            return link;
          }
        },
        (error: any) => {
          console.error(error);
        },
      );
      this.vacayLinks = data.filter(link => link.category === 'VacayMyWay');
      this.vacayHosts = data.filter(link => link.category === 'Vacay Hosts');
      this.vacayDestinations = data.filter(
        link => link.category === 'Vacay Destinations',
      );
    });
  }

  submit(event: Event) {
    event.preventDefault();
    if (this.form.invalid) {
      return;
    }
    this.landingPageService
      .subcribeToNewsletter(this.form.get('email').value)
      .pipe(
        this.blockUiService.blockPipe({
          text: 'Subscribing you to newsletter',
        }),
      )
      .subscribe(
        value => {
          if (value) {
            this.notificationService.showNotification({
              text:
                'You have subscribed to receive newsletter on email address ' +
                this.form.get('email').value,
              color: 'success',
              duration: 5000,
            });
          } else {
            // This cannot happen right now. Wordpress returns always true
            // Change this message accordingly, when returned value can be false
            this.showError('You cannot subscribe with this email');
          }
        },
        error => {
          this.showError(
            'Please, make sure, you have entered a correct email address: ' +
              getUserFriendlyError(error),
          );
        },
      );
  }

  private showError(error: string) {
    this.notificationService.showNotification({
      text: error,
      color: 'error',
      duration: 5000,
    });
  }
}
