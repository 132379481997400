export enum UserReservationCancelReasons {
  DATE_CHANGES = 'dateChanges',
  ACCOMMODATION_CHANGE = 'accommodationChange',
  SICKNESS_AMONG_PARTY = 'sicknessAmongParty',
  WEATHER_NOT_PERMITTING = 'weatherNotPermitting',
  BETTER_PRICING_ELSEWHERE = 'betterPricingElsewhere',
  SCHEDULE_CONFLICT = 'scheduleConflict',
  OTHER = 'other',
}

export const UserReservationCancelReasonsOptions = [
  {
    name: 'Date changes',
    value: UserReservationCancelReasons.DATE_CHANGES,
  },
  {
    name: 'Accommodation change',
    value: UserReservationCancelReasons.ACCOMMODATION_CHANGE,
  },
  {
    name: 'Sickness among the party',
    value: UserReservationCancelReasons.SICKNESS_AMONG_PARTY,
  },
  {
    name: 'Weather not permitting',
    value: UserReservationCancelReasons.WEATHER_NOT_PERMITTING,
  },
  {
    name: 'Better pricing elsewhere',
    value: UserReservationCancelReasons.BETTER_PRICING_ELSEWHERE,
  },
  {
    name: 'Schedule conflict',
    value: UserReservationCancelReasons.SCHEDULE_CONFLICT,
  },
  {
    name: 'Other',
    value: UserReservationCancelReasons.OTHER,
  },
];
