import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../../../../../../shared/models/user.model';
import { ParentAuthenticationDialog } from '../../../../parent-authentication-dialog';
import { BreakpointService } from '../../../../../../../core/services/breakpoint.service';
import { Router } from '@angular/router';
import { Url } from '../../../../../../../url';
import { env } from '../../../../../../../dynamic-environment';
import { ToggleService } from '../../../../../../../core/modules/toggle/toggle.service';

/**
 * Component dialog with welcome test
 */
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['welcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeComponent
  extends ParentAuthenticationDialog
  implements OnInit
{
  user$: Observable<User>;
  signUpRewardPoints = this.toggleService.isEnabled('signUpRewardPoints');
  saveAmount = this.toggleService.getVariantValue<number>('signUpRewardPoints');

  /** Large screen flag */
  ls: boolean;

  constructor(
    injector: Injector,
    private readonly breakpointService: BreakpointService,
    private readonly router: Router,
    private readonly toggleService: ToggleService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.user$ = this.userService.getCurrentUser();
    this.breakpointService.largeScreen$
      .pipe(this.untilDestroyed())
      .subscribe(isLargeScreen => {
        this.ls = isLargeScreen;
      });
  }

  openVerification() {
    this.router.navigate([Url.USER_VERIFICATION]);
    this.dialogService.clear();
  }
}
