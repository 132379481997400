import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { Url } from '../../url';

/**
 * Authentication guard. Decides whether user is allowed to access protected routes.
 * @author Jakub Jilek, Jan Helbich
 */
@Injectable({
  providedIn: 'root',
})
export class LoginPageGuard {
  constructor(
    private readonly router: Router,
    private readonly userService: UserService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.userService.isAuthenticated().pipe(
      take(1),
      tap(isAuthenticated => {
        if (isAuthenticated) {
          this.router.navigate([Url.INDEX], {
            queryParamsHandling: 'preserve',
          });
        }
      }),
      map(isAuthenticated => !isAuthenticated),
    );
  }
}
