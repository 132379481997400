<mat-form-field
subscriptSizing="dynamic"
>
  <mat-label>{{ label }}{{ required ? '*' : '' }}</mat-label>
  <input
    #input
    matInput
    [formControl]="control"
    [autocomplete]="autocomplete ? 'on' : 'off'"
    [type]="type"
    [placeholder]="placeholder"
    [readonly]="readonly"
    [errorStateMatcher]="errorStateMatcher"
    [mask]="mask"
    [dropSpecialCharacters]="dropSpecialCharacters"
  />
  <mat-error *ngIf="errorMessage$ | async as errorMessage">
    {{ errorMessage }}
  </mat-error>
  <ng-container
    *ngIf="this.inputPrefix"
    matPrefix
    [ngTemplateOutlet]="this.inputPrefix?.templateRef"
  >
  </ng-container>
  <ng-container
    *ngIf="this.inputSuffix"
    matSuffix
    [ngTemplateOutlet]="this.inputSuffix?.templateRef"
  >
  </ng-container>
</mat-form-field>
