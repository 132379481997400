import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { AuthService } from '../../../../../core/services/auth.service';
import { UserService } from '../../../../../core/services/user.service';
import { Observable } from 'rxjs';
import { User } from '../../../../../shared/models/user.model';

@Component({
  selector: 'app-navigation-menu-mobile-footer',
  templateUrl: './navigation-menu-mobile-footer.component.html',
  styleUrls: ['./navigation-menu-mobile-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationMenuMobileFooterComponent {
  @Output()
  protected readonly closeMenu = new EventEmitter<void>();

  protected readonly user$: Observable<User> =
    this.userService.getCurrentUser();

  constructor(
    private readonly authService: AuthService,
    private readonly userService: UserService,
  ) {}

  onSignOut() {
    this.closeMenu.emit();
    this.authService.logout({ redirect: true });
  }
}
