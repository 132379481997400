import { Injectable } from '@angular/core';
import { Property } from '../../shared/models/property';
import { PropertyService } from './property.service';
import { take } from 'rxjs/operators';
import { env as environment } from '../../dynamic-environment';

export class GAData {
  bookingStatus: string;
  dateFrom: Date;
  dateTo: Date;
  totalPrice: number;
  currency: string;
  propertyId?: string;
  property?: Property;
}

// eslint-disable-next-line
declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalytics4Service {
  constructor(private readonly propertyService: PropertyService) {}

  public sendBookingInfo(data: GAData) {
    if (!environment.enabledFeatures.ga4) {
      return;
    }
    if (data.property) {
      this.createEvent(data);
    } else if (data.propertyId) {
      this.propertyService
        .getProperty(data.propertyId)
        .pipe(take(1))
        .subscribe(
          property => {
            data.property = property;
            this.createEvent(data);
          },
          () => {
            return;
          },
        );
    }
  }

  public sendSignUpConversion(url?: any) {
    if (!environment.enabledFeatures.ga4ConversionReport) {
      return;
    }
    const callback = () => {
      // Redirection is implemented as a part of Sign Up
      if (typeof url !== 'undefined') {
        window.location = url;
      }
    };
    gtag('event', 'conversion', {
      send_to: `${environment.ga4.awConversionId}/Nlj6CNnd14QDEOug3N4B`,
      event_callback: callback,
    });
    return false;
  }

  public sendBookingConversion() {
    if (!environment.enabledFeatures.ga4ConversionReport) {
      return;
    }
    gtag('event', 'conversion', {
      send_to: `${environment.ga4.awConversionId}/nevTCPnyr4QDEOug3N4B`,
    });
  }

  public sendPaymentConversion() {
    if (!environment.enabledFeatures.ga4ConversionReport) {
      return;
    }
    gtag('event', 'conversion', {
      send_to: `${environment.ga4.awConversionId}/xz4aCIjj14QDEOug3N4B`,
    });
  }

  private createEvent(data: GAData) {
    gtag('event', 'booking_event', {
      send_to: environment.ga4.ga4Id,
      bookingStatus: data.bookingStatus,
      dateFrom: data.dateFrom,
      dateTo: data.dateTo,
      country: data.property.address.country,
      state: data.property.address.state,
      totalPrice: data.totalPrice,
      currency: data.currency,
    });
  }
}
