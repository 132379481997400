import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';

export type NotificationColor = 'success' | 'warning' | 'error' | 'info';

@Component({
  selector: '[notification]',
  template: `
    <ng-template #content>
      <ng-content></ng-content>
    </ng-template>
    <ng-container *ngIf="!showCloseButton; else notificationWithCloseButton">
      <div class="notification-content">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
      </div>
    </ng-container>
    <ng-template #notificationWithCloseButton>
      <div class="notification-button-space"></div>
      <div class="notification-content">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
      </div>
      <button
        button
        variant="icon"
        color="inherit"
        class="notification-button"
        (click)="onClose($event)"
      >
        <icon variant="cross" color="inherit"></icon>
      </button>
    </ng-template>
  `,
})
export class NotificationComponent implements OnInit {
  @Input()
  color: NotificationColor = 'error';
  @Input()
  showCloseButton = false;
  @Output()
  notificationClose = new EventEmitter<MouseEvent>();

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(this.elementRef.nativeElement, `notification`);
    this.renderer.addClass(
      this.elementRef.nativeElement,
      `notification-color-${this.color}`,
    );
  }

  onClose($event: MouseEvent) {
    this.notificationClose.emit($event);
  }
}
