import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import {
  MenuLink,
  MenuLinkGroup,
  NavbarService,
} from '../../../navbar/services/navbar.service';
import { BreakpointService } from '../../../../core/services/breakpoint.service';
import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';

@Component({
  selector: 'app-navigation-menu-content',
  templateUrl: './navigation-menu-content.component.html',
  styleUrls: ['./navigation-menu-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationMenuContentComponent
  extends AbstractComponent
  implements OnInit
{
  @Output()
  protected readonly linkClicked = new EventEmitter<MenuLink>();

  menuLinkGroups$: Observable<MenuLinkGroup[]>;

  constructor(
    private readonly navbarService: NavbarService,
    private readonly breakpointService: BreakpointService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.menuLinkGroups$ = combineLatest([
      this.breakpointService.largeScreen$,
      this.navbarService.menuInfo$,
    ]).pipe(
      map(([largeScreen, menuInfo]) =>
        largeScreen ? menuInfo.userMenuDesktop : menuInfo.userMenuMobile,
      ),
    );
  }

  onLinkClicked(link: MenuLink) {
    this.linkClicked.emit(link);
  }
}
