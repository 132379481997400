import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button/button.module';
import { CoreModule } from '../../../../core/core.module';
import { IconModule } from '@components/icon/icon.module';
import { AvatarModule } from '@components/avatar/avatar.module';
import { TypographyModule } from '@components/typography/typography.module';
import { NavigationMenuMobileComponent } from './navigation-menu-mobile.component';
import { NavigationMenuMobileHeaderComponent } from './header/navigation-menu-mobile-header.component';
import { NavigationMenuMobileFooterComponent } from './footer/navigation-menu-mobile-footer.component';
import { NavigationMenuContentModule } from '../content/navigation-menu-content.module';

@NgModule({
  declarations: [
    NavigationMenuMobileComponent,
    NavigationMenuMobileHeaderComponent,
    NavigationMenuMobileFooterComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    ButtonModule,
    IconModule,
    AvatarModule,
    TypographyModule,
    NavigationMenuContentModule,
  ],
  exports: [NavigationMenuMobileComponent],
})
export class NavigationMenuMobileModule {}
