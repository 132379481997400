import { Component, OnInit } from '@angular/core';
import { PropertyStatus } from '../../../../shared/models/property-status';
import { Url } from '../../../../url';
import { UserService } from '../../../../core/services/user.service';
import { PropertyService } from '../../../../core/services/property.service';
import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-property-dashboard-redirect',
  template: '',
})
export class PropertyDashboardRedirectComponent
  extends AbstractComponent
  implements OnInit
{
  constructor(
    private readonly userService: UserService,
    private readonly propertyService: PropertyService,
    private readonly router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.propertyService
      .getMyProperties({
        status: [
          PropertyStatus.PUBLISHED,
          PropertyStatus.UNPUBLISHED,
          PropertyStatus.READY_FOR_PUBLISH,
          PropertyStatus.ARCHIVED,
        ],
        page: 0,
        size: 1,
      })
      .pipe(this.untilDestroyed())
      .subscribe(properties => {
        if (properties.data.length <= 0) {
          this.router.navigate([Url.PROPERTIES]);
        } else {
          this.router.navigate([
            Url.PROPERTY_DASHBOARD_(properties.data[0].id),
          ]);
        }
      });
    return;
  }
}
