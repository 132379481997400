import {
  Booking,
  BookingAction,
  BookingCancellationRefund,
  BookingEvent,
  BookingInterval,
} from '../shared/models/booking';
import { isAfter, isSameDay } from 'date-fns';

export function bookingIsRefundable(booking: Booking) {
  return !(
    !bookingIsPaid(booking.history) ||
    !(booking.refundedAmount < booking.totalRevenue)
  );
}

function bookingIsPaid(history: BookingEvent[]) {
  return !!history.find(be => be.action === BookingAction.PAID);
}

export function isRefundRuleExpired(refundRule: BookingCancellationRefund) {
  if (!refundRule) {
    return true;
  }
  if (!refundRule.dateBefore) {
    return false;
  }
  return isAfter(new Date(), refundRule.dateBefore);
}

export function isRefundRuleSameDayAsCheckin(
  refundRule: BookingCancellationRefund,
  interval: BookingInterval,
) {
  if (!refundRule?.dateBefore || !interval?.checkIn) {
    return false;
  }
  return isSameDay(interval.checkIn, refundRule.dateBefore);
}
