import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NavbarPosition, NavbarService } from '../../services/navbar.service';

/**
 * Component to customize app navbar
 *
 * @author Libor Staněk
 */
@Component({
  selector: 'navbar-config',
  template: `<ng-content></ng-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarConfigComponent {}

/**
 * Component to customize app navbar position
 *
 * @author Libor Staněk
 */
@Component({
  selector: 'navbar-position',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarPositionComponent implements OnDestroy, OnChanges {
  @Input()
  position: NavbarPosition = 'sticky';

  constructor(private readonly navbarService: NavbarService) {}

  ngOnDestroy() {
    this.navbarService.updateMenuSettings({
      position: 'sticky',
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.position) {
      this.navbarService.updateMenuSettings({
        position: changes.position.currentValue,
      });
    }
  }
}

/**
 * Component to customize app navbar content
 *
 * @author Libor Staněk
 */
@Component({
  selector: 'navbar-content',
  template: `
    <ng-template #contentRef>
      <ng-content></ng-content>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarContentComponent implements OnDestroy, OnChanges {
  @Input()
  fullWidth: boolean;
  @Input()
  noGutters: boolean;

  @ViewChild('contentRef', { static: false })
  set contentRef(contentRef: TemplateRef<any>) {
    this.navbarService.updateMenuSettings({
      contentRef: contentRef,
    });
  }

  constructor(private readonly navbarService: NavbarService) {}

  ngOnDestroy() {
    this.navbarService.updateMenuSettings({
      contentFullWidth: false,
      contentNoGutters: false,
      contentRef: null,
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fullWidth) {
      this.navbarService.updateMenuSettings({
        contentFullWidth: changes.fullWidth.currentValue,
      });
    }
    if (changes.noGutters) {
      this.navbarService.updateMenuSettings({
        contentNoGutters: changes.noGutters.currentValue,
      });
    }
  }
}

/**
 * Component to customize app navbar tabs
 *
 * @author Libor Staněk
 */
@Component({
  selector: 'navbar-tabs',
  template: `
    <ng-template #contentRef>
      <ng-content></ng-content>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarTabsComponent implements OnDestroy {
  @ViewChild('contentRef', { static: false })
  set contentRef(contentRef: TemplateRef<any>) {
    this.navbarService.updateMenuSettings({
      tabsRef: contentRef,
    });
  }

  constructor(private readonly navbarService: NavbarService) {}

  ngOnDestroy() {
    this.navbarService.updateMenuSettings({
      tabsRef: null,
    });
  }
}

/**
 * Component for app navbar back navigation button
 *
 * @author Libor Staněk
 */
@Component({
  selector: 'navbar-back',
  template: `
    <navbar-content [fullWidth]="true">
      <ng-container *ngIf="backClick.observers.length; else backLinkRef">
        <button
          button
          variant="inline"
          startIcon="chevron_left"
          class="ml-md-20"
          (click)="onBack()"
        >
          {{ backText }}
        </button>
      </ng-container>
      <ng-template #backLinkRef>
        <a
          button
          variant="inline"
          startIcon="chevron_left"
          class="ml-md-20"
          [routerLink]="backLink"
        >
          {{ backText }}
        </a>
      </ng-template>
    </navbar-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarBackComponent {
  @Input() backText = 'Back';
  @Input() backLink: string | string[] = '..';
  @Output() readonly backClick = new EventEmitter<void>();

  onBack() {
    this.backClick.emit();
  }
}
