import { Injectable, Injector } from '@angular/core';
import { AbstractApi } from './abstract.api';
import { format } from 'date-fns';
import { DATE_FORMAT } from '../../utils/date.util';
import {
  CalendarDay,
  PropertyCalendar,
} from '../../shared/models/property-calendar';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class PropertyCalendarApi extends AbstractApi {
  constructor(injector: Injector) {
    super(injector);
    this.base = '/pricing/api/v1/property';
  }

  getPublicCalendar(
    propertyId: string,
    from: Date,
    to: Date,
    checkIn?: Date,
    occupancy?: number,
    checkOut?: Date,
  ): Observable<PropertyCalendar> {
    let params: HttpParams = new HttpParams({
      fromObject: {
        from: from ? format(from, DATE_FORMAT) : undefined,
        to: to ? format(to, DATE_FORMAT) : undefined,
      },
    });

    if (checkIn) {
      params = params.append('checkIn', format(checkIn, DATE_FORMAT));
    }
    if (checkOut) {
      params = params.append('checkOut', format(checkOut, DATE_FORMAT));
    }
    if (occupancy) {
      params = params.append('occupancy', occupancy);
    }

    return this.http.get<PropertyCalendar>(
      `${this.base}/${propertyId}/calendar`,
      {
        params,
      },
    );
  }

  getCalendarDays(
    propertyId: string,
    from: Date,
    to: Date,
  ): Observable<PropertyCalendar> {
    return this.http.get<PropertyCalendar>(
      `${this.base}/${propertyId}/calendar/day`,
      {
        params: {
          from: from ? format(from, DATE_FORMAT) : undefined,
          to: to ? format(to, DATE_FORMAT) : undefined,
        },
      },
    );
  }

  updateCalendarDays(
    propertyId: string,
    days: CalendarDay[],
  ): Observable<void> {
    return this.http.post<void>(
      `${this.base}/${propertyId}/calendar/day`,
      days.map(day => ({
        ...day,
        date: format(day.date, DATE_FORMAT) + 'T00:00:00.000Z',
      })),
      {
        params: {
          propertyId: propertyId,
        },
      },
    );
  }
}
