<div class="picks-area">
  <button
    *ngIf="showLeftArrow"
    class="pick-arrow pick-arrow-left"
    (click)="onLeftArrow()"
    aria-label="Scroll top picks left"
  >
    <icon variant="chevron_left" color="inherit"></icon>
  </button>

  <div class="pick-scroll" #scrollRef>
    <div class="pick-list">
      <a
        *ngFor="let pick of picks"
        class="pick"
        [routerLink]="pick.url"
        [queryParams]="pick.params"
        rel="noopener"
        target="_blank"
      >
        <div class="pick-image-container">
          <img
            loading="lazy"
            [src]="pick.imageUrl"
            class="pick-image"
            [alt]="pick.label"
          />
          <app-home-picks-badge [badgeType]="pick.badgeType"/>
        </div>
        <ng-container
          [ngTemplateOutlet]="descriptionTemplate"
          [ngTemplateOutletContext]="{ data: pick }"
        ></ng-container>
      </a>
    </div>
  </div>

  <button
    *ngIf="showRightArrow"
    class="pick-arrow pick-arrow-right"
    (click)="onRightArrow()"
    aria-label="Scroll top picks right"
  >
    <icon variant="chevron_right" color="inherit"></icon>
  </button>
</div>
