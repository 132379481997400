import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, of } from 'rxjs';
import { env as environment } from '../../dynamic-environment';
import { SKIP_AUTHORIZATION_HEADER } from '../interceptors/auth.interceptor';

export interface HomeFooterLink {
  category: string;
  text: string;
  url: string;
}

export enum PickCategory {
  PROPERTY_TYPE = 'property-type',
  LOCATION = 'location',
  TOP_VACAY_1 = 'top-vacay-1',
  TOP_VACAY_2 = 'top-vacay-2',
}

export type HomePick =
  | {
      label: string;
      image: string;
      url: string;
      category: PickCategory.LOCATION | PickCategory.PROPERTY_TYPE;
    }
  | {
      id: string;
      category: PickCategory.TOP_VACAY_1 | PickCategory.TOP_VACAY_2;
    };

export type HomePickInfo =
  | {
      label: string;
      image: string;
      url: string;
      category: PickCategory.LOCATION | PickCategory.PROPERTY_TYPE;
    }
  | {
      label: string;
      image: string;
      url: string;
      category: PickCategory.TOP_VACAY_1 | PickCategory.TOP_VACAY_2;
      location: string;
      price: number;
    };

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  constructor(private readonly http: HttpClient) {}

  getPicks(): Observable<HomePick[]> {
    return this.http
      .get<HomePick[]>(`${environment.content.url}picks.json`, {
        headers: new HttpHeaders({
          [SKIP_AUTHORIZATION_HEADER]: '',
          'Cache-Control': 'no-store, no-cache',
        }),
      })
      .pipe(catchError(() => of([])));
  }

  getLinks(): Observable<HomeFooterLink[]> {
    return this.http
      .get<HomeFooterLink[]>(`${environment.content.url}links.json`, {
        headers: new HttpHeaders({
          [SKIP_AUTHORIZATION_HEADER]: '',
          'Cache-Control': 'no-store, no-cache',
        }),
      })
      .pipe(catchError(() => of([])));
  }
}
