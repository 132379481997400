<div class="amenity-checkbox-group" [class.amenity-minimal-standard]="minimalStandardGroup">
  <ng-container *ngIf="(disabled$.asObservable() | async) === false">
    <div *ngIf="largeScreen$ | async;" class="property-search-left-panel">
      <div class="amenity-checkbox-group-title">
        <checkbox
          *ngIf="minimalStandardGroup"
          [formControl]="selectAllControl"
          [label]="label"
          color="ink"
          class="mb-16"
          [isBold]="true"
        ></checkbox>
        <span typography [variant]="groupLabelVariant" color="ink" class="mb-16 amenity-checkbox-group-title-text" *ngIf="!minimalStandardGroup && label">{{ label }}</span>
        <icon *ngIf="labelTooltip" variant="info" class="ml-8 amenity-checkbox-group-tooltip-icon" [tp]="labelTooltip" tooltipPosition="after"></icon>
      </div>

      <div class="amenity-checkbox-group-container row">
        <div class="amenity-checkbox-group-item col-lg-4 col-md-6 col-sm-6 col-xs-12 position-relative" *ngFor="let option of visibleOptions">
          <div>
            <checkbox [formControl]="option.control" [label]="getOptionLabel(option)"></checkbox>
            <div class="amenity-sub-option-container" *ngIf="option.optionControl && option.optionControl.value">
              <ng-container *ngFor="let suboption of getSelectedAmenityLabels(option); let isLast = last">
              <span
              typography
              variant="body3"
              color="inherit">{{ suboption }}<ng-container *ngIf="!isLast">,&nbsp;</ng-container></span>
              </ng-container>
            </div>
          </div>
          <div class="d-flex align-items-start" *ngIf="option.options?.length > 0">
            <amenity-menu
              [options]="option.options"
              [multiple]="option.type === 'multiple'"
              [control]="option.optionControl"
            >
            </amenity-menu>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
