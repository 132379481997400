<div (click)="redirect()" class="d-flex align-items-center mb-24">
  <div class="property-image mr-8 flex-shrink-0">
    <img *ngIf="(property | propertyFeaturedPhoto)?.imageUrl as imageUrl; else noImage" [src]="imageUrl | thumbnail"
      alt="Property image" />
    <ng-template #noImage>
      <span typography variant="body3">
        No image
      </span>
    </ng-template>
  </div>

  <div class="booking-content flex-grow-1">
    <p class="recipient-header" typography variant="em1">
      {{property?.headline}}
    </p>
    <p *ngIf="bookingInterval" typography variant="body2" class="booking-date-info">
      <span>{{bookingInterval.checkIn | formatDate}}
        - {{bookingInterval.checkOut | formatDate}}
      </span>
      <span>{{{
        start: bookingInterval.checkIn,
        end: bookingInterval.checkOut
        } | differenceInDays }} nights</span>
    </p>
    <p *ngIf="contextDetailInfo.booking" typography variant="body2">{{contextDetailInfo.booking.guests}} guests</p>
    <div class="context-status mt-4">
      <ng-container *ngIf="bookingStatus === BookingStatus.AUTHORIZED">
        <status color="tertiary">Pending</status>
      </ng-container>
      <ng-container *ngIf="bookingStatus === BookingStatus.APPROVED">
        <status color="success">Confirmed</status>
      </ng-container>
      <ng-container *ngIf="bookingStatus === BookingStatus.CANCELED">
        <status color="error">Canceled</status>
      </ng-container>
      <ng-container *ngIf="!bookingStatus">
        <status color="secondary">Inquiry</status>
      </ng-container>
    </div>
  </div>
  <icon color="ink" variant="chevron_right"></icon>
</div>
