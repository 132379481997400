import { Injector } from '@angular/core';
import { DialogService } from '../../../../core/services/dialog.service';
import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';

/**
 * Common class for authentication dialogs
 */
export abstract class AbstractDialog extends AbstractComponent {
  protected readonly dialog: DialogService;

  /**
   * Constructor
   * @param injector - for getting service
   */
  protected constructor(protected injector: Injector) {
    super();
    this.dialog = injector.get<DialogService>(DialogService);
  }

  closeDialog() {
    this.dialog.clear();
  }
}
