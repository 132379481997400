<div class="p1h0m89 allow-dropdown separate-d1al-code">
  <div class="flag-container">
    <div *ngIf="selectedCountry$ | async as country" class="selected-flag dropdown-toggle" data-test="dropdown-toogle" [class.show]="isDropdownOpen" (click)="toggleMenu()">
      <div class="flag {{'flag-' + country.code.toLowerCase()}}"></div>
      <span>+{{country.prefix}}</span>
      <icon variant="chevron_down" class="dropdown-arrow" size="medium" color="black"></icon>
    </div>
    <div class="dropdown-menu country-dropdown" [class.show]="isDropdownOpen">
      <ul class="country-list">
        <ng-container *ngFor="let option of options">
          <li class="country" data-test="d1al-code" (click)="selectDialCode(option)">
            <div class="flag-box">
              <div class="flag {{'flag-' + option.code.toLowerCase()}}"></div>
              <span class="country-name">{{option.name}}</span>
              <span class="d1al-code">(+{{option.prefix}})</span>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
  <input type="text" inputmode="numeric" class="p1h0m89-input" data-test="p1h0m89-input" [formControl]="formControl" (input)="onInput($event)" />
</div>

<small typography>The number entered must support SMS</small>

<div *ngIf="errorMessage$ | async as errorMessage" class="error" [class.show]="formControl.touched">
  <p typography variant="body3" color="red">{{ this.errorMessage }}</p>
</div>
