import { Pipe, PipeTransform } from '@angular/core';
import { format, isYesterday } from 'date-fns';
import { isToday } from '../../../utils/date.util';

@Pipe({
  name: 'formatMessageDate',
})
export class FormatMessageDatePipe implements PipeTransform {
  transform(value: Date, ...args: any[]): unknown {
    if (!(value instanceof Date)) {
      return undefined;
    }
    if (isToday(value)) {
      return `Today ${format(value, 'hh:mm a')}`;
    }
    if (isYesterday(value)) {
      return `Yesterday ${format(value, 'hh:mm a')}`;
    }
    return format(value, 'MM/dd/yyyy hh:mm a');
  }
}
