import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  User,
  VerificationStatus,
} from '../../../../../shared/models/user.model';
import { Observable } from 'rxjs';
import { UserService } from '../../../../../core/services/user.service';
import { StorageKey } from '../../../../../shared/enums/local-storage-key.enum';
import { LoginComponent } from '../../../../auth/components/login/dialog/login.component';
import { DialogService } from '../../../../../core/services/dialog.service';
import { StorageService } from '../../../../../core/services/storage.service';

@Component({
  selector: 'app-navigation-menu-mobile-header',
  templateUrl: `./navigation-menu-mobile-header.component.html`,
  styleUrls: ['./navigation-menu-mobile-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationMenuMobileHeaderComponent {
  protected readonly VerificationStatus = VerificationStatus;

  @Output()
  protected readonly closeMenu = new EventEmitter<void>();

  protected readonly user$: Observable<User> =
    this.userService.getCurrentUser();

  constructor(
    private readonly userService: UserService,
    private readonly dialogService: DialogService,
    private readonly storageService: StorageService,
  ) {}

  onSignIn() {
    this.closeMenu.emit();
    this.storageService.removeItem(StorageKey.BOOKING_SOCIAL_SIGN_UP);
    this.dialogService.open(LoginComponent);
  }

  onCloseMenu() {
    this.closeMenu.emit();
  }
}
