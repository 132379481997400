import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PropertyEvent, PropertyEventType } from '../models/property-event';
import { HttpClient } from '@angular/common/http';
import { DateInterval } from '../../../../libs/component-lib/utils/calendar.utils';
import { format } from 'date-fns';
import { DATE_FORMAT } from '../../../../utils/date.util';

const PROPERTY_EVENTS_API_BASE = '/pricing/api/v1/properties';

@Injectable({
  providedIn: 'root',
})
export class PropertyEventService {
  constructor(private readonly http: HttpClient) {}

  getPropertyEvents(
    propertyId: string,
    interval: DateInterval,
    eventType?: PropertyEventType,
  ): Observable<PropertyEvent[]> {
    const params = {} as any;
    params.from = format(interval.start, DATE_FORMAT);
    params.to = format(interval.end, DATE_FORMAT);
    if (eventType) {
      params.eventType = eventType;
    }
    return this.http.get<PropertyEvent[]>(
      `${PROPERTY_EVENTS_API_BASE}/${propertyId}/events`,
      {
        params: params,
      },
    );
  }

  getPropertyEvent(
    propertyId: string,
    eventId: string,
  ): Observable<PropertyEvent> {
    return this.http.get<PropertyEvent>(
      `${PROPERTY_EVENTS_API_BASE}/${propertyId}/events/${eventId}`,
    );
  }

  createPropertyEvent(
    propertyId: string,
    event: Partial<PropertyEvent>,
  ): Observable<PropertyEvent> {
    return this.http.post<PropertyEvent>(
      `${PROPERTY_EVENTS_API_BASE}/${propertyId}/events`,
      {
        ...event,
        start: format(event.start, DATE_FORMAT),
        end: format(event.end, DATE_FORMAT),
      },
    );
  }

  updatePropertyEvent(
    propertyId: string,
    eventId: string,
    event: Partial<PropertyEvent>,
  ): Observable<PropertyEvent> {
    return this.http.put<PropertyEvent>(
      `${PROPERTY_EVENTS_API_BASE}/${propertyId}/events/${eventId}`,
      event,
    );
  }

  deletePropertyEvent(propertyId: string, eventId: string): Observable<void> {
    return this.http.delete<void>(
      `${PROPERTY_EVENTS_API_BASE}/${propertyId}/events/${eventId}`,
    );
  }
}
