import { Pipe, PipeTransform } from '@angular/core';
import { format, isYesterday } from 'date-fns';
import { isToday } from '../../../utils/date.util';

@Pipe({
  name: 'formatConversationDate',
})
export class ChatConversationDateFormatPipe implements PipeTransform {
  transform(value: Date, ...args: any[]): unknown {
    if (!(value instanceof Date)) {
      return undefined;
    }
    if (isToday(value)) {
      return `${format(value, 'hh:mm a')}`;
    }
    if (isYesterday(value)) {
      return `Yesterday`;
    }
    return format(value, 'MM/dd/yyyy');
  }
}
