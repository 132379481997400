import { Injectable } from '@angular/core';

export const MIN_AVATAR_WIDTH = 180;
export const MIN_AVATAR_HEIGHT = 180;
export const MAX_AVATAR_WIDTH = 30000;
export const MAX_AVATAR_HEIGHT = 30000;
export const MAX_AVATAR_RATIO = 0.25;

export type AvatarSize = 'small' | 'medium' | 'large';

@Injectable()
export class AvatarPhotoService {
  validateDimension(width: number, height: number): string {
    if (
      (MIN_AVATAR_WIDTH && width < MIN_AVATAR_WIDTH) ||
      (MIN_AVATAR_HEIGHT && height < MIN_AVATAR_HEIGHT)
    ) {
      return `The file dimensions should be at least ${MIN_AVATAR_WIDTH}x${MIN_AVATAR_HEIGHT}px.`;
    }
    if (
      (MIN_AVATAR_WIDTH && width < MIN_AVATAR_WIDTH) ||
      (MIN_AVATAR_HEIGHT && height < MIN_AVATAR_HEIGHT)
    ) {
      return `The file maximum dimensions should be ${MAX_AVATAR_WIDTH}x${MAX_AVATAR_HEIGHT}px.`;
    }
    if (
      MAX_AVATAR_RATIO &&
      (width / height < MAX_AVATAR_RATIO || height / width < MAX_AVATAR_RATIO)
    ) {
      return `The file is too wide or too tall. Please choose image closer to square. Maximum ratio is 1:${
        1 / MAX_AVATAR_RATIO
      }.`;
    }
    return null;
  }
}
