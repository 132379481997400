import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Host,
  Input,
  OnInit,
  Optional,
  SkipSelf,
} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BreakpointService } from '../../../../../core/services/breakpoint.service';
import { AmenityParentComponent } from '../amenity-parent/amenity-parent.component';
import { TypographyVariant } from '@components/typography/typography.component';
import { CheckboxOption } from '../amenity-group-accordion/amenity-group-accordion.component';

@Component({
  selector: 'amenity-group',
  templateUrl: './amenity-group.component.html',
  styleUrls: ['./amenity-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AmenityGroupComponent),
      multi: true,
    },
  ],
})
export class AmenityGroupComponent
  extends AmenityParentComponent
  implements OnInit
{
  @Input()
  groupLabelVariant: TypographyVariant = 'h5';

  largeScreen$ = this.breakpointService.largeScreen$;

  constructor(
    private readonly breakpointService: BreakpointService,
    @Host() @SkipSelf() @Optional() parentControlContainer: ControlContainer,
  ) {
    super(parentControlContainer);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  registerOnChange(fn: any): void {
    super.registerOnChange(fn);
  }

  getOptionLabel(option: CheckboxOption): string {
    if (this.getSelectedAmenityLabels(option).length > 0) {
      return option.label + ':';
    }
    return option.label;
  }
}
