<div class="picks-wrapper" *ngIf="isPlatformBrowser">
  <app-home-section-header
    title="Vacay Blog"
    subtitle="Inspiration for Travelers and Hosts"
    description="What you need to know to effectivily manage your short-term rental."
  ></app-home-section-header>

  <div class="pick-list">
    <a *ngFor="let blog of blogs$ | async" class="pick" [href]="blog.link" rel="noopener" target="_blank" >
      <div class="pick-image">
        <img
        loading="lazy"
        [src]="blog.img"
        class="pick-image"
        [alt]="blog.title"
        />
      </div>
      <div class="pick-title">{{ blog.title }}</div>
      <div typography variant="body2" color="black" class="pick-description" *ngIf="blog.description">{{ blog.description | removeHtmlTags | ellipsis: 200 }}</div>
      <div class="pick-link">Read the article</div>
    </a>
  </div>

  <a button
     variant="contained"
     color="primary"
     size="normal"
     href="https://blog.vacaymyway.com/travel-journal/"
     class="read-more-button"
  >Read more on our blog</a>
</div>
