import { Component, Input } from '@angular/core';
import { BadgeType } from '../../../../../core/services/content.service';
import { IconModule } from '@components/icon/icon.module';
import { NgIf } from '@angular/common';
import { TypographyModule } from '@components/typography/typography.module';
import { IconColor, IconVariant } from '@components/icon/icon.component';

@Component({
  selector: 'app-home-picks-badge',
  standalone: true,
  imports: [IconModule, NgIf, TypographyModule],
  templateUrl: './home-picks-badge.component.html',
  styleUrl: './home-picks-badge.component.scss',
})
export class HomePicksBadgeComponent {
  @Input() badgeType?: BadgeType;

  getIconVariant(): IconVariant {
    switch (this.badgeType) {
      case 'top_pick':
        return 'trophy_f';
      case 'top_rated':
        return 'star_f';
      case 'new':
        return 'star_f';
      case 'hot':
        return 'fire';
      default:
        return 'star_f';
    }
  }

  getIconColor(): IconColor {
    switch (this.badgeType) {
      case 'top_pick':
        return 'yellow';
      case 'top_rated':
        return 'yellow';
      case 'new':
        return 'green';
      case 'hot':
        return 'red';
      default:
        return 'yellow';
    }
  }

  getBadgeText(): string {
    switch (this.badgeType) {
      case 'top_pick':
        return 'Top pick';
      case 'top_rated':
        return 'Top rated';
      case 'new':
        return 'New';
      case 'hot':
        return 'HOT';
      default:
        return '';
    }
  }
}
