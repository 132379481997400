import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';

export type FormatDateVariant = 'short' | 'long';

@Pipe({ name: 'formatDate' })
export class FormatDatePipe implements PipeTransform {
  transform(value: Date, variant: FormatDateVariant = 'short'): string {
    if (!value) {
      return undefined;
    }
    if (!(value instanceof Date) || isNaN(value.getTime())) {
      return 'Invalid Date';
    }
    return format(
      value,
      variant === 'long' ? 'EEE MMM dd, yyyy' : 'MM/dd/yyyy',
    );
  }
}
