<ng-container *ifEnabled="'rewardPointEarning'">
  <div class="reward-card">
    <icon variant="reward_point" class="d-block mr-8"></icon>
    <div>
      @if (!booking?.clientRewardAmount&&isNotLoggedIn$ | async) {
        <div>
          Sign up, get reward points and save!
            <span link variant="link1" data-test="sign-in" (click)="openSignIn()">Redeem now!</span>
        </div>
      } @else {
      <div *ngIf="booking?.clientRewardAmount">
        <ng-container *ngIf="isBeforeCheckIn(); else alreadyEarned">
          You will earn
          <strong data-test="reward"
          >{{ booking.clientRewardAmount | number }} reward points</strong
          >
          for this booking.
        </ng-container>
        <ng-template #alreadyEarned>
          You earned
          <strong
          >{{ booking.clientRewardAmount | number }} reward points</strong
          >
          for this booking.
        </ng-template>
      </div>
      <div *ngIf="showHints || !booking?.clientRewardAmount">
        {{ hintText }}
        <a
          link
          variant="link1"
          href="https://help.vacaymyway.com/article/50-what-are-vacayyourway-rewards"
        >{{ hintAction }}</a>
      </div>
      }
    </div>
  </div>
</ng-container>
