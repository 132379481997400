import { Injectable, TemplateRef } from '@angular/core';
import { AuthService } from '../../../core/services/auth.service';
import { UserService } from '../../../core/services/user.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { env as environment } from '../../../dynamic-environment';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { Role } from '../../../shared/models/role';
import { IconVariant } from '@components/icon/icon.component';
import { ToggleService } from '../../../core/modules/toggle/toggle.service';
import { Url } from '../../../url';

export type NavbarPosition = 'sticky' | 'fixed';

export interface NavbarSettings {
  position: NavbarPosition;
  contentFullWidth: boolean;
  contentNoGutters: boolean;
  contentRef: TemplateRef<any>;
  tabsRef: TemplateRef<any>;
}

export enum MenuTemplate {
  HOME = 'home',
  PROPERTIES = 'properties',
  CALENDAR = 'calendar',
  MY_VACAYS = 'vacays',
  MY_ACCOUNT = 'account',
  MY_PROFILE = 'profile',
  MESSAGES = 'chat',
  SETTINGS = 'settings',
  NEW_LISTING = 'new_listing',
  SIGN_OUT = 'sign_out',
}

export interface MenuLink {
  title: string;
  link?: string;
  href?: string;
  onClick?: () => void;
  active?: boolean;
  enabledIf?: () => boolean;
  icon?: IconVariant;
  template?: MenuTemplate;
}

export interface MenuLinkGroup {
  links: MenuLink[];
}

export interface MenuInfo {
  userMenuDesktop: MenuLinkGroup[];
  userMenuMobile: MenuLinkGroup[];
  navbarDesktop: MenuLink[];
}

/**
 * @author Libor Staněk
 */
@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  private readonly mobileMenuOpenSubject: BehaviorSubject<boolean>;
  readonly mobileMenuOpen$: Observable<boolean>;
  private readonly navbarSettingsSubject: BehaviorSubject<NavbarSettings>;
  readonly navbarSettings$: Observable<NavbarSettings>;
  private readonly menuInfoSubject: BehaviorSubject<MenuInfo>;
  readonly menuInfo$: Observable<MenuInfo>;

  constructor(
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly toggleService: ToggleService,
  ) {
    this.mobileMenuOpenSubject = new BehaviorSubject<boolean>(false);
    this.mobileMenuOpen$ = this.mobileMenuOpenSubject.asObservable();
    this.navbarSettingsSubject = new BehaviorSubject<NavbarSettings>({
      position: 'sticky',
      contentFullWidth: false,
      contentNoGutters: false,
      contentRef: null,
      tabsRef: null,
    });
    this.navbarSettings$ = this.navbarSettingsSubject.asObservable();
    this.menuInfoSubject = new BehaviorSubject<MenuInfo>({
      userMenuDesktop: [],
      userMenuMobile: [],
      navbarDesktop: [],
    });
    this.menuInfo$ = this.menuInfoSubject.asObservable();
    this.initRoutes();
  }

  private initRoutes() {
    combineLatest([
      this.userService.getCurrentUser(),
      this.router.events.pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd,
        ),
        map((event: NavigationEnd) => event.url),
      ),
      this.toggleService.onUpdate$,
    ])
      .pipe(
        map(([user, url]) => {
          const isPropertyOwner = user?.roles?.includes(Role.PROPERTY_OWNER);

          const userMenuDesktop: MenuLinkGroup[] = [];
          const userMenuMobile: MenuLinkGroup[] = [];
          const navbarDesktop: MenuLink[] = [];

          const mobileLinks = [
            {
              title: 'Home',
              link: '/',
              active: /^\/([?#].*)?$/.test(url),
              template: MenuTemplate.HOME,
            },
          ];
          if (user) {
            if (isPropertyOwner) {
              userMenuMobile.push({
                links: [
                  ...mobileLinks,
                  {
                    title: 'Properties',
                    link: Url.PROPERTIES,
                    // Solution for properties opened from my properties list,
                    active:
                      /^\/properties(\?.*)?$/.test(url) ||
                      /^\/properties\/.*\?.*edit=true/.test(url),
                    template: MenuTemplate.PROPERTIES,
                  },
                  {
                    title: 'Calendar',
                    link: Url.PROPERTY_DASHBOARD,
                    // Solution for properties opened from my properties list,
                    active: /^\/properties\/.*\/dashboard/.test(url),
                    template: MenuTemplate.CALENDAR,
                  },
                ],
              });
            } else {
              userMenuMobile.push({
                links: [
                  ...mobileLinks,
                  {
                    title: 'Properties',
                    link: Url.PROPERTIES,
                    // Solution for properties opened from my properties list,
                    active:
                      /^\/properties(\?.*)?$/.test(url) ||
                      /^\/properties\/.*\?.*edit=true/.test(url),
                    template: MenuTemplate.PROPERTIES,
                  },
                ],
              });
            }
          } else {
            userMenuMobile.push({
              links: mobileLinks,
            });
          }
          if (environment.enabledFeatures.devPages) {
            const devLinks = [
              {
                title: 'Template Demo',
                link: Url.DEMO_TEMPLATE,
                active: url.startsWith(Url.DEMO_TEMPLATE),
              },
              {
                title: 'Components Docs',
                link: Url.COMPONENT_DOCUMENTATION,
                active: url.startsWith(Url.COMPONENT_DOCUMENTATION),
              },
              {
                title: 'Property Search',
                link: Url.SEARCH,
                active:
                  url.startsWith(Url.SEARCH) ||
                  (url.startsWith('/properties') &&
                    !/^\/properties\/.*\?.*edit=true/.test(url) &&
                    !/^\/properties\/.*\/dashboard/.test(url) &&
                    !/^\/properties(\?.*)?$/.test(url)) ||
                  url.startsWith('/bookings'),
              },
            ];
            userMenuDesktop.push({
              links: devLinks,
            });
            userMenuMobile.push({
              links: devLinks,
            });
          }
          if (user) {
            const defaultLinks = [
              {
                title: 'My Vacays',
                link: Url.VACAYS,
                active: url.startsWith(Url.VACAYS),
                icon: 'briefcase' as IconVariant,
                template: MenuTemplate.MY_VACAYS,
              },
              {
                title: 'My Account',
                link: Url.ACCOUNT,
                active: url.startsWith(Url.ACCOUNT),
                icon: 'coin' as IconVariant,
                template: MenuTemplate.MY_ACCOUNT,
              },
              {
                title: 'My Profile',
                link: Url.USERS_SELF,
                active: url.startsWith(Url.USERS_(user.id)),
                icon: 'users' as IconVariant,
                template: MenuTemplate.MY_PROFILE,
              },
              {
                title: 'Messages',
                link: Url.CHAT,
                active: url.startsWith(Url.CHAT),
                enabledIf: () => !!environment.enabledFeatures.chat,
                icon: 'mail' as IconVariant,
                template: MenuTemplate.MESSAGES,
              },
            ];
            userMenuDesktop.push({
              links: [
                {
                  title: 'My Properties',
                  link: Url.PROPERTIES,
                  // Solution for properties opened from my properties list,
                  active:
                    /^\/properties(\?.*)?$/.test(url) ||
                    /^\/properties\/.*\?.*edit=true/.test(url),
                  icon: 'dashboard' as IconVariant,
                  template: MenuTemplate.PROPERTIES,
                },
                ...defaultLinks,
              ],
            });
            userMenuMobile.push({
              links: defaultLinks,
            });
            const userLinks = [
              {
                title: 'Settings',
                link: Url.SETTINGS,
                active: url.startsWith(Url.SETTINGS),
                template: MenuTemplate.SETTINGS,
              },
              {
                title: 'List Your Property',
                link: Url.PROPERTY_WIZARD,
                template: MenuTemplate.NEW_LISTING,
              },
            ];
            userMenuDesktop.push({
              links: [
                ...userLinks,
                {
                  title: 'Sign Out',
                  onClick: () => {
                    this.authService.logout({ redirect: true });
                  },
                  template: MenuTemplate.SIGN_OUT,
                },
              ],
            });
            userMenuMobile.push({
              links: userLinks,
            });
          }
          if (isPropertyOwner) {
            navbarDesktop.push(
              ...[
                {
                  title: 'Properties',
                  link: Url.PROPERTIES,
                  // Solution for properties opened from my properties list,
                  active:
                    /^\/properties(\?.*)?$/.test(url) ||
                    /^\/properties\/.*\?.*edit=true/.test(url),
                },
                {
                  title: 'Calendar',
                  link: Url.PROPERTY_DASHBOARD,
                  // Solution for properties opened from my properties list,
                  active: /^\/properties\/.*\/dashboard/.test(url),
                },
              ],
            );
          }
          return {
            userMenuMobile: userMenuMobile,
            userMenuDesktop: userMenuDesktop,
            navbarDesktop: navbarDesktop,
          };
        }),
      )
      .pipe(
        map(menuInfo => {
          // Filter enabled links
          return {
            userMenuMobile: menuInfo.userMenuMobile.map(group => ({
              ...group,
              links: this.filterEnabledMenuLinks(group.links),
            })),
            userMenuDesktop: menuInfo.userMenuDesktop.map(group => ({
              ...group,
              links: this.filterEnabledMenuLinks(group.links),
            })),
            navbarDesktop: this.filterEnabledMenuLinks(menuInfo.navbarDesktop),
          } as MenuInfo;
        }),
      )
      .subscribe(menuInfo => {
        this.menuInfoSubject.next(menuInfo);
      });
  }

  private filterEnabledMenuLinks(menuLinks: MenuLink[]): MenuLink[] {
    return menuLinks.filter(link => !link.enabledIf || link.enabledIf());
  }

  updateMenuSettings(menuSettings: Partial<NavbarSettings>) {
    this.navbarSettingsSubject.next({
      ...this.navbarSettingsSubject.getValue(),
      ...menuSettings,
    });
  }

  openMobileMenu() {
    if (!this.mobileMenuOpenSubject.getValue()) {
      this.mobileMenuOpenSubject.next(true);
    }
  }

  closeMobileMenu() {
    if (this.mobileMenuOpenSubject.getValue()) {
      this.mobileMenuOpenSubject.next(false);
    }
  }
}
