<dialog-wrapper width="sm" [class.h-100]="!isDialog">
  <dialog-header title="Reset Password" [showClose]="false"></dialog-header>
  <dialog-content class="pt-32">
    <div notification class="mb-16" *ngIf="error$ | async as error">
      {{error}}
    </div>

    <form ngNoForm (submit)="submit($event)">
      <div>
        <input-text
          [formControl]="form.controls.email"
          [label]="'Email'"
          class="mb-24"
        >
        </input-text>
      </div>

      <div class="row">
        <div class="col-6">
          <button
            button
            type="button"
            variant="contained"
            color="secondary"
            class="w-100"
            (click)="handleBackButton()"
          >
            Back
          </button>
        </div>
        <div class="col-6">
          <button
            button
            type="submit"
            variant="contained"
            color="primary"
            class="w-100"
          >
            Reset your password
          </button>
        </div>
      </div>
    </form>
  </dialog-content>
</dialog-wrapper>
