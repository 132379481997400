import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControlledComponent } from '@components/abstract/abstract-controlled.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SubmittableFormControl } from '../../../utils/form.util';

export type CheckboxColor = 'default' | 'inverted';

/**
 * @see https://www.figma.com/file/4URZYURZBcwfpYp3HvkSL6/Web-components-VMW?node-id=5092%3A20040
 * @author Libor Staněk
 */
@Component({
  selector: 'checkbox',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CheckboxComponent,
      multi: true,
    },
  ],
  template: ` <span class="checkbox-container">
      <input
        type="checkbox"
        [formControl]="control"
        data-test="property-checkbox"
        class="checkbox-input"
      />
      <span class="checkbox-box"></span>
      <svg
        class="checkbox-checkmark"
        viewBox="0 0 12 12"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.2242 1.97572C11.4586 2.21004 11.4586 2.58994 11.2242 2.82425L4.62424 9.42425C4.38993 9.65857 4.01003 9.65857 3.77571 9.42425L0.775712 6.42425C0.541397 6.18994 0.541397 5.81004 0.775712 5.57572C1.01003 5.34141 1.38993 5.34141 1.62424 5.57572L4.19998 8.15146L10.3757 1.97572C10.61 1.74141 10.9899 1.74141 11.2242 1.97572Z"
        />
      </svg>
      <svg
        class="checkbox-minus"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.75 9C3.75 9.41421 4.08579 9.75 4.5 9.75H13.5C13.9142 9.75 14.25 9.41421 14.25 9C14.25 8.58579 13.9142 8.25 13.5 8.25H4.5C4.08579 8.25 3.75 8.58579 3.75 9Z"
        />
      </svg>
    </span>
    <span class="checkbox-label" [class.fw-bold]="isBold" *ngIf="label">{{
      label
    }}</span>`,
})
export class CheckboxComponent
  extends AbstractControlledComponent<boolean>
  implements OnInit, OnChanges, AfterViewInit
{
  @Input()
  color: CheckboxColor = 'default';
  @Input()
  label: string;
  @Input()
  indeterminate: boolean;
  @Input()
  isBold = false;

  constructor(
    injector: Injector,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    const element = this.elementRef.nativeElement;
    this.renderer.addClass(element, `checkbox2`);
    this.renderer.addClass(element, `checkbox-color-${this.color}`);
    if (this.indeterminate) {
      this.renderer.addClass(element, `checkbox-indeterminate`);
    }
    this.value$.subscribe(value => {
      if (value) {
        this.renderer.addClass(element, `checkbox-checked`);
      } else {
        this.renderer.removeClass(element, `checkbox-checked`);
      }
    });
    this.disabled$.subscribe(disabled => {
      if (disabled) {
        this.renderer.addClass(element, `checkbox-disabled`);
      } else {
        this.renderer.removeClass(element, `checkbox-disabled`);
      }
    });
  }

  ngAfterViewInit(): void {
    this.control.statusChanges.pipe(this.untilDestroyed()).subscribe(() => {
      if (
        this.control.invalid &&
        ((this.control.touched && this.control.dirty) ||
          (this.control as SubmittableFormControl).submitted)
      ) {
        this.renderer.addClass(this.elementRef.nativeElement, 'checkbox-error');
      } else {
        this.renderer.removeClass(
          this.elementRef.nativeElement,
          'checkbox-error',
        );
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const element = this.elementRef.nativeElement;
    if (changes.color) {
      this.renderer.removeClass(
        element,
        `checkbox-color-${changes.color.previousValue}`,
      );
      this.renderer.addClass(
        element,
        `checkbox-color-${changes.color.currentValue}`,
      );
    }
    if (changes.indeterminate) {
      if (changes.indeterminate.currentValue) {
        this.renderer.addClass(element, `checkbox-indeterminate`);
      } else {
        this.renderer.removeClass(element, `checkbox-indeterminate`);
      }
    }
  }

  @HostListener('click')
  onClick() {
    this.value = !this.value;
  }
}
