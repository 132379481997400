<app-line-item
  [labelTemplate]="lineItemLabel"
  [valueTemplate]="lineItemValue"
  [hintTemplate]="showHints && lineItemHint"
>
  <ng-template #lineItemLabel>
    {{ isReservation ? 'Reservation' : 'Booking' }} amount
    <ng-container *ngIf="showBookingLength">
      ({{ bookingLength }} nights)</ng-container
    >
  </ng-template>
  <ng-template #lineItemValue>
    <span typography variant="body2">{{ originalStayPrice | currency }}</span>
  </ng-template>
  <ng-template #lineItemHint>
    <div *ngIf="showHints">
      <app-day-cost-breakdown
        [groupedPrices]="groupedPrices"
      ></app-day-cost-breakdown>
    </div>
  </ng-template>
</app-line-item>

<app-line-item
  *ngIf="currentBooking.discounts?.weeklyDiscount"
  variant="discount"
  [labelTemplate]="lineItemLabel"
  [valueTemplate]="lineItemValue"
  [hintTemplate]="showHints && lineItemHint"
>
  <ng-template #lineItemLabel
    >{{ currentBooking.discounts.weeklyDiscount.discountPercent }}% Weekly
    discount</ng-template
  >
  <ng-template #lineItemValue
    ><span typography variant="body2">{{
      -currentBooking.discounts.weeklyDiscount.discountAmount | currency
    }}</span></ng-template
  >
  <ng-template #lineItemHint
    >This host offers a weekly discount for stays of 7 nights to 27</ng-template
  >
</app-line-item>
<app-line-item
  *ngIf="currentBooking.discounts?.monthlyDiscount"
  variant="discount"
  [labelTemplate]="lineItemLabel"
  [valueTemplate]="lineItemValue"
  [hintTemplate]="showHints && lineItemHint"
>
  <ng-template #lineItemLabel
    >{{ currentBooking.discounts.monthlyDiscount.discountPercent }}% Monthly
    discount</ng-template
  >
  <ng-template #lineItemValue
    ><span typography variant="body2">{{
      -currentBooking.discounts.monthlyDiscount.discountAmount | currency
    }}</span></ng-template
  >
  <ng-template #lineItemHint
    >This host offers a discount for stays of 28 nights or longer</ng-template
  >
</app-line-item>

<ng-container *ngIf="bookingFees.total > 0">
  <app-collapsible-line-item
    [labelTemplate]="lineItemLabel"
    [valueTemplate]="lineItemValue"
  >
    <ng-template #lineItemLabel
      ><span link variant="link2">Host fees</span></ng-template
    >
    <ng-template #lineItemValue
      ><span typography variant="body2">{{
        bookingFees.total | currency
      }}</span></ng-template
    >

    <div class="mb-8">
      <app-line-item
        *ngFor="let fee of bookingFees.fees"
        class="mb-8"
        [labelTemplate]="lineItemLabel"
        [valueTemplate]="lineItemValue"
        [hintTemplate]="fee.feeType === 'cleaningFee' && cleaningFeeHint"
      >
        <ng-template #lineItemLabel>{{
          fee.name || getFeeName(fee.feeType)
        }}</ng-template>
        <ng-template #lineItemValue
          ><span typography variant="body2">{{
            fee.amount | currency
          }}</span></ng-template
        >
        <ng-template #cleaningFeeHint
          >This supports costs associated with cleaning the property after your
          stay</ng-template
        >
      </app-line-item>
    </div>
  </app-collapsible-line-item>
</ng-container>

<ng-container *ifEnabled="'travelProtection'">
  <app-line-item
    *ngIf="currentBooking.travelProtection && currentBooking.travelProtection.travelProtectionType !== travelProtectionType.NONE"
    [labelTemplate]="lineItemLabel"
  [valueTemplate]="lineItemValue"
>
  <ng-template #lineItemLabel>
    Travel Protection
  </ng-template>
  <ng-template #lineItemValue
    ><span typography variant="body2">
      {{ currentBooking.travelProtection.total | currency }}
      </span></ng-template
    >
  </app-line-item>
</ng-container>

<div *ngIf="showSubtotal && subtotal" class="divider"></div>
<div
  *ngIf="showSubtotal && subtotal"
  class="d-flex justify-content-between align-items-center mt-8"
>
  <span typography variant="body2" color="ink-light">Subtotal</span>
  <span typography [variant]="priceVariant" color="ink-light">{{
    subtotal | currency
  }}</span>
</div>
<div *ngIf="showSubtotal && subtotal" class="divider mt-8"></div>

<app-collapsible-line-item
  [labelTemplate]="lineItemLabel"
  [valueTemplate]="lineItemValue"
  [hintTemplate]="showHints && taxHint"
  *ngIf="bookingTaxes.taxes.length > 0; else noTaxes"
>
  <ng-template #lineItemLabel>
    <span link variant="link2">Taxes</span>
  </ng-template>
  <ng-template #lineItemValue>
    <span typography variant="body2">{{
        bookingTaxes.total | currency
      }}</span>
  </ng-template>
  <ng-template #taxHint>
      <p>For some area requirements taxes are included in the nightly rates</p>
  </ng-template>

  <div class="mb-8">
    <app-line-item
      *ngFor="let tax of bookingTaxes.taxes"
      [labelTemplate]="lineItemLabel"
      [valueTemplate]="lineItemValue"
    >
      <ng-template #lineItemLabel
        >{{ tax.name || getTaxName(tax.taxType)
        }}<span
          typography
          variant="body3"
          color="ink-light"
          class="ms-8"
          *ngIf="tax.taxPayer === 'vmw'"
          >Paid by VMW</span
        ></ng-template
      >
      <ng-template #lineItemValue
        ><span typography variant="body2">{{
          tax.amount | currency
        }}</span></ng-template
      >
    </app-line-item>
  </div>
</app-collapsible-line-item>

<ng-template #noTaxes>
  <app-line-item
    [labelTemplate]="lineItemLabel"
    [valueTemplate]="lineItemValue"
  >
    <ng-template #lineItemLabel>Taxes</ng-template>
    <ng-template #lineItemValue
      ><span typography variant="body2">{{ 0 | currency }}</span></ng-template
    >
  </app-line-item>
</ng-template>

<ng-container *ngIf="isPropertyOwner; else zeroVacayFee">
  <app-line-item
    [labelTemplate]="lineItemLabel"
    [valueTemplate]="lineItemValue"
  >
    <ng-template #lineItemLabel>Total payment</ng-template>
    <ng-template #lineItemValue
      ><span typography variant="body2">{{
        (payoutCanceled && isReservation ? 0 : currentBooking.totalPrice)
          | currency
      }}</span></ng-template
    >
  </app-line-item>
  <div class="divider mt-8"></div>

  <app-line-item
    [labelTemplate]="vacayFeelineItemLabel"
    [valueTemplate]="vacayFeelineItemValue"
  >
    <ng-template #vacayFeelineItemLabel>VMW Service</ng-template>
    <ng-template #vacayFeelineItemValue
      ><span typography variant="body2"
        >-{{ currentBooking.vacayFee | currency }}</span
      ></ng-template
    >
  </app-line-item>
</ng-container>
<ng-template #zeroVacayFee>
  <app-line-item
    *ngIf="!isPropertyOwner"
    [labelTemplate]="lineItemLabel"
    [valueTemplate]="lineItemValue"
  >
    <ng-template #lineItemLabel><span typography variant="em2">VacayMyWay fee</span></ng-template>
    <ng-template #lineItemValue><span typography variant="em2">{{ 0 | currency }}</span></ng-template>
  </app-line-item>
</ng-template>

<app-line-item
  *ngIf="!isPropertyOwner && currentBooking.discounts?.rewardPoints"
  variant="reward-points"
  [labelTemplate]="vacayFeelineItemLabel"
  [valueTemplate]="vacayFeelineItemValue"
>
  <ng-template #vacayFeelineItemLabel>
    <span
      typography
      variant="body2"
      color="ink"
      class="d-flex align-items-center"
    >
      <icon variant="reward_point" size="16" class="mr-8"></icon>
      Reward points ({{
        currentBooking.discounts.rewardPoints.rewardPoints | number
      }})
    </span>
  </ng-template>
  <ng-template #vacayFeelineItemValue>
    <span typography variant="body2" color="ink">{{
      -currentBooking.discounts.rewardPoints.discountAmount | currency : 'USD'
    }}</span>
  </ng-template>
</app-line-item>

<div class="divider mt-8"></div>
<div class="mt-8">
  <div
    class="d-flex justify-content-between align-items-center"
    [class.payment-canceled]="payoutCanceled"
  >
    <ng-container *ngIf="!isPropertyOwner; else payoutContent">
      <span typography variant="h4">Total</span>
      <span typography variant="h4">{{
        (payoutCanceled && isReservation ? 0 : currentBooking.totalPrice)
          | currency
      }}</span>
    </ng-container>
    <ng-template #payoutContent>
      <span typography variant="body1">Total Payout</span>
      <span typography variant="h4">{{
        payoutCanceled && isReservation
          ? 0
          : (currentBooking.totalRevenue | currency)
      }}</span>
    </ng-template>
  </div>
</div>

<ng-container *ngIf="currentBooking.autoWithdrawal && isPropertyOwner">
  <div class="divider mt-8"></div>
  <div class="d-flex justify-content-between align-items-center mt-8">
    <span typography variant="body2"
      >Auto withdrawal enabled for this booking. Bank account ID:
      <strong>{{ currentBooking.autoWithdrawal.bankAccountId }}</strong
      >.</span
    >
  </div>
</ng-container>

<div
  *ngIf="
    !isPropertyOwner &&
    currentBooking.clientRewardAmount &&
    currentBooking.status !== BookingStatus.CANCELED
  "
  class="my-16"
>
  <app-booking-reward-card
    [booking]="currentBooking"
    [showHints]="false"
  ></app-booking-reward-card>
</div>

<div *ngIf="currentBooking.refundedAmount" class="divider mt-8"></div>
<div
  *ngIf="currentBooking.refundedAmount"
  class="d-flex justify-content-between align-items-center mt-8"
>
  <span typography variant="body2" color="ink"
    >Refund {{ isPropertyOwner ? 'sent' : 'received' }}</span
  >
  <span typography [variant]="priceVariant" color="ink">{{
    currentBooking.refundedAmount | currency : 'USD'
  }}</span>
</div>
