import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  NotificationInstance,
  NotificationService,
} from '../../services/notification.service';

/**
 * Component for providing notifications
 * TODO LS close notification user action
 * @author Libor Staněk
 */
@Component({
  selector: 'app-notification-provider',
  template: `
    <div
      notification
      *ngFor="let notification of notifications$ | async"
      [color]="notification.color"
      [showCloseButton]="!!notification.onClose"
      (notificationClose)="notification.onClose($event)"
      [ngClass]="notification.styleClasses"
    >
      {{ notification.text }}
    </div>
  `,
})
export class NotificationProviderComponent implements OnInit {
  notifications$: Observable<NotificationInstance[]>;

  /** Routing flag. Global notifications do not have key set, local notifications do. */
  @Input() notificationKey?: string;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.notifications$ = this.notificationService.notifications$.pipe(
      map(it =>
        it.filter(
          notification => notification.notificationKey === this.notificationKey,
        ),
      ),
    );
    this.renderer.addClass(
      this.elementRef.nativeElement,
      `notification-provider`,
    );
  }
}
