import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CookieService } from 'ngx-cookie-service';
import { BlockUiProviderComponent } from './components/block-ui-provider/block-ui-provider.component';
import { ForbiddenPageComponent } from './components/error-pages/forbidden-page/forbidden-page.component';
import { NotFoundPageComponent } from './components/error-pages/not-found-page/not-found-page.component';
import { NotificationProviderComponent } from './components/notification-provider/notification-provider.component';
import { BreakpointDownDirective } from './directives/breakpoint-down.directive';
import { BreakpointUpDirective } from './directives/breakpoint-up.directive';
import { CardIconPipe } from './pipes/card-icon.pipe';
import { FormatDatePipe } from './pipes/date-format.pipe';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { FormatCardExpirePipe } from './pipes/format-card-expire.pipe';
import { FormatDateToNowPipe } from './pipes/format-date-to-now.pipe';
import {
  PropertyFeaturedPhotoPipe,
  ThumbnailPipe,
} from './pipes/property-featured-photo.pipe';
import { UserFullNamePipe } from './pipes/user-full-name.pipe';
import { StateToAbbreviationPipe } from './pipes/state-to-abbreviation.pipe';
import { UserInitialsPipe } from './pipes/user-initials.pipe';
import { ChatApi } from './api/chat.api';
import { StartCasePipe } from './pipes/start-case.pipe';
import { DifferenceInDaysPipe } from './pipes/difference-in-days.pipe';
import { IntegratorFormatPipe } from './pipes/integrator-format.pipe';
import { IfEnabledDirective } from './directives/if-enabled.directive';
import { AddressFormatPipe } from './pipes/address-format.pipe';
import { PropertyApi } from './api/property.api';
import { PropertyTypePipe } from './pipes/property-type.pipe';
import { ConfigurationService } from './services/configuration.service';
import { FormatInvalidReason } from './pipes/invalid-reason-format.pipe';
import { PluralizePipe } from './pipes/pluralize.pipe';
import { RemoveHtmlTagsPipe } from './pipes/remove-html-tags.pipe';
import { AvailablePaymentMethodsPipe } from './pipes/available-payment-methods.pipe';
import { TypographyModule } from '@components/typography/typography.module';
import { NotificationLegacyModule } from '../libs/component-lib/notification-legacy.module';
import { PromoBannerComponent } from './components/promo-banner/promo-banner.component';
import { IconModule } from '../libs/components/icon/icon.module';
import { ButtonModule } from '@components/button/button.module';

export function initConfig(appConfig: ConfigurationService) {
  return () => appConfig.loadConfig();
}

@NgModule({
  declarations: [
    NotificationProviderComponent,
    PromoBannerComponent,
    BlockUiProviderComponent,
    FormatDateToNowPipe,
    FormatCardExpirePipe,
    CardIconPipe,
    PropertyTypePipe,
    UserFullNamePipe,
    UserInitialsPipe,
    EllipsisPipe,
    RemoveHtmlTagsPipe,
    FormatDatePipe,
    BreakpointUpDirective,
    BreakpointDownDirective,
    IfEnabledDirective,
    NotFoundPageComponent,
    ForbiddenPageComponent,
    StateToAbbreviationPipe,
    StartCasePipe,
    DifferenceInDaysPipe,
    IntegratorFormatPipe,
    AddressFormatPipe,
    FormatInvalidReason,
    PluralizePipe,
    AvailablePaymentMethodsPipe,
  ],
  exports: [
    NotificationProviderComponent,
    PromoBannerComponent,
    BlockUiProviderComponent,
    FormatDateToNowPipe,
    PropertyFeaturedPhotoPipe,
    PropertyTypePipe,
    ThumbnailPipe,
    UserFullNamePipe,
    UserInitialsPipe,
    FormatCardExpirePipe,
    CardIconPipe,
    EllipsisPipe,
    RemoveHtmlTagsPipe,
    FormatDatePipe,
    BreakpointUpDirective,
    BreakpointDownDirective,
    IfEnabledDirective,
    NotFoundPageComponent,
    ForbiddenPageComponent,
    StateToAbbreviationPipe,
    StartCasePipe,
    DifferenceInDaysPipe,
    IntegratorFormatPipe,
    AddressFormatPipe,
    FormatInvalidReason,
    PluralizePipe,
    AvailablePaymentMethodsPipe,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatProgressSpinnerModule,
    TypographyModule,
    NotificationLegacyModule,
    PropertyFeaturedPhotoPipe,
    ThumbnailPipe,
    IconModule,
    ButtonModule,
  ],
  providers: [
    ChatApi,
    PropertyApi,
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [ConfigurationService],
      multi: true,
    },
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
  ],
})
export class CoreModule {}
