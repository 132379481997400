import { NgModule } from '@angular/core';
import { NotificationComponent } from './components/notification.component';
import { ButtonModule } from '@components/button/button.module';
import { IconModule } from '@components/icon/icon.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [NotificationComponent],
  imports: [CommonModule, ButtonModule, IconModule],
  exports: [NotificationComponent],
})
export class NotificationLegacyModule {}
