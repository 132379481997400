import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { BehaviorSubject, defer, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

export interface BlockUiOptions {
  text: string;
}

export interface BlockUiInstance {
  id: number;
  text: string;
  unblock?: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class BlockUiService {
  lastId: 0;
  activeBlocks = new BehaviorSubject<BlockUiInstance[]>([]);

  getBlocks(): Observable<BlockUiInstance[]> {
    return this.activeBlocks;
  }

  block(options?: Partial<BlockUiOptions>): BlockUiInstance {
    const blockUiInstance: BlockUiInstance = {
      id: this.lastId,
      text: !isNil(options?.text)
        ? options.text
        : 'We are preparing your\nvacay for you!',
    };
    this.lastId++;
    blockUiInstance.unblock = () => {
      this.unblock(blockUiInstance);
    };
    this.activeBlocks.next([...this.activeBlocks.value, blockUiInstance]);
    return blockUiInstance;
  }

  unblock(blockUiInstance: BlockUiInstance) {
    const activeBlocks = [...this.activeBlocks.value];
    const index = activeBlocks.indexOf(blockUiInstance);
    activeBlocks.splice(index, 1);
    this.activeBlocks.next(activeBlocks);
  }

  unblockAll() {
    this.activeBlocks.next([]);
  }

  blockPipe(options?: Partial<BlockUiOptions>) {
    return blockUi(this, options);
  }
}

/**
 * Pipe operator for UI blocking during async requests.
 * @param blockUiService UI blocking service
 */
export function blockUi(
  blockUiService: BlockUiService,
  options?: Partial<BlockUiOptions>,
) {
  return <T>(source: Observable<T>) =>
    defer(() => {
      const block = blockUiService.block(options);
      return source.pipe(finalize(() => block.unblock()));
    });
}
