import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ChatWindowService } from '../../../services/chat-window.service';
import { Observable } from 'rxjs';
import { Conversation } from '../../../models/conversation.model';

@Component({
  selector: 'app-chat-window-provider',
  templateUrl: './chat-window-provider.component.html',
  styleUrls: ['./chat-window-provider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatWindowProviderComponent implements OnInit {
  conversation$: Observable<Conversation>;

  constructor(private readonly chatWindowService: ChatWindowService) {}

  ngOnInit(): void {
    this.conversation$ = this.chatWindowService.conversation$;
  }
}
