import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-property-arrival-instructions',
  template: `<span typography variant="h5">Arrival Instructions</span>
    <div class="mt-8">
      <multi-paragraph-text
        [contentText]="instructions"
        contentLinesLimit="5"
      ></multi-paragraph-text>
    </div>`,
})
export class PropertyArrivalInstructionsComponent extends AbstractComponent {
  @Input()
  instructions: string;
}
