import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationMenuContentModule } from '../content/navigation-menu-content.module';
import { RouterLink } from '@angular/router';
import { TypographyModule } from '@components/typography/typography.module';
import { IconModule } from '@components/icon/icon.module';
import { NavigationMenuDesktopComponent } from './navigation-menu-desktop.component';

@NgModule({
  declarations: [NavigationMenuDesktopComponent],
  imports: [
    CommonModule,
    NavigationMenuContentModule,
    RouterLink,
    TypographyModule,
    IconModule,
  ],
  exports: [NavigationMenuDesktopComponent],
})
export class NavigationMenuDesktopModule {}
