import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserReservationsCalendarComponent } from './user-reservations-calendar.component';
import { ButtonModule } from '@components/button/button.module';
import { TypographyModule } from '@components/typography/typography.module';

@NgModule({
  declarations: [UserReservationsCalendarComponent],
  imports: [CommonModule, ButtonModule, TypographyModule],
  exports: [UserReservationsCalendarComponent],
})
export class UserReservationsCalendarModule {}
