import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { AbstractInputComponent } from '@components/abstract/abstract-input.component';
import { Option } from '@components/input/dropdown/dropdown.component';
import { createArrayOfNumbers } from '@components/components.utils';
import { CALENDAR_US_LOCALE } from '../../../../utils/datepicker.util';
import { isNil } from 'lodash-es';
import {
  markAsSubmitted,
  SubmittableFormControl,
  updateControls,
} from '../../../../utils/form.util';

@Component({
  selector: 'input-day-month-year',
  styleUrls: ['../input.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputDayMonthYearComponent,
      multi: true,
    },
  ],
  template: `
    <div class="row" [formGroup]="internalControl">
      <dropdown
        formControlName="day"
        [options]="options.day"
        label="Day"
        data-test="day-dropdown"
        [required]="required"
        class="col-4"
      ></dropdown>
      <dropdown
        formControlName="month"
        [options]="options.month"
        label="Month"
        data-test="month-dropdown"
        [required]="required"
        class="col-4"
      ></dropdown>
      <dropdown
        formControlName="year"
        [options]="options.year"
        label="Year"
        data-test="year-dropdown"
        [required]="required"
        class="col-4"
      ></dropdown>
    </div>
  `,
})
export class InputDayMonthYearComponent
  extends AbstractInputComponent<Date>
  implements OnInit, AfterContentInit
{
  @Input()
  required: boolean;

  internalControl: UntypedFormGroup;
  submitted: boolean;

  options: {
    day: Option[];
    month: Option[];
    year: Option[];
  };

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initOptions();
    this.initControls();
  }

  ngAfterContentInit(): void {
    super.ngAfterContentInit();
    this.control.statusChanges.subscribe(() => {
      if (
        this.control.touched &&
        !Object.values(this.internalControl.controls).every(
          control => control.touched,
        )
      ) {
        this.internalControl.markAllAsTouched();
      }
      if (
        (this.control as SubmittableFormControl).submitted &&
        !this.submitted
      ) {
        this.submitted = true;
        markAsSubmitted(this.internalControl);
        updateControls(this.internalControl);
      }
    });
  }

  private initOptions() {
    const maxYear = new Date().getFullYear();
    const minYear = maxYear - 150;
    const monthNames = CALENDAR_US_LOCALE.monthNames;
    this.options = {
      day: createArrayOfNumbers(1, 31).map(day => ({
        value: day,
        name: '' + day,
      })),
      month: createArrayOfNumbers(0, 11).map(month => ({
        value: month,
        name: monthNames[month],
      })),
      year: createArrayOfNumbers(minYear, maxYear)
        .reverse()
        .map(year => ({ value: year, name: '' + year })),
    };
  }

  private initControls() {
    this.internalControl = new UntypedFormGroup({
      day: new UntypedFormControl(),
      month: new UntypedFormControl(),
      year: new UntypedFormControl(),
    });
    this.internalControl.valueChanges.subscribe(value => {
      const date = new Date(Date.UTC(value.year, value.month, value.day));
      const validDate =
        date.getUTCFullYear() === value.year &&
        date.getUTCMonth() === value.month &&
        date.getUTCDate() === value.day;
      this.value = validDate ? date : new Date(NaN);
    });
    this.internalControl.statusChanges.subscribe(() => {
      this.control.markAsTouched();
    });
    this.value$.subscribe(value => {
      if (isNil(value)) {
        this.internalControl.reset(
          {
            day: undefined,
            month: undefined,
            year: undefined,
          },
          { emitEvent: false },
        );
        return;
      }
      const validDate = value instanceof Date && !Number.isNaN(value.getDate());
      if (validDate) {
        this.internalControl.reset(
          {
            day: value.getUTCDate(),
            month: value.getUTCMonth(),
            year: value.getUTCFullYear(),
          },
          { emitEvent: false },
        );
      }
    });
  }
}
