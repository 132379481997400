import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Directive,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ToggleService } from '../../../../core/modules/toggle/toggle.service';
import { first, map } from 'rxjs/operators';
import { LoginComponent } from 'src/app/modules/auth/components/login/dialog/login.component';
import { DialogService } from 'src/app/core/services/dialog.service';
import { UserService } from 'src/app/core/services/user.service';
import { Router } from '@angular/router';
import { Url } from 'src/app/url';

@Directive({
  selector: '[appChristmasThemeIcon]',
})
export class ChristmasThemeIconDirective implements OnInit, AfterViewInit {
  private img: HTMLImageElement;
  private originalSrc: string;

  private winterTheme = false;

  constructor(
    private readonly elm: ElementRef<HTMLElement>,
    private readonly renderer: Renderer2,
    private readonly toggleService: ToggleService,
  ) {}

  ngOnInit() {
    this.toggleService
      .isEnabled$('christmasTheme')
      .pipe(first())
      .subscribe(enabled => (this.winterTheme = enabled));
  }

  ngAfterViewInit() {
    const img = this.elm.nativeElement.getElementsByTagName('img')[0];
    this.originalSrc = img.getAttribute('src');
    this.img = img;
  }

  @HostListener('mouseenter')
  private mouseover() {
    if (this.winterTheme) {
      this.renderer.addClass(this.img, 'theme-christmas');
      this.img.src = this.originalSrc.replace('.svg', '_christmas.svg');
    }
  }

  @HostListener('mouseleave')
  private mouseleave() {
    if (this.winterTheme) {
      this.renderer.removeClass(this.img, 'theme-christmas');
      this.img.src = this.originalSrc;
    }
  }
}

@Component({
  selector: 'app-home-nouns',
  templateUrl: './home-nouns.component.html',
  styleUrls: ['./home-nouns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeNounsComponent {
  readonly christmasTheme$ = this.toggleService.isEnabled$('christmasTheme');
  readonly isLoggedIn$ = this.userService
    .getCurrentUser()
    .pipe(map(user => user != null));

  constructor(
    private readonly toggleService: ToggleService,
    private readonly dialogService: DialogService,
    private readonly userService: UserService,
    private readonly router: Router,
  ) {}

  onSignIn() {
    this.dialogService.open(LoginComponent);
  }

  onViewBalance() {
    this.router.navigate([Url.ACCOUNT]);
  }
}
