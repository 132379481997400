import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { addDays, isAfter } from 'date-fns';
import { BehaviorSubject, Observable, map, mergeMap, of } from 'rxjs';
import { BookingService } from 'src/app/core/services/booking.service';
import { DialogService } from 'src/app/core/services/dialog.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { createBookingLockedDialogConfig } from 'src/app/modules/property/property-dashboard/utils/booking-locked-dialog.utils';
import {
  Booking,
  BookingInfo,
  BookingStatus,
} from 'src/app/shared/models/booking';
import { Property } from 'src/app/shared/models/property';
import { User } from 'src/app/shared/models/user.model';
import { getUserFriendlyError } from 'src/app/utils/errors';
import { bookingIsRefundable } from 'src/app/utils/refund.util';
import { chooseCancellationRefundRule } from '../../../../../../utils/cancellation.util';

@Component({
  selector: 'app-chat-context-booking-detail',
  templateUrl: 'chat-context-booking-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatContextBookingDetailComponent implements OnChanges, OnDestroy {
  @Input() currentUser: User;
  @Input() recipient: User;
  @Input() conversationId: string;
  @Input() booking: BookingInfo;
  @Input() property: Property;

  readonly BookingStatus = BookingStatus;

  bookingInfo$: Observable<BookingInfo>;
  bookingSubject: BehaviorSubject<Booking>;
  refundDisabled$ = new BehaviorSubject<boolean>(true);

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly dialogService: DialogService,
    private readonly bookingService: BookingService,
    private readonly notificationService: NotificationService,
  ) {
    this.bookingSubject = new BehaviorSubject<Booking>(null);
    this.bookingInfo$ = this.bookingSubject.asObservable().pipe(
      mergeMap(booking => {
        if (!booking) {
          return of(null);
        }
        if (!this.isPropertyOwner) {
          return of(booking as BookingInfo);
        }

        return this.bookingService.getBookingCancelDetail(booking.id).pipe(
          map(cancelDetail => {
            return {
              ...this.booking,
              cancelDetail: cancelDetail,
              // Recalculate if booking is cancellable for now
              cancelable: isAfter(cancelDetail.cancelAllowedBefore, new Date()),
            } as BookingInfo;
          }),
        );
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.booking) {
      if (changes.booking.currentValue !== changes.booking.previousValue) {
        this.refundDisabled$.next(true);
        this.bookingSubject.next(changes.booking.currentValue);

        this.bookingService
          .getRefundableInterval(
            this.booking.interval.checkIn,
            this.booking.interval.checkOut,
          )
          .subscribe(
            interval => {
              const now = new Date();
              if (now >= interval.from && now <= interval.to) {
                if (bookingIsRefundable(this.booking)) {
                  this.refundDisabled$.next(false);
                }
              }
            },
            error => {
              this.notificationService.showNotification({
                color: 'error',
                text: getUserFriendlyError(error),
              });
            },
          );
      }
    }
  }

  get isPropertyOwner() {
    return this.property.ownerId === this.currentUser.id;
  }

  get recipientTitle() {
    return this.recipient?.id === this.property.ownerId
      ? 'Your host'
      : 'Your guest';
  }

  get refundRule() {
    return chooseCancellationRefundRule(
      this.booking.cancellationPolicy?.refundRules,
    );
  }

  historyBooking(booking: BookingInfo) {
    return isAfter(new Date(), addDays(booking.interval.checkIn, 1)); // TODO: use exact booking time / end of booking day
  }

  navigateCancel(booking: BookingInfo): void {
    if (booking.locked) {
      this.dialogService.open(
        ConfirmationDialogComponent,
        createBookingLockedDialogConfig('canceled'),
      );
    } else {
      this.router.navigate([
        'properties',
        this.property.id,
        'dashboard',
        booking.id,
        'cancel',
      ]);
    }
  }

  closeDetail() {
    this.router.navigate(['.', this.conversationId], {
      relativeTo: this.route.parent,
    });
  }

  ngOnDestroy(): void {
    this.bookingSubject.complete();
  }
}
