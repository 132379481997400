import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormControl } from '@angular/forms';
import { defer, EMPTY, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, delay, retryWhen } from 'rxjs/operators';
import { defaultValidators } from './validators';

export function getErrorMessage(
  control: UntypedFormControl,
  options?: { label: string },
) {
  if (!control || !control.errors) {
    return undefined;
  }
  const errors = control.errors;
  const keys = Object.keys(errors);
  if (!keys || keys.length <= 0) {
    return undefined;
  }
  const error = keys[0];
  if (!!defaultValidators[error]) {
    return defaultValidators[error](options?.label ?? 'Field', errors[error]);
  }
  const errorInfo = errors[error];
  if (errorInfo && errorInfo.message) {
    return errorInfo.message;
  }
  return JSON.stringify(errorInfo);
}

/**
 * Observable operator for handling 4xx error responses.
 */
export function handle4xxErrors(errorsSubject: Subject<string>) {
  return <T>(source: Observable<T>) =>
    defer(() => {
      errorsSubject.next(null);
      return source.pipe(
        catchError(e => {
          if (e.status >= 400 && e.status <= 499) {
            errorsSubject.next(e.error.message);
            return EMPTY;
          } else {
            throw e;
          }
        }),
      );
    });
}

/**
 * Retry on error pipe operator.
 * Operator will retry on HTTP request failed with certain error HTTP status up to 5 times with 1s delay and merge
 * success/error response.
 * @returns either success or error response operator
 */
export const retryOnError =
  () =>
  <T>(source: Observable<T>) =>
    source.pipe(
      retryWhen(errors =>
        errors.pipe(
          concatMap((error, count) => {
            if (
              count < 5 &&
              error instanceof HttpErrorResponse &&
              [0, 408, 503, 504].includes(error.status)
            ) {
              return of(error.status).pipe(delay(1000));
            }
            return throwError(error);
          }),
        ),
      ),
    );
