import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ToggleService } from '../modules/toggle/toggle.service';
import { first } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class MetaPixelService {
  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private readonly toggleService: ToggleService,
  ) {}

  public init() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.toggleService
      .isEnabled$('metaPixel')
      .pipe(first())
      .subscribe(enabled => {
        if (enabled) {
          this.initTracking();
        }
      });
  }

  private initTracking() {
    const id = environment.metaPixel.id;
    const script = document.createElement('script');
    script.innerHTML = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '${id}');
    fbq('track', 'PageView');
    `;
    window.document.head.append(script);
  }
}
