import { Injectable, Injector } from '@angular/core';
import {
  User,
  UserPasswordChange,
  UserUnreadConversations,
  VerificationStatus,
} from '../../shared/models/user.model';
import { EMPTY, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, filter, first, map, take, tap } from 'rxjs/operators';
import {
  FileStorageService,
  parseResourceKeyFromUrl,
} from './file-storage.service';
import { getUserFriendlyError } from '../../utils/errors';
import { NotificationService } from './notification.service';
import { Role } from '../../shared/models/role';
import { NotificationColor } from '../../libs/component-lib/components/notification.component';
import { GatewayService } from './gateway.service';
import { UserChangedGatewayEvent } from '../model/gateway-event';
import { UserApi } from '../api/user.api';
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { EMAIL_ALREADY_USED } from '../../utils/validators';

/**
 * User service communicating with Users API endpoints.
 */
@Injectable({
  providedIn: 'root',
})
export class UserService {
  private user: User = null;
  private readonly user$ = new ReplaySubject<User>(1);

  constructor(
    private readonly fileStorageService: FileStorageService,
    private readonly notificationService: NotificationService,
    private readonly userApiService: UserApi,
    private readonly injector: Injector,
  ) {
    this.user$.subscribe(user => {
      this.user = user;
    });
    // Lister for user updates
    this.user$.pipe(take(1)).subscribe(() => {
      this.injector
        .get(GatewayService)
        .fromEvent(UserChangedGatewayEvent)
        .pipe(filter(event => this.user && event.userId === this.user?.id))
        .subscribe(event => {
          const { changes } = event;
          let userChanged = false;
          const user = { ...this.user };
          if (changes.verification) {
            user.verification = {
              ...user.verification,
              status: changes.verification.status,
            };
            userChanged = true;
          }
          if (userChanged) {
            this.user$.next(user);
          }
        });
    });
  }

  createUser(firstName: string, lastName: string) {
    return this.userApiService
      .createUser(firstName, lastName)
      .pipe(tap(user => this.user$.next(user)));
  }

  createSmsUser(
    email: string,
    firstName: string,
    lastName: string,
  ): Observable<any> {
    return this.userApiService
      .createSmsUser(email, firstName, lastName)
      .pipe(tap(user => this.user$.next(user)));
  }

  /** Get if user session exists */
  isAuthenticated(): Observable<boolean> {
    return this.user$.pipe(map(user => !!user));
  }

  /** Check if user has phone verified */
  userVerified(): Observable<boolean> {
    return this.user$.pipe(map(user => user && !!user.phone?.phoneNumber));
  }

  /**
   * Get current session user from local memory
   * On every app user update, this Observable is updated
   * TODO: refresh user session every x minutes from backend, to keep user profile fresh
   *
   * @return User model if user session exists, otherwise null is returned
   */
  getCurrentUser(): Observable<User | null> {
    return this.user$;
  }

  /**
   * Get current session user directly from backend
   *
   * @throws {UserNotFoundError} if user not exists
   */
  fetchCurrentUser(): Observable<User> {
    return this.userApiService.fetchCurrentUser().pipe(
      first(),
      tap(user => this.user$.next(user)),
    );
  }

  /**
   * Get current session user unread conversations
   */
  fetchCurrentUserUnreadConversations(): Observable<UserUnreadConversations> {
    return this.userApiService
      .fetchCurrentUserUnreadConversations()
      .pipe(first());
  }

  /**
   * Get user from backend by id
   *
   * @throws {UserNotFoundError} if user not exists
   */
  getUser(id: string): Observable<User> {
    return this.userApiService.getUser(id);
  }

  /**
   * Get current session user from local memory
   * On every app user update, this Observable is updated
   * TODO: refresh user session every x minutes from backend, to keep user profile fresh
   *
   * @return User model if user session exists, otherwise null is returned
   */
  isPropertyOwner(): Observable<boolean> {
    return this.user$.pipe(
      map(user => user && user.roles?.includes(Role.PROPERTY_OWNER)),
    );
  }

  registerUser(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
  ): Observable<User> {
    return this.userApiService
      .registerUser(email, password, firstName, lastName)
      .pipe(tap(updatedUser => this.user$.next(updatedUser)));
  }

  logout() {
    this.user$.next(null);
  }

  updateTimeZone() {
    let timeZone: string;
    try {
      timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e) {
      console.warn(`The time zone was not successfully acquired.`, e);
      return;
    }
    if (timeZone) {
      this.updateCurrentUser({ timeZone: timeZone })
        .pipe(catchError(() => EMPTY))
        .subscribe();
    }
  }

  /**
   * User avatar update - delete existing file from blobstore & upload new one,
   * project changes in avatarUrl on user profile
   */
  updateAvatar(avatar: File = null) {
    if (this.user && this.user.avatarUrl) {
      // if already have avatarUrl => delete file from blobstore
      this.fileStorageService
        .deleteUserAvatar(parseResourceKeyFromUrl(this.user.avatarUrl))
        .subscribe();
    }
    if (avatar) {
      // upload new avatar to blobstore and save it in profile
      this.fileStorageService.uploadUserAvatar(avatar).subscribe(
        (data: { href: string }) => {
          this.updateCurrentUser({ avatarUrl: data.href }).subscribe();
        },
        error => {
          this.notificationService.showNotification({
            text: getUserFriendlyError(error),
            color: 'error',
            duration: 5000,
          });
          this.updateCurrentUser({ avatarUrl: '' }).subscribe();
        },
      );
    } else {
      // if there was not any avatar clear also avatar url
      this.updateCurrentUser({ avatarUrl: '' }).subscribe();
    }
  }

  updateCurrentUser(user: Partial<User>) {
    return this.userApiService
      .updateCurrentUser(user)
      .pipe(tap(updatedUser => this.user$.next(updatedUser)));
  }

  updateEmailVerified(email: string) {
    return this.userApiService.updateEmailVerified(email);
  }

  resetPassword(email: string) {
    return this.userApiService.resetPassword(email);
  }

  sendVerificationEmail() {
    return this.userApiService.sendVerificationEmail();
  }

  /** Password change request. */
  changePassword(data: UserPasswordChange) {
    return this.userApiService.changePassword(data);
  }

  doesUserExist(email: string): Observable<boolean> {
    if (email.length > 0) {
      return this.userApiService.doesUserExist(email);
    }
    return of(false);
  }

  userEmailUniqueValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.doesUserExist(control.value).pipe(
        map((res: boolean) => {
          return res
            ? {
                emailAlreadyUsed: {
                  value: control.value,
                  message: EMAIL_ALREADY_USED,
                },
              }
            : null;
          // NB: Return null if there is no error
        }),
      );
    };
  }

  listenToVerification() {
    // todo Missing definitions of 'user-changed' event types and model
    if (
      !this.user ||
      this.user.verification?.status === VerificationStatus.CONFIRMED
    ) {
      return EMPTY;
    }
    const gatewayService = this.injector.get(GatewayService);
    return gatewayService.fromEvent(UserChangedGatewayEvent).pipe(
      filter(event => !!event.changes.verification),
      tap(event => {
        this.handleVerificationEvent(event.changes.verification);
      }),
    );
  }

  private showVerificationNotification(
    text?: string,
    color?: NotificationColor,
  ) {
    if (text) {
      this.notificationService.showNotification({
        text,
        color,
      });
    }
  }

  private handleVerificationEvent(verification: {
    status: VerificationStatus;
  }) {
    if (verification.status === VerificationStatus.CONFIRMED) {
      this.showVerificationNotification('Verification succeeded', 'success');
    } else if (verification.status === VerificationStatus.FAILED) {
      this.showVerificationNotification(
        'Verification failed. Please try again.',
        'error',
      );
    } else {
      this.showVerificationNotification();
    }
    this.fetchCurrentUser().pipe(first()).subscribe();
  }
}
