import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'dialog-content',
  template: '<ng-content></ng-content>',
})
export class DialogContentComponent implements OnInit {
  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(this.elementRef.nativeElement, `dialog-content`);
  }
}
