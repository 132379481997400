import { Address } from '../shared/models/address.model';

export function isValidUserAddress(address: Address) {
  if (!address) {
    return false;
  }
  return (
    !!address.street1 &&
    !!address.city &&
    !!address.postalCode &&
    !!address.country
  );
}
