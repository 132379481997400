import { Pipe, PipeTransform } from '@angular/core';
import { ClientInfo, User } from '../../shared/models/user.model';
import { ConversationParticipant } from '../../modules/chat/models/conversation.model';

@Pipe({
  name: 'userFullName',
  pure: false,
})
export class UserFullNamePipe implements PipeTransform {
  transform(
    user: User | ClientInfo | ConversationParticipant,
    args?: any,
  ): string {
    return `${user.firstName} ${user.lastName}`;
  }
}
