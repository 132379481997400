import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  MenuLink,
  MenuTemplate,
} from '../../../navbar/services/navbar.service';

@Component({
  selector: 'app-navigation-menu-link',
  templateUrl: './navigation-menu-link.component.html',
  styleUrls: ['./navigation-menu-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationMenuLinkComponent {
  @Input()
  menuLink: MenuLink;

  @Output()
  protected readonly linkClicked = new EventEmitter<MenuLink>();

  protected readonly MenuTemplate = MenuTemplate;

  onLinkClick() {
    this.linkClicked.emit(this.menuLink);
  }
}
