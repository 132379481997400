import { Component, Input } from '@angular/core';
import {
  CategoryLink,
  PickCategory,
} from '../../../../core/services/content.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

interface ProcessedPickCategory extends PickCategory {
  rows: CategoryLink[][];
}

@Component({
  selector: 'app-picks-block',
  templateUrl: 'home-picks-block.component.html',
  styleUrls: ['home-picks-block.component.scss'],
})
export class PicksBlockComponent {
  private _categories: PickCategory[];
  processedCategories$: Observable<ProcessedPickCategory[]> = of([]);

  @Input()
  set categories(value: PickCategory[]) {
    this._categories = value;
    this.processedCategories$ = this.processCategories();
  }

  private processCategories(): Observable<ProcessedPickCategory[]> {
    return of(this._categories).pipe(
      map(categories =>
        categories.map(category => ({
          ...category,
          rows: this.calculateRows(category.items, category.maxRows),
        })),
      ),
    );
  }

  private calculateRows(
    items: CategoryLink[],
    maxRows: number,
  ): CategoryLink[][] {
    const total = items.length;
    const minPerRow = 4;

    let rowsAttempt = maxRows;
    while (rowsAttempt > 0) {
      const requiredMinimum = rowsAttempt * minPerRow;
      if (total >= requiredMinimum) break;
      rowsAttempt--;
    }

    rowsAttempt = Math.max(rowsAttempt, 1);

    const baseCount = Math.floor(total / rowsAttempt);
    let remainder = total % rowsAttempt;

    const rows: CategoryLink[][] = [];
    let currentIndex = 0;

    for (let i = 0; i < rowsAttempt; i++) {
      const rowSize = baseCount + (remainder > 0 ? 1 : 0);
      remainder = Math.max(remainder - 1, 0);

      rows.push(items.slice(currentIndex, currentIndex + rowSize));
      currentIndex += rowSize;
    }

    return rows;
  }
}
