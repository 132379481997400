import { Injectable } from '@angular/core';
import { PropertyAddress } from '../../shared/models/property';

@Injectable({
  providedIn: 'root',
})
export class MapLinkService {
  getDirectionsUrl(propertyAddress: PropertyAddress) {
    return `https://www.google.com/maps/search/${encodeURIComponent(
      propertyAddress.street1,
    )}${encodeURIComponent(
      propertyAddress.street2 ? '+' + propertyAddress.street2 : '',
    )}+${encodeURIComponent(propertyAddress.city)}/@${
      propertyAddress.location.lat
    },${propertyAddress.location.lon},16z`;
  }
}
