import { VerificationStatus } from '../../shared/models/user.model';
import {
  InquiryMessage,
  TextMessage,
} from '../../modules/chat/models/text-message.model';
import {
  BookingEvent,
  BookingInterval,
  BookingStatus,
} from '../../shared/models/booking';
import { BookingMessage } from 'src/app/modules/chat/models/booking-message.model';

export enum GatewayEventType {
  USER_CHANGE = 'user-change',
  CHAT_MESSAGE = 'chat-message',
  CHAT_CONVERSATION_STATUS = 'chat-conversation-status',
  BOOKING_CHANGE = 'booking-changed',
  USER_VERIFICATION_FORM_COMPLETE = 'user-verification-form-complete',
}

export class GatewayEvent {
  type: GatewayEventType;
  userId: string;
}

export class UserChangedGatewayEvent extends GatewayEvent {
  type = GatewayEventType.USER_CHANGE;
  changes: {
    verification?: { status: VerificationStatus };
  };
}
export class ChatMessageGatewayEvent extends GatewayEvent {
  type = GatewayEventType.CHAT_MESSAGE;
  conversationId: string;
  message: TextMessage | InquiryMessage | BookingMessage;
}

export class ChatConversationStatusGatewayEvent extends GatewayEvent {
  type = GatewayEventType.CHAT_CONVERSATION_STATUS;
  conversationId: string;
  read: boolean;
}

export class BookingGatewayEvent extends GatewayEvent {
  type = GatewayEventType.BOOKING_CHANGE;
  bookingId: string;
  propertyId: string;
  clientId: string;
  hostId: string;
  interval: BookingInterval;
  status: BookingStatus;
  eventData: BookingEvent;
}

export class UserVerificationFormCompleteEvent extends GatewayEvent {
  type = GatewayEventType.USER_VERIFICATION_FORM_COMPLETE;
}
