import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InquiryMessageData } from 'src/app/modules/chat/models/text-message.model';
import { BookingInterval } from 'src/app/shared/models/booking';
import { Property } from 'src/app/shared/models/property';

@Component({
  selector: 'app-chat-message-inquiry',
  templateUrl: './chat-message-inquiry.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageInquiryComponent {
  @Input() conversationId: string;
  @Input() message: InquiryMessageData;
  INQUIRY_TEXT = 'Inquiry';

  property: Property;
  @Input() set properties(value: Property[]) {
    this.property = value.find(v => v.id === this.message.payload.propertyId);
  }

  get interval(): BookingInterval {
    return this.message.payload.checkIn && this.message.payload.checkOut
      ? {
          checkIn: this.message.payload.checkIn,
          checkOut: this.message.payload.checkOut,
        }
      : null;
  }

  get text() {
    return this.message.currentUserId === this.message.senderId
      ? 'sent'
      : 'received';
  }

  get routeLinkToProperty() {
    return `/properties/${this.message.payload.propertyId}`;
  }
}
