<div class="home-host-benefits">
  <div class="image"></div>
  <img src="./assets/img/home/banner/banner2.webp" alt="Host Benefits">
  <div class="benefits">
    <app-home-section-header title="Vacay Hosts" subtitle="Host Benefits"></app-home-section-header>
    <a button
       variant="contained"
       color="primary"
       size="normal" class="mt-28"
       [routerLink]="Url.HOST_BENEFITS"
    >Learn More</a>
  </div>
</div>
