import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

export interface AppConfig {
  buildNumber: string;
  applicationUrl: string;
}

@Injectable({ providedIn: 'root' })
export class ConfigurationService {
  private config: AppConfig;
  public loaded = false;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private http: HttpClient,
  ) {}

  loadConfig(): Promise<void> {
    if (this.loaded) {
      return;
    }

    if (isPlatformBrowser(this.platformId)) {
      return this.loadAtClient();
    } else if (isPlatformServer(this.platformId)) {
      return this.loadAtServer();
    }

    throw new Error('Unsupported platform');
  }

  public getConfig(key: keyof AppConfig): string {
    if (!this.loaded) {
      throw new Error('Runtime configuration is not loaded yet!');
    }

    return this.config[key];
  }

  private async loadAtClient(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.get<AppConfig>('/assets/config.json').subscribe({
        next: data => {
          this.config = data;
          this.loaded = true;
          resolve(null);
        },
        error: e => {
          console.error('Could not load runtime configuration', e);
          reject(e);
        },
      });
    });
  }

  private async loadAtServer() {
    this.config = require('../../../assets/config.json');
    this.loaded = true;
  }
}
