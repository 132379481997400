<p typography>Last Updated: May 31, 2021</p>
<p typography>Welcome to VacayMyWay!</p>
<p typography>
  These Terms of Service (“Terms”) are a legally binding agreement between you and Vaycaymyway, Inc. d/b/a VacayMyWay (“Vacay,” “we,” “us,” or “our”).
  By using or accessing <a typography variant="link1" href="https://vacaymyway.com/">VacayMyWay.com</a> or any mobile application for the website (collectively referred
  to as the “Vacay Platform”), you acknowledge and agree that you are subject to these Terms, as well as our Privacy Policy, which also governs your use of the Vacay
  Platform. Please read these Terms carefully, as they contain important information about limitations of liability and resolution of disputes through arbitration.
</p>
<p typography>
  If you do not fully agree to these Terms and any other terms and conditions posted or linked to the Vacay Platform, you are not authorized to access
  or otherwise use the Vacay Platform. You must be at least 18 years of age and able to enter into a legally binding contract to use the Vacay Platform.
  We do not knowingly collect the information of anyone under the age of 18.
</p>
<p typography>
  Vacay offers our users (“Members”) an online marketplace for the rental of vacation properties. Vacay refers to our Members that publish properties as “Hosts”
  and Members that search for and book properties as “Travelers.” Vacay does not own, manage, control, or otherwise offer any of the properties listed on the
  Vacay Platform (each a “Listing”). Vacay is NOT a party to any rental agreement or other transaction entered into directly between Hosts and Travelers on the
  Vacay Platform, nor is Vacay an agent for any Member.
</p>
<p typography>
  These Terms are separated into terms that apply to Travelers (“Traveler Terms”), terms that apply to Hosts (“Host Terms”), and terms that apply to all users
  (“General Terms”).
</p>
<h2 typography variant="h4">Traveler Terms</h2>
<p typography>
  <strong>1. Searching Listings.</strong> Vacay allows you to search Listings using criteria like destination, dates, price, type of accommodation, and the number
  of guests. You can also refine your search results using a wide selection of property features. Vacay’s search results are ordered based on each Listing’s relevance
  to your criteria and desired amenities.
</p>
<p typography>
  <strong>2. Bookings.</strong> When you book a Vacay Listing (a “Booking”), you agree to all of the terms provided in the listing. This includes payment of all Booking
  charges identified during checkout such as the listing price, taxes, and cleaning service (collectively the “Total Vacay Price”) and the specified cancellation policy.
  Where a Host utilizes a rental agreement or otherwise displays terms related to the Listing during the Booking process, you agree to each and every term and condition
  set forth in the rental agreement or such terms displayed during the Booking process. Your Booking confirmation forms a contract directly between you and the Host.
  Your Booking is a limited license to enter, occupy, and use the Host’s property (the “Accommodation”). If you stay past checkout, the Host has the right to make you leave.
</p>
<p typography>
  <strong>3. Cancellations and Refunds.</strong> If a Traveler cancels a Booking, the amount refunded is determined by the cancellation policy that applies to the Booking.
  If the Host cancels a Booking, the Traveler may be eligible for rebooking assistance or a partial or full refund under Vacay’s Traveler refund policy.
</p>
<p typography>
  <strong>4. Booking Modifications.</strong> Where a Traveler and Host mutually agree to modify a Booking via the Vacay Platform or by directing Vacay to modify their
  Booking (each a "Booking Modification"), the Traveler and Host agree to be bound by the terms of the Booking Modification, including responsibility for any additional
  amounts, fees, or taxes associated with the Booking Modification.
</p>
<p typography>
  <strong>5. Identity Verification.</strong> Vacay uses a third-party technology risk management vendor, Guardhog, to verify the identity of Travelers for each Booking
  on the Vacay Platform. As a condition of your Booking, you must successfully complete Guardhog’s identity verification program. Vacay reserves the right to cancel
  your Booking at any time if you fail to complete the Guardhog identify verification program or if the results are unsatisfactory, in Vacay’s sole opinion.<br>
  Member identity verification on the internet is complicated and Vacay does not assume any responsibility for confirming each Member's purported identity.
  Vacay encourages you to communicate directly with a Host through the communication tools available on the Vacay Platform. Vacay also encourages you to take other
  reasonable measures to assure yourself of the Hosts’ identity, the Accommodation, and the details of your Booking.
</p>
<p typography>
  <strong>6. Traveler Responsibilities.</strong> You are responsible and liable for your own acts and omissions concerning a Booking and the Accommodation.
  You are also responsible for the acts and omissions of anyone else you invite to or allow to access the Accommodation. If you fail to leave the Accommodation
  and its contents in the same condition it was when you arrived, you are legally and financially responsible for the damage.
</p>
<p typography>
  By purchasing and using Trip Cancellation and Travel Insurance Coverage provided by Vacay’s third-party partner, Rental Guardian, you agree to the terms and conditions
  under the plan and acknowledge that certain policy restrictions apply.
</p>
<p typography>
  <strong>7. Traveler Assumption of Risk.</strong> You acknowledge that use of the Vacay Platform, making a Booking, and use of an Accommodation carry inherent risks.
  You agree that, to the maximum extent permitted by law, you assume the entire risk arising out of your access to and use of the Vacay Platform. This includes, without
  limitation, your stay at an Accommodation and any interactions you have with other Members. It is solely your responsibility to investigate an Accommodation to determine
  whether it is suitable for you. Vacay does not, and cannot, verify the identity of any Member or the existence, location, condition, or suitability of any Accommodation.
  You understand and agree that the use of an Accommodation ALWAYS carries the risk of serious illness, bodily injury, disability, or death, and that you freely and
  willfully assume those risks by choosing to make a Booking and use an Accommodation.
</p>
<h2 typography variant="h4">Host Terms</h2>
<p typography>
  <strong>8. Search Ranking.</strong> Vacay’s ranking of Listings in search results will fluctuate depending on a variety of factors, including: (i) the Traveler’s
  search parameters, (ii) characteristics of the Listing, (iii) requirements of the Host, and (iv) the Traveler’s preferences. Vacay cannot guarantee that a Listing
  will appear in any specific order in the search results on the Vacay Platform. Vacay reserves the right to apply various search algorithms and use other methods to
  optimize search results.
</p>
<p typography>
  <strong>9. Managing Your Listing.</strong> Your Listing must include accurate information about your Accommodation. This includes the price, cleaning service, and any
  rules or requirements that apply to your Accommodation. Your Listing must not be misleading in any manner. You are responsible for keeping your Listing information,
  availability, and content up-to-date and accurate at all times. Hosts are solely responsible for obtaining insurance coverage sufficient to protect their Accommodation
  and contents. You agree that you have or will obtain the appropriate insurance coverage sufficient to cover the Accommodation during the entire duration of a Booking.
  You agree to provide us with copies of relevant proof of coverage upon request. By utilizing the Host Guarantee provided by Vacay’s third-party partner, Guardhog, you
  agree to the terms and conditions under the Host Guarantee policy, acknowledge that certain policy restrictions apply, and agree that the Host Guarantee is included in
  the Booking in lieu of a security deposit. If you elect to decline the Guardhog Host Guarantee, you do so at your own risk.
</p>
<p typography>
  <strong>10. Booking Contracts.</strong> Hosts are not employees of, agents for, joint venturers with, or partners of Vacay. Vacay does not direct or control your
  Accommodation. You agree that you have complete discretion whether and when to provide your Accommodation to a Traveler and to set your price and terms. By accepting
  a Booking or receiving a Booking confirmation from Vacay, you are entering into a contract directly with the Traveler. You are responsible for delivering the
  Accommodation under the terms, conditions, and price displayed in the Listing and during the Booking process. You are also agreeing to pay Vacay’s service fee and any
  applicable taxes for the Booking. Vacay will deduct amounts you owe from your Host payment. The service fee is non-refundable. Vacay reserves the right to change the
  service fee at any time, and will provide Hosts notice of any fee changes before they become effective. Any changes to the service fee will not affect bookings made
  prior to the effective date of the fee change. If you disagree with a fee change you may terminate this agreement at any time pursuant to Section 22.
</p>
<p typography>
  By utilizing a rental agreement or otherwise displays terms related to the Listing during the Booking process, you agree to each and every term and condition set forth
  in the rental agreement or such terms displayed during the Booking process, including the cancellation policy.<br>
  Any terms, policies, or conditions that you include in any supplemental rental agreement with Travelers must be consistent with these Terms and the information disclosed
  in your Listing. These Terms will control any discrepancies or disputes. Your use of a rental agreement or copied and pasted terms as part of a Booking is solely at your
  own risk. Vacay recommends that you seek the legal advice of an attorney to prepare, review and revise as necessary any rental agreements to ensure compliance with
  federal, state, and local law and their particular circumstances, and to revise the rental agreement as necessary to accurately represent the Accommodation, rules, and
  amenities.
</p>
<p typography>
  <strong>11. Complying With Your Legal Obligations.</strong> As the Host, you are solely responsible for researching, understanding, and complying with any laws, rules,
  or regulations that apply to your Listing or Accommodation. These may include: (i) contractual or zoning restrictions on short-term rentals; (ii) permitting or licensing
  of short-term rentals; (iii) requirements to register Travelers who stay at your Accommodation; and (iv) local landlord-tenant, rent control, and eviction laws that may
  apply to longer rentals. It is solely the Hosts responsibility to review local laws, ordinances, and rules to determine any restrictions or requirements that apply to
  their Listing and Accommodation. Any information provided by Vacay concerning legal requirements is strictly for informational purposes only. Hosts must independently
  confirm their obligations. You should seek legal advice if you have any questions or concerns about how local laws apply to your Accommodation.
</p>
<p typography>
  <strong>12. Taxes.</strong> Hosts are responsible for determining and fulfilling all obligations under applicable laws to accurately report, collect, remit, or include
  in your price any applicable occupancy taxes, tourist, income, or other taxes ("Taxes").
</p>
<p typography>
  <strong>13. Booking Modifications.</strong> Hosts and Travelers are mutually responsible for any Booking Modifications and agree to be bound by the terms of the
  Booking Modification, including responsibility for any additional amounts, fees, or taxes associated with the Booking Modification.
</p>
<p typography>
  <strong>14. Cancellations.</strong> If a Traveler cancels a Booking, the amount paid to you is determined by the cancellation policy that applies to the Booking.
  As a Host, you should not cancel a Booking without extenuating circumstances. If you cancel a Booking without such a valid reason, we may impose a cancellation fee and
  other consequences. If you cancel a Booking due to Extenuating Circumstance, the amount you are paid will be reduced by the amount we provide to the Traveler and the
  other reasonable costs we incur as a result of the cancellation. If a Traveler receives a refund after you have already been paid, or the amount of the refund and other
  costs incurred by Vacay exceeds your payment, we may recover that amount from you, including by offsetting the refund against your future payments.
</p>
<p typography>
  <strong>15. Host Responsibilities.</strong> You are responsible and liable for your own acts and omissions concerning a Booking or the Accommodation. You are also
  responsible for the acts and omissions of your agents that participate in providing the Accommodation to a Traveler. You must set your price and establish rules and
  requirements for your Listing. You must describe all fees in your Listing. You are prohibited from collecting any additional fees outside the Vacay Platform.
</p>
<p typography>
  <strong>16. Host Assumption of Risk.</strong> You acknowledge that use of the Vacay Platform and hosting Travelers at your Accommodation carries inherent risks. You
  agree that, to the maximum extent permitted by law, you assume the entire risk arising out of your access to and use of the Vacay Platform. This includes, without
  limitation, hosting a Traveler at your Accommodation and any interactions you have with other Members. It is solely your responsibility to investigate any laws, rules,
  regulations, or obligations that may be applicable to your Listing or Accommodation. You agree that you are not relying upon any interpretation or statement of law made
  by Vacay concerning a Listing, Booking, Accommodation, or Traveler.
</p>
<p typography>
  <strong>17. Identity Verification.</strong> Member identity verification on the internet is complicated and Vacay does not assume any responsibility for confirming each
  Traveler's purported identity. Vacay uses a third-party technology risk management vendor, Guardhog, to assist Hosts in verify the identity of Travelers for each Booking
  on the Vacay Platform. Vacay reserves the right to cancel a Booking at any time if a Traveler fails to complete the Guardhog identify verification program or if the
  results are unsatisfactory, in Vacay’s sole opinion. Hosts have the option to decline use of the Guardhog identity verification tool. Regardless of whether a Host uses
  the Guardhog identity verification tool, Vacay encourages Hosts to communicate directly with a Traveler through the communication tools available on the Vacay Platform.
  Vacay also encourages Hosts to take other reasonable measures to assure themselves of a Traveler’s identity.
</p>
<h2 typography variant="h4">General Terms</h2>
<p typography>
  <strong>18. Use of the Vacay Platform.</strong> Vacay grants its Members a limited, revocable, and non-exclusive license to access the Vacay Platform and its services
  and content solely for the purpose of publishing a property, searching for a property, booking a property, or researching any of the products or services offered on the
  Vacay Platform, all in accordance with these Terms. Use of the Vacay Platform for any other purpose or in a manner not in accordance with these Terms is expressly
  prohibited.
</p>
<p typography>
  <strong>19. Unauthorized Use of the Vacay Platform.</strong> The license granted by Vacay to use the Vacay Platform only extends to permitted uses. It does not include
  any right of collection, aggregation, copying, scraping, duplication, display or any derivative use of the Vacay Platform nor any right of use of data mining, robots,
  spiders or similar data gathering and extraction tools without our prior written permissions. Unauthorized uses of the Vacay Platform also includes, without limitation,
  those uses specified below:
</p>
<ul>
  <li>Any commercial use of the Vacay Platform or any Content on the Vacay Platform;</li>
  <li>Any use of the Vacay Platform or the tools and services offered on the Vacay Platform for the purpose of booking or soliciting a rental of an Accommodation other
    than through a Booking on the Vacay Platform;
  </li>
  <li>Organizing, facilitating, or entering into a Booking for the purpose of unauthorized parties or events. You are responsible and liable for any party or event
    during your Booking that violates these Terms or the terms of your Booking;
  </li>
  <li>Copying, reproducing, uploading, posting, displaying, republishing, distributing or transmitting any part of the Content in any form;</li>
  <li>
    Reproduction any portion of the Vacay Platform through frame or border environment around the Vacay Platform, or other framing technique to enclose any portion or
    aspect of the Vacay Platform, or mirror or replicate any portion of the Vacay Platform;
  </li>
  <li>Deep-link to any portion of the Vacay Platform without our express written permission;</li>
  <li>Modify, translate into any language or computer language or create derivative works from, any content or any part of the Vacay Platform;</li>
  <li>Reverse engineer any part of the Vacay Platform;</li>
  <li>Sell, offer for sale, transfer or license any portion of the Vacay Platform in any form to any third parties;</li>
  <li>
    Use the Vacay Platform and its inquiry or booking functionality other than to advertise and/or research vacation rentals, to make legitimate inquiries to Vacay’s
    Members or any other use expressly authorized on the Vacay Platform;
  </li>
  <li>
    Use the Vacay Platform to post or transmit information that is in any way false, fraudulent, or misleading, including making any Booking or inquiry under false
    pretenses, or taking any action that may be considered phishing or that would give rise to criminal or civil liability;
  </li>
  <li>Post or transmit any unlawful, threatening, abusive, libelous, defamatory, obscene, vulgar, indecent, inflammatory, sexually explicit, pornographic or profane
    material;
  </li>
  <li>
    Violate, plagiarize or infringe the rights of us or third parties including, without limitation, copyright, trademark, patent, trade secrets, rights of publicity or
    privacy or any other intellectual or proprietary rights; or
  </li>
  <li>
    Use or access the Vacay Platform in any way that, in our sole discretion, adversely affects, or could adversely affect, the performance or function of the Vacay
    Platform or any other system used by us or the Vacay Platform.
  </li>
</ul>
<p typography>
  If you believe that a Member, Listing or Content poses an imminent risk of harm to a person or property, you should immediately contact local authorities before
  contacting Vacay. In addition, if you are aware of, or experience, any content, activity or communication through or in connection with the Vacay Platform that appears
  to be in violation of the above restrictions, or in violation of any other provision of these Terms, you should report your concerns to Vacay. Except as required by law,
  you agree that we are not obligated to take action in response to any report.
</p>
<p typography>
  <strong>20. Reviews.</strong> After a Traveler completes a stay at a Host’s Accommodation, the Traveler and Host will have an opportunity to review each other.
  Your Review must be accurate and may not contain any discriminatory, offensive, defamatory, or other language that violates our Content Guidelines. Vacay does not
  verify reviews for accuracy.
</p>
<p typography>
  <strong>21. Content.</strong> Parts of the Vacay Platform enable you to provide feedback, text, photos, audio, video, information, and other content (collectively,
  “Content”). We have no duty to pre-screen or are otherwise responsible in any manner for Content posted on the Vacay Platform by Members.<br>
  We reserve the right to refuse the posting of Content on the Vacay Platform and to remove from the Vacay Platform any Content that we determine, in our sole discretion,
  violates our Content guidelines or otherwise violates these Terms. We also reserve the right, but do not assume the obligation, to edit Content to cause it to comply
  with our Content Guidelines or formatting requirements or to improve listings. Members remain responsible for reviewing their Content to ensure it is accurate and not
  misleading.
</p>
<p typography>
  <strong>Content Guidelines:</strong> Content must (as determined by us in our discretion):
</p>
<ul>
  <li>not infringe anyone's rights, including but limited to copyright and rights of publicity and privacy, violate the law or otherwise be inappropriate;</li>
  <li>not include any other person’s private or personal information that can be used to identify or contact any person;</li>
  <li>not attempt to impersonate another person, account, company, or employee of Vacay;</li>
  <li>not include unsolicited promotional content, advertising, political campaigns, contests, raffles or solicitations;</li>
  <li>be directly related to the Vacay Platform, a Booking, a Listing, an Accommodation, or a product or service where the Content is submitted;</li>
  <li>not be obscene, abusive, discriminatory or illegal; or</li>
  <li>not be false or misleading.</li>
</ul>
<p typography>
  All Listings on the Vacay Platform are the sole responsibility of the Host and Vacay specifically disclaims any and all liability arising from the alleged accuracy of
  the Listings. Hosts are solely responsible for keeping their property information up-to-date on the Vacay Platforms. We do not represent or warrant that any of the copy,
  Content, reviews, property location, pricing or availability information published on the Vacay Platform is accurate or up-to-date. Vacay assumes no responsibility to
  verify Listing Content or the accuracy of the location. Members are solely responsible for ensuring the accuracy of location, geographic and other Content and location
  or geographic descriptions. Travelers are solely responsible for verifying the accuracy of such Content and descriptions.
</p>
<p typography>
  All other Content is the sole responsibility of the Member who contributed such Content. Members are solely responsible for their Content and we specifically disclaim
  all liability for Content. Each Member represents and warrants that they owns or otherwise controls and has all necessary legal rights to the Content. You are responsible
  and liable if any of your Content violates or infringes the intellectual property or privacy rights of any third party.
</p>
<p typography>
  By submitting and providing Content, in whatever form and through whatever means, you grant Vacay a non-exclusive, worldwide, royalty-free, irrevocable, perpetual,
  sub-licensable and transferable license to copy, modify, prepare derivative works of, distribute, publish and otherwise exploit, that Content, without limitation.
  If Content includes personal information, our Privacy Policy describes how we use that personal information. You warrant that you are authorized to grant Vacay the
  rights described in these Terms.
</p>
<p typography>
  <strong>Copyright Infringement Notifications.</strong> Vacay respects the intellectual property rights of others, and we do not permit, condone or tolerate the posting
  of any Content on the Vacay Platform that infringes any person's copyright. In accordance with the Digital Millennium Copyright Act of 1998 (DMCA), Vacay responds
  expeditiously to claims of copyright infringement committed using the Vacay Platform.
</p>
<p typography>
  <strong>DMCA Notice of Alleged Infringement.</strong> If you believe that Content on the Vacay Platform infringes copyrights, please notify us in writing. The Notice
  must include:
</p>
<ul class="customBullet">
  <li>
    (1)	Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification,
    a representative list of such works at that site.
  </li>
  <li>
    (2)	Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be
    disabled, and information reasonably sufficient to permit Vacay to locate the material.
  </li>
  <li>
    (3) Information reasonably sufficient to permit Vacay to contact you, such as an address, telephone number, and, if available, an electronic mail address at which you
    may be contacted.
  </li>
  <li>
    (4) The following statement and representation (to repeat verbatim): I have a good faith belief that use of the material in the manner complained of is not authorized by
    the copyright owner, its agent, or the law.
  </li>
  <li>
    (5) The following statement and representation (to repeat verbatim): The information in this Notice is accurate, and under penalty of perjury, that (choose one) (i) I am
    the owner or (ii) I am authorized to act on behalf of the owner, of an exclusive right that is allegedly infringed.
  </li>
  <li>
    (6) A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
  </li>
</ul>
<p typography>
  You must deliver the notice to:
</p>
<p typography>
  Ted Hight<br>
  c/o VacayMyWay<br>
  Legal Department – Copyrights<br>
  608 E Hickory Street<br>
  Suite 128<br>
  Denton, Texas 76205<br>
</p>
<p typography>
  <strong>DMCA Counter Notice of Alleged Infringement.</strong> If you have posted material subject to a DMCA Notice that allegedly infringes a copyright, you may send
  Vacay a written Counter Notice. When Vacay receives a Counter Notice, it may, in its discretion, reinstate the material in question not less than ten (10) nor more than
  fourteen (14) days after receiving the Counter Notice unless Vacay first receives notice from the claimant that he or she has filed a legal action to restrain the
  allegedly infringing activity.  Please note that Vacay will send a copy of the Counter Notice to the address provided by the claimant. A counterclaimant may be liable
  for damages (including costs and attorneys’ fees) if he or she knowingly makes a material misrepresentation that that material or activity was removed or disabled by
  mistake or misidentification. The Counter Notice must include:
</p>
<ul class="customBullet">
  <li>
    (1)	Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or
    access to it was disabled.
  </li>
  <li>
    (2) The following statement and representation (to repeat verbatim): I have a good faith belief that the material was removed or disabled as a result of mistake or
    misidentification of the material to be removed or disabled.
  </li>
  <li>
    (3) Your name, address, and telephone number, and the following statement and representation (to repeat verbatim):  I consent to the jurisdiction of Federal District
    Court for the judicial district in which the address is located, or if your address is outside of the United States, for any judicial district in which Vacay may be
    found, and I will accept service of process from the person who provided Notice under subsection (c)(1)(C) of the DMCA or an agent of such person.
  </li>
  <li>
    (4) Your physical or electronic signature.
  </li>
</ul>
<p typography>
  Deliver this Counter Notice to:
</p>
<p typography>
  Ted Hight<br>
  c/o VacayMyWay<br>
  Legal Department – Copyrights<br>
  608 E Hickory Street<br>
  Suite 128<br>
  Denton, Texas 76205<br>
</p>
<p typography>
  <strong>22. Vacay’s Right to Terminate or Suspend an Account and take other Measures.</strong> These Terms are effective when you access the Vacay Platform and remains
  in effect until either you or Vacay terminate this agreement in accordance with these Terms. You may terminate this agreement at any time by contacting us or by
  deleting your account. Vacay may terminate this agreement and your account for any reason by giving you 30 days’ notice. Vacay may also terminate this agreement
  immediately and without notice and terminate your access to the Vacay Platform if you breach these Terms, you violate our policies, you violate applicable laws, or we
  reasonably believe termination is necessary to protect Vacay, its Members, or third parties. Alternatively, in its sole and absolutely discretion, Vacay may, with or
  without prior notice: <strong>(i)</strong> suspend or limit your access to the Vacay Platform and/or account; <strong>(ii)</strong> suspend or remove your Listings,
  Reviews, or Content; <strong>(iii)</strong> cancel any pending or confirmed Booking; or <strong>(iv)</strong> suspend or revoke any special status. For minor violations,
  as Vacay determines in its sole discretion, Vacay will provide you notice and an opportunity to resolve the issue before action is taken. Vacay specifically reserves
  the right to take any action it determines is reasonably necessary to comply with applicable law, or the order or request of a court, law enforcement, or other
  administrative agency or governmental body.
</p>
<p typography>
  <strong>23. Effect of Termination.</strong> If you are a Host and terminate your Vacay account, any confirmed Booking(s) will be canceled and your Travelers will
  receive a full refund. If you are a Traveler and terminate your Vacay account, any confirmed Booking(s) will be canceled and your refund, if any, will depend upon the
  terms of the cancellation policy applicable to the Booking. Once your account is terminated, you will not be entitled to restore your account or any of your Content.
  If Vacay limits, suspends, or terminates your account, you may not register for a new account or otherwise use or access the Vacay Platform through another Member’s
  account. Upon termination of your account for any reason, those Sections of these Terms that by their nature survive termination, will survive termination of this
  agreement, including Sections 1 through 40.
</p>
<p typography>
  <strong>24. Modification.</strong> Vacay reserves the right to modify these Terms at any time. If the changes are material, we will post the revised Terms on the Vacay
  Platform and provide notice of any material changes by email at least 30 days before the date they become effective. If you disagree with the revised Terms, you may
  terminate this agreement immediately as provided in these Terms. If you do not terminate your agreement, your continued access to or use of the Vacay Platform will
  constitute acceptance of the revised Terms.
</p>
<p typography>
  <strong>25. Vacay’s Role.</strong> While the Vacay Platform facilitates the direct Booking of Accommodations, Vacay is not a party to any rental or other agreement
  between Travelers and Hosts. Vacay merely offers a platform that enables Members to publish, offer, search for, and book Accommodations. Any part of a Booking, including
  the quality, condition, safety or legality of the Accommodation advertised, the truth or accuracy of a Listing, the ability of Hosts to rent an Accommodation or the
  ability of Travelers to contract for an Accommodation are solely the responsibility of each Member. Vacay works hard to ensure our that our Members have great experiences
  using the Vacay Platform, however, we cannot control the conduct of Travelers and Hosts. Vacay has the right, but not the obligation, to monitor the use of the Vacay
  Platform and verify information provided by Members. You acknowledge and agree that Vacay administers its policies and standards in its sole discretion. You agree to
  cooperate with and assist Vacay in good faith, and to provide Vacay with such information and take such actions as may be reasonably requested by Vacay.
</p>
<p typography>
  <strong>26. Member Accounts.</strong> In order to access and use many essential features of the Vacay Platform like making a Booking or Listing an Accommodation, Vacay
  requires that you register for an account. You must be at least 18 years of age or older to register for an account. Some Listings may require a higher minimum age for
  Travelers as a requirement for Bookings. In creating your account, you agree to provide accurate, current, and complete information during registration and keep your
  account information up-to-date. You are prohibited from registering more than one account. You are prohibited from transferring your account to someone else. You are
  solely responsible for maintaining the confidentiality and security of your account credentials. Do not disclose your credentials to any third party. Ultimately, you are
  responsible and liable for all activities conducted through your account. You must immediately notify Vacay if you suspect that your account credentials have been lost,
  stolen, or your account is otherwise compromised.
</p>
<p typography>
  <strong>27. Disclaimer of Warranties.</strong> YOU EXPRESSLY AGREE THAT YOUR USE OF THE VACAY PLATFORM IS AT YOUR SOLE RISK. THE VACAY PLATFORM AND ALL CONTENT, SOFTWARE,
  FUNCTIONS, MATERIALS, AND INFORMATION MADE AVAILABLE THROUGH THE VACAY PLATFORM IS PROVIDED “AS IS” AND “AS AVAILABLE” FOR YOUR USE, WITHOUT WARRANTIES OF ANY KIND,
  UNLESS SUCH WARRANTIES ARE LEGALLY INCAPABLE OF EXCLUSION. VACAY SPECIFICALLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED. VACAY DOES NOT ENDORSE OR WARRANT
  THE EXISTENCE, CONDUCT, PERFORMANCE, SAFETY, QUALITY, LEGALITY OR SUITABILITY OF ANY TRAVELER, HOST, ACCOMMODATION, OR LISTING. VACAY DOES NOT WARRANT THAT THE FUNCTIONS,
  MATERIALS, OR CONTENT CONTAINED ON THE VACAY PLATFORM ARE OR WILL REMAIN UNINTERRUPTED OR ERROR FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE VACAY PLATFORM OR SERVER
  THAT MAKES IT AVAILABLE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT ANY PERSON USING THE VACAY PLATFORM WILL BE THE PERSON THAT HE OR SHE REPRESENTS HIMSELF
  OR HERSELF TO BE. VACAY DOES NOT WARRANT THAT VERIFICATION, IDENTITY OR BACKGROUND CHECKS CONDUCTED ON LISTINGS OR MEMBERS (IF ANY) WILL IDENTIFY PAST MISCONDUCT OR
  PREVENT FUTURE MISCONDUCT. YOU ACKNOWLEDGE THAT BY DISPLAYING INFORMATION OR LISTINGS IN PARTICULAR DESTINATIONS, VACAY DOES NOT REPRESENT OR WARRANT THAT TRAVEL TO SUCH
  DESTINATIONS IS WITHOUT RISK AND THAT VACAY IS NOT LIABLE FOR DAMAGES WITH RESPECT TO TRAVEL TO ANY DESTINATION. THE DISCLAIMERS IN THESE TERMS APPLY TO THE MAXIMUM
  EXTENT PERMITTED BY LAW.
</p>
<p typography>
  <strong>28. Limitations on Liability.</strong> IF YOU ARE DISSATISFIED WITH THE VACAY PLATFORM, YOU DO NOT AGREE WITH ANY PART OF THESE TERMS, OR HAVE OTHER DISPUTE OR CLAIM
  WITH OR AGAINST VACAY, THEN VACAY’S ENTIRE LIABILITY AND YOUR SOLE AND EXCLUSIVE REMEDY AGAINST VACAY IS TO DISCONTINUE USING THE VACAY PLATFORM AND TERMINATE YOUR
  ACCOUNT. VACAY (INCLUDING ITS SUBSIDIARIES, AFFILIATES, OFFICERS, DIRECTORS, CONSULTANTS, AGENTS, EMPLOYEES, AND PERSONNEL) SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT,
  INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR LOSS OF GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR
  SYSTEM FAILURE OR THE COST OF SUBSTITUTE PRODUCTS OR SERVICES, OR FOR ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR EMOTIONAL DISTRESS ARISING OUT OF OR IN CONNECTION
  WITH (I) THESE TERMS, (II) ANY BREACH OF THESE TERMS BY YOU OR A THIRD PARTY, (III) THE USE OF OR INABILITY TO USE THE VACAY PLATFORM OR ANY CONTENT, (IV) ANY
  COMMUNICATIONS OR INTERACTIONS YOU MAY HAVE WITH SOMEONE YOU INTERACT OR MEET WITH THROUGH, OR AS A RESULT OF, YOUR USE OF THE VACAY PLATFORM, OR (V) PUBLISHING OR
  BOOKING OF A LISTING, INCLUDING THE PROVISION OR USE OF AN ACCOMMODATION, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY
  OTHER LEGAL THEORY, AND WHETHER OR NOT VACAY HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET OUT IN THESE TERMS IS FOUND TO HAVE FAILED
  OF ITS ESSENTIAL PURPOSE. EXCEPT FOR OUR OBLIGATION TO TRANSMIT PAYMENTS TO HOSTS UNDER THESE TERMS, IN NO EVENT WILL VACAY’S AGGREGATE LIABILITY FOR ANY CLAIM OR
  DISPUTE ARISING OUT OF OR IN CONNECTION WITH THESE TERMS, YOUR INTERACTION WITH ANY MEMBERS, OR YOUR USE OF OR INABILITY TO USE THE VACAY PLATFORM, ANY CONTENT, OR ANY
  ACCOMMODATION, EXCEED: (A) TO TRAVELERS, THE AMOUNT YOU PAID AS A TRAVELER DURING THE 12-MONTH PERIOD PRIOR TO THE EVENT GIVING RISE TO THE LIABILITY, (B) TO HOSTS,
  THE AMOUNT PAID TO YOU AS A HOST IN THE 12-MONTH PERIOD PRIOR TO THE EVENT GIVING RISE TO THE LIABILITY, OR (C) TO ANYONE ELSE, ONE HUNDRED U.S. DOLLARS ($100 USD).
  THESE LIMITATIONS OF LIABILITY AND DAMAGES ARE FUNDAMENTAL ELEMENTS OF THE AGREEMENT BETWEEN YOU AND VACAY.
</p>
<p typography>
  <strong>29. Indemnification.</strong> To the maximum extent permitted by law, you agree to defend (at Vacay’s sole option), indemnify, and hold Vacay and its
  subsidiaries, affiliates, owners, officers, directors, consultants, agents, employees, and personnel harmless from and against any claims, liabilities, demands, damages,
  losses, suits, proceedings, costs and expenses, including, without limitation, reasonable legal and accounting fees, assessed or incurred by Vacay, directly or
  indirectly, and arising out of or in any way connected with: (i) your failure to comply with or breach of these Terms, (ii) your improper use of the Vacay Platform,
  (iii) your interaction with any Member or stay at an Accommodation, including without limitation any injuries, losses or damages (whether compensatory, direct,
  incidental, consequential or otherwise) of any kind arising in connection with or as a result of such interaction, stay, or use, (iv) your failure, or our failure at
  your direction, to accurately report, collect or remit Taxes, or (v) your breach of any laws, regulations or third party rights such as intellectual property or privacy
  rights. You shall cooperate as fully as reasonably required by Vacay in the defense of any claim hereunder. We reserve the right, at our own expense, to assume the
  exclusive defense and control of any matter otherwise subject to indemnification by you and you shall not in any event settle any matter without Vacay’s express written
  consent.
</p>
<p typography>
  <strong>30. Governing Law and Venue.</strong> These Terms will be interpreted in accordance with the laws of the State of Delaware and the United States of America,
  without regard to conflict-of-law provisions. Judicial proceedings (other than small claims actions) that are excluded from the arbitration agreement in Section 31 must
  be brought in state or federal court in Denton, Texas, unless we both agree to some other location. You and we both consent to venue and personal jurisdiction in Denton,
  Texas.
</p>
<p typography>
  <strong>31. Dispute Resolution and Agreement to Arbitrate.</strong>
</p>
<p typography>
  <strong>Dispute Resolution Process.</strong> Vacay has implemented a consumer-friendly two-part dispute resolution process: (1) an informal negotiation directly with
  Vacay’s customer service team, and if necessary (2) a binding arbitration administered by the American Arbitration Association (“AAA”). You and Vacay each retain the
  right to seek relief in small claims court as an alternative to arbitration.
</p>
<p typography>
  <strong>Mandatory Pre-Arbitration Dispute Resolution and Notification.</strong> You agree to give us an opportunity to resolve any disputes or claims relating in any way
  to the Vacay Platform (“Claims”) by notifying the Vacay of the dispute in writing and attempt in good faith to negotiate an informal resolution. You must send your
  notice of dispute to Vacay by mailing it to Vacay’s agent for service: Vaycaymyway, Inc. d/b/a VacayMyWay, c/o Northwest Registered Agent Service, Inc., 8 The Green,
  Suite B, Dover, DE 19901. Your notice of dispute must include: your name and preferred contact information, a brief description of the dispute, and the relief sought.
  If we are not able to resolve your Claims within 60 days, you may seek relief through arbitration or in small claims court.
</p>
<p typography>
  <strong>Agreement to Arbitrate.</strong> You and Vacay mutually agree that any dispute, claim or controversy arising out of or relating to these Terms or the
  applicability, breach, termination, validity, enforcement or interpretation thereof, or any use of the Vacay Platform, an Accommodation, or any Content, will be settled
  by binding individual arbitration rather than in court. The arbitration will be administered by the American Arbitration Association (“AAA”) in accordance with the
  Consumer Arbitration Rules (the “AAA Rules”) then in effect, except as modified here. In order to initiate arbitration, a completed written demand (available at
  <a typography variant="link1" href="https:/www.adr.org">www.adr.org</a>) must be filed with the AAA and provided to Vacay, as specified in the AAA rules.
</p>
<p typography>
  <strong>Exceptions to Arbitration.</strong> You and Vacay mutually agree that the following causes of action and/or claims for relief are exceptions to the Arbitration
  Agreement and will be brought in a judicial proceeding in a court of competent jurisdiction (as defined by Section 30): (i) any claim or cause of action alleging actual
  or threatened infringement, misappropriation or violation of a party’s intellectual property rights; (ii) any claim or cause of action seeking emergency injunctive relief
  based on exigent circumstances; or (iii) a request for the remedy of public injunctive relief.
</p>
<p typography>
  <strong>Arbitrator’s Decision.</strong> The arbitrator’s decision will include the essential findings and conclusions upon which the arbitrator based the award. Judgment
  on the arbitration award may be entered in any court with proper jurisdiction. The arbitrator may award any relief allowed by law or the AAA Rules, but declaratory or
  injunctive relief may be awarded only on an individual basis and only to the extent necessary to provide relief warranted by the claimant’s individual claim.
</p>
<p typography>
  <strong>No Class Actions and Jury Trial Waiver.</strong> Any and all proceedings to resolve Claims will be conducted only on an individual basis and not in a class,
  consolidated or representative action. If for any reason a Claim proceeds in court rather than in arbitration we each waive any right to a jury trial. The Federal
  Arbitration Act and federal arbitration law apply to these Terms. An arbitration decision may be confirmed by any court with competent jurisdiction.
</p>
<p typography>
  <strong>32. Entire Agreement.</strong> Except as they may be supplemented by additional terms, conditions, policies, guidelines, and standards, these Terms constitute
  the entire agreement between you and Vacay regarding your use of and access to the Vacay Platform. These Terms supersede any and all prior oral or written understandings
  or agreements between you and Vacay. If any provision of these Terms is held to be invalid or unenforceable by a court of competent jurisdiction, such invalidity will
  not affect the remaining provisions of these Terms.
</p>
<p typography>
  <strong>33. No Waiver.</strong> Vacay’s failure to enforce any right or provision in these Terms will not constitute a waiver of such right or provision unless agreed to
  by Vacay in writing.
</p>
<p typography>
  <strong>34. Assignment.</strong> You may not assign, transfer or delegate this agreement or your rights and obligations hereunder without Vacay 's prior written consent,
  which may be withheld at Vacay’s sole and absolute discretion.
</p>
<p typography>
  <strong>35 Notices.</strong> Unless specified otherwise, any notices or other communications to Members permitted or required under this agreement, will be sent to the
  email address you provide during the account registration or Booking or as later updated in your account (if applicable). Notice shall be deemed given upon receipt or
  24 hours after an email is sent, unless the sending party is notified that the email address is invalid. We may give you notice by certified mail, postage prepaid and
  return receipt requested, to any physical address provided to us during the account registration process or as later updated in your account (if applicable). Notice
  shall be deemed given three days after the date of mailing to a physical address and one day after mailing to an electronic address. Except as expressly stated otherwise
  in these Terms, any notices required to be given to Vacay shall be given by postal mail to: Vaycaymyway, Inc. d/b/a VacayMyWay, Attn: Legal Department, 608 E Hickory
  Street, Suite 128, Denton, Texas 76205
</p>
<p typography>
  <strong>37. Third-Party Websites.</strong> The Vacay Platform may contain hyperlinks to third-party websites, applications, or services that are subject to different
  terms of service and privacy practices than these Terms. The links to third-party websites is for your convenience and information only. Vacay is not responsible or
  liable for any aspect of any third-party website or service. If you decide to access any of the third-party websites linked to the Vacay Platform, you do so entirely at
  your own risk. Any link to third-party websites or services on the Vacay Platform is not an indication of endorsement, authorization, sponsorship, affiliation, joint
  venture or partnership by or with Vacay.
</p>
<p typography>
  <strong>38. Vacay’s Content.</strong> The Vacay Platform and Content made available through the Vacay Platform may be protected by copyright, trademark, and/or other
  laws of the United States and international laws and conventions. You acknowledge that all intellectual property rights for Vacay’s Content are the exclusive property
  of Vacay and you agree that you will not remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices. Copying, storing or
  otherwise accessing the Vacay Platform or any Content on the Vacay Platform other than for your personal, noncommercial use (other than in accordance with a valid
  Listing) is expressly prohibited. Nothing on the Vacay Platform should be construed as granting, by implication, estoppel or otherwise, any license or right to use any
  of Vacay’s trademarks or service marks without Vacay’s prior written permission. Vacay aggressively enforces its intellectual property rights. Neither the name of Vacay
  nor any of Vacay’s other trademarks, service marks or copyrighted materials may be used in any way, including in any advertising, hyperlink, publicity or promotional
  materials of any kind, whether relating to the Vacay Platform or otherwise, without Vacay’s prior written permission. Subject to your compliance with these Terms, Vacay
  grants you, as part of the Accommodation search and Booking process, a limited, non-exclusive, non-sublicensable, revocable, non-transferable license to access and view
  the Content made available on or through the Vacay Platform and accessible to you, solely for your personal and non-commercial use.
</p>
<p typography>
  <strong>39. Force Majeure.</strong> Vacay shall not be liable for any delay or failure to perform resulting from causes outside our reasonable control, including, but
  not limited to, acts of God, natural disasters, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, pandemics, epidemics
  or disease, strikes or shortages of transportation facilities, fuel, energy, labor or materials.
</p>
<p typography>
  <strong>40. Contact Us.</strong> If you have any questions about these Terms please email us.
</p>
