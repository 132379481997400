import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '../../../../../core/services/dialog.service';
import { ParentAuthenticationDialog } from '../../parent-authentication-dialog';
import { BreakpointService } from '../../../../../core/services/breakpoint.service';

/**
 * Component for login dialog
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent
  extends ParentAuthenticationDialog
  implements OnInit
{
  @Input() isDialog = true;

  /** Large screen flag */
  ls = false;

  constructor(
    injector: Injector,
    private readonly breakpointService: BreakpointService,
    private readonly changeDetection: ChangeDetectorRef,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.breakpointService.largeScreen$
      .pipe(this.untilDestroyed())
      .subscribe(isLargeScreen => {
        this.ls = isLargeScreen;
        this.changeDetection.detectChanges();
      });
  }
}

/**
 * Login link, opens the Login dialog.
 */
@Component({
  selector: 'app-login-link',
  template: `
    <a link variant="link1" (click)="openLogin()">
      <ng-content></ng-content>
    </a>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginLinkComponent {
  @Input() isDialog = true;

  constructor(
    private readonly router: Router,
    private readonly dialogService: DialogService,
  ) {}

  openLogin() {
    if (!this.isDialog) {
      this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });
    } else {
      this.dialogService.clearAndOpen(LoginComponent);
    }
  }
}
