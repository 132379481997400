import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject, Observable, of } from 'rxjs';
import { Message, MessageType } from '../models/message.model';
import { ChatApi, GetConversationsOptions } from '../../../core/api/chat.api';
import { PagedModel } from '../../../shared/models/pagination';
import { GatewayService } from '../../../core/services/gateway.service';
import { ChatMessageGatewayEvent } from '../../../core/model/gateway-event';
import { User } from '../../../shared/models/user.model';
import { UserService } from '../../../core/services/user.service';
import { UserConversationsService } from '../../../core/services/user-conversations.service';
import { map, take, tap } from 'rxjs/operators';
import {
  Conversation,
  ConversationContext,
} from '../models/conversation.model';
import { InquiryMessage, TextMessage } from '../models/text-message.model';
import { PropertyService } from 'src/app/core/services/property.service';
import { BookingService } from 'src/app/core/services/booking.service';
import { Booking } from 'src/app/shared/models/booking';
import { Property } from 'src/app/shared/models/property';
import { BookingMessage } from '../models/booking-message.model';
import { ChatUtils } from '../utils/chat.utils';

@Injectable({
  providedIn: 'root',
})
export class ChatStore {
  conversations = new Map<string, Conversation>();

  user: User;

  private readonly conversationChangesSubject: Subject<Conversation>;
  private readonly messageChangesSubject: Subject<Message>;
  private readonly openConversationIdsSubject: BehaviorSubject<string[]>;
  private readonly propertiesSubject: BehaviorSubject<Property[]>;
  private readonly bookingsSubject: BehaviorSubject<Booking[]>;
  private readonly conversationIdContextLoadedSubject: BehaviorSubject<string>;

  conversationChanges$: Observable<Conversation>;
  messageChanges$: Observable<Message>;
  openConversationIds$: Observable<string[]>;

  unreadConversationIds$: Observable<string[]>;

  conversationIdContextLoaded$: Observable<string>;
  properties$: Observable<Property[]>;
  bookings$: Observable<Booking[]>;

  constructor(
    private readonly chatApi: ChatApi,
    private readonly gatewayService: GatewayService,
    private readonly userService: UserService,
    private readonly userConversationsService: UserConversationsService,
    private readonly propertyService: PropertyService,
    private readonly bookingService: BookingService,
  ) {
    this.conversationChangesSubject = new Subject<Conversation>();
    this.conversationChanges$ = this.conversationChangesSubject.asObservable();
    this.messageChangesSubject = new Subject<Message>();
    this.messageChanges$ = this.messageChangesSubject.asObservable();
    this.openConversationIdsSubject = new BehaviorSubject<string[]>([]);
    this.openConversationIds$ = this.openConversationIdsSubject.asObservable();
    this.propertiesSubject = new BehaviorSubject<Property[]>([]);
    this.properties$ = this.propertiesSubject.asObservable();
    this.bookingsSubject = new BehaviorSubject<Booking[]>([]);
    this.bookings$ = this.bookingsSubject.asObservable();
    this.conversationIdContextLoadedSubject = new BehaviorSubject<string>(null);
    this.conversationIdContextLoaded$ =
      this.conversationIdContextLoadedSubject.asObservable();

    this.userService.getCurrentUser().subscribe(user => {
      this.user = user;
    });

    this.unreadConversationIds$ = combineLatest([
      this.openConversationIds$,
      this.userConversationsService.unreadConversationIds$,
    ]).pipe(
      map(([openConversationIds, unreadConversation]) => {
        return unreadConversation.filter(
          unread => !openConversationIds.includes(unread),
        );
      }),
    );

    this.gatewayService.fromEvent(ChatMessageGatewayEvent).subscribe(event => {
      const conversationId = event.conversationId;
      const message = event.message;
      const conversation = this.conversations.get(conversationId);
      if (conversation) {
        conversation.lastMessageAt = message.sentAt;
        if (message.type === MessageType.TEXT) {
          conversation.lastMessageText = (message as TextMessage).text;
        } else if (message.type === MessageType.INQUIRY) {
          conversation.lastMessageText = (
            message as InquiryMessage
          ).payload.text;
        }
        conversation.open = this.isConversationOpen(conversation.id);
        conversation.participants.forEach(p => (p.archived = false));
        if (this.isConversationOpen(conversationId)) {
          conversation.participants.forEach(
            p => (p.lastReadAt = message.sentAt),
          );
        } else {
          conversation.participants.forEach(p => (p.lastReadAt = new Date(0)));
        }

        if (message.type === MessageType.INQUIRY) {
          const propertyId = (message as InquiryMessage).payload.propertyId;
          this.updateConversationContext(
            conversation,
            (c: ConversationContext) =>
              c.propertyId === propertyId && !c.bookingId,
            { propertyId },
            message.sentAt,
          );
        } else if (ChatUtils.isBookingMessage(message)) {
          const payload = (message as BookingMessage).payload;
          this.updateConversationContext(
            conversation,
            (c: ConversationContext) =>
              c.propertyId === payload.propertyId &&
              c.bookingId === payload.bookingId,
            { propertyId: payload.propertyId, bookingId: payload.bookingId },
            message.sentAt,
          );
        }

        this.emitConversationChange(conversation);
      } else {
        this.getConversation(conversationId).subscribe(loadedConversation => {
          loadedConversation.open = this.isConversationOpen(
            loadedConversation.id,
          );
          if (this.isConversationOpen(loadedConversation.id)) {
            loadedConversation.participants.forEach(
              p => (p.lastReadAt = new Date()),
            );
          }
          this.emitConversationChange(loadedConversation);
        });
      }
    });
  }

  private updateConversationContext(
    conversation: Conversation,
    findFunction: (context: ConversationContext) => boolean,
    updatedContext: ConversationContext,
    sentAt: Date,
  ) {
    const index = conversation.context.findIndex(c => findFunction(c));
    if (index < 0) {
      conversation.context = [
        ...conversation.context,
        {
          ...updatedContext,
          lastOpenedAt: sentAt,
        },
      ];
    } else {
      conversation.context[index].lastOpenedAt = sentAt;
    }
  }

  getConversation(conversationId: string): Observable<Conversation> {
    return this.chatApi
      .getConversation({ conversationId: conversationId })
      .pipe(
        tap(conversation => {
          conversation.open = this.isConversationOpen(conversation.id);
          this.conversations.set(conversation.id, conversation);
        }),
      );
  }

  getConversations(
    options: GetConversationsOptions,
  ): Observable<PagedModel<Conversation>> {
    return this.chatApi.getConversations(options).pipe(
      tap(pagedConversations => {
        for (const conversation of pagedConversations.data) {
          this.conversations.set(conversation.id, conversation);
          conversation.open = this.isConversationOpen(conversation.id);
          if (this.isConversationOpen(conversation.id)) {
            conversation.participants.forEach(p => (p.lastReadAt = new Date()));
          }
        }
      }),
    );
  }

  private emitConversationChange(conversation: Conversation): void {
    this.conversationChangesSubject.next(conversation);
  }

  public loadContextInfomations(conversation: Conversation) {
    this.conversationIdContextLoaded$
      .pipe(take(1))
      .subscribe(loadedConversationId => {
        if (!conversation || loadedConversationId === conversation.id) {
          return;
        }

        this.conversationIdContextLoadedSubject.next(null);

        const propertyIds = conversation.context
          .filter(c => c.propertyId && !c.bookingId)
          .map(c => c.propertyId);
        const bookingIds = conversation.context.filter(c => c.bookingId);

        combineLatest([
          propertyIds.length
            ? combineLatest(
                propertyIds.map(p => this.propertyService.getProperty(p)),
              )
            : of([]),
          bookingIds.length
            ? combineLatest(
                bookingIds.map(p =>
                  this.bookingService.getBookingById(p.bookingId),
                ),
              )
            : of([]),
        ])
          .pipe(take(1))
          .subscribe({
            next: ([properties, bookings]) => {
              this.propertiesSubject.next(properties);
              this.bookingsSubject.next(bookings);
              this.conversationIdContextLoadedSubject.next(conversation.id);
            },
            error: () => {
              this.propertiesSubject.next([]);
              this.bookingsSubject.next([]);
              this.conversationIdContextLoadedSubject.next(conversation.id);
            },
          });
      });
  }

  public updateBookingInContext(conversationId: string, bookingId: string) {
    this.conversationIdContextLoaded$.pipe(take(1)).subscribe(loadedId => {
      if (loadedId === conversationId) {
        this.updateContextInfo(
          bookingId,
          this.bookings$,
          id => this.bookingService.getBookingById(id),
          this.bookingsSubject,
        );
      }
    });
  }

  public updatePropertyInContext(conversationId: string, propertyId: string) {
    this.conversationIdContextLoaded$.pipe(take(1)).subscribe(loadedId => {
      if (loadedId === conversationId) {
        this.updateContextInfo(
          propertyId,
          this.properties$,
          id => this.propertyService.getProperty(id),
          this.propertiesSubject,
        );
      }
    });
  }

  private updateContextInfo<T extends { id?: string }>(
    id: string,
    currentInfo: Observable<T[]>,
    getUpdatedItem: (id: string) => Observable<T>,
    subject: BehaviorSubject<T[]>,
  ) {
    combineLatest([currentInfo, getUpdatedItem(id)])
      .pipe(take(1))
      .subscribe(([items, updatedInfo]) => {
        const index = items.findIndex(i => i.id === id);
        const updatedItems = [...items];
        if (index < 0) {
          updatedItems.push(updatedInfo);
        } else {
          updatedItems[index] = { ...updatedInfo };
        }
        subject.next(updatedItems);
      });
  }

  onConversationOpen(conversationId: string): void {
    const openConversationIds = new Set(
      this.openConversationIdsSubject.getValue(),
    );

    openConversationIds.add(conversationId);
    this.openConversationIdsSubject.next(Array.from(openConversationIds));
    const conversation = this.conversations.get(conversationId);
    if (conversation) {
      conversation.open = true;
      const currentParticipant = conversation.participants.find(
        p => p.userId === this.user.id,
      );
      if (currentParticipant) {
        currentParticipant.lastReadAt = new Date();
      }

      this.emitConversationChange(conversation);
    }
  }

  onConversationClose(conversationId: string): void {
    const openConversationIds = new Set(
      this.openConversationIdsSubject.getValue(),
    );
    openConversationIds.delete(conversationId);
    this.openConversationIdsSubject.next(Array.from(openConversationIds));

    const conversation = this.conversations.get(conversationId);
    if (conversation) {
      conversation.open = false;
      this.emitConversationChange(conversation);
    }
  }

  isConversationOpen(conversationId: string): boolean {
    return this.openConversationIdsSubject.getValue().includes(conversationId);
  }

  archiveConversation(conversation: Conversation): Observable<Conversation> {
    return this.chatApi
      .archiveConversation({
        conversationId: conversation.id,
      })
      .pipe(
        tap(updatedConversation => {
          updatedConversation.open = this.isConversationOpen(
            updatedConversation.id,
          );
          this.conversations.set(updatedConversation.id, updatedConversation);
          this.emitConversationChange(updatedConversation);
        }),
      );
  }

  readConversation(conversation: Conversation) {
    this.chatApi
      .readConversation({ conversationId: conversation.id })
      .subscribe();
  }
}
