import { ParentComponent } from '../../../../../libs/component-lib/components/parent.component';
import {
  Directive,
  Host,
  Input,
  OnInit,
  Optional,
  SkipSelf,
} from '@angular/core';
import {
  ControlContainer,
  UntypedFormArray,
  UntypedFormControl,
} from '@angular/forms';
import {
  CheckboxOption,
  OptionValue,
} from '../amenity-group-accordion/amenity-group-accordion.component';
import { Amenity, AmenityType } from '../../../../../shared/models/amenity';
import isArray from 'lodash-es/isArray';
import isEqual from 'lodash-es/isEqual';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AmenityParentComponent
  extends ParentComponent
  implements OnInit
{
  @Input()
  options: Amenity[] = [];

  @Input()
  labelTooltip: string = null;

  @Input()
  minimalStandardGroup = false;

  @Input()
  visibleOptionsCount: number = undefined;

  visibleOptions: CheckboxOption[] = [];
  hiddenOptions: CheckboxOption[] = [];
  selectAllControl: UntypedFormControl = new UntypedFormControl();
  loaded = false;

  constructor(
    @Host() @SkipSelf() @Optional() parentControlContainer: ControlContainer,
  ) {
    super(parentControlContainer);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.options = this.options ? this.options : [];

    this.model$.subscribe((amenities: OptionValue[]) => {
      if (amenities) {
        const filteredAmenities: OptionValue[] = [];
        this.options.forEach(option => {
          const amenity = amenities.find(a => a.id === option.id);
          if (!amenity) {
            return;
          }
          let value = amenity.value;
          if (this.isOptionSelected(value)) {
            switch (option.type) {
              case AmenityType.SINGLE:
                value = !!value;
                break;
              case AmenityType.SELECT:
                if (typeof value === 'boolean') {
                  if (value) {
                    value = option.defaultOption;
                  }
                } else if (typeof value !== 'number') {
                  value = false;
                }
                break;
              case AmenityType.MULTIPLE:
                if (value === true) {
                  value = [];
                } else if (!isArray(value)) {
                  value = false;
                }
                break;
            }
            filteredAmenities.push({ id: option.id, value });
          }
        });
        if (!isEqual(amenities, filteredAmenities)) {
          this.writeValue(filteredAmenities);
          return;
        }
        this.applyValues(filteredAmenities);
      } else {
        this.applyValues(null);
      }
    });

    this.selectAllControl.valueChanges
      .pipe(this.untilDestroyed())
      .subscribe(selectAllValue => {
        if (selectAllValue) {
          this.writeValue(
            this.options.map(option => {
              switch (option.type) {
                case AmenityType.SINGLE:
                  return { id: option.id, value: true };
                case AmenityType.SELECT:
                case AmenityType.MULTIPLE:
                  return this.getAmenity(option);
              }
            }),
          );
        } else {
          this.writeValue([]);
        }
      });
  }

  isOptionSelected(value: any): boolean {
    if (typeof value === 'boolean') {
      return value;
    }
    if (typeof value === 'number') {
      return true;
    }
    return isArray(value);
  }

  applyValues(selectedAmenities: OptionValue[]) {
    if (!selectedAmenities) {
      this.selectAllControl.reset(false, {
        emitEvent: false,
      });
      for (const checkbox of this.visibleOptions) {
        checkbox.control.reset(false, { emitEvent: false });
        checkbox.optionControl.reset(undefined, { emitEvent: false });
      }
      return;
    }
    if (!this.loaded) {
      this.loaded = true;
      this.initCheckboxes(selectedAmenities);
    }
    // Set selectAllControl value
    const isAllOptionsSelected = this.isAllOptionsSelected(selectedAmenities);
    if (isAllOptionsSelected !== this.selectAllControl.value) {
      this.selectAllControl.reset(isAllOptionsSelected, {
        emitEvent: false,
      });
    }

    // Add all options to visible/hidden helper arrays
    this.options.forEach(option => {
      const amenity = selectedAmenities.find(s => s.id === option.id);
      const isSelected = !!amenity && this.isOptionSelected(amenity.value);
      const isVisible = this.visibleOptions.some(o => o.id === option.id);
      const amenityOption = (
        isVisible ? this.visibleOptions : this.hiddenOptions
      ).find(o => o.id === option.id);

      switch (option.type) {
        case AmenityType.SINGLE:
          amenityOption.control.reset(isSelected, { emitEvent: false });
          break;
        case AmenityType.SELECT:
          amenityOption.control.reset(isSelected, { emitEvent: false });
          if (isSelected) {
            amenityOption.optionControl.reset(amenity.value, {
              emitEvent: false,
            });
          } else {
            amenityOption.optionControl.reset(undefined, { emitEvent: false });
          }
          break;
        case AmenityType.MULTIPLE:
          amenityOption.control.reset(isSelected, { emitEvent: false });
          if (isSelected) {
            amenityOption.optionControl.reset(
              option.options.map(o =>
                (amenity.value as number[]).includes(o.id),
              ),
              { emitEvent: false },
            );
          } else {
            amenityOption.optionControl.reset([], { emitEvent: false });
          }
          break;
      }
    });
  }

  initCheckboxes(selected: OptionValue[]) {
    this.options.forEach(option => {
      const control = new UntypedFormControl(undefined);
      let optionControl;
      switch (option.type) {
        case AmenityType.SINGLE:
          optionControl = undefined;
          break;
        case AmenityType.SELECT:
          optionControl = new UntypedFormControl(undefined);
          break;
        case AmenityType.MULTIPLE:
          optionControl = new UntypedFormArray(
            option.options.map(_ => new UntypedFormControl(false)),
          );
          break;
      }
      const checkboxOption: CheckboxOption = {
        control,
        optionControl,
        ...option,
      };
      if (
        this.isOptionSelected(selected[option.id]) ||
        !this.visibleOptionsCount ||
        this.visibleOptionsCount > this.visibleOptions.length
      ) {
        this.visibleOptions.push(checkboxOption);
      } else {
        this.hiddenOptions.push(checkboxOption);
      }
      control.valueChanges.subscribe(value => {
        switch (option.type) {
          case AmenityType.SELECT:
            if (value) {
              optionControl.reset(checkboxOption.defaultOption, {
                emitEvent: false,
              });
            } else {
              optionControl.reset(undefined, { emitEvent: false });
            }
            break;
          case AmenityType.MULTIPLE:
            if (!value) {
              optionControl.reset(
                option.options.map(_ => false),
                { emitEvent: false },
              );
            }
            break;
        }
        this.writeValue(this.getOptionObject());
      });

      if (optionControl) {
        optionControl.valueChanges.subscribe(value => {
          if (option.type === AmenityType.MULTIPLE) {
            if (value.some(v => v)) {
              control.reset(true, { emitEvent: false });
            }
          } else {
            if (value) {
              control.reset(true, { emitEvent: false });
            }
          }
          this.writeValue(this.getOptionObject());
        });
      }
    });
  }

  getSelectedAmenityLabels(option: CheckboxOption): string[] {
    const amenityValues = [];
    const controlValue = option.optionControl?.value;
    if (controlValue) {
      switch (option.type) {
        case AmenityType.SELECT:
          if (option.defaultOption !== controlValue) {
            amenityValues.push(
              option.options.find(o => o.id === controlValue)?.label,
            );
          }
          break;
        case AmenityType.MULTIPLE:
          controlValue.forEach((value, i) => {
            if (value) {
              amenityValues.push(option.options[i].label);
            }
          });
          break;
      }
    }
    return amenityValues;
  }

  getOptionObject(): OptionValue[] {
    const formValue = [];
    this.visibleOptions.forEach(option => {
      let value;
      switch (option.type) {
        case AmenityType.SINGLE:
          value = !!option.control.value;
          break;
        case AmenityType.SELECT:
          if (option.control.value) {
            value = option.optionControl.value;
          } else {
            value = false;
          }
          break;
        case AmenityType.MULTIPLE:
          if (option.control.value) {
            value = option.options
              .filter((_, i) => option.optionControl.value[i])
              .map(o => o.id);
          } else {
            value = false;
          }
          break;
      }
      if (value) {
        formValue.push({ id: option.id, value });
      }
    });
    return formValue;
  }

  isAllOptionsSelected(selectedAmenities: OptionValue[]): boolean {
    return this.options.length === selectedAmenities.length;
  }

  /** If "Vacay Standard" checkbox is checked - keep also every sub-options of amenity if the amenity was not unchecked */
  getAmenity(option: Amenity) {
    if (this.model$ && this.model$.getValue().length > 0) {
      const checkedAmenity = this.model$
        .getValue()
        .filter(value => value.id === option.id);
      if (checkedAmenity?.length) {
        return checkedAmenity.map(amenity => {
          if (amenity) {
            return { id: amenity.id, value: amenity.value };
          } else {
            return this.getDefaultAmenity(option);
          }
        })[0];
      }
    }
    return this.getDefaultAmenity(option);
  }

  getDefaultAmenity(option: Amenity) {
    if (option.type === AmenityType.SELECT) {
      return { id: option.id, value: option.defaultOption };
    } else if (option.type === AmenityType.MULTIPLE) {
      return { id: option.id, value: [] };
    }
  }
}
