import { Pipe, PipeTransform } from '@angular/core';
import { PropertyAddress } from '../../shared/models/property';

export type FormatAddressVariant = 'short' | 'long';

@Pipe({ name: 'addressFormat' })
export class AddressFormatPipe implements PipeTransform {
  transform(
    address: PropertyAddress,
    variant: FormatAddressVariant = 'short',
  ): string {
    if (!address) {
      return undefined;
    }
    return variant === 'short' || !address.street2
      ? `${address.street1}, ${address.city}, ${address.state}`
      : `${address.street1}, ${address.city}, ${address.state}, ${address.street2}`;
  }
}
