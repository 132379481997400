import { PropertyStatus } from './property-status';
import { AmenityValue, PropertyAmenity } from './amenity';
import { PropertyWizardSectionKey } from './property-form.model';
import { IntegrationStatus } from './integration-status.model';
import { Time } from '../../utils/time';
import { Address } from './address.model';
import { SelectList } from './selectlist.model';

export class LonLatPosition {
  lat?: number;
  lon?: number;
}

export class PropertyAddress implements Partial<Address> {
  location?: LonLatPosition;
  street1?: string;
  street2?: string;
  city?: string;
  postalCode?: string;
  state?: string;
  country?: string;
}

export class Property {
  id?: string;
  ownerId: string;
  createdAt?: Date;
  updatedAt?: Date;
  status?: PropertyStatus;
  isOwner?: boolean;
  visitedWizardSections?: PropertyWizardSectionKey[];
  protection?: Protection;
  propertyValidity?: PropertyValidity;
  registrationNumber?: string;
  registrationExpirationDate?: Date;
  rentalAgreementUrl?: string;
  // Property info
  address?: PropertyAddress;
  headline?: string;
  description?: string;
  propertyType?: string;
  bathroomsFull?: number;
  bathroomsHalf?: number;
  bedrooms?: number;
  beds?: {
    total: number;
    subTypes: PropertyBedType[];
  };
  guests?: number;
  maxPets?: number;
  amenities?: PropertyAmenity[];
  amenityMinimalStandard?: boolean;
  propertyImages?: PropertyImage[];
  houseRules?: string;
  instructions?: string;
  instantBookingAllowed?: boolean;
  // Pricing
  pricePerNight?: number;
  pricePerNightWeekends?: number;
  weeklyDiscountPercent?: number;
  monthlyDiscountPercent?: number;
  cleaningFee?: number;
  taxRatePercent?: number;
  // Reviews
  /** percentage 0.0 - 1.0 */
  reviewScore?: number;
  cancellationPolicy?: string;
  // Integration
  integrationPartner?: string;
  integrationPropertyId?: string;
  integrationStatus?: IntegrationStatus;
  lengthOfStay?: LengthOfStay;
  weekendLengthOfStay?: LengthOfStay;
  bookingRules?: PropertyBookingRules;
  bookingStats?: PropertyBookingStats;
  tinyUrl?: string;
}

export class PropertyPreview {
  id: string;
  headline: string;
  address: PropertyAddress;
  pricePerNight: number;
  propertyImage?: PropertyImage;
}

export class PropertyBedType {
  type: string;
  count: number;
}

export enum InvalidPropertyReasonType {
  NO_USD_PRICE = 'no_usd_price',
  UNSUPPORTED_CLASSIFICATION = 'unsupported_classification',
  UNSUPPORTED_TAX_TYPE = 'unsupported_tax_type',
  UNSUPPORTED_FEE_TYPE = 'unsupported_fee_type',
  UNSUPPORTED_PROPERTY_TYPE = 'unsupported_property_type',
  HIDDEN_FEE_PRESENT = 'hidden_fee_present',
  INCOMPLETE_ADDRESS = 'incomplete_address',
  UNSUPPORTED_CANCELLATION_POLICY = 'unsupported_cancellation_policy',
  ERROR_DURING_IMPORT = 'error_during_import',
  UNSUPPORTED_DAMAGE_DEPOSIT = 'unsupported_damage_deposit',
  UNSUPPORTED_BOOKING_POLICY_TYPE = 'unsupported_booking_policy_type',
  UNSUPPORTED_FEATURE = 'unsupported_feature',
}

export interface PropertyValidity {
  isValid: boolean;
  invalidReasons: {
    type: InvalidPropertyReasonType;
    message?: string;
  }[];
}

export class PropertyUpdateDto {
  // Property info
  address?: PropertyAddress;
  headline?: string;
  description?: string;
  propertyType?: string;
  bathroomsFull?: number;
  bathroomsHalf?: number;
  bedrooms?: number;
  beds?: {
    total: number;
    //subTypes: any,
  };
  guests?: number;
  petsAllowed?: number;
  amenities?: {
    id: string;
    value: AmenityValue;
  }[];
  propertyImages?: PropertyImage[];
  houseRules?: string;
  instructions?: string;
  instantBookingAllowed?: boolean;
  // Pricing
  pricePerNight?: number;
  pricePerNightWeekends?: number;
  weeklyDiscountPercent?: number;
  monthlyDiscountPercent?: number;
  cleaningFee?: number;
  taxRatePercent?: number;
  cancellationPolicy?: string;
  visitedWizardSections?: PropertyWizardSectionKey[];
  protection?: Protection;
  lengthOfStay?: LengthOfStay;
  bookingRules?: PropertyBookingRules;
}

export interface LengthOfStay {
  min?: number;
  max?: number;
}

export interface PropertyBookingRules {
  leadDays: number;
  sameDayLeadTime?: Time;
}

export interface PropertyBookingStats {
  authorizedBookings: number;
  approvedBookings: number;
}

export interface IndividualLengthOfStay {
  date: Date;
  lengthOfStay: LengthOfStay;
}

export class PropertyImage {
  featured?: boolean;
  imageUrl?: string;
  label?: string;
  room?: number;
  position?: number;
}

export type PropertyType = SelectList;

export interface PropertyStatistics {
  numberOfViews: number;
  timeStamp: number;
}

export interface Protection {
  isProtected?: boolean;
  variant?: ProtectionVariant;
}

export enum ProtectionVariant {
  FULL = 'full',
  PARTIAL = 'partial',
  NONE = 'none',
}

// cancellation policies
export interface RefundLevel {
  refundName?: string;
  minHoursBeforeStay: number;
  totalPricePercent?: number;
  stayPricePercent?: number;
  cleaningFeePercent?: number;
}

export interface CancellationRule {
  minLengthOfStay: number;
  refunds: RefundLevel[];
}

export interface CancellationPolicy {
  id: string;
  name: string;
  rules: CancellationRule[];
}

export interface PropertiesBulkActionResult {
  updatedProperties: number;
}
