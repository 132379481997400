import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../core/core.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import {
  ChristmasThemeIconDirective,
  HomeNounsComponent,
} from './components/home-nouns/home-nouns.component';
import { HomePicksComponent } from './components/home-picks/home-picks.component';
import { HomeGetStartedComponent } from './components/home-get-started/home-get-started.component';
import { HomePropertySearchComponent } from './components/home-property-search/home-property-search.component';
import { FooterComponent } from '@components/footer/footer.component';
import { HomeOfferComponent } from './components/home-offer/home-offer.component';
import { HomeInspirationComponent } from './components/home-inspiration/home-inspiration.component';
import { NavbarModule } from '../navbar/navbar.module';
import { HomePartnersComponent } from './components/home-partners/home-partners.component';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';
import { IconModule } from '@components/icon/icon.module';
import { TypographyModule } from '@components/typography/typography.module';
import { ButtonModule } from '@components/button/button.module';
import { InputModule } from '@components/input/input.module';
import { InputGuestsModule } from '@components/input-guests/input-guests.module';
import { InputLocationModule } from '@components/input-location/input-location.module';
import { InputCalendarComponent } from '@components/input-calendar/input-calendar.component';
import { PropertySearchDialogModule } from '@components/property-search-dialog/property-search-dialog.module';
import { MenuUserPanelComponent } from '../navigation/menu-user-panel/menu-user-panel.component';
import { HomeSectionHeaderComponent } from './components/home-section-header/home-section-header.component';
import { PicksBlockComponent } from './components/home-picks-block/home-picks-block.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { HomePicksBadgeComponent } from './components/home-picks/home-picks-badge/home-picks-badge.component';

@NgModule({
  declarations: [
    HomeComponent,
    HomeNounsComponent,
    HomePicksComponent,
    PicksBlockComponent,
    HomeGetStartedComponent,
    HomePropertySearchComponent,
    HomeSectionHeaderComponent,
    HomeOfferComponent,
    HomeInspirationComponent,
    HomePartnersComponent,
    ChristmasThemeIconDirective,
  ],
  imports: [
    CommonModule,
    CoreModule,
    ReactiveFormsModule,
    RouterModule,
    NavbarModule,
    TranslocoModule,
    IconModule,
    TypographyModule,
    ButtonModule,
    InputModule,
    InputLocationModule,
    InputGuestsModule,
    InputCalendarComponent,
    PropertySearchDialogModule,
    FooterComponent,
    MenuUserPanelComponent,
    MatProgressSpinner,
    HomePicksBadgeComponent,
  ],
  exports: [FooterComponent],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: { scope: 'home-page', alias: 'home-page' },
    },
  ],
})
export class HomeModule {}
