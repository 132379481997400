import { isNil } from 'lodash-es';
import { defer, Observable, throwError } from 'rxjs';
import { UserNotFoundError } from '../../utils/errors';
import { catchError } from 'rxjs/operators';

/**
 * Map object of params to key-value string object for angular HTTP client
 */
export function plainToParams(params: { [key: string]: any }): {
  [key: string]: string;
} {
  if (!params) {
    return {};
  }
  if (typeof params !== 'object') {
    console.warn(
      `'Argument 'params' must be an object. Type: ${typeof params}'`,
    );
    return {};
  }
  const result = {};
  Object.entries(params).forEach(([key, value]) => {
    if (isNil(value)) {
      return;
    }
    if (value instanceof Date) {
      result[key] = value.toISOString();
      return;
    }
    result[key] = String(value);
  });
  return result;
}

export function processSortQuery(params: {
  [key: string]: any;
  sortBy?: string;
  sortDirection?: string;
}): {
  [key: string]: any;
  sort?: string;
} {
  if (!params) {
    return {};
  }
  const sortBy = params.sortBy;
  const sortDirection = params.sortDirection;
  if (sortBy) {
    params.sort = `${sortBy},${sortDirection ?? 'asc'}`;
  }
  delete params.sortBy;
  delete params.sortDirection;
  return params;
}

export function handleGetUserError() {
  return <T>(source: Observable<T>) =>
    defer(() => {
      return source.pipe(
        catchError(e => {
          if (e.status === 404) {
            return throwError(
              new UserNotFoundError(e.error?.message || e.message),
            );
          }
          return throwError(e);
        }),
      );
    });
}
