import { Component } from '@angular/core';
import { BreakpointService } from '../../../services/breakpoint.service';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
})
export class NotFoundPageComponent {
  constructor(public readonly bs: BreakpointService) {}
}
