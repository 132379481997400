import type { LngLatLike } from 'mapbox-gl';
import { PropertyAddress } from './property';

export interface MapAddress {
  id?: string;
  place_name?: string;
  matching_place_name?: string;
  matching_text?: string;
  place_type?: LocationDataType[];
  text?: string;
  type?: string;
  center?: LngLatLike;
  bbox?: [number, number, number, number]; // minLon,minLat,maxLon,maxLat = AddressBBox
  context?: {
    id?: string;
    text?: string;
    short_code?: string;
    wikidata?: string;
  }[];
}
export enum AddressBBox {
  minLon = 0,
  minLat = 1,
  maxLon = 2,
  maxLat = 3,
}

/* DataTypes documentation: https://docs.mapbox.com/api/search/geocoding/#data-types */
export enum LocationDataType {
  COUNTRY = 'country',
  REGION = 'region',
  POSTCODE = 'postcode',
  DISTRICT = 'district',
  PLACE = 'place',
  LOCALITY = 'locality',
  NEIGHBORHOOD = 'neighborhood',
  ADDRESS = 'address',
  POI = 'poi',
}

export function convertMapAddressToPropertyAddress(
  mapAddress: MapAddress,
): PropertyAddress {
  return {
    location: {
      lat: mapAddress.center[1],
      lon: mapAddress.center[0],
    },
    street1: mapAddress.place_name.split(',')[0],
    city: mapAddress.context.find(context => context.id.startsWith('place.'))
      ?.text,
    postalCode: mapAddress.context.find(context =>
      context.id.startsWith('postcode.'),
    )?.text,
    state: mapAddress.context.find(context => context.id.startsWith('region.'))
      ?.text,
    country: mapAddress.context.find(context =>
      context.id.startsWith('country.'),
    )?.short_code,
  };
}
export function convertPropertyAddressToMapAddress(
  propertyAddress: PropertyAddress,
): MapAddress {
  if (propertyAddress) {
    return {
      place_name: `${propertyAddress.street1}, ${propertyAddress.city}, ${
        propertyAddress.state ?? ''
      } ${
        propertyAddress.postalCode
      }, ${propertyAddress.country.toUpperCase()}`,
      center: [
        propertyAddress.location?.lon ?? 0,
        propertyAddress.location?.lat ?? 0,
      ],
      context: [
        {
          id: 'country.' + propertyAddress.country,
          short_code: propertyAddress.country,
        },
        {
          id: 'region.' + propertyAddress.state,
          text: propertyAddress.state,
          // TODO keeping region code would be nice
        },
        {
          id: 'postcode.' + propertyAddress.postalCode,
          text: propertyAddress.postalCode,
        },
        {
          id: 'place.' + propertyAddress.city,
          text: propertyAddress.city,
        },
      ],
    };
  }
  return null;
}

export interface IpApiResponse {
  as: string;
  city: string;
  country: string;
  countryCode: string;
  isp: string;
  lat: string;
  lon: string;
  org: string;
  query: string;
  region: string;
  regionName: string;
  status: string;
  timezone: string;
  zip: string;
}
