import {
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { AbstractDialog } from '../../../../libs/component-lib/components/dialog/abstract-dialog';
import { BreakpointService } from '../../../../core/services/breakpoint.service';
import { handle4xxErrors } from '../../../../utils/error.util';
import { StorageKey } from '../../../../shared/enums/local-storage-key.enum';
import { NotificationService } from '../../../../core/services/notification.service';
import {
  PhoneService,
  PhoneVerificationIntent,
} from '../../../../core/services/phone.service';
import { AuthService } from '../../../../core/services/auth.service';
import { Router } from '@angular/router';
import { BlockUiService } from '../../../../core/services/block-ui.service';
import { UserService } from '../../../../core/services/user.service';
import { StorageService } from '../../../../core/services/storage.service';

@Component({
  selector: 'app-p1h0m89-verification',
  templateUrl: './phone-verification-dialog.component.html',
  styleUrls: ['./phone-verification-dialog.component.scss'],
})
export class PhoneVerificationDialogComponent
  extends AbstractDialog
  implements OnInit, OnDestroy
{
  /** Phone number input */
  phoneNumber: string;

  errors$ = new BehaviorSubject<string>(null);

  largeScreen: boolean;
  title = `Let's get you verified`;

  intervalForResendButton;
  resendButtonCountdown = 0;

  resetAndUpdateValidity = new Subject<void>();

  constructor(
    protected readonly bs: BreakpointService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly notificationService: NotificationService,
    protected readonly authService: AuthService,
    protected readonly router: Router,
    protected readonly blockUiService: BlockUiService,
    protected readonly userService: UserService,
    protected readonly phoneService: PhoneService,
    protected readonly storageService: StorageService,
    public dialogRef: MatDialogRef<PhoneVerificationDialogComponent>,
    injector: Injector,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    super(injector);
    this.phoneNumber = data.phoneNumber;
    if (data.title) {
      this.title = data.title;
    }
  }

  ngOnInit(): void {
    this.bs.largeScreen$.subscribe(ls => {
      this.largeScreen = ls;
      this.cdr.detectChanges();
    });

    this.userService
      .getCurrentUser()
      .pipe(this.untilDestroyed())
      .subscribe(user => {
        if (user?.phone?.validFrom) {
          this.dialogRef.close(user.phone);
        }
      });
  }

  ngOnDestroy() {
    this.errors$.complete();
    clearInterval(this.intervalForResendButton);
    super.ngOnDestroy();
  }

  getResendButtonString() {
    if (this.resendButtonCountdown > 0) {
      return 'Resend the code (' + this.resendButtonCountdown + 's)';
    } else {
      return 'Resend the code';
    }
  }

  /** Resend phone verification code. The resendButtonCountdown value is cooldown of the button in seconds */
  resendPhoneVerification() {
    this.resendButtonCountdown = 30;
    this.intervalForResendButton = setInterval(() => {
      if (this.resendButtonCountdown <= 0) {
        clearInterval(this.intervalForResendButton);
      } else {
        this.resendButtonCountdown--;
      }
    }, 1000);
    this.resetAndUpdateValidity.next();

    this.resendVerificationCodeSms();
  }

  resendVerificationCodeSms() {
    const phoneIntent: PhoneVerificationIntent = {
      id: this.getUnauthUserPhoneIdItem()?.phoneId,
      phoneNumber: this.phoneNumber,
    };
    this.phoneService
      .resendPhoneVerification(phoneIntent)
      .pipe(handle4xxErrors(this.errors$))
      .subscribe(() => this.showResendCodeNotification());
  }

  /** When user entered code and click on phone verification code submit button  */
  onSubmitPhoneVerification(data: string) {
    this.phoneService
      .verifyPhoneNumber({
        verificationCode: data,
        phoneVerificationId: this.getUnauthUserPhoneIdItem()?.phoneId,
      })
      .pipe(this.untilDestroyed(), handle4xxErrors(this.errors$))
      .subscribe(res => {
        this.dialogRef.close(res);
      });
  }

  getUnauthUserPhoneIdItem() {
    return JSON.parse(
      this.storageService.getItem(StorageKey.UNAUTH_VERIFY_PHONE_ID),
    );
  }

  protected showResendCodeNotification() {
    this.notificationService.showNotification({
      color: 'success',
      text: 'Verification code has been sent',
    });
  }
}
