import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class WindowSizeService {
  lastInnerHeight: number = undefined;

  constructor(@Inject(PLATFORM_ID) private readonly platformId: any) {}

  init() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.updateHeightCssVariable();
    window.addEventListener('resize', () => {
      this.updateHeightCssVariable();
      // This timeout solves iOS window resize problems
      setTimeout(() => {
        this.updateHeightCssVariable();
      }, 0);
    });
  }

  private updateHeightCssVariable() {
    const innerHeight = window.innerHeight;
    // Ignore non changed value
    if (this.lastInnerHeight === innerHeight) {
      return;
    }
    this.lastInnerHeight = innerHeight;
    const vh = innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}
