import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button.component';
import { IconModule } from '@components/icon/icon.module';

/**
 * Module for all variants of button components
 *
 * @author Libor Staněk
 */
@NgModule({
  declarations: [ButtonComponent],
  exports: [ButtonComponent],
  imports: [CommonModule, IconModule],
})
export class ButtonModule {}
