<ng-container *ngIf="user$ | async as user">
  <div class="chat-window">
    <div class="chat-window-header">
      <div class="d-flex">
        <button button variant="inline" color="secondary" icon="chevron_left" class="mr-8">
        </button>
        <p typography variant="em2" style="margin-top: 2px">{{getRecipient(conversation, user) | userFullName}}</p>
      </div>
      <div class="d-flex ml-auto">
        <button button variant="inline" color="secondary" icon="maximize" (click)="onMaximize()">
        </button>
        <button button variant="inline" color="secondary" icon="cross" class="ml-8" (click)="onClose()">
        </button>
      </div>
    </div>
    <app-chat-messages [conversation]="conversation" class="chat-window-messages"></app-chat-messages>
  </div>

</ng-container>
