import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { UserActivityApi } from '../api/user-activity.api';
import { UserService } from './user.service';
import { combineLatest, EMPTY, fromEvent, merge } from 'rxjs';
import { catchError, distinctUntilChanged, throttleTime } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UserActivityService {
  private readonly ACTIVITY_INTERVAL = 60_000; // 60 seconds

  private lastApplicationActivity: number = undefined;

  constructor(
    private readonly userService: UserService,
    private readonly userActivityApi: UserActivityApi,
    @Inject(PLATFORM_ID) private readonly platformId: any,
  ) {}

  /**
   * Initialize all activity listeners
   */
  init() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    // Send activity update on user login
    this.lastApplicationActivity = performance.now();
    this.userService
      .isAuthenticated()
      .pipe(distinctUntilChanged())
      .subscribe(isAuthenticated => {
        if (isAuthenticated) {
          this.updateUserActivity();
        }
      });
    // Listen for user activity events
    combineLatest([
      merge(
        fromEvent(window, 'click'),
        fromEvent(window, 'scroll'),
        fromEvent(window, 'keypress'),
      ).pipe(throttleTime(1000)),
      this.userService.isAuthenticated(),
    ]).subscribe(([event, isAuthenticated]) => {
      if (isAuthenticated) {
        this.checkUserActivity();
      }
    });
  }

  /**
   * Check if time to send next user activity update elapsed
   */
  checkUserActivity() {
    if (
      performance.now() - this.lastApplicationActivity <
      this.ACTIVITY_INTERVAL
    ) {
      return;
    }
    this.updateUserActivity();
  }

  /**
   * Send user activity update to notification service
   */
  updateUserActivity() {
    this.lastApplicationActivity = performance.now();
    this.userActivityApi
      .updateActivity()
      .pipe(catchError(() => EMPTY))
      .subscribe();
  }
}
