import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Injector,
  Input,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInputComponent } from '@components/abstract/abstract-input.component';

export interface Option {
  value: string | number;
  name: string;
}

@Component({
  selector: 'dropdown',
  styleUrls: ['../dropdown.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DropdownComponent,
      multi: true,
    },
  ],
  template: `
    <mat-form-field subscriptSizing="dynamic">
      <mat-label *ngIf="label">{{ label }}{{ required ? '*' : '' }}</mat-label>
      <mat-select
        [formControl]="control"
        [errorStateMatcher]="errorStateMatcher"
      >
        <mat-option
          *ngFor="let option of options"
          [value]="option.value"
          class="not-empty-select"
        >
          <span typography color="inherit">{{ option.name }}</span>
        </mat-option>
      </mat-select>
      <mat-error *ngIf="errorMessage$ | async as errorMessage">
        {{ errorMessage }}
      </mat-error>
    </mat-form-field>
  `,
})
export class DropdownComponent
  extends AbstractInputComponent<string | number>
  implements OnInit
{
  @Input()
  options: Option[];
  @Input()
  required: boolean;

  constructor(
    injector: Injector,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    const element = this.elementRef.nativeElement;

    this.renderer.addClass(element, 'dropdown2');
    this.disabled$.subscribe(disabled => {
      if (disabled) {
        this.renderer.addClass(element, `dropdown-disabled`);
      } else {
        this.renderer.removeClass(element, `dropdown-disabled`);
      }
    });
    if (!this.label) {
      this.renderer.addClass(element, `dropdown-no-label`);
    }
  }
}
