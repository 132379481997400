import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Host,
  Input,
  OnInit,
  Optional,
  Renderer2,
  SkipSelf,
} from '@angular/core';
import { Amenity, AmenityValue } from '../../../../../shared/models/amenity';
import {
  ControlContainer,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { AmenityParentComponent } from '../amenity-parent/amenity-parent.component';

export interface CheckboxOption extends Amenity {
  control: UntypedFormControl;
  optionControl: UntypedFormControl;
}

export interface OptionValue {
  id: number;
  value: AmenityValue;
}

@Component({
  selector: 'amenity-accordion',
  templateUrl: './amenity-group-accordion.component.html',
  styleUrls: ['./amenity-group-accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AmenityGroupAccordionComponent),
      multi: true,
    },
  ],
})
export class AmenityGroupAccordionComponent
  extends AmenityParentComponent
  implements OnInit
{
  @Input()
  selectAll = false;

  @Input()
  title: string;

  @Input()
  multiple = true;

  @Input()
  open = false;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    @Host() @SkipSelf() @Optional() parentControlContainer: ControlContainer,
  ) {
    super(parentControlContainer);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  toggleOpen() {
    this.open = !this.open;
  }
}
