import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { CategoryLink } from '../../../../core/services/content.service';
import { BreakpointService } from '../../../../core/services/breakpoint.service';

@Component({
  selector: 'app-home-picks',
  templateUrl: './home-picks.component.html',
  styleUrls: ['./home-picks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePicksComponent
  extends AbstractComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @ViewChild('scrollRef', { static: true })
  scrollRef: ElementRef<HTMLDivElement>;
  showLeftArrow = false;
  showRightArrow = false;
  private _picks: CategoryLink[] = [];

  private readonly largeScreen$ = this.bs.screenBreakpoint$;
  private isDesktop = false;

  @Input() descriptionTemplate: TemplateRef<any>;

  @Input() set picks(value: CategoryLink[]) {
    this._picks = value.map(pick => {
      const urlArr = pick.url.split('?');
      let queryParams = undefined;

      if (urlArr.length > 0 && urlArr[1]) {
        const query = decodeURI(urlArr[1]);
        queryParams = query.split('&').reduce((agg, qp) => {
          const [key, val] = qp.split('=');
          return { ...agg, [key]: val };
        }, {});
      }

      return {
        ...pick,
        url: urlArr[0],
        params: queryParams,
      } as CategoryLink;
    });
  }

  get picks(): CategoryLink[] {
    return this._picks;
  }

  constructor(
    private readonly changeDetectionRef: ChangeDetectorRef,
    private readonly bs: BreakpointService,
  ) {
    super();
  }

  ngOnInit(): void {
    fromEvent(this.scrollRef.nativeElement, 'scroll')
      .pipe(startWith(), debounceTime(100), this.untilDestroyed())
      .subscribe(() => this.updateArrows());

    this.largeScreen$
      .pipe(map(breakpoint => this.bs.isBreakpointUpOrSame(breakpoint, 'md')))
      .subscribe(isDesktop => {
        this.isDesktop = isDesktop;
        this.updateArrows();
        this.changeDetectionRef.detectChanges();
      });
  }

  ngOnChanges(): void {
    this.changeDetectionRef.detectChanges();
    this.updateArrows();
  }

  ngAfterViewInit() {
    // Force browser to calculate initial width of top picks
    this.scrollRef.nativeElement.scrollLeft = 1;
    this.scrollRef.nativeElement.scrollLeft = 0;
  }

  private updateArrows() {
    const element = this.scrollRef.nativeElement;
    const showLeftArrow = element.scrollLeft > 0;
    const showRightArrow =
      element.scrollLeft + element.clientWidth < element.scrollWidth;

    if (!this.isDesktop) {
      element.style.maskImage = 'none';
    } else {
      if (showLeftArrow && showRightArrow) {
        element.style.maskImage = `linear-gradient(
        to right, transparent 0%, black 9.125%,
        black 90.875%, transparent 100%)`;
      } else if (showRightArrow) {
        element.style.maskImage = `linear-gradient(to right, black 90.875%, transparent)`;
      } else if (showLeftArrow) {
        element.style.maskImage = `linear-gradient(to left, black 90.875%, transparent)`;
      } else {
        element.style.maskImage = 'none';
      }
    }
    if (
      this.showLeftArrow !== showLeftArrow ||
      this.showRightArrow !== showRightArrow
    ) {
      this.showLeftArrow = showLeftArrow;
      this.showRightArrow = showRightArrow;
      this.changeDetectionRef.detectChanges();
    }
  }

  onLeftArrow() {
    const element = this.scrollRef.nativeElement;
    element.scrollTo({
      left: element.scrollLeft - element.clientWidth,
      behavior: 'smooth',
    });
  }

  onRightArrow() {
    const element = this.scrollRef.nativeElement;
    element.scrollTo({
      left: element.scrollLeft + element.clientWidth,
      behavior: 'smooth',
    });
  }
}
