import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Booking,
  BookingCalendarInfoInput,
  BookingCalendarInfoResponse,
  BookingCancelDetail,
  BookingInterval,
  BookingPayRequest,
  BookingPayRequestResponse,
  BookingReservationStatus,
  CreateBookingRequest,
  BookingPricing,
  UpdateBookingRequest,
} from '../../shared/models/booking';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { format } from 'date-fns';
import { DATE_FORMAT } from '../../utils/date.util';
import { isEqual } from 'lodash-es';
import { SortOptions } from 'src/app/libs/component-lib/components/dialog/mobile-sort-dialog.component';

/** Booking API endpoint base path. */
const BOOKING_API_BASE = '/pricing/api/v1/bookings';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  private readonly bookingSubject = new BehaviorSubject<Booking>(null);
  public readonly booking$ = this.bookingSubject.asObservable();

  constructor(private readonly http: HttpClient) {}

  /**
   * Should be called with init app within booking.
   */
  loadBookingById(bookingId) {
    if (bookingId !== this.bookingSubject.getValue()?.id) {
      this.bookingSubject.next(null);
    }
    return this.getBookingById(bookingId).pipe(
      tap(booking => {
        if (!isEqual(this.bookingSubject.getValue(), booking)) {
          this.bookingSubject.next(booking);
        }
      }),
    );
  }

  public getCurrentBooking() {
    return this.bookingSubject.getValue();
  }

  getBookingById(bookingId) {
    return this.http.get<Booking>(`${BOOKING_API_BASE}/${bookingId}`).pipe(
      map(booking => {
        if (!booking?.discounts) {
          return booking;
        }
        let discountAmount = 0;
        discountAmount += booking.discounts.rewardPoints?.discountAmount ?? 0;
        booking.totalPrice -= discountAmount;
        return booking;
      }),
    );
  }

  getUserBookings(
    userId: string,
    status: BookingReservationStatus,
    page: number,
    pageSize = 10,
    sort?: SortOptions,
  ) {
    const sortOptions = sort ? { sort: `${sort.field},${sort.direction}` } : {};

    return this.http.get<Booking[]>(`${BOOKING_API_BASE}`, {
      params: {
        status,
        userId,
        page: page.toString(),
        size: pageSize.toString(),
        ...sortOptions,
      },
    });
  }

  preReservation(createBooking: CreateBookingRequest): Observable<Booking> {
    createBooking.interval = this.mapIntervalDatesToString(
      createBooking.interval,
    );
    return this.http
      .post<Booking>(`${BOOKING_API_BASE}/pre-reservation`, createBooking)
      .pipe(
        map(booking => {
          if (!booking?.discounts) {
            return booking;
          }
          let discountAmount = 0;
          discountAmount += booking.discounts.rewardPoints?.discountAmount ?? 0;
          booking.totalPrice -= discountAmount;
          return booking;
        }),
      );
  }

  createBooking(createBooking: CreateBookingRequest) {
    this.bookingSubject.next(null);
    createBooking.interval = this.mapIntervalDatesToString(
      createBooking.interval,
    );
    return this.http.post<Booking>(`${BOOKING_API_BASE}`, createBooking).pipe(
      map(booking => {
        if (!booking?.discounts) {
          return booking;
        }
        let discountAmount = 0;
        discountAmount += booking.discounts.rewardPoints?.discountAmount ?? 0;
        booking.totalPrice -= discountAmount;
        return booking;
      }),
      tap(createdBooking => this.bookingSubject.next(createdBooking)),
    );
  }

  updateBooking(bookingId: string, updateBookingRequest: UpdateBookingRequest) {
    if (bookingId !== this.bookingSubject.getValue()?.id) {
      this.bookingSubject.next(null);
    }
    updateBookingRequest.interval = this.mapIntervalDatesToString(
      updateBookingRequest.interval,
    );
    return this.http
      .put<Booking>(`${BOOKING_API_BASE}/${bookingId}`, updateBookingRequest)
      .pipe(
        map(booking => {
          if (!booking?.discounts) {
            return booking;
          }
          let discountAmount = 0;
          discountAmount += booking.discounts.rewardPoints?.discountAmount ?? 0;
          booking.totalPrice -= discountAmount;
          return booking;
        }),
        tap(createdBooking => this.bookingSubject.next(createdBooking)),
      );
  }

  claimBooking(bookingId: string) {
    return this.http
      .post<Booking>(`${BOOKING_API_BASE}/${bookingId}/claim`, {})
      .pipe(
        map(booking => {
          if (!booking?.discounts) {
            return booking;
          }
          let discountAmount = 0;
          discountAmount += booking.discounts.rewardPoints?.discountAmount ?? 0;
          booking.totalPrice -= discountAmount;
          return booking;
        }),
        tap(booking => {
          this.bookingSubject.next(booking);
        }),
      );
  }

  /**
   * Gain all prices for the property.
   */
  calculateBooking(bookingInput: CreateBookingRequest) {
    bookingInput.interval = this.mapIntervalDatesToString(
      bookingInput.interval,
    );
    return this.http
      .post<BookingPricing>(`${BOOKING_API_BASE}/calculate-price`, bookingInput)
      .pipe(
        map(booking => {
          if (!booking?.discounts) {
            return booking;
          }
          let discountAmount = 0;
          discountAmount += booking.discounts.rewardPoints?.discountAmount ?? 0;
          booking.totalPrice -= discountAmount;
          return booking;
        }),
      );
  }

  createBookingPayRequest(bookingId: string, input: BookingPayRequest) {
    return this.http.post<BookingPayRequestResponse>(
      `${BOOKING_API_BASE}/${bookingId}/pay`,
      input,
    );
  }

  refreshBookingPayment(bookingId: string) {
    return this.http.post<BookingPayRequestResponse>(
      `${BOOKING_API_BASE}/${bookingId}/pay/refresh`,
      {},
    );
  }

  /** @deprecated */
  getCalendarInfo(calendarInfoInput: BookingCalendarInfoInput) {
    calendarInfoInput.interval = this.mapIntervalDatesToString(
      calendarInfoInput.interval,
    );
    return this.http.post<BookingCalendarInfoResponse>(
      `${BOOKING_API_BASE}/calendar-info`,
      calendarInfoInput,
    );
  }

  private mapIntervalDatesToString(interval: BookingInterval): BookingInterval {
    if (interval) {
      return {
        checkIn: format(interval.checkIn, DATE_FORMAT) as any,
        checkOut: format(interval.checkOut, DATE_FORMAT) as any,
      };
    }
    return interval;
  }

  approveBooking(bookingId: string): Observable<Booking> {
    // TODO: waiting for backend
    return this.http.post<Booking>(
      `${BOOKING_API_BASE}/${bookingId}/approve`,
      {},
    );
  }

  declineBooking(
    bookingId: string,
    message: string,
    blockDates = false,
  ): Observable<Booking> {
    // TODO: waiting for backend
    return this.http.post<Booking>(`${BOOKING_API_BASE}/${bookingId}/decline`, {
      message,
      blockDates,
    });
  }

  cancelBooking(
    bookingId: string,
    message: string,
    blockDates = false,
  ): Observable<Booking> {
    return this.http.post<Booking>(`${BOOKING_API_BASE}/${bookingId}/cancel`, {
      message,
      blockDates,
    });
  }

  /**
   * Sends request to refund a client.
   * @param bookingId id of booking, where refund will be issued
   * @param amount amount of money to be refunded
   * @param message message to refunded client
   * @returns Booking observable with updated values
   */
  sendRefund(
    bookingId: string,
    amount: number,
    message: string,
  ): Observable<Booking> {
    return this.http.post<Booking>(`${BOOKING_API_BASE}/${bookingId}/refund`, {
      amount,
      message,
    });
  }

  /**
   * Return booking cancel detail
   */
  getBookingCancelDetail(bookingId: string): Observable<BookingCancelDetail> {
    return this.http.get<BookingCancelDetail>(
      `${BOOKING_API_BASE}/${bookingId}/cancel-detail`,
    );
  }

  /**
   * Gets date interval, in which the client can be refunded
   * @param checkIn check in date
   * @param checkOut check out date
   * @returns interval observable
   */
  getRefundableInterval(
    checkIn: Date,
    checkOut: Date,
  ): Observable<{ from: Date; to: Date }> {
    return this.http.get<{ from: Date; to: Date }>(
      `${BOOKING_API_BASE}/refund-interval`,
      {
        params: {
          checkIn: checkIn.toISOString(),
          checkOut: checkOut.toISOString(),
        },
      },
    );
  }
}
