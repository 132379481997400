import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ToggleName, ToggleService } from '../modules/toggle/toggle.service';

/**
 * Directive for enabling/disabling component section based on toggle
 *
 * @example
 *  *ifEnabled="'superhog'" -> display block, only if superhog toggle is enabled
 *  *ifEnabled="'!superhog'"-> display block, only if superhog toggle is disabled
 *  Similarly like for *ngIf, you can configure alternative content like this:
 *  *ifEnabled="'superhog'; else superhogDisabledInfo" where superhogDisabledInfo
 *  should be <ng-template #superhogDisabledInfo>...</ng-template>
 *
 * @author Libor Staněk
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ifEnabled]',
})
export class IfEnabledDirective implements OnInit, OnDestroy {
  @Input()
  ifEnabled: ToggleName | `!${ToggleName}`;
  @Input() ifEnabledElse?: TemplateRef<any>;

  private toggleSubscription: Subscription;
  private show: boolean;

  constructor(
    private readonly element: ElementRef,
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly toggleService: ToggleService,
  ) {}

  ngOnInit(): void {
    const invertToggle = this.ifEnabled.startsWith('!');
    const toggle = (
      invertToggle ? this.ifEnabled.substring(1) : this.ifEnabled
    ) as ToggleName;
    this.toggleSubscription = this.toggleService
      .isEnabled$(toggle)
      .subscribe(enabled => {
        const show = invertToggle ? !enabled : enabled;
        if (this.show === show) {
          return;
        }
        if (show) {
          this.viewContainer
            .createEmbeddedView(this.templateRef)
            .detectChanges();
        } else if (this.ifEnabledElse) {
          this.viewContainer.createEmbeddedView(this.ifEnabledElse);
        } else {
          this.viewContainer.clear();
        }
        this.show = show;
      });
  }

  ngOnDestroy(): void {
    if (this.toggleSubscription) {
      this.toggleSubscription.unsubscribe();
    }
  }
}
