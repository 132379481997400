import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, catchError, of } from 'rxjs';
import { updateControls } from '../../../../../utils/form.util';
import { emailValidator } from '../../../../../utils/validators';
import { ParentAuthenticationDialog } from '../../parent-authentication-dialog';
import { getUserFriendlyError } from '../../../../../utils/errors';
import { ResetPasswordConfirmationDialogComponent } from './reset-password-confirmation-dialog.component';
import { Router } from '@angular/router';

/**
 * Component dialog for changing password of user´s auth0 account
 */
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordComponent
  extends ParentAuthenticationDialog
  implements OnInit, OnDestroy
{
  form: UntypedFormGroup;
  error$ = new BehaviorSubject<string | null>(null);

  @Input() isDialog = true;

  constructor(
    injector: Injector,
    private readonly router: Router,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required,
        emailValidator(),
      ]),
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  handleBackButton(): void {
    if (!this.isDialog) {
      this.router.navigate(['/login/email']);
    } else {
      this.closeDialog();
    }
  }

  submit(event: Event) {
    event.preventDefault();
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      updateControls(this.form);
      return;
    }
    this.error$.next(null);
    this.userService
      .resetPassword(this.form.controls.email.value)
      .pipe(
        catchError(error => {
          this.error$.next(getUserFriendlyError(error));
          return of(null);
        }),
      )
      .subscribe(() => {
        if (this.error$.value) {
          return;
        }
        this.dialogService
          .open(ResetPasswordConfirmationDialogComponent, {
            data: {
              email: this.form.controls.email.value,
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.router.navigate(['/login']);
          });
      });
  }
}
