import { BookingCancellationRefund } from 'src/app/shared/models/booking';
import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-booking-refund-tracking',
  template: `<span typography variant="h5">Refund tracking</span>
    <div class="mt-16">
      <span typography
        >Your refund {{ refundRule.totalAmount | currency: 'USD' }} should be
        processed within 7 days.</span
      >
    </div>`,
})
export class BookingRefundTrackingComponent extends AbstractComponent {
  @Input()
  refundRule: BookingCancellationRefund;
}
