import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { NoInternetError } from '../../utils/errors';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class InternetConnectionInterceptor implements HttpInterceptor {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (isPlatformBrowser(this.platformId) && !window.navigator.onLine) {
      return throwError(
        new NoInternetError(
          'Oops, something went wrong. Please check your internet connection.',
        ),
      );
    } else {
      return next.handle(request);
    }
  }
}
