<navbar-config *breakpointDown="'sm'">
  <ng-container *ngIf="(conversation$ | async) === null; else conversationDetailMobileMenu">
    <ng-container *ngTemplateOutlet="conversationsListMobileMenu"></ng-container>
  </ng-container>
  <ng-template #conversationDetailMobileMenu>
    <ng-container *ngIf="!isContextOpen; else conversationContextMobileMenu">
      <ng-container *ngTemplateOutlet="conversationMessagesMobileMenu"></ng-container>
    </ng-container>
  </ng-template>
</navbar-config>

<div class="layout" *ngIf="{
    conversation: conversation$ | async
  } as observables">
  <div *ngIf="(largeScreen$ | async) === true || !observables.conversation"  class="conversations-panel">
    <app-chat-conversations [conversation]="observables.conversation"></app-chat-conversations>
  </div>
  <div *ngIf="(largeScreen$ | async) === true || observables.conversation" class="content-panel">
    <app-chat-conversation-content
      [conversation]="observables.conversation"
      [isContextOpen]="isContextOpen"
    ></app-chat-conversation-content>
  </div>
</div>


<ng-template #conversationsListMobileMenu>
  <navbar-content [noGutters]="true">
    <div typography variant="em1" class="ml-16">Inbox</div>
  </navbar-content>
</ng-template>

<ng-template #conversationMessagesMobileMenu>
  <navbar-content [noGutters]="true" [fullWidth]="true" *ngIf="conversation$ | async as conversation">
    <button type="button"
            class="button-reset d-flex align-items-center px-24 cursor-pointer mr-auto"
            [routerLink]="[Url.CHAT]">
      <icon variant="chevron_left"></icon>
    </button>
    <div class="d-flex align-items-center px-24" *ngIf="recipient$ | async as recipient">
      <p typography variant="em1">{{recipient | userFullName}}</p>
    </div>
    <button type="button" class="button-reset d-flex align-items-center px-24 cursor-pointer ml-auto"
            (click)="toggleContext()">
      <icon variant="chevron_right"></icon>
    </button>
  </navbar-content>
</ng-template>

<ng-template #conversationContextMobileMenu>
  <navbar-content [noGutters]="true" [fullWidth]="true">
    <button type="button"
            class="button-reset d-flex align-items-center px-24 cursor-pointer"
            (click)="toggleContext()">
      <icon variant="chevron_left"></icon>
    </button>
    <div class="align-items-center px-24 ml-auto mr-auto">
      Chat context detail
    </div>
  </navbar-content>
</ng-template>
