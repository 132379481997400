import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StringUtils } from '../../../../../../../utils/string.utils';
import { TextMessageData } from 'src/app/modules/chat/models/text-message.model';

@Component({
  selector: 'app-chat-message-text',
  templateUrl: './chat-message-text.component.html',
  styleUrls: ['./chat-message-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageTextComponent {
  @Input()
  message: TextMessageData;
  @Input()
  text: string;

  getAvatarText(name) {
    return StringUtils.getAvatarText(name);
  }

  getLines(text: string) {
    if (!text) {
      return [''];
    }
    let lines = text.split('\n');
    lines = lines.map(line => line.trim());
    return lines;
  }
}
