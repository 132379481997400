import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarMonthComponent } from './calendar-month/calendar-month.component';
import { CalendarMonthOverlayComponent } from './calendar-month-overlay/calendar-month-overlay.component';
import { TippyDirective } from '@ngneat/helipopper';
import initTooltip from '../../component-lib/utils/init-tooltip';

@NgModule({
  declarations: [CalendarMonthComponent, CalendarMonthOverlayComponent],
  exports: [CalendarMonthComponent, CalendarMonthOverlayComponent],
  imports: [CommonModule, TippyDirective],
  providers: [initTooltip()],
})
export class CalendarModule {}
