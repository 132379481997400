import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { merge, Observable, ReplaySubject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { IMutableContext, UnleashClient } from 'unleash-proxy-client';
import { UserService } from '../../services/user.service';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { env } from '../../../dynamic-environment';
import { isNil, omitBy } from 'lodash-es';

export type ToggleName = keyof typeof env.enabledFeatures;

interface ToggleValueChangeEvent {
  toggleName: ToggleName;
  previousValue: boolean;
  value: boolean;
}

/**
 * Service for unleash toggles
 * @author Libor Staněk
 */
@Injectable({
  providedIn: 'root',
})
export class ToggleService {
  private readonly UNLEASH_LOAD_TIMEOUT = 5 * 1000; // milliseconds;
  private readonly UNLEASH_REFRESH_INTERVAL = 30; // seconds;
  private readonly UNLEASH_METRICS_INTERVAL = 30; // seconds;

  private unleash: UnleashClient;
  private readonly context: IMutableContext;

  private readonly toggleReadySubject: ReplaySubject<void>;
  /** Listen for unleash ready */
  readonly toggleReady$: Observable<void>;

  private readonly onUpdateSubject: ReplaySubject<void>;
  /** Listen for unleash update */
  readonly onUpdate$: Observable<void>;

  private readonly changesSubject: ReplaySubject<ToggleValueChangeEvent>;
  /** Listen for toggle change event */
  readonly changes$: Observable<ToggleValueChangeEvent>;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private readonly userService: UserService,
  ) {
    this.toggleReadySubject = new ReplaySubject<void>();
    this.toggleReady$ = this.toggleReadySubject.asObservable();
    this.onUpdateSubject = new ReplaySubject<void>();
    this.onUpdate$ = this.onUpdateSubject.asObservable();
    this.changesSubject = new ReplaySubject<ToggleValueChangeEvent>();
    this.changes$ = this.changesSubject.asObservable();
    this.context = {};
    // Subscribe for user changes
    this.userService
      .getCurrentUser()
      .pipe(distinctUntilChanged((u1, u2) => u1?.id === u2?.id))
      .subscribe(user => {
        this.onUserIdChange(user?.id ?? undefined);
      });
  }

  init(): Observable<void> {
    // Ignore unleash on SSR
    if (!isPlatformBrowser(this.platformId)) {
      this.onReady();
      return this.toggleReady$;
    }
    if (env.unleash.unleashEnabled !== true) {
      this.onReady();
      return this.toggleReady$;
    }

    // If unleash failed to load, after 5 seconds
    const unleashReadyTimeout = setTimeout(() => {
      this.onReady();
      console.warn(
        'Using default toggle values because of values load timeout.',
      );
    }, this.UNLEASH_LOAD_TIMEOUT);

    this.unleash = new UnleashClient({
      url: window.location.origin + env.unleash.url,
      clientKey: env.unleash.clientKey,
      appName: 'vacay-ui',
      context: this.context,
      refreshInterval: this.UNLEASH_REFRESH_INTERVAL,
      metricsInterval: this.UNLEASH_METRICS_INTERVAL,
    });
    // Listen for unleash value updates
    this.unleash.on('update', () => {
      this.onUnleashValuesChange();
    });
    this.unleash.on('ready', () => {
      this.onReady();
      clearTimeout(unleashReadyTimeout);
    });
    this.unleash.start().catch(err => {
      // it is failing
    });
    return this.toggleReady$;
  }

  /**
   * Return observable of toggle value
   * If toggle values changes, new value will be emitted
   */
  isEnabled$(toggleName: ToggleName): Observable<boolean> {
    return merge(
      this.toggleReady$,
      this.changes$.pipe(filter(change => change.toggleName === toggleName)),
    ).pipe(
      map(() => this.isEnabled(toggleName)),
      distinctUntilChanged(),
    );
  }

  /**
   * Return if toggle is enabled
   */
  isEnabled(toggleName: ToggleName): boolean {
    const toggleEnv = env.enabledFeatures[toggleName];
    if (typeof toggleEnv === 'object') {
      return toggleEnv.enabled;
    } else {
      return !!toggleEnv;
    }
  }

  /**
   * Return value of unleash variant
   */
  public getVariantValue<T extends string | number | object = string>(
    toggleName: ToggleName,
  ): T | null {
    const variant = this.unleash.getVariant(toggleName);
    if (!variant.enabled || !variant.payload) {
      return null;
    } else {
      return variant.payload.value as T;
    }
  }

  private onUnleashValuesChange() {
    for (const toggleName of Object.keys(env.enabledFeatures)) {
      this.updateValue(
        toggleName as ToggleName,
        this.unleash.isEnabled(toggleName),
      );
    }
    console.log('toggle values updated');
    this.onUpdateSubject.next();
  }

  private updateValue(toggleName: ToggleName, value: boolean) {
    let currentValue = this.isEnabled(toggleName);
    if (currentValue === value) {
      return;
    }

    if (typeof env.enabledFeatures[toggleName] === 'boolean') {
      (env.enabledFeatures[toggleName] as any) = value;
    } else {
      (env.enabledFeatures[toggleName] as any).enabled = value;
    }

    this.changesSubject.next({
      toggleName: toggleName,
      previousValue: currentValue,
      value: value,
    });
  }

  private onUserIdChange(userId: string) {
    this.updateContext({ ...this.context, userId: userId });
  }

  private updateContext(context: IMutableContext) {
    context = omitBy(context, isNil);
    if (this.unleash) {
      this.unleash.updateContext(context);
    }
  }

  private onReady() {
    this.toggleReadySubject.next();
    this.toggleReadySubject.complete();
    this.onUpdateSubject.next();
  }
}
