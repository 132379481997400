import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { VerificationStatus } from '../../../../shared/models/user.model';
import { UserService } from '../../../../core/services/user.service';
import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';

@Component({
  selector: 'app-navigation-menu-desktop',
  templateUrl: './navigation-menu-desktop.component.html',
  styleUrls: ['./navigation-menu-desktop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationMenuDesktopComponent extends AbstractComponent {
  protected readonly VerificationStatus = VerificationStatus;

  protected readonly user$ = this.userService.getCurrentUser();

  @Output()
  protected readonly linkClicked = new EventEmitter<void>();

  constructor(private readonly userService: UserService) {
    super();
  }

  onLinkClicked() {
    this.linkClicked.emit();
  }
}
