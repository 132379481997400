import { Component } from '@angular/core';
import { TypographyModule } from '@components/typography/typography.module';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule, TypographyModule],
  selector: 'app-terms-and-conditions-content',
  templateUrl: './terms-and-conditions-content.component.html',
  styleUrls: ['./terms-and-conditions-content.component.scss'],
})
export class TermsAndConditionsContentComponent {}
