import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertyBookingHeaderComponent } from './property-booking-header.component';
import { IconModule } from '@components/icon/icon.module';
import { CoreModule } from 'src/app/core/core.module';
import { BookingInfoComponent } from './booking-info.component';
import { TypographyModule } from '@components/typography/typography.module';
import { PropertyBookingPricingModule } from '../property-booking-pricing/property-booking-pricing.module';
import { BookingPayoutDetailsComponent } from './booking-payout-details.component';
import { ButtonModule } from '@components/button/button.module';
import { PropertyInfoComponent } from './property-info.component';
import { RouterModule } from '@angular/router';
import { BookingCancelationPolicyComponent } from './booking-cancellation-policy.component';
import { CancellationPolicyChartLegacyModule } from 'src/app/libs/component-lib/cancellation-policy-chart-legacy.module';
import { MultiParagraphModule } from '@components/multi-paragraph/multi-paragraph.module';
import { PropertyHouseRulesComponent } from './property-house-rules.component';
import { BookingRefundTrackingComponent } from './booking-refund-tracking.component';
import { PropertyArrivalInstructionsComponent } from './property-arrival-instructions.component';
import { PropertyAvailabilityComponent } from './property-availability.component';
import { UserReservationsCalendarModule } from 'src/app/modules/user/shared/user-reservations-calendar/user-reservations-calendar.module';

@NgModule({
  declarations: [
    PropertyBookingHeaderComponent,
    BookingInfoComponent,
    BookingPayoutDetailsComponent,
    PropertyInfoComponent,
    BookingCancelationPolicyComponent,
    PropertyHouseRulesComponent,
    BookingRefundTrackingComponent,
    PropertyArrivalInstructionsComponent,
    PropertyAvailabilityComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    IconModule,
    TypographyModule,
    PropertyBookingPricingModule,
    ButtonModule,
    RouterModule,
    CancellationPolicyChartLegacyModule,
    MultiParagraphModule,
    UserReservationsCalendarModule,
  ],
  exports: [
    PropertyBookingHeaderComponent,
    BookingInfoComponent,
    BookingPayoutDetailsComponent,
    PropertyInfoComponent,
    BookingCancelationPolicyComponent,
    PropertyHouseRulesComponent,
    BookingRefundTrackingComponent,
    PropertyArrivalInstructionsComponent,
    PropertyAvailabilityComponent,
  ],
})
export class PropertyBookingDetailModule {}
