<form [formGroup]="form" (submit)="onSubmit($event)">
  <div class="ml-0 mr-0 my-16">
    <p1h0m89-input [formControl]="form.controls.phoneNumber"></p1h0m89-input>
  </div>

  <div *ngIf="authType === 'signup'">
    <div class="d-flex mt-8">
      <checkbox [formControl]="form.controls.agreeTermsAndConditions" class="mx-4"></checkbox>
      <span typography variant="body1" color="inherit">
        I have read and agree to VacayMyWay
        <a link variant="link1" color="primary" (click)="openTermsAndConditions()">
          Terms and Conditions
        </a>
      </span>

    </div>
    <div class="d-flex mt-8 mb-24">
      <checkbox [formControl]="form.controls.agreePrivacyPolicy" class="mx-4"></checkbox>
      <span typography variant="body1" color="inherit">
        I have read and agree to VacayMyWay
        <a link variant="link1" color="primary" (click)="openPrivacyPolicy()">
          Privacy Policy
        </a>
      </span>
    </div>
  </div>
  <button button type="submit" color="primary" class="w-100">
    Continue
  </button>
</form>