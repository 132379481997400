import { User } from '../../../shared/models/user.model';
import { Conversation } from '../models/conversation.model';
import { Message, MessageType } from '../models/message.model';

export class ChatUtils {
  static isArchived(conversation: Conversation, user: User): boolean {
    return conversation.participants.some(
      participant => participant.userId === user.id && participant.archived,
    );
  }
  static isBookingMessage(message: Message) {
    switch (message.type) {
      case MessageType.BOOKING_APPROVED:
      case MessageType.BOOKING_AUTHORIZED:
      case MessageType.BOOKING_CANCELED:
      case MessageType.BOOKING_CREATED:
      case MessageType.BOOKING_DECLINED:
      case MessageType.BOOKING_REFUNDED:
        return true;
      default:
        return false;
    }
  }
}
