import { Directive, Injector, OnDestroy } from '@angular/core';
import { DialogService } from '../../../core/services/dialog.service';
import { UserService } from '../../../core/services/user.service';
import { AbstractComponent } from '../../../core/components/abstract/abstract.component';

/**
 * Common class for authentication dialogs
 */
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class ParentAuthenticationDialog
  extends AbstractComponent
  implements OnDestroy
{
  protected readonly dialogService: DialogService;
  protected readonly userService: UserService;

  /**
   * Constructor
   * @param injector - for getting service
   */
  protected constructor(protected injector: Injector) {
    super();
    this.dialogService = injector.get<DialogService>(DialogService);
    this.userService = injector.get<UserService>(UserService);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  closeDialog() {
    this.dialogService.clear();
  }
}
