<dialog-wrapper width="sm" [position]="ls ? 'center' : 'bottom'" [class.h-100]="!isDialog">
  <dialog-header (dialogClose)="closeDialog()" [showClose]="isDialog" title="Sign in using email"></dialog-header>
  <dialog-content [class.pt-32]="!isDialog">
    <div notification class="mb-16" *ngIf="error$ | async as error">
      {{error}}
    </div>

    <form ngNoForm (submit)="submit($event)">
      <input-text
        [formControl]="form.controls.email"
        [label]="'Email'"
        type="email"
        inputMode="email"
        class="my-8"
        data-test="login-email-email">
      </input-text>
      <input-password
        [formControl]="form.controls.password"
        label="Password"
        forgotPassword="true"
        data-test="login-email-passwd"
        class="my-8"
        (clickEvent)="forgotPassword()">
      </input-password>

      <button
        button
        type="submit"
        variant="contained"
        color="primary"
        data-test="login-email"
        class="w-100 mt-16"
      >
        Login
      </button>
    </form>

    <div class="divider mt-16 mb-16"></div>

    <div class="mb-8 mb-md-0">
      <div>
        <app-login-link [isDialog]="isDialog">
          Use your social accounts
        </app-login-link>
      </div>
      <div>
        <span typography variant="body1">
          Don’t have an account?
        </span>
        <app-signup-link data-test="sign-up" [isEmailLink]="true" [isDialog]="isDialog">Sign Up</app-signup-link>
      </div>
    </div>
  </dialog-content>
</dialog-wrapper>
