import { kebabCase } from 'lodash-es';

export function createUrlSlug(url: string): string {
  // kebabCase doesn't remove emojis, so we handle it manually
  return kebabCase(kebabCase(url).replace(/[^\x00-\x7F]/g, ''));
}

export function createUrlWithParams(
  url: string,
  params: Record<string, string>,
): string {
  const urlParams = new URLSearchParams(params);
  return `${url}?${urlParams.toString()}`;
}
