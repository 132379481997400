import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatLayoutComponent } from './components/chat-layout/chat-layout.component';
import { ChatMessagesComponent } from './components/chat/chat-messages/chat-messages.component';
import { ChatInputComponent } from './components/chat/chat-input/chat-input.component';
import { ChatConversationsComponent } from './components/chat/chat-conversations/chat-conversations.component';
import { ChatMessageTextComponent } from './components/chat/chat-messages/types/chat-message-text/chat-message-text.component';
import { CoreModule } from '../../core/core.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NavbarModule } from '../navbar/navbar.module';
import { RouterModule } from '@angular/router';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ChatWindowProviderComponent } from './components/chat-window/chat-window-provider/chat-window-provider.component';
import { ChatWindowComponent } from './components/chat-window/chat-window/chat-window.component';
import { ChatContextComponent } from './components/chat/chat-context/chat-context.component';
import { ChatConversationContentComponent } from './components/chat/chat-conversation-content/chat-conversation-content.component';
import { FormatMessageDatePipe } from './pipes/chat-message-date-format.pipe';
import { ChatConversationDateFormatPipe } from './pipes/chat-conversation-date-format.pipe';
import { OverlayModule } from '@angular/cdk/overlay';
import { AuthGuard } from '../../core/guards/auth.guard';
import { ConfirmationDialogModule } from '@components/confirmation-dialog/confirmation-dialog.module';
import { BadgeModule } from '@components/badge/badge.module';
import { TippyDirective } from '@ngneat/helipopper';
import initTooltip from 'src/app/libs/component-lib/utils/init-tooltip';
import { ChatMessageBookingApprovedComponent } from './components/chat/chat-messages/types/chat-message-booking-approved/chat-message-booking-approved.component';
import { ChatMessageBookingAuthorizedComponent } from './components/chat/chat-messages/types/chat-message-booking-authorized/chat-message-booking-authorized.component';
import { ChatMessageBookingCanceledComponent } from './components/chat/chat-messages/types/chat-message-booking-canceled/chat-message-booking-canceled.component';
import { ChatMessageBookingDeclinedComponent } from './components/chat/chat-messages/types/chat-message-booking-declined/chat-message-booking-declined.component';
import { ChatMessageBookingRefundedComponent } from './components/chat/chat-messages/types/chat-message-booking-refunded/chat-message-booking-refunded.component';
import { ChatMessageSystemComponent } from './components/chat/chat-messages/types/chat-message-system/chat-message-system.component';
import { LinkModule } from '@components/link/link.module';
import { FormatMoneyPipe } from '../../shared/pipes/money-format.pipe';
import { ChatMessageInquiryComponent } from './components/chat/chat-messages/types/chat-message-inquiry/chat-message-inquiry.component';
import { StatusModule } from '@components/status/status.module';
import { PropertyBookingPricingModule } from '../property/shared/property-booking-pricing/property-booking-pricing.module';
import { ChatContextListComponent } from './components/chat/chat-context/chat-context-list/chat-context-list.component';
import { ChatRecipientComponent } from './components/chat/chat-context/chat-recipient/chat-recipient.component';
import { ChatContextListItemComponent } from './components/chat/chat-context/chat-context-list/chat-context-list-item/chat-context-list-item.component';
import { SeparatorModule } from '@components/separator/separator.module';
import { PropertyBookingDetailModule } from '../property/shared/property-booking-detail/property-booking-detail.module';
import { ChatContextInquiryDetailComponent } from './components/chat/chat-context/chat-context-inquiry-detail/chat-context-inquiry-detail.component';
import { ChatContextBookingDetailComponent } from './components/chat/chat-context/chat-context-booking-detail/chat-context-booking-detail.component';
import { IconModule } from '@components/icon/icon.module';
import { ButtonModule } from '@components/button/button.module';
import { TypographyModule } from '@components/typography/typography.module';
import { AvatarModule } from '@components/avatar/avatar.module';
import { CardLegacyModule } from '../../libs/component-lib/card-legacy.module';
import { MultiParagraphModule } from '@components/multi-paragraph/multi-paragraph.module';
import { StarsLegacyModule } from '../../libs/component-lib/stars-legacy.module';
import { InputModule } from '@components/input/input.module';
import { DialogModule } from '@components/dialog/dialog.module';
import { RadioButtonLegacyModule } from '../../libs/component-lib/radio-button-legacy.module';
import { ChipLegacyModule } from '../../libs/component-lib/chip-legacy.module';

@NgModule({
  declarations: [
    ChatLayoutComponent,
    ChatMessagesComponent,
    ChatInputComponent,
    ChatConversationsComponent,
    ChatMessageTextComponent,
    ChatMessageInquiryComponent,
    ChatMessageSystemComponent,
    ChatMessageBookingApprovedComponent,
    ChatMessageBookingAuthorizedComponent,
    ChatMessageBookingCanceledComponent,
    ChatMessageBookingDeclinedComponent,
    ChatMessageBookingRefundedComponent,
    ChatWindowProviderComponent,
    ChatWindowComponent,
    ChatContextComponent,
    ChatContextListComponent,
    ChatContextListItemComponent,
    ChatContextInquiryDetailComponent,
    ChatContextBookingDetailComponent,
    ChatRecipientComponent,
    ChatConversationContentComponent,
    FormatMessageDatePipe,
    ChatConversationDateFormatPipe,
    FormatMoneyPipe,
  ],
  exports: [ChatLayoutComponent, ChatWindowProviderComponent],
  imports: [
    CommonModule,
    CoreModule,
    ConfirmationDialogModule,
    ReactiveFormsModule,
    RouterModule,
    BadgeModule,
    SeparatorModule,
    LinkModule,
    TextFieldModule,
    MatProgressSpinnerModule,
    OverlayModule,
    StatusModule,
    PropertyBookingPricingModule,
    PropertyBookingDetailModule,
    RouterModule.forChild([
      {
        path: '',
        component: ChatLayoutComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: ':conversationId',
            children: [
              {
                path: 'property/:propertyId',
                children: [],
              },
              {
                path: 'booking/:bookingId',
                children: [],
              },
            ],
          },
        ],
      },
    ]),
    NavbarModule,
    TippyDirective,
    IconModule,
    ButtonModule,
    TypographyModule,
    AvatarModule,
    CardLegacyModule,
    MultiParagraphModule,
    StarsLegacyModule,
    InputModule,
    DialogModule,
    RadioButtonLegacyModule,
    ChipLegacyModule,
  ],
  providers: [initTooltip()],
})
export class ChatModule {}
