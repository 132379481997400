import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
} from '@angular/core';
import { timer } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { startOfDay } from 'date-fns';
import { DateInterval } from '@components/calendar/calendar.utils';

@Component({
  selector: 'property-search-calendar-dialog',
  template: `
    <dialog-wrapper position="bottom" layout="content">
      <dialog-content class="h-100">
        <div class="w-100 mt-8">
          <input-calendar
            *ngIf="showCalendar"
            [formControl]="formControl"
            [min]="today$ | async"
            [showInFullscreen]="true"
            [numberOfMonths]="4"
            (searchEvent)="search()"
            (backEvent)="back()"
          ></input-calendar>
        </div>
      </dialog-content>
    </dialog-wrapper>
  `,
})
export class PropertySearchCalendarDialogComponent {
  formControl: UntypedFormControl;
  today$ = timer(0, 60_000).pipe(map(() => startOfDay(new Date())));

  /**
   * Using showCalendar to 'refresh' the input calendar component when opening dynamic dialog,
   * when no interval is selected, calendar can be shown immediately
   */
  showCalendar = false;

  constructor(
    private readonly dialogRef: MatDialogRef<PropertySearchCalendarDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      interval: DateInterval;
    },
  ) {
    this.formControl = new UntypedFormControl(data.interval);
    this.showCalendar = !data.interval.start && !data.interval.end;
    this.dialogRef.afterOpened().subscribe(() => (this.showCalendar = true));
  }

  search(): void {
    this.dialogRef.close(this.formControl.value);
  }

  back(): void {
    this.dialogRef.close();
  }
}
