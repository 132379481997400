import { NgModule } from '@angular/core';
import { CancellationPolicyChartComponent } from './components/charts/cancellation-policy-chart/cancellation-policy-chart.component';
import { TodayPointerImageComponent } from './components/charts/cancellation-policy-chart/today-pointer-image/today-pointer-image.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [CancellationPolicyChartComponent, TodayPointerImageComponent],
  imports: [CommonModule],
  exports: [CancellationPolicyChartComponent],
})
export class CancellationPolicyChartLegacyModule {}
