import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DialogService } from '../../../core/services/dialog.service';
import { FileStorageService } from '../../../core/services/file-storage.service';
import { BlockUiService } from '../../../core/services/block-ui.service';
import { isPlatformBrowser } from '@angular/common';
import { of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { AvatarEditorComponent } from '../../component-lib/components/avatar-editor/avatar-editor.component';
import {
  AvatarPhotoService,
  AvatarSize,
  MIN_AVATAR_HEIGHT,
  MIN_AVATAR_WIDTH,
} from '@components/avatar/avatar-photo.service';

/**
 * @see https://www.figma.com/file/4URZYURZBcwfpYp3HvkSL6/Web-components-VMW?node-id=5592%3A20865
 */
@Component({
  selector: 'avatar',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <button
      type="button"
      (click)="pickPhoto ? uploader.click() : false"
      (mouseenter)="options ? mouseEnter() : false"
      (mouseleave)="options ? mouseLeave() : false"
      class="avatar-button"
    >
      <div
        class="avatar-content avatar-text"
        data-test="avatar-mini"
        [class.pointer]="cursorPointer || clicked.observers.length > 0"
        [class.hover-disabled]="hoverDisabled"
        (click)="clickContent()"
        *ngIf="!src"
      >
        <ng-content></ng-content>
      </div>
      <div
        *ngIf="src"
        class="avatar-content"
        data-test="avatar-mini"
        [class.pointer]="cursorPointer || clicked.observers.length > 0"
        [class.hover-disabled]="hoverDisabled"
        (click)="clickContent()"
      >
        <img
          class="avatar-image"
          [src]="src"
          (error)="onError()"
          alt="Avatar"
        />
      </div>
      <div
        *ngIf="showOptions"
        class="avatar-content"
        (click)="openMobileDialog()"
      >
        <div class="avatar-content-settings mobile-hidden">
          <div class="avatar-settings">
            <div class="avatar-settings-text" (click)="uploader.click()">
              <p typography variant="body2" color="white">
                {{ src ? 'Change' : 'Add' }} profile picture
              </p>
            </div>
            <div
              class="avatar-settings-text"
              (click)="deleteImage()"
              *ngIf="src"
            >
              <p typography variant="body2" color="white">
                Delete profile picture
              </p>
            </div>
          </div>
        </div>
      </div>
    </button>
    <input
      hidden
      type="file"
      #uploader
      (change)="openEditorDialog($event)"
      [accept]="['.jpeg', '.jpg', '.png', '.heic', '.heif']"
    />
    <div
      class="mobile-edit-image-dialog desktop-hidden"
      *ngIf="mobileDialogVisible"
    >
      <div
        class="mobile-edit-image-dialog-close"
        (click)="closeMobileDialog()"
      ></div>
      <div class="mobile-controls-container">
        <div class="mobile-control-group">
          <button
            button
            type="button"
            variant="contained"
            color="secondary"
            class="mobile-control w-100"
            (click)="uploader.click()"
          >
            {{ src ? 'Change' : 'Open' }} photo
          </button>
        </div>
        <div class="mobile-control-group">
          <button
            button
            *ngIf="src"
            type="button"
            variant="contained"
            color="secondary"
            class="mobile-control mobile-control-critical w-100"
            (click)="deleteImage()"
          >
            Delete photo
          </button>
        </div>
        <div class="mobile-control-group">
          <button
            button
            type="button"
            variant="contained"
            color="secondary"
            class="mobile-control w-100"
            (click)="closeMobileDialog()"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  `,
})
export class AvatarComponent implements OnInit, AfterViewInit {
  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly dialogService: DialogService,
    private readonly fileStorageService: FileStorageService,
    private readonly blockUiService: BlockUiService,
    private readonly avatarPhotoService: AvatarPhotoService,
  ) {}

  @ViewChild('uploader') uploader;

  @Input()
  src: any;

  /** Show options for add, edit and delete photo. */
  @Input()
  options = false;

  @Input()
  size: AvatarSize;

  /** Enable upload photo by clicking on circle button . */
  @Input()
  pickPhoto = false;

  @Input()
  hoverDisabled = false;

  @Input()
  cursorPointer = false;

  @Output()
  upload = new EventEmitter<File>();

  @Output()
  delete = new EventEmitter();

  @Output()
  imageError = new EventEmitter();

  @Output()
  clicked = new EventEmitter();

  defaultOptions: boolean;
  defaultPickPhoto: boolean;
  showOptions: boolean;
  defaultBackground: any;
  mobileDialogVisible = false;

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.renderer.addClass(this.elementRef.nativeElement, `avatar`);
    if (this.size) {
      this.renderer.addClass(
        this.elementRef.nativeElement,
        `avatar-size-${this.size}`,
      );
    }
    this.defaultPickPhoto = this.pickPhoto;
    this.defaultOptions = this.options;
  }

  ngAfterViewInit() {
    this.resize();

    this.defaultBackground = (
      this.elementRef.nativeElement as HTMLElement
    ).style.background;
  }

  deleteImage() {
    this.delete.emit(this.src);
    this.uploader.nativeElement.value = '';
    setTimeout(() => {
      this.src = undefined;

      this.pickPhoto = this.defaultPickPhoto;
      this.options = this.defaultOptions;
      this.showOptions = false;
    }, 1);
    this.setDefaultBackground();
  }

  openEditorDialog(event) {
    this.closeMobileDialog();
    of(event.target.files[0])
      .pipe(
        filter(file => !!file),
        switchMap(file => {
          if (
            file.name?.toLowerCase().endsWith('.heif') ||
            file.name?.toLowerCase().endsWith('.heic')
          ) {
            return this.fileStorageService
              .convertHeicToJpeg(file)
              .pipe(this.blockUiService.blockPipe({ text: 'Uploading' }));
          } else {
            return of(file);
          }
        }),
      )
      .subscribe(file => {
        const inputFileReader = new FileReader();
        inputFileReader.readAsDataURL(file);
        inputFileReader.onload = () => {
          // when file has loaded
          const img = new Image();
          img.src = inputFileReader.result as string;
          img.onload = () => {
            const error = this.avatarPhotoService.validateDimension(
              img.width,
              img.height,
            );
            if (error) {
              this.imageError.emit(error);
            } else {
              this.openAvatarEditorDialog(file);
            }
          };
          img.onerror = () => {
            this.imageError.emit('File must be an image.');
          };
        };
      });
  }

  openAvatarEditorDialog(file: File) {
    const editorDialogRef = this.dialogService.open(AvatarEditorComponent, {
      data: {
        file,
        minWidth: MIN_AVATAR_WIDTH,
        minHeight: MIN_AVATAR_HEIGHT,
      },
      maxHeight: '100vh',
      maxWidth: '100vw',
    });

    editorDialogRef
      .afterClosed()
      .subscribe(data => this.afterAvatarEditorDialogClosed(data));
  }

  afterAvatarEditorDialogClosed(img) {
    // show image and upload
    if (img) {
      const reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = () => {
        this.src = reader.result;
        this.pickPhoto = false;
        this.options = this.defaultOptions;
        this.renderer.setStyle(
          this.elementRef.nativeElement,
          'background',
          'white',
        );
        this.upload.emit(img);
      };
    }
    this.uploader.nativeElement.value = '';
  }

  onError() {
    this.setDefaultBackground();
  }

  setDefaultBackground() {
    this.src = '';
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'background',
      this.defaultBackground,
    );
  }

  mouseEnter() {
    this.showOptions = true;
  }

  mouseLeave() {
    this.showOptions = false;
  }

  @HostListener('window:resize')
  resize() {
    const el = this.elementRef.nativeElement as HTMLElement;
    this.renderer.setStyle(el, 'font-size', `${el.offsetWidth / 4}px`);
  }

  clickContent() {
    this.clicked.emit();
  }

  closeMobileDialog() {
    this.mobileDialogVisible = false;
  }

  openMobileDialog() {
    this.mobileDialogVisible = true;
  }
}
