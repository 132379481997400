<div class="get-started-wrapper">
  <div class="get-started-content">
    <div class="get-started-image"></div>
    <div class="get-started-text">
      <app-home-section-header title="Get Started" subtitle="List Your Property"></app-home-section-header>
      <p typography variant="h5" class="my-20">At Vacay we’re giving back freedom to our hosts with:</p>
      <div class="point"><icon variant="check" color="primary"></icon><p typography variant="body1">Open guest communication</p></div>
      <div class="point"><icon variant="check" color="primary"></icon><p typography variant="body1">Higher booking revenues (upwards of 20%)</p></div>
      <div class="point"><icon variant="check" color="primary"></icon><p typography variant="body1">Robust property matching with the Vacay Standard</p></div>
      <div class="point"><icon variant="check" color="primary"></icon><p typography variant="body1">Inclusive $5M damage protection &amp; guest ID verification</p></div>
      <div class="point">
        <a button
           variant="contained"
           color="primary"
           size="normal"
           href="https://blog.vacaymyway.com/vacaymyway-price-comparison/"
        >Let's get Started</a>
      </div>
    </div>
  </div>
</div>
