import {
  AfterContentInit,
  Component,
  ContentChild,
  ElementRef,
  Injector,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInputComponent } from '@components/abstract/abstract-input.component';
import { InputSuffixDirective } from '@components/input/input-suffix.directive';
import { InputPrefixDirective } from '@components/input/input-prefix.directive';

@Component({
  selector: 'input-text',
  styleUrls: ['../input.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputTextComponent,
      multi: true,
    },
  ],
  templateUrl: './input-text.component.html',
})
export class InputTextComponent
  extends AbstractInputComponent<string>
  implements OnInit, AfterContentInit
{
  @Input()
  type = 'text';
  @Input()
  placeholder: string;
  @Input()
  readonly: boolean;
  @Input()
  required: boolean;
  @Input()
  autocomplete: boolean;
  @Input()
  mask?: string;
  @Input()
  dropSpecialCharacters = true;

  @ViewChild('input', { static: false })
  input: ElementRef<HTMLInputElement>;

  @ContentChild(InputPrefixDirective, { static: false })
  inputPrefix: InputPrefixDirective;

  @ContentChild(InputSuffixDirective, { static: false })
  inputSuffix: InputSuffixDirective;

  constructor(
    injector: Injector,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.renderer.addClass(this.elementRef.nativeElement, 'input2');
    this.renderer.addClass(this.elementRef.nativeElement, 'input-text');
  }

  focus() {
    this.input.nativeElement.focus();
  }
}
