import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

export const SCREEN_SIZES = {
  xs: 0,
  sm: 375,
  md: 720,
  lg: 1200,
  xl: 1440,
};

export type Breakpoint = keyof typeof SCREEN_SIZES;

export const BREAKPOINTS = Object.keys(SCREEN_SIZES) as Breakpoint[];

/**
 * Common service for tracking viewport width breakpoint.
 * @author Jan Helbich
 */
@Injectable({ providedIn: 'root' })
export class BreakpointService {
  /** Internal subject for viewport size breakpoint. */
  private readonly screenBreakpointSubject$: BehaviorSubject<Breakpoint>;
  /** Public observable for viewport size breakpoint. */
  readonly screenBreakpoint$: Observable<Breakpoint>;
  /** Public observable for viewport size matching large screen selector. */
  readonly largeScreen$: Observable<boolean>;

  /** Initiation logic. */
  constructor(@Inject(PLATFORM_ID) private readonly platformId: any) {
    if (!isPlatformBrowser(this.platformId)) {
      this.largeScreen$ = of();
      return;
    }
    const breakpoint = this.getBreakpointByScreenWidth(window.innerWidth);
    this.screenBreakpointSubject$ = new BehaviorSubject<Breakpoint>(breakpoint);
    this.screenBreakpoint$ = this.screenBreakpointSubject$.asObservable();
    this.largeScreen$ = this.screenBreakpoint$.pipe(
      map(b => this.isBreakpointUpOrSame(b, 'md')),
    );
    window.addEventListener('resize', this.onResize.bind(this));
  }

  onResize() {
    const breakpoint = this.getBreakpointByScreenWidth(window.innerWidth);
    if (this.screenBreakpointSubject$.getValue() !== breakpoint) {
      this.screenBreakpointSubject$.next(breakpoint);
    }
  }

  getBreakpointByScreenWidth(width: number): Breakpoint {
    let lastBreakpoint: Breakpoint = 'xs';
    for (const breakpoint of BREAKPOINTS) {
      if (width < SCREEN_SIZES[breakpoint]) {
        return lastBreakpoint;
      }
      lastBreakpoint = breakpoint;
    }
    return 'xl';
  }

  isBreakpointUpOrSame(breakpoint: Breakpoint, compareTo: Breakpoint) {
    return BREAKPOINTS.indexOf(breakpoint) >= BREAKPOINTS.indexOf(compareTo);
  }

  isBreakpointDownOrSame(breakpoint: Breakpoint, compareTo: Breakpoint) {
    return BREAKPOINTS.indexOf(breakpoint) <= BREAKPOINTS.indexOf(compareTo);
  }

  getVisibleTableColumns(
    columns: [string, Breakpoint][],
  ): Observable<string[]> {
    return this.screenBreakpoint$.pipe(
      map(breakpoint => {
        return columns
          .filter(column => this.isBreakpointUpOrSame(breakpoint, column[1]))
          .map(column => column[0]);
      }),
    );
  }
}
