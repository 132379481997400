<dialog-wrapper
  width="sm"
  [position]="ls ? 'center' : 'bottom'"
  [class.h-100]="!isDialog"
>
  <dialog-header
    (dialogClose)="closeDialog()"
    [showClose]="isDialog"
    title="Sign in"
  ></dialog-header>
  <dialog-content
    [class.pt-32]="!isDialog"
    [class.no-overflow]="ls || !isDialog"
  >
    <div class="d-flex flex-column">
      <app-login-form-link [isDialog]="isDialog"
        >Continue with E-mail</app-login-form-link
      >
      <app-login-google>Continue with Google</app-login-google>
    </div>
    <app-text-separator text="or" />
    <app-p1h0m89-input authType="login"></app-p1h0m89-input>
    <div class="mt-24 mb-8 mb-md-0">
      <span typography variant="body1"> Don’t have an account? </span>
      <app-signup-link data-test="sign-up" [isDialog]="isDialog"
        >Sign up</app-signup-link
      >
    </div>
  </dialog-content>
</dialog-wrapper>
