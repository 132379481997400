import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';
import { Component, Input } from '@angular/core';
import { MapLinkService } from 'src/app/core/services/map-link.service';
import { PropertyService } from 'src/app/core/services/property.service';
import { Property, PropertyAddress } from 'src/app/shared/models/property';

@Component({
  selector: 'app-property-info',
  template: `<div class="d-flex">
      <div class="mr-8 property-image">
        <img
          *ngIf="
            (property | propertyFeaturedPhoto)?.imageUrl as imageUrl;
            else noImage
          "
          [src]="imageUrl"
          alt="Property image"
        />
        <ng-template #noImage>
          <span typography variant="body2"> No image </span>
        </ng-template>
      </div>
      <div class="flex-grow-1">
        <div class="d-flex justify-content-between">
          <p typography variant="h5" color="ink" class="mt-8">
            {{ property.headline }}
          </p>
          <div *ngIf="property.id" class="d-flex align-items-center">
            <a
              button
              [routerLink]="getPropertyLink(property.id)"
              variant="inline"
              class="ml-8"
              color="primary"
              target="_blank"
              >Detail</a
            >
          </div>
        </div>
        <p
          *ngIf="
            getPropertyTypeLabel(property.propertyType)
              | async as propertyTypeLabel
          "
          typography
          variant="body2"
          color="ink"
          class="my-8"
        >
          {{ propertyTypeLabel }} in {{ property.address?.city }},
          {{ property.address?.state }}
        </p>
      </div>
    </div>
    <div *ngIf="!isPropertyOwner" class="mt-24">
      <a
        button
        variant="contained"
        color="secondary"
        [href]="getDirectionsUrl(property.address)"
        rel="noopener"
        target="_blank"
        class="d-flex justify-content-center mt-8 text-align-center"
        >Get directions</a
      >
    </div>`,
  styles: `
    .property-image {
      border-radius: 8px;
      overflow: hidden;
      width: 110px;
      height: 75px;

      img {
        border-radius: 8px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
        -webkit-user-drag: none;
      }
    }
  `,
})
export class PropertyInfoComponent extends AbstractComponent {
  @Input()
  property: Property;
  @Input()
  isPropertyOwner: Boolean;

  constructor(
    private readonly propertyService: PropertyService,
    private readonly mapLinkService: MapLinkService,
  ) {
    super();
  }

  getPropertyTypeLabel(propertyTypeKey: string) {
    return this.propertyService.getPropertyTypeLabel(propertyTypeKey);
  }

  getPropertyLink(id: string) {
    return `/properties/${id}`;
  }

  getDirectionsUrl(propertyAddress: PropertyAddress) {
    return this.mapLinkService.getDirectionsUrl(propertyAddress);
  }
}
