import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BookingCanceledMessage } from 'src/app/modules/chat/models/booking-message.model';
import { Booking } from 'src/app/shared/models/booking';

@Component({
  selector: 'app-chat-message-booking-canceled',
  templateUrl: './chat-message-booking-canceled.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageBookingCanceledComponent {
  @Input() conversationId: string;
  @Input() message: BookingCanceledMessage;

  booking: Booking;
  readonly TEXT_MESSAGE_PREFIX = 'Reason for cancellation: ';

  @Input() set bookings(value: Booking[]) {
    this.booking = value.find(v => v.id === this.message.payload.bookingId);
  }

  get isTooltipEnabled() {
    return (
      this.message.payload.canceledReason !== undefined ||
      this.message.payload.refund?.amount > 0
    );
  }
}
