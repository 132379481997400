export class StringUtils {
  static ellipsis(value: string, maxLength: number): string {
    if (typeof value !== 'string') {
      return value;
    }
    if (value.length > maxLength) {
      return value.substring(0, maxLength) + '…';
    }
    return value;
  }

  static getAvatarText(text: string) {
    if (!text) {
      return '';
    }
    return text
      .split(' ')
      .map(a => a.charAt(0)?.toUpperCase())
      .filter(a => !!a)
      .slice(0, 2)
      .join('');
  }
}
