<dialog-wrapper width="md" [position]="ls ? 'center' : 'bottom'">
  <dialog-header (dialogClose)="close()" title="Privacy Policy"></dialog-header>
  <dialog-content>
    <app-privacy-policy-content [showEffectiveDate]="true"></app-privacy-policy-content>
  </dialog-content>
  <dialog-actions>
    <div class="row">
      <div class="col-6">
        <button button variant="contained" color="secondary" (click)="close()" class="w-100">Back</button>
      </div>
      <div class="col-6">
          <button button variant="contained" color="primary" (click)="agree()" class="w-100">{{ data?.agreeButtonText ? data.agreeButtonText : 'I agree' }}</button>
      </div>
    </div>
  </dialog-actions>
</dialog-wrapper>
