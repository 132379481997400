export enum Currency {
  USD = 'usd',
  CAD = 'cad',
  REWARD_POINT = 'reward_point',
}

export class VirtualAccountBalance {
  availableBalance: number;
  currency: Currency;
}

export class VirtualAccount extends VirtualAccountBalance {
  futurePayouts: number;
}
