import {
  Directive,
  Host,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  SkipSelf,
} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  UntypedFormControl,
} from '@angular/forms';
import { BehaviorSubject, merge, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbstractComponent } from '../../../core/components/abstract/abstract.component';
import { getErrorMessage } from '../../../utils/error.util';

/**
 * Form control base class & control value accessor. Inheriting the control value accessor login
 * in child classes is optional. If desired, pass control's parent container to super call.
 * @author Libor Staněk, Jan Helbich
 */

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class ParentComponent
  extends AbstractComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  /** Form control reference. */
  @Input() formControl: UntypedFormControl;
  /** Form control's name - used with control value accessor. */
  @Input() formControlName?: string;
  @Input() fullWidth: boolean;
  /** Input's label. */
  @Input() label: string = undefined;
  /** Enabled / disabled input handling. */
  readonly disabled$ = new BehaviorSubject<boolean>(false);
  /** Inner form value. */
  readonly model$ = new BehaviorSubject<any>(undefined);
  /** First model value for registerOnChange value update. */
  firstValue = undefined;
  isFirstValue = false;
  /** Touched notification callback. */
  onTouchedCallback!: () => void;
  /** Value change notification callback. */
  onChangeCallback!: (_: any) => void;
  /** If call onChangeCallback on writeValue change */
  onChangeCallbackActive = true;
  /** Errors observable. */
  errorMessage$: Observable<string>;

  protected constructor(
    @Host()
    @SkipSelf()
    @Optional()
    private readonly parentControlContainer?: ControlContainer,
  ) {
    super();
  }

  /**
   * Initialize control (either input or by name).
   * Initialize errors notification observable.
   */
  ngOnInit(): void {
    if (!this.formControl) {
      if (!!this.parentControlContainer && !!this.formControlName) {
        this.formControl = this.parentControlContainer.control.get(
          this.formControlName,
        ) as UntypedFormControl;
      } else {
        throw new Error(`Form control ${this.formControlName} is not set.`);
      }
    }
    this.errorMessage$ = merge(of([]), this.formControl.statusChanges).pipe(
      this.untilDestroyed(),
      map(() => {
        return getErrorMessage(this.formControl, { label: this.label });
      }),
    );
  }

  /** Observables cleanup. */
  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.model$.complete();
    this.disabled$.complete();
  }

  /** Register value change callback. */
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
    // if value changed before registerOnChange, set value
    if (this.getModelValue() !== this.firstValue) {
      this.onChangeCallback(this.getModelValue());
    }
    delete this.firstValue;
  }

  /** Register touch callback. */
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  /** Handle enabled / disabled state chenge. */
  setDisabledState(isDisabled: boolean): void {
    if (this.disabled$.getValue() !== isDisabled) {
      this.disabled$.next(isDisabled);
    }
  }

  /** Write out current control's value. */
  writeValue(value: any, options: { force?: boolean } = {}): void {
    if (!this.isFirstValue) {
      this.isFirstValue = true;
      this.firstValue = value;
    }
    if (this.model$.getValue() !== value || options.force) {
      this.model$.next(value);
      if (this.onChangeCallbackActive && this.onChangeCallback) {
        this.onChangeCallback(this.getModelValue());
      }
    }
  }

  /** Model value accessor. Overwritable in child classes. */
  getModelValue(): any {
    return this.model$.getValue();
  }
}
