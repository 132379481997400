import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Message, MessageType } from '../../modules/chat/models/message.model';
import { PagedModel } from '../../shared/models/pagination';
import { plainToParams } from '../util/api.util';
import { AbstractApi } from './abstract.api';
import {
  SendMessage,
  TextMessage,
} from 'src/app/modules/chat/models/text-message.model';
import { Conversation } from 'src/app/modules/chat/models/conversation.model';

export interface GetConversationsOptions {
  page?: number;
  size?: number;
  archived?: boolean;
  unread?: boolean;
}

export interface GetConversationsOption {
  conversationId: string;
}

export interface GetMessagesOptions {
  conversationId: string;
  page?: number;
  size?: number;
  sentBefore?: Date;
  sentAfter?: Date;
}

export interface SendTextMessageOptions {
  type: MessageType.TEXT | MessageType.INQUIRY;
  conversationId: string;
  text: string;
  propertyId?: string;
  checkIn?: Date;
  checkOut?: Date;
}

export interface ArchiveConversationOptions {
  conversationId: string;
}

export interface ReadConversationOptions {
  conversationId: string;
}

@Injectable({ providedIn: 'root' })
export class ChatApi extends AbstractApi {
  constructor(injector: Injector) {
    super(injector);
    this.base = '/chat/api/v1/conversations';
  }

  getConversations(
    options: GetConversationsOptions,
  ): Observable<PagedModel<Conversation>> {
    return this.http.get<PagedModel<Conversation>>(`${this.base}`, {
      params: plainToParams(options),
    });
  }

  getConversation(options: GetConversationsOption): Observable<Conversation> {
    return this.http.get<Conversation>(
      `${this.base}/${options.conversationId}`,
    );
  }

  archiveConversation(
    options: ArchiveConversationOptions,
  ): Observable<Conversation> {
    return this.http.post<Conversation>(
      `${this.base}/${options.conversationId}/archive`,
      {},
    );
  }

  readConversation(options: ReadConversationOptions): Observable<Conversation> {
    return this.http.post<Conversation>(
      `${this.base}/${options.conversationId}/read`,
      {},
    );
  }

  getMessages(options: GetMessagesOptions): Observable<PagedModel<Message>> {
    return this.http.get<PagedModel<Message>>(
      `${this.base}/${options.conversationId}/messages`,
      { params: plainToParams(options) },
    );
  }

  sendTextMessage(options: SendTextMessageOptions): Observable<TextMessage> {
    const body: SendMessage = {
      type: options.type,
      body: {
        text: options.text,
        propertyId: options.propertyId,
        checkIn: options.checkIn,
        checkOut: options.checkOut,
      },
    };

    return this.http.post<TextMessage>(
      `${this.base}/${options.conversationId}/messages`,
      body,
    );
  }
}
